import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector, useFreshRef, usePrevious } from '../hooks'
import { Platform, Switch, TextInput, View, KeyboardAvoidingView, ScrollView, ActivityIndicator } from 'react-native'
import { ListItem, Item as ItemList, TextList, Right, PickerCell, SelectCell, Separator } from '../components/List'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { ChannelConfigFormData, ScreenProps } from '#app/types'
import {
  channelTypeList,
  selectChannelForCreateOrUpdate,
  selectChannelsForThreewayPicker,
  selectChannelTypesForPicker,
  createChannel,
  updateChannel,
  deleteChannel,
  selectChannelName,
  ChannelType,
  getChannelKind
} from '#app/channel'
import { selectSlaveChannelsForPicker } from '#app/slave'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import { selectScenesForPicker } from '#app/scene'
import DeleteButton from '#app/components/Form/DeleteButton'

const CreateOrUpdateChannel = ({ navigation, route: { params } }: ScreenProps<'CreateChannel'> | ScreenProps<'ChannelConfig'>) => {
  const theme = useAppTheme()

  const channelTypes = useAppSelector(selectChannelTypesForPicker)
  const remoteScenes = useAppSelector(selectScenesForPicker)
  const threewayChannels = useAppSelector(selectChannelsForThreewayPicker)
  const { id, defaultValues } = useAppSelector(state => selectChannelForCreateOrUpdate(state, params))
  const form = useForm<ChannelConfigFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const { control, handleSubmit, formState: { isDirty }, watch } = form
  const slaveId = watch('channel.slave_id')
  const slaveChannels = useAppSelector(state => selectSlaveChannelsForPicker(state, { id: String(slaveId) }))

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<ChannelConfigFormData>>((data) => {
    dispatch(id === ''
      ? createChannel({ data })
      : updateChannel({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loaderId = id === ''
    ? 'channel/create'
    : 'channel/update'
  const loader = useAppSelector(state => selectLoaderById(state, { id: loaderId }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.getParent()?.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : isDirty
          ? <HeaderButtons>
            <Item
              disabled={!isDirty}
              title={t`Save`}
              iconName="save"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>
          : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  const type = watch('channel.type')
  const formRef = useFreshRef(form)
  const lastTypeRef = useFreshRef(usePrevious(type))
  const twName = useAppSelector(state => selectChannelName(state, { id: String(watch('channel.channel_id')) }))
  useEffect(() => {
    const { resetField, setValue } = formRef.current
    const lastType = lastTypeRef.current
    if (type !== lastType) {
      if (lastType === 'remote' || lastType === 'threeway') {
        resetField('channel.name')
      }
      if (lastType === 'remote') {
        setValue('channel.scene_up_id', null)
        setValue('channel.scene_down_id', null)
      } else if (lastType === 'threeway') {
        setValue('channel.channel_id', null)
      }
    }
    if (type === 'threeway') {
      setValue('channel.name', (t`ThreeWay` + (twName !== '' ? ` (${twName})` : '')))
    } else if (type === 'remote') {
      setValue('channel.name', t`Remote Button`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, twName])

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      <KeyboardAvoidingView enabled={(Platform.OS === 'ios') || (Platform.OS === 'android')}>
        {showError && <Error>{message}</Error>}

        <View style={theme.form.area}>
          <Controller
            control={control}
            name="channel.name"
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <TextInput
                ref={ref}
                editable={!['remote', 'threeway'].includes(type)}
                style={theme.form.input}
                placeholder={t`Name`}
                placeholderTextColor={theme.formForm.placeholderTextColor}
                autoCapitalize="words"
                autoCorrect={false}
                onChangeText={onChange}
                value={value}
                onBlur={onBlur}
              />
            }
          />
        </View>

        <Separator text=""/>

        <Controller
          control={control}
          name="channel.channel"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <SelectCell
              ref={ref}
              onBlur={onBlur}
              selectedValue={value}
              onValueChange={onChange}
              title={t`Channel`}
              items={slaveChannels}
            />
          }
        />

        <Controller
          control={control}
          name="channel.type"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <SelectCell
              ref={ref}
              onBlur={onBlur}
              selectedValue={value}
              onValueChange={onChange}
              title={t`Type`}
              items={channelTypes}
            />
          }
        />

        {type === 'threeway' &&
            <Controller
              control={control}
              name="channel.channel_id"
              render={({ field: { ref, onChange, onBlur, value } }) =>
                <PickerCell
                  id="threeway"
                  title={t`Device to activate`}
                  items={threewayChannels}
                  mode="single"
                  value={String(value)}
                  onChange={(v) => onChange(Number(v))}
                  onBlur={onBlur}
                />
              }
            />
          }
          {type === 'remote' && (<>
            <Controller
              control={control}
              name="channel.scene_up_id"
              render={({ field: { ref, onChange, onBlur, value } }) =>
                <PickerCell
                  id="scene_up_id"
                  title={t`Up Scene`}
                  items={remoteScenes}
                  mode="single"
                  value={value != null ? String(value) : null}
                  onChange={(v) => onChange(Number(v))}
                  onBlur={onBlur}
                />
              }
            />
            <Controller
              control={control}
              name="channel.scene_down_id"
              render={({ field: { ref, onChange, onBlur, value } }) =>
                <PickerCell
                  id="scene_down_id"
                  title={t`Down Scene`}
                  items={remoteScenes}
                  mode="single"
                  value={value != null ? String(value) : null}
                  onChange={(v) => onChange(Number(v))}
                  onBlur={onBlur}
                />
              }
            />
          </>)}

        {getChannelKind(type) === 'actionable' &&
          <ListItem>
            <ItemList>
              <TextList text={t`Confirm action?`}/>
              <Right>
                <Controller
                  control={control}
                  name="channel.config.confirm"
                  render={({ field: { ref, onChange, onBlur, value } }) =>
                    <Switch
                      ref={ref}
                      onValueChange={onChange}
                      value={value}
                    />
                  }
                />
              </Right>
            </ItemList>
          </ListItem>
        }
        <Separator text=""/>
        {id !== '' &&
          <View style={theme.form.area}>
            <DeleteButton
              id={id}
              title={t`Delete Device`}
              loader="channel/delete"
              creator={deleteChannel}
            />
          </View>
        }
      </KeyboardAvoidingView>
    </ScrollView>
  )
}

export default CreateOrUpdateChannel

const schema: Yup.SchemaOf<ChannelConfigFormData> = Yup.object({
  channel: Yup.object({
    name: Yup.string().required(),
    type: Yup.mixed()
      .oneOf(channelTypeList)
      .required(),
    slave_id: Yup.number().required(),
    channel: Yup.number().required(),
    channel_id: Yup.mixed()
      .when('channel.type', {
        is: (type: ChannelType) => type === 'threeway',
        then: Yup.number().required(),
        otherwise: Yup.number().nullable().defined()
      }),
    scene_up_id: Yup.number().nullable().defined(),
    scene_down_id: Yup.number().nullable().defined(),
    config: Yup.object({
      confirm: Yup.boolean()
    })
  }).required()
}).required()
