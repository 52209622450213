import React, { useCallback, useEffect, useState } from 'react'
import { Platform, Text, TouchableOpacity, View, StyleSheet, Dimensions, ActivityIndicator } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { get } from 'lodash'
import BlurView from './BlurView'
import { gettext, t } from 'ttag'
import { alert } from '../components/Utils'
import { useAppTheme } from '#app/theme'

function calculateButtonSize () {
  const screenWidth = Dimensions.get('window').width
  const size = (screenWidth / 3) - 22
  if (size > 110) return 110
  return size
}

function renderExclamation (bgColor: any) {
  return (
    <MaterialCommunityIcons
      name="information"
      size={20}
      color={bgColor}
      style={{ position: 'absolute', top: 12, right: 12 }}
    />
  )
}

function getConnectionStatus (status: any) {
  if (status === 'offline') {
    return renderExclamation('rgba(240, 80, 80, 1)')
  } else if (status === 'bad') {
    return renderExclamation('#f59b10')
  } else {
    return null
  }
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
  onLongPress?: (...args: any[]) => any
  icon?: React.ReactNode
  disable?: boolean
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof GenericButton.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type 'Props'.
export default function GenericButton ({ onPress, onLongPress, icon, text, on, offline, status, disable, iconOnColor, textColor, removeButton, removeButtonPress, connectionStatus, loading, confirm, hide, hideButtonPress }: Props) {
  let timer: any
  const theme = useAppTheme()
  const [loading2, setLoading2] = useState(true)

  const currentThemeStyle = {
    text: {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      color: theme.genericButton.textColor[on ? 'on' : 'off']
    }
  }

  useEffect(() => {
    clearTimeout(timer)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (loading) {
      setLoading2(true)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timer = setTimeout(() => { setLoading2(false) }, 10000)
    } else {
      setLoading2(false)
    }
  }, [loading])

  const _onPress = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!hide) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (confirm) {
        alert(
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${text}`,
          gettext('Toggle device?'),
          [
            { text: gettext('No'), style: 'destructive' },
            { text: gettext('Yes'), onPress: onPress }
          ],
          { cancelable: false }
        )
      } else {
        onPress()
      }
    }
  }

  const getIconColor = useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!on) { return theme.genericButton.iconColor.off }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (iconOnColor) { return iconOnColor }

    return theme.genericButton.iconColor.on
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [on, theme])

  const getStatusText = () => {
    if (connectionStatus === 'offline') {
      return (
        <Text style={[currentThemeStyle.text, {
          fontWeight: 'bold',
          fontSize: 12,
          color: '#ca6566'
        }]}
        >
          {t`Unavailable`}
        </Text>
      )
    }
    return <Text style={textColor}>{status}</Text>
  }

  const buttonViewStyle = Platform.select({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    android: on ? theme.genericButton.androidButton.on : theme.genericButton.androidButton.off,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    default: on ? styles.backgroundOn : styles.backgroundOff
  })

  return (
    (<View style={{ position: 'relative' }}>
      <TouchableOpacity disabled={disable || hide} onPress={_onPress} onLongPress={onLongPress} delayPressIn={50}>
        <BlurView
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          vibrant={!on}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          tint={get(theme.genericButton.blurView[on ? 'on' : 'off'], 'tint')}
          intensity={theme.blurView.intensity}
          style={[styles.container, buttonViewStyle, { ...theme.border }]}
        >

          <View style={styles.top}>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <MaterialCommunityIcons name={icon} size={22} color={getIconColor()} style={styles.icon} />
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {connectionStatus && !loading &&
              getConnectionStatus(connectionStatus)}
            {loading2 && (
              <ActivityIndicator style={{ position: 'absolute', top: 12, right: 12 }} color="black" size="small" />
            )}
          </View>

          <View style={styles.bottom}>
            <Text style={[styles.text, currentThemeStyle.text]} numberOfLines={2} ellipsizeMode="tail">{text}</Text>
            <Text style={[styles.status, currentThemeStyle.text]}>
              {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2. */}
              {getStatusText(status, connectionStatus)}
            </Text>
          </View>

        </BlurView>
      </TouchableOpacity>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {removeButton && !hide &&
        <TouchableOpacity style={[styles.removeButton, { backgroundColor: theme.colors.danger }]} onPress={removeButtonPress}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <MaterialCommunityIcons name="minus" size={22} color="#FFFFFF" style="" />
        </TouchableOpacity>}

      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {hide &&
        <TouchableOpacity style={[styles.removeButton, { backgroundColor: theme.colors.danger }]} onPress={hideButtonPress}>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <MaterialCommunityIcons name="minus" size={22} color="#FFFFFF" style="" />
        </TouchableOpacity>}
    </View>)
  )
}

GenericButton.defaultProps = {
  children: null,
  disable: false,
  onPress: () => { },
  onLongPress: () => { }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: calculateButtonSize(),
    height: calculateButtonSize(),
    marginLeft: 6,
    marginRight: 6,
    marginTop: 6,
    marginBottom: 6,
    borderRadius: 15
  },
  backgroundOn: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    opacity: 1
  },
  backgroundOff: {
    // opacity: 0.6
  },
  top: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: 'space-between'
  },
  icon: {
    position: 'absolute',
    top: 12,
    left: 12
  },
  bottom: {
    flex: 3,
    padding: 12
  },
  text: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  status: {
    marginTop: 0,
    fontSize: 14
  },
  removeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: 32,
    height: 32,
    borderRadius: 16,
    backgroundColor: '#000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
})
