import { put, call, select } from 'redux-saga/effects'
import ConsumptionActions from '../redux/consumption'
import WsActions from '../redux/ws'
import moment from 'moment'
import { get } from 'lodash'
import { selectApi } from '#app/api'

export function * getAllConsumptionHourly (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  const { interval, startDate, endDate, typeReport } = action

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getAllConsumptionHourly, interval, startDate, endDate)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(ConsumptionActions.consumptionGetAllHourlyRequestSuccess({ ...response.data, typeReport: typeReport }))
    } else {
      yield put(ConsumptionActions.consumptionGetAllHourlyRequestFailure({ typeReport: typeReport }))
    }
  } catch (e) {
    yield put(ConsumptionActions.consumptionGetAllHourlyRequestFailure({ typeReport: typeReport }))
  }
}

export function * getThreePhaseConsumption (action: any) {
  const { payload } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const threePhaseId = yield select(state => get(state, 'consumption.consumptionThreePhase.selectedThreePhase.slave_id'))

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!threePhaseId) { return }

  let iniDate = ''
  let endDate = ''

  if (payload.period === 'hour') {
    console.log('payload.date', payload.date)

    iniDate = moment(payload.date).startOf('day').format('YYYY-MM-DD 03:00:00')
    endDate = moment(payload.date).endOf('day').add(1, 'days').format('YYYY-MM-DD 02:59:59')
  } else if (payload.period === 'day') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    iniDate = moment(`${payload.year}-${payload.month}`).startOf('month').format('YYYY-MM-DD 03:00:00')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    endDate = moment(`${payload.year}-${payload.month}`).endOf('month').add(1, 'days').format('YYYY-MM-DD 02:59:59')
  } else if (payload.period === 'month') {
    iniDate = moment(payload.year).startOf('year').format('YYYY-MM-DD 03:00:00')
    endDate = moment(payload.year).endOf('year').add(1, 'days').format('YYYY-MM-DD 02:59:59')
  }

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getThreePhaseEnergy, threePhaseId, payload.period, iniDate, endDate)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(ConsumptionActions.getThreePhaseConsumptionRequestSuccess(response.data))
    } else {
      yield put(ConsumptionActions.getThreePhaseConsumptionRequestFailure())
    }
  } catch (e) {
    yield put(ConsumptionActions.getThreePhaseConsumptionRequestFailure())
  }
}

export function * unsubscribeConsumption (action: any) {
  const { payload } = action

  yield put(WsActions.wsConnectionSendMessage({
    type: 'consumption',
    command: 'unsubscribe',
    scope: 'ambient',
    id: payload
  }))
}

export function * subscribeConsumption (action: any) {
  const { payload } = action

  yield put(WsActions.wsConnectionSendMessage({
    type: 'consumption',
    command: 'subscribe',
    scope: 'ambient',
    id: payload
  }))
}
