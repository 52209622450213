import React, { useCallback, useEffect, useState } from 'react'
import {
  Text,
  StyleSheet,
  Dimensions,
  ImageBackground,
  View,
  SectionList,
  TextInput,
  ActivityIndicator,
  TouchableOpacity,
  Platform,
  Pressable
} from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { map, get, flatten, filter, groupBy, cloneDeep, remove, orderBy } from 'lodash'
import { useFocusEffect, useTheme } from '@react-navigation/native'
import { useActionSheet } from '@expo/react-native-action-sheet'
import RemoteWidget from '../components/Switch/RemoteWidget'
import TemperatureSensor from '../components/Remote/TemperatureSensor'
import LightSwitch from '../components/Switch/LightSwitch'
import PulseUp from '../components/Switch/PulseUp'
import PulseDown from '../components/Switch/PulseDown'
import Outlet from '../components/Switch/Outlet'
import Energy from '../components/Switch/Energy'
import FlowSensor from '../components/Switch/FlowSensor'
import DoorSensor from '../components/Switch/DoorSensor'
import SmokeSensor from '../components/Switch/SmokeSensor'
import PresenceSensor from '../components/Switch/PresenceSensor'
import LightSensor from '../components/Switch/LightSensor'
import WaterLevel from '../components/Switch/WaterLevel'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import Icon from '../components/Icon'
import WsActions from '../redux/ws'
import ConsumptionActions from '../redux/consumption'
import SceneBox from '../components/Scenes/SceneBox'
import { t, gettext } from 'ttag'
import { getFavoriteData } from '../redux/utils'
import { useAppTheme } from '#app/theme'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { selectAmbientByIdDefaulting, selectAmbientScenes, updateAmbient } from '#app/ambient'
import { selectCan } from '#app/centraluser'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import { ScreenProps } from '#app/types'
import SegmentedControl from '@react-native-segmented-control/segmented-control'
import { selectCurrentCentralId } from '#app/central'
import { patchCentralAmbient, selectCentralAmbientById } from '#app/centralambient'

function calculateButtonSize () {
  const screenWidth = Dimensions.get('window').width
  const size = (screenWidth / 3) - 22
  if (size > 110) { return 110 }
  return size
}

const AmbientDetail = ({
  navigation,
  route: {
    params: {
      editing = false,
      id: ambientId
    }
  }
}: ScreenProps<'AmbientDetail'>) => {
  const SECTION = {
    energy_sensor: gettext('Energy Sensor'),
    temperature_sensor: gettext('Temperature Sensor'),
    lamp: gettext('Actionable'),
    plug: gettext('Actionable'),
    smoke_sensor: gettext('Sensors'),
    door_sensor: gettext('Sensors'),
    flow_sensor: gettext('Sensors'),
    light_sensor: gettext('Sensors'),
    presence_sensor: gettext('Sensors'),
    infrared: gettext('Remotes'),
    edit_slave: gettext('Products'),
    hidden_devices: gettext('Hidden Devices'),
    dimmer: gettext('Actionable'),
    remote_button: gettext('Actionable'),
    both_phases: gettext('Actionable'),
    pulsed_actuation: gettext('Actionable'),
    no_phase_actuation: gettext('Actionable'),
    follows_phase: gettext('Actionable'),
    negates_phase: gettext('Actionable'),
    no_action: gettext('Actionable'),
    count_pulses_on_variation: gettext('Actionable'),
    count_pulses_on_no_phase: gettext('Actionable'),
    pulse_up: gettext('Actionable'),
    pulse_down: gettext('Actionable'),
    water_level: gettext('Sensors'),
    water_level_inverted: gettext('Sensors'),
    remote: gettext('Actionable'),
    inverted_actionable: gettext('Actionable')
  }

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'ambient/update' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.setParams({ editing: false })
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  const dispatch = useAppDispatch()
  const { allSlaves } = useAppSelector(state => state.slaves)
  const theme = useAppTheme()
  const navTheme = useTheme()
  const { filters, filterChanged } = useAppSelector(state => state.ambients)
  const ambient = useAppSelector(state => selectAmbientByIdDefaulting(state, { id: ambientId }))
  const scenes = useAppSelector(state => selectAmbientScenes(state, { id: ambientId }))
  const favorites = useAppSelector(state => state.favorites.favorites)
  const can = useAppSelector(selectCan)
  const canUse = can('use')
  const canEdit = can('edit')
  const centralId = useAppSelector(selectCurrentCentralId)
  const selectedAmbientView = useAppSelector(state => selectCentralAmbientById(state, { id: ambientId }).groupDevicesBy)
  const [selectedIndex, setSelectedIndex] = useState(selectedAmbientView !== 'kind' ? 1 : 0)
  useEffect(() => {
    dispatch(patchCentralAmbient({
      [centralId]: {
        [ambientId]: {
          groupDevicesBy: selectedIndex !== 0
            ? 'product'
            : 'kind'
        }
      }
    }))
  }, [dispatch, centralId, ambientId, selectedIndex])

  const { showActionSheetWithOptions } = useActionSheet()

  const [ambientModel, setAmbientModel] = useState<any>({})
  const [data, setData] = useState([])
  const backgroundImage = ambient.image != null ? { uri: ambient.image } : theme.backgroundImage

  const insets = useSafeAreaInsets()

  useEffect(() => { navigation.setOptions({ title: ambient.name }) })

  /* Subscribe to ambient consumption */
  useFocusEffect(useCallback(() => {
    dispatch(ConsumptionActions.consumptionSubscribeAmbientConsumption(ambientId))

    return () => {
      dispatch(ConsumptionActions.consumptionUnsubscribeAmbientConsumption(ambientId))
    }
  }, [dispatch, ambientId]))

  const validateMinMaxFilterEnergyMeters = (value: any) => {
    return (
      filters.consumption.greatherThan.value === '' ||
      (filters.consumption.greatherThan.value !== '' && value >= parseInt(filters.consumption.greatherThan.value))
    ) &&
      (
        filters.consumption.lessThan.value === '' ||
        (filters.consumption.lessThan.value !== '' && value <= parseInt(filters.consumption.lessThan.value))
      )
  }

  const showDevice = (device: any, type: any) => {
    const list = get(ambient, 'config.hide_devices_v1')

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (list && list.length > 0) {
      let exists = {}
      if (type === 'channel') {
        exists = list.find((d: any) => d.channel === device.id)
      } else if (type === 'slave-energy') {
        exists = list.find((d: any) => d.slave_id === device.slave_id && d.is_slave && d.energy)
      } else if (type === 'slave-temperature') {
        exists = list.find((d: any) => d.slave_id === device.slave_id && d.is_slave && d.temperature)
      } else if (type === 'device') {
        exists = list.find((d: any) => d.slave_id === device.slave_id && device.id === d.remote_id && d.is_device)
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return !exists
    } else {
      return true
    }
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => never[] | undefined' is no... Remove this comment to see the full error message
  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!allSlaves) { return [] }

    const ambientSlaves = get(ambientModel, 'slaves')

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!ambientSlaves) { return }

    /* If we are in edit mode, we should return only
     * slaves, the title should also be different. */

    const ambientDevices = filter(allSlaves, (slave) => {
      if (!editing) {
        return ambientSlaves.indexOf(slave.slave_id) > -1
      } else {
        return ambientSlaves.indexOf(slave.slave_id) > -1 && (
          slave.is_slave
        )
      }
    })

    const channels = map(filter(ambientDevices, (device) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return device.is_channel && showDevice(device, 'channel') && (
        (device.type === 'lamp' && get(filters, 'category.lamp.value') && (
          (device.output === 100 && get(filters, 'stateLight.on.value')) ||
          (device.output === 0 && get(filters, 'stateLight.off.value'))
        )) ||
        (device.type === 'dimmer' && get(filters, 'category.dimmer.value')) ||
        (device.type === 'plug' && get(filters, 'category.plug.value') && (
          (device.output === 100 && get(filters, 'stateLight.on.value')) ||
          (device.output === 0 && get(filters, 'stateLight.off.value'))
        )) ||
        ((device.type === 'inverted_actionable' && get(filters, 'category.plug.value')) && (
          (device.output === 0 && get(filters, 'stateLight.on.value')) ||
          (device.output === 100 && get(filters, 'stateLight.off.value'))
        )) ||
        // (device.type === 'remote' && get(filters, 'category.remote.value')) ||
        (device.type === 'pulse_up' && get(filters, 'category.pulse_up.value')) ||
        (device.type === 'pulse_down' && get(filters, 'category.pulse_down.value')) ||
        (device.type === 'flow_sensor' && get(filters, 'category.flow_sensor.value')) ||
        (device.type === 'light_sensor' && get(filters, 'category.light_sensor.value') && (
          (device.input === 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input !== 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (device.type === 'presence_sensor' && get(filters, 'category.presence_sensor.value') && (
          (device.input > 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input === 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (device.type === 'door_sensor' && get(filters, 'category.door_sensor.value') && (
          (device.input === 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input !== 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (device.type === 'water_level' && get(filters, 'category.water_level.value') && (
          (device.input > 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input === 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (device.type === 'water_level_inverted' && get(filters, 'category.water_level.value') && (
          (device.input === 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input > 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (device.type === 'smoke_sensor' && get(filters, 'category.smoke_sensor.value') && (
          (device.input > 0 && get(filters, 'stateSensor.detected.value')) ||
          (device.input === 0 && get(filters, 'stateSensor.not_detected.value'))
        )) ||
        (['both_phases',
          'pulsed_actuation',
          'no_phase_actuation',
          'follows_phase',
          'negates_phase',
          'no_action',
          'count_pulses_on_variation',
          'count_pulses_on_no_phase'].includes(device.type) && get(filters, 'category.advanced.value'))
      )
    }), (device) => {
      return { ...device, group: device.type }
    })

    const infrareds = map(filter(ambientDevices, (device) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return device.is_device && showDevice(device, 'device') && (
        (device.category === 'ac' && filters.remotes.ac.value) ||
        (device.category === 'tv' && filters.remotes.tv.value) ||
        (device.category === 'other' && filters.remotes.other.value)
      )
    }), (device) => {
      return { ...device, group: 'infrared' }
    })

    const energy = map(filter(ambientDevices, (device) => {
      return showDevice(device, 'slave-energy') && (
        (device.type === 'outlet' &&
          filters.energyMeters.others.value &&
          validateMinMaxFilterEnergyMeters(device.lastConsumption)) ||
        (device.type === 'light_switch' &&
          filters.energyMeters.others.value &&
          validateMinMaxFilterEnergyMeters(device.lastConsumption)) ||
        (device.type === 'three_phase_sensor' &&
          filters.energyMeters.three_phase_sensor.value &&
          validateMinMaxFilterEnergyMeters(device.lastConsumption))
      )
    }), (device) => {
      return { ...device, temperature: undefined, group: 'energy_sensor' }
    })

    const temperature = map(filter(ambientDevices, (device) => {
      return showDevice(device, 'slave-temperature') && (
        (device.type === 'infrared' || device.type === 'outlet') &&
        device.temperature &&
        device.temperature !== 255 &&
        filters.category.temperature_sensor.value
      )
    }), (device) => {
      return { ...device, group: 'temperature_sensor' }
    })

    let devices
    if (editing) {
      devices = map(ambientDevices, (device) => {
        return { ...device, group: 'edit_slave' }
      })

      const hiddenDevices = map(get(ambientModel, 'config.hide_devices_v1'), (device) => {
        let exists = {}
        const allSlavesClone = cloneDeep(allSlaves)

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (device.is_channel) {
          exists = allSlavesClone.find((d: any) => d.id === device.channel && d.is_channel)
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        } else if (device.is_slave) {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          if (device.temperature) {
            exists = allSlavesClone.find((d: any) => d.slave_id === device.slave_id && d.is_slave && ['outlet', 'infrared'].includes(d.type) && d.temperature)
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          } else if (device.energy) {
            exists = allSlavesClone.find((d: any) => d.slave_id === device.slave_id && d.is_slave && ['outlet', 'light_switch', 'three_phase_sensor'].includes(d.type))
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (exists) {
              (exists as any).temperature = undefined
            }
          }
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        } else if (device.is_device) {
          exists = allSlavesClone.find((d: any) => d.slave_id === device.slave_id && device.remote_id === d.id && d.is_device)
        }

        return { ...exists, hide: true, group: 'hidden_devices' }
      })

      devices.push(...hiddenDevices)
      devices.push({ type: 'edit_add_button', group: 'edit_slave' })
    } else {
      devices = flatten([channels, infrareds, energy, temperature])
    }

    let deviceGroups: Record<string, any[]> = {}
    let newData: any[] = []

    if (selectedAmbientView === 'product') {
      if (editing) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        deviceGroups = groupBy(devices, (device) => SECTION[device.group])
        newData = map(deviceGroups, (value, index) => {
          return { title: index, data: value }
        })
      } else {
        deviceGroups = groupBy(devices, (device) => device.slave_id)
        newData = map(deviceGroups, (value, index) => {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          const name = allSlaves.find((s: any) => s.is_slave && s.slave_id === value[0].slave_id).name
          return { title: name, data: value }
        })
      }
    } else { // kind
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      deviceGroups = groupBy(devices, (device) => SECTION[device.group])
      newData = map(deviceGroups, (value, index) => {
        return {
          title: index,
          data: orderBy(value, ['config.order'], ['asc'])
        }
      })
    }

    if (scenes.length === 0 || editing) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: any; data: any[]; }[] |... Remove this comment to see the full error message
      setData(newData)
    } else {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'never'.
      setData([{ title: gettext('Scenes'), data: [scenes], renderItem: renderScenes }, ...newData])
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [allSlaves, ambientModel, editing, filters, selectedAmbientView])

  useEffect(() => {
    setAmbientModel({ ...ambient })
  }, [ambient])

  const removeDevice = (slaveId: any) => {
    // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
    setAmbientModel({ ...ambientModel, slaves: ambientModel.slaves.filter(s => s !== slaveId) })
  }

  const toggleVisibility = (device: any) => {
    const hiddenList = cloneDeep(get(ambientModel, 'config.hide_devices_v1'))

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device.is_channel) {
      remove(hiddenList, (d) => ((d as any).channel === device.id))
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_slave) {
      if (device.temperature === undefined) {
        remove(hiddenList, (d) => ((d as any).slave_id === device.slave_id && (d as any).is_slave && (d as any).energy))
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      } else if (device.temperature) {
        remove(hiddenList, (d) => ((d as any).slave_id === device.slave_id && (d as any).is_slave && (d as any).temperature))
      } else {
        remove(hiddenList, (d) => ((d as any).slave_id === device.slave_id && (d as any).is_slave))
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_device) {
      remove(hiddenList, (d) => ((d as any).slave_id === device.slave_id && device.id === (d as any).remote_id && (d as any).is_device))
    }

    setAmbientModel({ ...ambientModel, config: { hide_devices_v1: hiddenList } })
  }

  const onClickEnergyThreePhase = useCallback((device) => {
    dispatch(ConsumptionActions.setSelectedThreePhase(device))
    navigation.navigate('EnergyThreePhase')
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onClickLightSwitchChannel = useCallback((channel) => {
    if (canUse) {
      const value = channel.output === 0 ? 100 : 0
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'light_control',
        channel: channel.channel,
        value
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onClickPulseUp = useCallback((channel) => {
    if (canUse) {
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'pulse',
        channel: channel.channel,
        value: 100
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onClickPulseDown = useCallback((channel) => {
    if (canUse) {
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'pulse',
        channel: channel.channel,
        value: 0
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onPressFilter = useCallback((param) => {
    navigation.navigate('FilterAmbients')
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onOpenIRRemoteControl = useCallback((remote) => {
    navigation.navigate('RemoteControl', { remote })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onOpenIRRemoteControlConfig = useCallback((remote) => {
    dispatch({ type: 'REMOTE_SET_REMOTE', remote: remote })
    navigation.navigate('RemoteConfig')
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const renderItem = ({
    section,
    index
  }: any) => {
    if (index !== 0) {
      return (null)
    }

    return (
      <View style={[styles.sectionContainer, { display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }]}>
        {section.data.map((item: any) => getDeviceType(item))}
      </View>
    )
  }

  const onLongPressSlave = useCallback((device) => {
    const isEnergy = device.group === 'energy_sensor'
    const type = isEnergy ? 'energy' : 'temperature'
    const currentFavorites = isEnergy ? favorites.energy : favorites.temperature
    const { favoriteOption, favoriteAction } = getFavoriteData(type, currentFavorites, device.id)
    const hideAction = {
      type: 'HIDE_DEVICE_AMBIENT',
      payload: {
        ambient: ambient,
        data: {
          slave_id: device.slave_id,
          is_slave: device.is_slave,
          ...(isEnergy ? { energy: true } : { temperature: true })
        }
      }
    }
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 3,
        options: [gettext('Product Configuration'), favoriteOption, gettext('Hide Device'), gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 1:
            dispatch(favoriteAction)
            break
          case 2:
            dispatch(hideAction)
            break
          case 3:
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [gettext('Add Favorite'), gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, ambient, canUse, favorites])

  const onLongPressChannel = useCallback((device) => {
    const { favoriteOption, favoriteAction } = getFavoriteData('channels', favorites.channels, device.id)
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 4,
        options: [gettext('Device Configuration'), gettext('Product Configuration'), favoriteOption, gettext('Hide Device'), gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            navigation.navigate('ChannelConfig', { id: String(device.id) })
            break
          case 1:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 2:
            dispatch(favoriteAction)
            break
          case 3:
            dispatch({
              type: 'HIDE_DEVICE_AMBIENT',
              payload: {
                ambient: ambient,
                data: { channel: device.id, is_channel: true }
              }
            })
            break
          case 4:
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, canUse, ambient, favorites])

  const onLongPressRemote = useCallback((device) => {
    const { favoriteOption, favoriteAction } = getFavoriteData('devices', favorites.devices, device.id)
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 5,
        options: [
          gettext('Edit Buttons'),
          gettext('Device Configuration'),
          gettext('Product Configuration'),
          favoriteOption,
          gettext('Hide Device'),
          gettext('Cancel')
        ]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            onOpenIRRemoteControlConfig(cloneDeep(device))
            break
          case 1:
            navigation.navigate('IrAccessory', { id: String(device.id) })
            break
          case 2:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 3:
            dispatch(favoriteAction)
            break
          case 4:
            dispatch({
              type: 'HIDE_DEVICE_AMBIENT',
              payload: {
                ambient: ambient,
                data: {
                  slave_id: device.slave_id,
                  remote_id: device.id,
                  is_device: device.is_device
                }
              }
            })
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, onOpenIRRemoteControlConfig, canUse, ambient, favorites])

  const _renderSection = ({
    section
  }: any) => {
    return (
      <Text style={[{
        fontSize: 16,
        marginLeft: 16,
        marginTop: 16,
        fontWeight: 'bold'
      }, { color: theme.colors.white }]}
      >
        {section.title}
      </Text>
    )
  }

  const _onPressAdd = useCallback(() => {
    navigation.navigate('AmbientAddSlaves', { ambient: ambientModel })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ambientModel])

  const onBackgroundImagePress =
  Platform.select({
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    web: useCallback(() => dispatch({ type: 'AMBIENT_BACKGROUND_CHANGE_TRIGGER', payload: { id: ambient.id, source: 'library' } }), [dispatch]),
    default: useCallback(() => showActionSheetWithOptions(
      {
        cancelButtonIndex: 2,
        options: [
          gettext('Choose from library'),
          gettext('Take a photo'),
          gettext('Cancel')
        ]
      },
      (buttonIndex) => {
        const options = ['library', 'camera']
        dispatch({ type: 'AMBIENT_BACKGROUND_CHANGE_TRIGGER', payload: { id: ambient.id, source: options[buttonIndex] } })
      }),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showActionSheetWithOptions, dispatch])
  })

  const _onPressEdit = useCallback(() => {
    navigation.setParams({ editing: true })
  }, [navigation])

  const _onPressSave = useCallback(() => {
    saveAmbient(ambientModel)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ambientModel])

  const _onPressCancel = useCallback(() => {
    navigation.setParams({ editing: false })
    setAmbientModel({ ...ambient })
  }, [navigation, ambient])

  const saveAmbient = useCallback((a) => {
    dispatch(updateAmbient({ data: a }))
  }, [dispatch])

  const getEditButtonRight = useCallback(() => {
    if (!editing && !isLoading) {
      return (
          <HeaderButtons>
            <Item title={t`Edit`} onPress={_onPressEdit} />
          </HeaderButtons>
      )
    }
    if (isLoading) {
      return (
        <View style={theme.p4}>
          <ActivityIndicator color={theme.textColor} size="small" />
        </View>
      )
    }

    return (
        <HeaderButtons>
          <Item title="OK" onPress={_onPressSave} />
        </HeaderButtons>
    )
  }, [editing, isLoading, _onPressEdit, _onPressSave, theme])

  const getEditButtonLeft = useCallback(() => {
    if (isLoading) {
      return () => <></>
    }
    if (editing) {
      return () => (
        <HeaderButtons>
          <Item title={t`Cancel`} onPress={_onPressCancel} color={theme.colors.danger} />
        </HeaderButtons>
      )
    }
  }, [isLoading, editing, _onPressCancel, theme.colors.danger])

  useEffect(() => {
    // if (ambientId == null) return
    navigation.setOptions({
      headerRight: () => { if (canEdit) return getEditButtonRight() },
      headerLeft: getEditButtonLeft()
    })
  }, [navigation, ambient, canEdit, getEditButtonRight, getEditButtonLeft])

  const getDeviceType = (device: any) => {
    if (device.type === 'edit_add_button') {
      return (
        <TouchableOpacity onPress={_onPressAdd}>
          <View style={[styles.addProductWrapper]}>
            <Icon name="add-circle-outline" size={32} color={theme.textColor70} />
          </View>
        </TouchableOpacity>
      )
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device.is_channel) {
      if (['both_phases',
        'pulsed_actuation',
        'no_phase_actuation',
        'follows_phase',
        'negates_phase',
        'no_action',
        'count_pulses_on_variation',
        'count_pulses_on_no_phase'].includes(device.type)) {
        return (
          <LightSwitch
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      }

      if (device.type === 'pulse_up') {
        return (
          <PulseUp
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickPulseUp(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      }

      if (device.type === 'pulse_down') {
        return (
          <PulseDown
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickPulseDown(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      }

      if (device.type === 'lamp') {
        return (
          <LightSwitch
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'plug') {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'inverted_actionable') {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output === 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'flow_sensor') {
        return (
          <FlowSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onLong... Remove this comment to see the full error message
            text={device.name}
            value={device.value}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'water_level_inverted') {
        return (
          <WaterLevel
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; inverted: true; text: any; va... Remove this comment to see the full error message
            inverted
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'water_level') {
        return (
          <WaterLevel
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'door_sensor') {
        return (
          <DoorSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'smoke_sensor') {
        return (
          <SmokeSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input > 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'presence_sensor') {
        return (
          <PresenceSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input > 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else if (device.type === 'light_sensor') {
        return (
          <LightSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onLong... Remove this comment to see the full error message
            text={device.name}
            value={device.input}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      } else {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
            hide={device.hide}
            hideButtonPress={() => toggleVisibility(device)}
          />
        )
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_device) {
      return (
        <RemoteWidget
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`device_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; category: any; on:... Remove this comment to see the full error message
          text={device.name}
          category={device.category}
          on={100}
          onPress={() => onOpenIRRemoteControl(device)}
          onLongPress={() => onLongPressRemote(device)}
          connectionStatus={device.status}
          hide={device.hide}
          hideButtonPress={() => toggleVisibility(device)}
        />
      )
    }

    if ((device.type === 'outlet' || device.type === 'infrared') &&
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      device.temperature &&
      device.temperature !== 255) {
      return (
        <TemperatureSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`slave_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: string; on: true; value... Remove this comment to see the full error message
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          text={`${device.name}`}
          on
          value={device.temperature}
          disable={editing}
          removeButton={(editing && !isLoading)}
          removeButtonPress={() => removeDevice(device.slave_id)}
          onLongPress={() => onLongPressSlave(device)}
          connectionStatus={device.status}
          hide={device.hide}
          hideButtonPress={() => toggleVisibility(device)}
        />
      )
    }

    if (device.type === 'light_switch') {
      return (
        <Energy
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`slave_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: true; onPress:... Remove this comment to see the full error message
          text={device.name}
          on
          onPress={() => { }}
          value={device.lastConsumption}
          disable={editing}
          removeButton={(editing && !isLoading)}
          removeButtonPress={() => removeDevice(device.slave_id)}
          onLongPress={() => onLongPressSlave(device)}
          connectionStatus={device.status}
          hide={device.hide}
          hideButtonPress={() => toggleVisibility(device)}
        />
      )
    } else if (device.type === 'outlet' || device.type === 'three_phase_sensor') {
      return (
        <Energy
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`slave_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: true; onPress:... Remove this comment to see the full error message
          text={device.name}
          on
          onPress={() => onClickEnergyThreePhase(device)}
          value={device.lastConsumption}
          connectionStatus={device.status}
          disable={editing}
          removeButton={(editing && !isLoading)}
          onLongPress={() => onLongPressSlave(device)}
          removeButtonPress={() => removeDevice(device.slave_id)}
          hide={device.hide}
          hideButtonPress={() => toggleVisibility(device)}
        />
      )
    }
  }

  return (
  <ImageBackground style={{ flex: 1 }} source={backgroundImage}>
    <View style={{
      maxHeight: '100%',
      flexBasis: 1,
      flexGrow: 1,
      backgroundColor: theme.backgroundImageOverlay
    }}>
          {showError && <Error>{message}</Error>}
          {editing && !isLoading &&
            <View>
              <View style={{ backgroundColor: theme.backgroundColor }}>
                <View style={theme.form.area}>
                  <View>
                    <TextInput
                      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                      disabled={isLoading}
                      style={[theme.form.input]}
                      placeholder={t`Ambient name`}
                      placeholderTextColor={theme.formForm.placeholderTextColor}
                      autoCapitalize="none"
                      autoCorrect={false}
                      value={ambientModel.name}
                      onChangeText={(name) => { setAmbientModel({ ...ambientModel, name: name }) }}
                    />
                  </View>
                </View>
                <View style={styles.backgroundImageWrapper}>
                  {Platform.OS !== 'web' && (
                    <TouchableOpacity onPress={onBackgroundImagePress}>
                      <Text style={{ color: theme.textColor }}>{t`Change Background Image`}</Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>}
          <SectionList
            sections={data}
            horizontal={false}
            stickySectionHeadersEnabled={false}
            renderSectionHeader={_renderSection}
            renderItem={renderItem}
            keyExtractor={(item, index) => `${index}`}
            ListHeaderComponent={
              <View style={[theme.form.formGroup, theme.m4]}>
                <SegmentedControl
                  appearance="dark"
                  values={[t`Kind`, t`Product`]}
                  selectedIndex={selectedIndex}
                  onChange={(event) => setSelectedIndex(event.nativeEvent.selectedSegmentIndex)}
                />
              </View>
            }
          />

        {!editing &&
          <View
            style={{
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              backgroundColor: navTheme.colors.card,
              borderTopColor: navTheme.colors.border,
              borderTopWidth: 1,
              paddingTop: 8,
              paddingBottom: insets.bottom + 8,
              paddingLeft: insets.left + 8,
              paddingRight: insets.right + 8
            }}
          >
              <Pressable
                onPress={() => onPressFilter(null)}
                hitSlop={10}>
                <MaterialCommunityIcons
                  name="filter-variant"
                  size={24}
                  color={navTheme.colors.primary}
                />
                {(filterChanged > 0) && <View style={[styles.notificationDot, { backgroundColor: navTheme.colors.notification }]} />}
              </Pressable>
          </View>
        }

      </View>
  </ImageBackground>
  )
}

export default AmbientDetail

const renderScenes = ({ item }: {item: number[]}) => <SceneBox key="scenes" scenes={item} />

const styles = StyleSheet.create({
  notificationDot: {
    position: 'absolute',
    right: 0,
    width: 10,
    height: 10,
    borderRadius: 5
  },
  addProductWrapper: {
    padding: 16,
    width: calculateButtonSize(),
    height: calculateButtonSize(),
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 4,
    marginTop: 4
  },
  backgroundImageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    paddingTop: 0
  },
  sectionContainer: {
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 15
  },
  container: {
    paddingBottom: 30,
    height: '100%'
  },
  imageBg: {
    width: Dimensions.get('window').width, // for full screen
    height: Dimensions.get('window').height // for full screen
  }
})
