import { call, put, select } from 'redux-saga/effects'
import RemoteAction from '../redux/remote'
import SlavesActions from '../redux/slaves'
import * as RootNavigation from '../navigation/RootNavigation'
import { selectApi } from '#app/api'

export function * setRemote (action: any) {
  const { remote } = action
  yield put(RemoteAction.setRemote(remote))
}

export function * setActionModal (action: any) {
  const { actionWidget } = action
  yield put(RemoteAction.setActionModal(actionWidget))
}

export function * setRemoteAndActionModal (action: any) {
  const { remote, actionWidget } = action
  yield put(RemoteAction.setRemote(remote))
  yield put(RemoteAction.setActionModal(actionWidget))
}

export function * setNewButton (action: any) {
  yield put(RemoteAction.setNewButton())
}

export function * updateButton (action: any) {
  yield put(RemoteAction.updateButton(action.button))
}

export function * setEditButton (action: any) {
  const { button } = action
  yield put(RemoteAction.setEditButton(button))
}

export function * setSaveStatusButton (action: any) {
  const { status } = action
  yield put(RemoteAction.setStatusButton({ status }))
}

export function * saveButton (action: any) {
  const { button, slaveId, remoteId } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  button.color = button.color ? 1 : 0

  const btn = {
    color: button.color,
    indexes: button.order,
    order: button.order,
    name: button.name,
    rfir_command_id: button.rfirCommandId,
    rfir_remote_id: button.rfirRemoteId
  }

  let response = null

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (button.id) {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    response = yield call(api.updateButton, button.id, btn)
  } else {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    response = yield call(api.addButton, btn)
  }

  // const responseRemotes = yield call(api.getRemotes, remoteId)

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (response.ok) {
    yield put(RemoteAction.setStatusButton({ status: '' }))
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!button.id) {
      yield put(RemoteAction.saveButtonRequestSuccess(response.data))
      yield put(SlavesActions.addButton({ button: response.data, slaveId, remoteId }))
      yield put({ type: 'DATA_GET_SLAVES_REQUEST' })
    } else {
      yield put(RemoteAction.updateButton(button))
      yield put(SlavesActions.updateButton({ button: response.data, slaveId, remoteId }))
      yield put({ type: 'DATA_GET_SLAVES_REQUEST' })
    }
    RootNavigation.pop()
  } else {
    console.log('ERROR')
    yield put(RemoteAction.saveButtonRequestFailure())
  }
}

export function * saveFakeButton (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  const { order, slaveId, remoteId } = action
  const button = {
    color: 0,
    indexes: order,
    order: order,
    name: 'FAKE_BUTTON',
    rfir_command_id: null,
    rfir_remote_id: remoteId
  }

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.addButton, button)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(RemoteAction.saveButtonRequestSuccess(response.data))
      yield put(SlavesActions.addButton({ button: response.data, slaveId, remoteId }))
    } else {
      yield put(RemoteAction.saveButtonRequestFailure())
    }
  } catch (e) {
    yield put(RemoteAction.saveButtonRequestFailure())
  }
}

export function * deleteButton (action: any) {
  const { id, slaveId, remoteId } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.deleteButton, id)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(RemoteAction.deleteButtonRequestSuccess(id))
      yield put(SlavesActions.removeButton({ id, slaveId, remoteId }))
    } else {
      yield put(RemoteAction.deleteButtonRequestFailure())
    }
  } catch (e) {
    yield put(RemoteAction.deleteButtonRequestFailure())
  }
}

export function * updateButtonsOrder (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  const { positions, slaveId, remoteId } = action

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.updateButtonsOrder, positions)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      console.log('OK')
      yield put(RemoteAction.updateButtonsOrderRequestSuccess(positions))
      yield put(SlavesActions.updateButtonsOrder({ positions, slaveId, remoteId }))
    } else {
      console.log('ERROR')
      yield put(RemoteAction.updateButtonsOrderRequestFailure())
    }
  } catch (e) {
    yield put(RemoteAction.updateButtonsOrderRequestFailure())
  }
}
