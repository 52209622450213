import React from 'react'
import GenericButton from '../GenericButton'

function getValue (value: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (value !== 0 && !value) {
    return '-'
  }

  if (value >= 1000) {
    const newVal = (value / 1000).toFixed(2)

    return `${newVal} kW`
  } else {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${value} W`
  }
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof Energy.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onLongPress' does not exist on type 'Pro... Remove this comment to see the full error message
export default function Energy ({ onPress, onLongPress, offline, value, text, disable, removeButton, removeButtonPress, connectionStatus, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon="flash"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; status: string; c... Remove this comment to see the full error message
      text={text}
      status={getValue(value)}
      connectionStatus={connectionStatus}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      on={!offline}
      onLongPress={onLongPress}
      offline={offline}
      onPress={onPress}
      disable={disable}
      removeButton={removeButton}
      removeButtonPress={removeButtonPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

Energy.defaultProps = {
  children: null,
  onPress: () => { }
}
