import React from 'react'
import { Image, StyleSheet } from 'react-native'
import logo from '#assets/logo.png'

const LoginLogo = () => <Image source={logo} style={styles.logo}/>

export default LoginLogo

const styles = StyleSheet.create({
  logo: {
    width: 150,
    height: 50,
    marginVertical: 30
  }
})
