import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { useForm, useFieldArray, FormProvider, Controller } from 'react-hook-form'
import { createTable } from 'robodux'
import { RootStackParamList } from '#app/routes'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { State } from '#app/types'
import { useNavigation } from '@react-navigation/native'
import { FlatList, ListRenderItem, Switch, Text, View } from 'react-native'
import { useAppTheme } from '#app/theme'
import { sortBy } from 'lodash/fp'

export const sortPickerItems = sortBy<PickerItem>(['title'])

const { reducer, actions, getSelectors } = createTable<PickerState>({ name: 'picker' })
const { selectById } = getSelectors((state: State) => state.picker)

export { reducer }

// value => rename to defaultValues
// newValue => rename to value
// oldValue => new, create selector which returns both simultaneously
// useEffect value != oldValue -> onChange

export const usePicker = ({ id, onChange, onBlur, items, title = '', value, route = 'PickerScreen', mode = 'multiple' }: PickerInputProps) => {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const newValue = useAppSelector(state => selectById(state, { id }))

  const onPress = useCallback(() => {
    navigation.navigate(route, { id, items, value, title, mode })
  }, [navigation, id, items, value, title, route, mode])

  useEffect(() => {
    if (newValue != null) {
      const data = newValue.filter(v => v.value).map(v => v.id)
      onChange(...(mode === 'single' ? data : [data]))
      dispatch(actions.remove([id]))
    }
  }, [dispatch, onChange, newValue, id, mode])

  return {
    onPress,
    value
  }
}

// TODO: improve single mode UX
export const PickerScreen = ({ navigation, route: { params: { id, items, value: valueParam } } }: PickerScreenProps) => {
  const value = Array.isArray(valueParam)
    ? valueParam
    : valueParam != null
      ? [valueParam]
      : []
  const form = useForm({
    defaultValues: {
      [id]: items.map(i => ({ ...i, value: value.includes(i.id), type: 'boolean' as const }))
    },
    shouldUnregister: false
  })
  const { control, handleSubmit } = form
  const { fields } = useFieldArray({ control, name: id })
  const dispatch = useAppDispatch()

  const onSubmit = useCallback((data) => {
    dispatch(actions.add(data))
  }, [dispatch])

  useEffect(() => {
    navigation.addListener('beforeRemove', () => {
      handleSubmit(onSubmit)().finally(() => {})
    })
  }, [navigation, handleSubmit, onSubmit])

  const renderItem: ListRenderItem<PickerItem> = useCallback(({ item, index }) =>
    <Cell
      name={`${id}.${index}.value`}
      {...item}
    />, [id])

  return (
    <FormProvider {...form}>
      <FlatList
        data={fields}
        renderItem={renderItem}
        contentInsetAdjustmentBehavior="automatic"
      />
    </FormProvider>
  )
}

const Cell = ({ name, title }: {name: string, title: string}) => {
  const theme = useAppTheme()
  return (
    <>
      <View style={theme.list.container}>
        <Text style={theme.list.textList}>{title}</Text>
        <View style={{ flex: 1, alignSelf: 'center', alignItems: 'flex-end' }}>
          <Controller
            name={name}
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <Switch
                ref={ref}
                onValueChange={onChange}
                value={value}
              />
            }
          />
        </View>
      </View>
      <View style={theme.list.borderBottom} />
    </>
  )
}

export interface PickerItem {
  id: string
  title: string
}

export type PickerInputProps = {
  id: string
  items: PickerItem[]
  onChange: (...event: any[]) => void
  onBlur?: () => void
  title?: string
  route?: keyof RootStackParamList
} & ({
  mode?: 'multiple'
  value?: string[]
} | {
  mode: 'single'
  value?: string | null
})

interface PickerItemState extends PickerItem {
  value: boolean
}

type PickerState = PickerItemState[]

type PickerScreenProps = NativeStackScreenProps<RootStackParamList, 'PickerScreen'>
