import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { CentralFormData, ScreenProps } from '#app/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import ButtonForm from '../components/Form/ButtonForm'
import { Separator } from '../components/List'
import { Text, TextInput, View, ScrollView, ActivityIndicator } from 'react-native'
import { useAppTheme } from '#app/theme'
import { selectCentralById, updateCentral } from '#app/central'

const SuperadminCentralEdit = ({ navigation, route: { params: { id = '' } } }: ScreenProps<'SuperadminCentralEdit'>) => {
  const central = useAppSelector(state => selectCentralById(state, { id }))
  const { control, handleSubmit, formState: { isDirty } } = useForm<CentralFormData>({
    defaultValues: {
      name: central.name
    },
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<CentralFormData>>((data) => {
    dispatch(updateCentral({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'central/update' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.pop()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({ title: showError ? message : central.name })
  }, [navigation, central.name, showError, message])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : isDirty
          ? <HeaderButtons>
            <HeaderItem
              disabled={!isDirty}
              title="Save"
              iconName="save"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>
          : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  const theme = useAppTheme()

  const onPressRevoke = useCallback(() => {}, [])

  return (
    <ScrollView>
      <Separator text="Informations"/>
      <View style={theme.form.area}>
        <View style={[theme.form.formGroup, { marginBottom: 0 }]}>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>Choose the display name of the myio central</Text>
          <Controller
            control={control}
            name="name"
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <TextInput
                ref={ref}
                onChangeText={onChange}
                onBlur={onBlur}
                style={theme.form.input}
                placeholder="Central Name"
                value={value}
              />
            }
          />
        </View>
      </View>

      <View style={theme.form.area}>
        <ButtonForm
          title="Revoke access"
          btnStyle={theme.form.btn}
          color={theme.colors.grey}
          onPress={onPressRevoke}
        />
      </View>
    </ScrollView>
  )
}

export default SuperadminCentralEdit

const schema: Yup.SchemaOf<CentralFormData> = Yup.object({
  name: Yup.string().required()
}).required()
