import React from 'react'
import GenericButton from '../GenericButton'
import { gettext } from 'ttag'

function getIcon (on: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (on) {
    return 'lightbulb-on'
  }

  return 'lightbulb-outline'
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
  onLongPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof LightSwitch.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'on' does not exist on type 'Props'.
export default function LightSwitch ({ onPress, onLongPress, on, offline, text, connectionStatus, loading, confirm, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon={getIcon(on)}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; connectionStatus:... Remove this comment to see the full error message
      text={text}
      connectionStatus={connectionStatus}
      iconOnColor="#f59b10"
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      status={on ? gettext('On') : gettext('Off')}
      on={on}
      offline={offline}
      onPress={onPress}
      onLongPress={onLongPress}
      loading={loading}
      confirm={confirm}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

LightSwitch.defaultProps = {
  children: null,
  onPress: () => { },
  onLongPress: () => { }
}
