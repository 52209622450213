import { AppRegistry, Platform } from 'react-native'
import { patchFlatListProps } from 'react-native-web-refresh-control'
import FontAwesomeFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import IoniconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf'
import MaterialCommunityIconsFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import App from './App'

AppRegistry.registerComponent('main', () => App)
Platform.select({
  web: () => {
    patchFlatListProps()
    // Generate required css
    const styles = `
    @font-face {
      src: url(${FontAwesomeFont});
      font-family: FontAwesome;
    }
    @font-face {
      src: url(${IoniconsFont});
      font-family: Ionicons;
    }
    @font-face {
      src: url(${MaterialCommunityIconsFont});
      font-family: MaterialCommunityIcons;
    }
    body {
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    `
    // Create stylesheet
    const style = document.createElement('style')
    style.type = 'text/css'
    if (style.styleSheet) {
      style.styleSheet.cssText = styles
    } else {
      style.appendChild(document.createTextNode(styles))
    }

    // Inject stylesheet
    document.head.appendChild(style)

    AppRegistry.runApplication('main', {
      rootTag: document.getElementById('root')
    })
  },
  default: () => { }
})()
