import { AnyAction, combineReducers } from 'redux'
import { all, call, put, select, takeEvery } from 'typed-redux-saga'
import { createSelector } from 'reselect'
import { createTable, createAction, createAssign, mustSelectEntity, createList } from 'robodux'
import { batchActions } from 'redux-batched-actions'
import { normalize } from 'normalizr'
import { merge, reduce, uniq } from 'lodash'

import { userLogout, wsCentralCapabilities } from '#app/actions'
import { Central, CentralFormData, State, WSCentralCapabilitiesMessage } from '#app/types'
import { getCentralsResponse } from '#app/schema'
import { setLoaderStart, setLoaderError, setLoaderSuccess } from '#app/loader'
import { addPageInfo, selectApi, selectPageInfoById } from '#app/api'
import { includes } from 'lodash/fp'

export const defaultCentral = (p: Partial<Central> = {}): Central => merge({
  id: '',
  serial: '',
  name: '',
  createdAt: 0
}, p)

const createCentralSelector = mustSelectEntity(defaultCentral)

const centrals = createTable<Central>({ name: 'centrals' })
const centralIds = createList<string[]>({ name: 'centralIds' })
const currentCentral = createAssign({ name: 'currentCentral', initialState: '' })

export const {
  add: addCentrals,
  set: setCentrals,
  remove: removeCentrals,
  reset: resetCentrals,
  patch: patchCentrals
} = centrals.actions
const {
  add: addCentralIds,
  // remove: removeCentralIds,
  reset: resetCentralIds
} = centralIds.actions
export const {
  set: setCurrentCentral,
  reset: resetCurrentCentral
} = currentCentral.actions

const currentCentralCapabilities = createList<string[]>({
  name: 'currentCentralCapabilities',
  extraReducers: {
    [wsCentralCapabilities.toString()]: (state: string[], { enabled }: WSCentralCapabilitiesMessage) => enabled,
    [resetCurrentCentral.toString()]: () => []
  }
})

export const reducer = combineReducers({
  centrals: centrals.reducer,
  centralIds: centralIds.reducer,
  currentCentral: currentCentral.reducer,
  currentCentralCapabilities: currentCentralCapabilities.reducer
})

const {
  selectTable: selectCentralsTable,
  selectTableAsList: selectCentralsAsList,
  selectByIds: selectCentralsByIds,
  ...centralsSelectors
} = centrals.getSelectors<State>(state => state.central.centrals)
export const selectCentralById = createCentralSelector(centralsSelectors.selectById)
export const selectCurrentCentralId = (state: State) => state.central.currentCentral
export const selectCurrentCentral = createSelector(
  [
    (state: State) => state,
    selectCurrentCentralId
  ],
  (state, id) => selectCentralById(state, { id })
)
export const selectIsCentralSelected = createSelector(
  selectCurrentCentralId,
  id => id !== ''
)
export const selectCentrals = createSelector(
  (state: State) => state,
  (state: State) => ({ ids: uniq(state.central.centralIds) }),
  selectCentralsByIds
)
export const selectCurrentCentralCapabilities = (state: State) => state.central.currentCentralCapabilities
export const selectIsSlaveSwapEnabled = createSelector(
  selectCurrentCentralCapabilities,
  includes('slave_swap')
)

export const fetchCentrals = createAction('FETCH_CENTRALS')
export const fetchMoreCentrals = createAction('FETCH_MORE_CENTRALS')
export const updateCentral = createAction<{id: Central['id'], data: CentralFormData}>('UPDATE_CENTRAL')

function * onFetchCentrals ({ type }: ReturnType<typeof fetchCentrals> | ReturnType<typeof fetchMoreCentrals>) {
  const isMore = type === fetchMoreCentrals.toString()
  const { hasNextPage, endCursor } = yield * select(state => selectPageInfoById(state, { id: 'centrals' }))
  if (isMore && !hasNextPage) return
  yield * put(setLoaderStart({ id: 'centrals' }))
  const api = yield * select(selectApi)
  const after = isMore
    ? endCursor
    : undefined
  const res = yield * call(api.getCentrals, after)
  if (!res.ok || res.data == null) {
    yield * put(setLoaderError({ id: 'centrals' }))
    return
  }
  const data = res.data.edges.map(e => e.node)
  const { entities, result } = normalize(data, getCentralsResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'centrals':
        return [...acc, addCentrals(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield put(batchActions([
    ...actions,
    addPageInfo({ centrals: res.data.pageInfo }),
    ...(!isMore ? [resetCentralIds()] : []),
    addCentralIds(result),
    setLoaderSuccess({ id: 'centrals' })
  ]))
}

export function * onUpdateCentral ({ payload: { id, data } }: ReturnType<typeof updateCentral>) {
  yield * put(setLoaderStart({ id: 'central/update' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.patchCentral, id, data)
  if (!res.ok) {
    const message = res.data?.err ?? 'Unknown Error'
    yield * put(setLoaderError({ id: 'central/update', message }))
    return
  }
  yield * put(batchActions([
    patchCentrals({ [id]: data }),
    setLoaderSuccess({ id: 'central/update' })
  ]))
}

function * onUserLogout (action: ReturnType<typeof userLogout>) {
  yield put(batchActions([
    resetCurrentCentral(),
    resetCentrals()
  ]))
}

export function * saga () {
  yield all([
    takeEvery([fetchCentrals, fetchMoreCentrals], onFetchCentrals),
    takeEvery(updateCentral, onUpdateCentral),
    takeEvery(userLogout, onUserLogout)
  ])
}
