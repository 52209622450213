import React from 'react'
import { Pressable, PressableProps, StyleProp, ViewStyle } from 'react-native'

export const PressableScale = ({
  scale = 0.98,
  style,
  ...props
}: PressableScaleProps) => (
  <Pressable
    style={({ pressed }) => [style, { transform: [{ scale: pressed ? scale : 1 }] }]} {...props}
  />
)

export type PressableScaleProps = PressableProps & {
  scale?: number
  style?: StyleProp<ViewStyle>
}
