import React, { useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Text } from 'react-native'
import { Left, ItemPress, ListItem, Right } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import Icon from '../components/Icon'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'

export default function Schedules () {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  const schedules = useAppSelector(state => state.schedules.schedules)
  const theme = useAppTheme()

  const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={23} color="grey" />

  useEffect(() => {
    navigation.setOptions({
      title: gettext('Schedules'),
      headerRight: (state: any) => {
        return (
          <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
            <Item title={t`Add`} iconName="add" onPress={() => navigation.navigate('ScheduleDetails', { new: true })} />
          </HeaderButtons>
        )
      }
    })
  })

  const editSchedule = useCallback((schedule) => {
    dispatch({
      type: 'SET_SELECTED_SCHEDULE', schedule
    })
    navigation.navigate('ScheduleDetails', { new: false })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  return (
    <ListItem>
      {
        schedules.map((s: any) => <ItemPress key={s.id} onPress={() => editSchedule(s)}>
          <Left style={{ flexDirection: 'column' }}>
            <Text style={{ color: theme.textColor }}>{s.name}</Text>
          </Left>

          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </Right>
        </ItemPress>
        )
      }

      {
        schedules.length === 0 && (
          <Text style={{ color: theme.textColor, textAlign: 'center', marginTop: 25 }}>
            {t`No scheduling found.`}
          </Text>
        )
      }
    </ListItem>
  )
}
