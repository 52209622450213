import React, { useCallback } from 'react'
import { ActivityIndicator } from 'react-native'
import { ActionCreator } from 'redux'
import { ActionWithPayload, PropId } from 'robodux'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { useAppTheme } from '#app/theme'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import ButtonForm from './ButtonForm'

const ActionButton = ({ id, creator, onSuccess, loader: loaderId, ...props }: ActionButtonProps) => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()
  const onPress = useCallback(() => {
    dispatch(creator({ id }))
  }, [dispatch, creator, id])
  const loader = useAppSelector(state => selectLoaderById(state, { id: loaderId }))
  const { isLoading, message } = loader
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)
  if (showError) return <Error>{message}</Error>
  if (isLoading) return <ActivityIndicator />
  return <ButtonForm btnStyle={theme.form.btn} onPress={onPress} {...props} />
}

export default React.memo(ActionButton)

export interface ActionButtonProps {
  id: string
  creator: ActionCreator<ActionWithPayload<PropId>>
  loader: string
  onSuccess: () => void
  title: string
  color?: string
}
