import React from 'react'
import { Platform, StyleSheet, TextInput, TextInputProps } from 'react-native'
import { useAppTheme } from '#app/theme'
import { useStyle } from 'react-native-style-utilities'

const SlaveCodeInput = React.forwardRef<TextInput, SlaveCodeInputProps>((
  {
    isError = false,
    onChange,
    value: valueProp,
    style: styleProp,
    ...props
  },
  ref
) => {
  const theme = useAppTheme()
  const style = useStyle(() => [theme.form.input, styles.textInput, (isError ? [styles.textInputError] : []), styleProp], [isError, theme.form.input, styleProp])
  const value = valueProp == null
    ? ''
    : String(valueProp).padStart(3, '0')
  const onChangeText = (text: string) => {
    const number = Number(text)
    onChange?.(isNaN(number) ? Number(value) : number)
  }

  return (
    <TextInput
      ref={ref}
      style={style}
      value={value}
      keyboardType="numeric"
      placeholder="000"
      onChangeText={onChangeText}
      {...props}
    />
  )
})

export default SlaveCodeInput

const styles = StyleSheet.create({
  textInput: {
    fontSize: 22,
    textAlign: 'center',
    ...(Platform.select({
      web: {
        width: '100%',
        margin: 5
      },
      default: {}
    }))
  },
  textInputError: {
    borderColor: 'red',
    borderWidth: StyleSheet.hairlineWidth
  }
})

interface SlaveCodeInputProps extends Omit<TextInputProps, 'keyboardType' | 'placeholder' | 'value' | 'onChangeText' | 'onChange'> {
  isError?: boolean
  value?: number
  onChange?: (n: number) => void
}
