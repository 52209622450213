import React from 'react'
import WebViewScreen from '#app/components/WebViewScreen'
import { useAppSelector } from '../hooks'
import { selectCurrentCentralId } from '#app/central'
import { selectCentralToken } from '#app/api'

const NodeRed = () => {
  const centralId = useAppSelector(selectCurrentCentralId)
  const token = useAppSelector(selectCentralToken)
  if (token == null) {
    return <></>
  }
  const uri = `https://${centralId}.y.myio.com.br/red?access_token=${token}`
  return <WebViewScreen uri={uri} />
}

export default NodeRed
