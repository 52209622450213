import React from 'react'
import { useAppSelector } from '../hooks'
import { Text, TextInput, ScrollView, View } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { t } from 'ttag'

import { useAppTheme } from '#app/theme'
import { ScreenProps } from '#app/types'
import {
  selectSlaveAddress,
  selectSlaveChannelCount,
  selectSlaveCode,
  selectSlaveStatusAverageRetries,
  selectSlaveStatusFormatted,
  selectSlaveTemperatureToleranceFormatted,
  selectSlaveClampTypeFormatted,
  selectSlaveType
} from '#app/slave'
import { Separator, ItemPress, Item, TextList, Right, Left } from '#app/components/List'
import StatusBox from '#app/components/StatusBox'
import { selectIsSlaveSwapEnabled } from '#app/central'

const SlaveDetail = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const theme = useAppTheme()

  const address = useAppSelector(state => selectSlaveAddress(state, { id }))
  const channels = useAppSelector(state => selectSlaveChannelCount(state, { id }))
  const code = useAppSelector(state => selectSlaveCode(state, { id }))
  const status = useAppSelector(state => selectSlaveStatusFormatted(state, { id }))
  const type = useAppSelector(state => selectSlaveType(state, { id }))
  const retries = useAppSelector(state => selectSlaveStatusAverageRetries(state, { id }))
  const temperatureTolerance = useAppSelector(state => selectSlaveTemperatureToleranceFormatted(state, { id }))
  const clampType = useAppSelector(state => selectSlaveClampTypeFormatted(state, { id }))
  const swapEnabled = useAppSelector(selectIsSlaveSwapEnabled)

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      <View style={theme.form.area}>
      {['outlet', 'three_phase_sensor'].includes(type) &&
        <View style={theme.mb3}>
          <StatusBox id={id} />
        </View>
      }
        <View style={theme.mb3}>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Code`}</Text>
          <TextInput
            style={theme.form.input}
            editable={false}
            value={code}
          />
        </View>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Address`}</Text>
          <TextInput
            style={theme.form.input}
            editable={false}
            value={address}
          />
      </View>
      <Separator text=""/>
      <Item>
        <Text style={theme.list.textList}>{t`Status`}</Text>
        <Right>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{status}</Text>
        </Right>
      </Item>
      {retries != null &&
      <Item>
        <Text style={theme.list.textList}>{t`Average Retries`}</Text>
        <Right>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{retries}</Text>
        </Right>
      </Item>
      }
      <Separator text=""/>
      {type === 'infrared' &&
        <ItemPress onPress={() => navigation.navigate('SlaveRfIrDeviceList')}>
          <TextList text={t`Devices`}/>
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
          </Right>
        </ItemPress>
      }
      {['outlet', 'three_phase_sensor'].includes(type) &&
        <ItemPress onPress={() => navigation.navigate('SlaveChannelList')}>
          <TextList text={t`Devices`}/>
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
          </Right>
        </ItemPress>
      }

      {swapEnabled &&
        <ItemPress onPress={() => navigation.navigate('SwapSlaveStepCode')}>
          <TextList text={t`Replace product`}/>
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
          </Right>
        </ItemPress>
      }

      <Separator text={t`Preferences`}/>
      <ItemPress onPress={() => navigation.navigate('SlaveConfig', { id })}>
        <Text style={theme.list.textList}>{t`General`}</Text>
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
        </Right>
      </ItemPress>

      {type === 'outlet' &&
        <ItemPress onPress={() => navigation.navigate('SlaveTemperatureTolerance', { id })}>
          <Left>
            <Text style={theme.list.textList}>{t`Temperature Update Threshold`}</Text>
          </Left>
          <Right style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={theme.list.textList}>{temperatureTolerance}</Text>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
          </Right>
        </ItemPress>
      }

      {['outlet', 'three_phase_sensor'].includes(type) && (
        <ItemPress onPress={() => navigation.navigate('SlaveClamp', { id })}>
          <Left>
            <Text style={theme.list.textList}>{t`Current Transformer (CT)`}</Text>
          </Left>
          <Right style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end' }}>
            <Text style={theme.list.textList}>{clampType}</Text>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
          </Right>
        </ItemPress>
      )}

      {type === 'outlet' && Array.from(
        { length: channels },
        (_, channel) => {
          const n = channel + 1
          return (
            <ItemPress key={`${channel}`} onPress={() => navigation.navigate('InOutPage', { id, channel })}>
              <Text style={theme.list.textList}>{t`Channel ${n}`}</Text>
              <Right>
                <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
              </Right>
            </ItemPress>
          )
        }
      )}
    </ScrollView>
  )
}

export default SlaveDetail
