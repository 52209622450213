import React, { useEffect, useCallback } from 'react'
import { ActivityIndicator, View, TextInput, StyleSheet } from 'react-native'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { Error } from '#app/components/Utils'
import { t } from 'ttag'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { useAppTheme } from '#app/theme'
import { NewSlaveFormData } from '#app/types'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { onboardNewSlave } from '#app/slave'
import { useNavigation } from '@react-navigation/native'

const NewSlaveStepOnboardNew = () => {
  const navigation = useNavigation()
  const theme = useAppTheme()

  const form = useForm<NewSlaveFormData>({
    defaultValues,
    shouldUnregister: false,
    resolver: yupResolver(schema)
  })
  const { handleSubmit, setFocus, control } = form

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<NewSlaveFormData>>((data) => {
    dispatch(onboardNewSlave({ data }))
  }, [dispatch])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'slave/onboard' }))
  const { isLoading, message, meta } = loader
  const onSuccess = useCallback(() => {
    navigation.navigate('NewSlaveStepSuccess')
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)
  useEffect(() => {
    if (showError && meta.abort === true) {
      navigation.getParent()?.goBack()
    }
  }, [navigation, showError, meta])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return isLoading
          ? <ActivityIndicator />
          : <HeaderButtons>
              <Item
                title={t`Next`}
                onPress={handleSubmit(onSubmit)}
              />
            </HeaderButtons>
      }
    })
  }, [navigation, handleSubmit, onSubmit, isLoading])

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  return (
    <View style={styles.container}>
      <View style={theme.form.area}>
        <View style={theme.form.formGroup}>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <>
                <TextInput
                  ref={ref}
                  style={theme.form.input}
                  placeholder={t`Name`}
                  placeholderTextColor={theme.formForm.placeholderTextColor}
                  autoCapitalize="words"
                  autoCorrect={false}
                  value={value}
                  onChangeText={onChange}
                  onBlur={onBlur}
                  onSubmitEditing={handleSubmit(onSubmit)}
                />
                {error?.message != null && <Error>{error.message}</Error> }
              </>
            )}
          />
          {showError && <Error>{message}</Error>}
        </View>
      </View>
    </View>
  )
}

export default NewSlaveStepOnboardNew

const defaultValues = {
  name: ''
}

const schema: Yup.SchemaOf<NewSlaveFormData> = Yup.object({
  name: Yup.string().required()
}).required()

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column'
  }
})
