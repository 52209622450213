import React, { ReactNode } from 'react'
import { StyleSheet, View, ViewProps } from 'react-native'

const LoginFormArea = (props: LoginFormAreaProps) => <View style={styles.form} {...props} />

export default LoginFormArea

const styles = StyleSheet.create({
  form: {
    alignSelf: 'stretch'
  }
})

export interface LoginFormAreaProps extends ViewProps {
  children: ReactNode
}
