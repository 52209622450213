import { getOr } from 'lodash/fp'
import { filter, find } from 'lodash'
import { Action } from 'redux'
import { ActionPattern } from '@redux-saga/types'
import { AnyState, excludesFalse, MapEntity, PropId } from 'robodux'
import { delay, race, take } from 'typed-redux-saga'
import createCachedSelector from 're-reselect'
import { t } from 'ttag'

export const formatError = getOr(t`Unknown error`, 'message')

export const selectPropId = createCachedSelector(
  (s: any, p: any) => p,
  (p): PropId => typeof p?.id === 'string' ? p : { id: '' }
)(
  (s, p) => typeof p?.id === 'string' ? p.id : ''
)

export function moreTableSelectors<Entity extends AnyState = AnyState, S = any> (
  selectTable: (s: S) => MapEntity<Entity>
) {
  const findByProps = (data: MapEntity<Entity>, props: Partial<Entity>) => filter(data, props).filter(excludesFalse)
  const findOneByProps = (data: MapEntity<Entity>, props: Partial<Entity>) => find(data, props)
  const selectOneByProps = (state: S, props: Partial<Entity>): Entity | undefined => {
    const data = selectTable(state)
    return findOneByProps(data, props)
  }
  const selectByProps = (state: S, props: Partial<Entity>): Entity[] => {
    const data = selectTable(state)
    return findByProps(data, props)
  }

  return {
    findByProps,
    findOneByProps,
    selectOneByProps,
    selectByProps
  }
}

export function * waitSaga <S extends Action, E extends Action> (
  successPattern: ActionPattern<S>,
  errorPattern: ActionPattern<E>,
  timeoutMs = 10000
) {
  const { error, timeout } = yield * race({
    success: take(successPattern),
    error: take(errorPattern),
    timeout: delay(timeoutMs)
  })
  if (timeout != null) throw new Error('Timeout')
  if (error != null) throw new Error('Error')
}

export function * waitSaga2 <S extends Action, E extends Action> (
  successPattern: ActionPattern<S>,
  errorPattern: ActionPattern<E>,
  timeoutMs = 10000
) {
  return yield * race({
    success: take(successPattern),
    error: take(errorPattern),
    timeout: delay(timeoutMs)
  })
}
