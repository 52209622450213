import React, { useEffect, useState, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ScrollView, StyleSheet, View } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { ItemPress, TextList, Right } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import Search from '../components/Search'
import { t, gettext } from 'ttag'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { selectCan } from '#app/centraluser'
import { useAppTheme } from '#app/theme'

export default function SceneList () {
  const scenes = useAppSelector(state => state.scene.scenes)
  const theme = useAppTheme()
  const can = useAppSelector(selectCan)
  const canEdit = can('edit')
  const [searchTerm, setSearchTerm] = useState('')
  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (canEdit) {
      navigation.setOptions({
        headerRight: (state: any) => {
          return (
            <HeaderButtons>
              <Item title={t`New`} iconName="save" onPress={setBlankScene} />
            </HeaderButtons>
          )
        }
      })
    }
  })

  useEffect(() => { navigation.setOptions({ title: gettext('Scenes') }) })

  const setScene = useCallback((scene?: any) => {
    dispatch({ type: 'DATA_SET_SELECTED_SCENE', data: scene })
  }, [dispatch])

  const setBlankScene = useCallback(() => {
    dispatch({ type: 'CLEAR_COMMANDS' })
    setScene()
  }, [dispatch, setScene])

  const onTrashPress = useCallback(() => {
    setSearchTerm('')
  }, [])

  return (
    <View style={{ maxHeight: '100%', flexBasis: 1, flexGrow: 1 }}>
      <Search
        placeholder={t`Search scenes...`}
        placeholderTextColor={theme.formForm.placeholderTextColor}
        style={[styles.searchBox, { backgroundColor: theme.formForm.search }]}
        value={searchTerm}
        valueTextColor={theme.textColor}
        onChangeText={setSearchTerm}
        onTrashPress={onTrashPress}
        iconColor={theme.formForm.searchIconColor}
      />
      <ScrollView>
        {
          scenes.filter((scene: any) => {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (!searchTerm) { return true }

            return scene.name.toLowerCase().includes(searchTerm.toLowerCase())
          }).map((scene: any) => <ItemPress onPress={() => setScene(scene)} key={scene.id}>
            <TextList text={scene.name} />
            <Right>
              <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
            </Right>
          </ItemPress>)
        }

      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  searchBox: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    height: 40
  }
})
