import React from 'react'
import GenericButton from '../GenericButton'
import { gettext } from 'ttag'

function getIcon (on: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (on) {
    return 'alarm-light'
  }

  return 'alarm-light-outline'
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof LightSensor.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onLongPress' does not exist on type 'Pro... Remove this comment to see the full error message
export default function LightSensor ({ onPress, onLongPress, offline, value, text, connectionStatus, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon={getIcon(value < 100)}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; iconOnColor: stri... Remove this comment to see the full error message
      text={text}
      iconOnColor="#f59b10"
      connectionStatus={connectionStatus}
      status={value < 100 ? gettext('Detected') : ''}
      on={value < 100}
      onLongPress={onLongPress}
      offline={offline}
      onPress={onPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

LightSensor.defaultProps = {
  children: null,
  onPress: () => { }
}
