import { createReducer, createActions } from 'reduxsauce'
import _, { keyBy, merge, values } from 'lodash'

const { Types, Creators } = createActions({
  clearAmbientState: null,
  setAmbientClear: null,
  setAmbientFilters: ['payload'],
  setAmbientFilter: ['payload'],
  clearAmbientFilter: null,
  uploadAmbientImageRequest: ['payload'],
  uploadAmbientImageRequestSuccess: ['payload'],
  uploadAmbientImageRequestFailure: null,
  hideDeviceAmbient: null,
  updatePositionRequest: ['payload'],
  updatePositionRequestSuccess: ['payload'],
  updatePositionRequestFailure: null,
  updatePositionRequestFulfill: null
})

function defaultFilters () {
  return {
    remotes: {
      ac: { label: 'AC', value: true },
      tv: { label: 'TV', value: true },
      other: { label: 'Outros', value: true }
    },
    energyMeters: {
      three_phase_sensor: { label: 'Trifásico', value: true },
      others: { label: 'Outros', value: true }
    },
    category: {
      lamp: { label: 'Lâmpada', value: true },
      dimmer: { label: 'Dimmer', value: true },
      plug: { label: 'Tomada', value: true },
      remote: { label: 'Botão Remoto', value: true },
      pulse_up: { label: 'Pulse Up', value: true },
      pulse_down: { label: 'Pulse Down', value: true },
      presence_sensor: { label: 'Sensor de Presença', value: true },
      temperature_sensor: { label: 'Sensor de Temperatura', value: true },
      flow_sensor: { label: 'Sensor de Fluxo', value: true },
      door_sensor: { label: 'Sensor de Porta', value: true },
      smoke_sensor: { label: 'Sensor de Fumaça', value: true },
      light_sensor: { label: 'Fotocélula', value: true },
      water_level: { label: 'Sensor de Nível', value: true },
      advanced: { label: 'Avançados', value: true }
    },
    stateLight: {
      on: { label: 'Ligado', value: true },
      off: { label: 'Desligado', value: true }
    },
    stateSensor: {
      detected: { label: 'Detectado', value: true },
      not_detected: { label: 'Não Detectado', value: true }
    },
    consumption: {
      greatherThan: { label: 'Maior que', value: '' },
      lessThan: { label: 'Menor que', value: '' }
    }
  }
}

export const INITIAL_STATE = {
  favorites: [],
  loading: false,
  saveError: false,
  editing: false,
  getError: false,
  filterChanged: false,
  filters: defaultFilters()
}

export default Creators

function clearAmbientState () {
  return { ...INITIAL_STATE }
}

function updatePositionRequest (state: any, action: any) {
  return {
    ...state,
    loading: true
  }
}

function updatePositionRequestSuccess (state: any, action: any) {
  const merged = merge(
    {},
    keyBy(state.ambients, a => String(a.id)),
    keyBy(action.payload, a => String(a.id))
  )
  return {
    ...state,
    ambients: values(merged)
  }
}

function updatePositionRequestFulfill (state: any, action: any) {
  return {
    ...state,
    loading: false
  }
}

function setAmbientClear (state: any) {
  return {
    ...state,
    saveError: false,
    ambient: {
      id: '',
      name: '',
      slaves: [],
      image: null
    }
  }
}

function loadFilterChanged (filters: any) {
  let filterChanged = 0

  Object.keys(filters).forEach(i => {
    Object.keys(filters[i]).forEach(f => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (typeof filters[i][f].value === 'boolean' && !filters[i][f].value) { filterChanged += 1 } else
      if (typeof filters[i][f].value === 'string' && filters[i][f].value !== '') filterChanged += 1
    })
  })

  return filterChanged
}

function setAmbientFilters (state: any, action: any) {
  const { filters } = action.payload

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (filters) {
    const filterChanged = loadFilterChanged(filters)
    return { ...state, filters, filterChanged }
  }

  return { ...state }
}

function setAmbientFilter (state: any, action: any) {
  const { key, name, value } = action.payload
  const filters = _.clone(state.filters)

  filters[key][name].value = value

  const filterChanged = loadFilterChanged(filters)

  return { ...state, filters, filterChanged }
}

function clearAmbientFilter (state: any) {
  return {
    ...state,
    filterChanged: 0,
    filters: defaultFilters()
  }
}

function uploadAmbientImageRequest (state: any, action: any) {
  return {
    ...state,
    loading: true
  }
}

function uploadAmbientImageRequestSuccess (state: any, action: any) {
  return {
    ...state,
    loading: false
  }
}

function uploadAmbientImageRequestFailure (state: any, action: any) {
  return {
    ...state,
    loading: false
  }
}

function hideDeviceAmbient (state: any, action: any) {
  return {
    ...state,
    loading: true
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_AMBIENT_STATE]: clearAmbientState,
  [Types.SET_AMBIENT_CLEAR]: setAmbientClear,
  [Types.SET_AMBIENT_FILTERS]: setAmbientFilters,
  [Types.SET_AMBIENT_FILTER]: setAmbientFilter,
  [Types.CLEAR_AMBIENT_FILTER]: clearAmbientFilter,
  [Types.UPLOAD_AMBIENT_IMAGE_REQUEST]: uploadAmbientImageRequest,
  [Types.UPLOAD_AMBIENT_IMAGE_REQUEST_SUCCESS]: uploadAmbientImageRequestSuccess,
  [Types.UPLOAD_AMBIENT_IMAGE_REQUEST_FAILURE]: uploadAmbientImageRequestFailure,
  [Types.HIDE_DEVICE_AMBIENT]: hideDeviceAmbient,
  [Types.UPDATE_POSITION_REQUEST]: updatePositionRequest,
  [Types.UPDATE_POSITION_REQUEST_SUCCESS]: updatePositionRequestSuccess,
  [Types.UPDATE_POSITION_REQUEST_FULFILL]: updatePositionRequestFulfill
})
