import { createTable, PropId, MapEntity } from 'robodux'
import { createSelector } from 'reselect'
import { merge } from 'lodash/fp'
import { selectCurrentCentralId } from '#app/central'
import { State } from '#app/types'
import { combineReducers } from 'redux'

interface CentralAmbient {
  groupDevicesBy: 'kind' | 'product'
}
const defaultCentralAmbient = (p: Partial<CentralAmbient> = {}): CentralAmbient =>
  merge({
    groupDevicesBy: 'kind'
  }, p)
type CentralAmbientState = MapEntity<CentralAmbient>
const centralAmbient = createTable<CentralAmbientState>({
  name: 'centralAmbient'
})

export const { patch: patchCentralAmbient } = centralAmbient.actions
export const reducer = combineReducers({
  centralAmbient: centralAmbient.reducer
})

const {
  selectById: selectCentralAmbient
} = centralAmbient.getSelectors<State>(state => state.centralambient.centralAmbient)
const selectCurrentCentralCentralAmbientById = createSelector(
  (s: State) => s,
  createSelector(
    selectCurrentCentralId,
    id => ({ id })
  ),
  selectCentralAmbient
)
export const selectCentralAmbientById = createSelector(
  selectCurrentCentralCentralAmbientById,
  (s: State, { id }: PropId) => id,
  (ca, id) => defaultCentralAmbient(ca?.[id])
)
