import { AnyAction } from 'redux'
import {
  actionChannel,
  all,
  call,
  cancelled,
  delay,
  fork,
  put,
  race,
  retry,
  select,
  take,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
import { channel, eventChannel, END, EventChannel, Channel } from 'redux-saga'
import WsActions from '../redux/ws'
import SlavesAction from '../redux/slaves'
import RemoteActions from '../redux/remote'
import ConsumptionActions from '../redux/consumption'
import { map } from 'lodash'
import { showMessage } from 'react-native-flash-message'
import { gettext } from 'ttag'
import { RootState } from '../store'
import { batchActions } from 'redux-batched-actions'
import { wsConnectionRequest, wsDisconnectionRequest, wsSendMessage, wsStateUpdate, wsAuthReq } from '#app/actions'
import { WSInboxMessage, WSOutboxMessage } from '#app/types'

export const WS_CONNECTED = 'connected'
export const WS_PING_MAX_INTERVAL_MS = 1500
export const WS_PING_MAX_MISS_COUNT = 3
export const WS_SEND_RETRY_COUNT = 10
export const WS_SEND_RETRY_INTERVAL_MS = 100
export const WS_RECONNECT_INTERVAL_MS = 250

export function * onWsConnectionRequest ({ payload }: ReturnType<typeof wsConnectionRequest> | ReturnType<typeof wsDisconnectionRequest>) {
  if (payload == null) return
  const { url, centralId, centralToken } = payload
  const sendQueue = yield * actionChannel(wsSendMessage)
  while (true) {
    try {
      const ws = yield * call(createWebSocketConnection, url)
      const wsChan = yield * call(createWebSocketEventChannel, ws)
      const wdChan = channel<''>()
      yield * race([
        call(watchReceivedMessages, wsChan, wdChan),
        call(watchSendMessage, ws, sendQueue),
        call(watchAuthReq, ws, centralId, centralToken),
        call(watchdog, ws, wdChan)
      ])
      console.debug('[WebSocket]', 'task finished early without error, restarting all tasks')
    } catch (e) {
      console.debug('[WebSocket]', e)
    }
    yield * delay(WS_RECONNECT_INTERVAL_MS)
  }
}

/**
 * See HACKS.md
 */
export function * watchdog (ws: WebSocket, wdChan: Channel<''>) {
  let consecutiveTimeoutCount = 0
  while (true) {
    if (consecutiveTimeoutCount >= WS_PING_MAX_MISS_COUNT) throw new Error('Websocket is unresponsive')
    const { timeout } = yield * race({
      message: take(wdChan),
      timeout: delay(WS_PING_MAX_INTERVAL_MS)
    })
    if (timeout === true) {
      yield * call(onSendMessage, ws, wsSendMessage({ type: 'ping', command: '' }))
      consecutiveTimeoutCount = consecutiveTimeoutCount + 1
      continue
    }
    consecutiveTimeoutCount = 0
  }
}

export function * watchAuthReq (ws: WebSocket, centralId: string, centralToken: string) {
  while (true) {
    yield * take(wsAuthReq)
    yield * call(onAuthReq, ws, centralId, centralToken)
  }
}
export function * onAuthReq (ws: WebSocket, centralId: string, centralToken: string) {
  yield * call(onSendMessage, ws, wsSendMessage({
    message_type: 'auth',
    token: centralToken,
    central_id: centralId
  }))
}

export function * watchSendMessage (ws: WebSocket, queue: Channel<ReturnType<typeof wsSendMessage>>) {
  while (true) {
    const action = yield * take(queue)
    yield * call(onSendMessage, ws, action)
  }
}
export function * onSendMessage (ws: WebSocket, { payload }: ReturnType<typeof wsSendMessage>) {
  if (payload.command === 'light_control') {
    yield * put(SlavesAction.toggleSlaveLoading({ slaveId: payload.id, channel: payload.channel, type: 'on' }))
  }
  yield * retry(WS_SEND_RETRY_COUNT, WS_SEND_RETRY_INTERVAL_MS, sendWebSocketMessage, ws, payload)
}

export function * watchReceivedMessages (c: EventChannel<string>, wdChan: Channel<''>) {
  try {
    while (true) {
      const message = yield * take(c)
      yield * put(wdChan, '')
      yield * fork(onReceivedMessage, message)
    }
  } finally {
    if (yield * cancelled()) {
      c.close()
    }
    yield * put(WsActions.wsConnectionDisconnected())
  }
}

export function * onReceivedMessage (message: string) {
  if (message === WS_CONNECTED) {
    yield * put(WsActions.wsConnectionConnected())
    return
  }
  const data = yield * call(parseWsMessage, message)
  yield * call(handleMessage, data)
}

export function * handleMessage ({ message_type: type, ...payload }: WSInboxMessage) {
  switch (type) {
    case 'pong':
      return
    case 'state_update':
      yield * put(batchActions([
        wsStateUpdate(),
        ...map(payload.data, mapMessageToAction)
      ]))
      break
    default:
      yield * put(mapMessageToAction({ message_type: type, ...payload }))
  }
}

export function * legacyMessageHandler ({ type, payload }: AnyAction) {
  let slaveType, message, description, slavesWaiting

  switch (type) {
    case 'WS_MESSAGE_TIMEOUT':
      slaveType = yield * select((state: RootState) => state.slaves.allSlaves.find(s => s.slave_id === payload?.id)?.type)
      slavesWaiting = yield * select((state: RootState) => state.ws.slavesWaiting)
      message = gettext('Please try again')
      if (slaveType === 'three_phase_sensor') {
        description = gettext('Unable to load data.')
      } else {
        description = gettext('The device could not be activated.')
      }
      if (slavesWaiting.includes(payload?.id)) {
        if (slaveType === 'three_phase_sensor') {
          description = gettext('Unable to load data.')
        } else {
          description = gettext('The device could not be activated.')
        }
        showMessage({ description, message, type: 'danger', duration: 4000 })
      }
      yield * put(batchActions([
        RemoteActions.setActivedButton({ slaveId: null, rfirCommandId: null }),
        SlavesAction.toggleSlaveLoading({ slaveId: payload?.id, channel: null, type: 'off' }),
        ConsumptionActions.websocketRequestSetTimeout(payload)
      ]))
      break

    case 'WS_MESSAGE_TRANSMIT_SUCCESS':
      slavesWaiting = yield * select((state: RootState) => state.ws.slavesWaiting)
      if (slavesWaiting.includes(payload?.id)) {
        showMessage({
          description: gettext('Command sent!'),
          message: gettext('Done...'),
          type: 'success',
          duration: 1200
        })
      }
      yield * put(batchActions([
        RemoteActions.setActivedButton({ slaveId: null, rfirCommandId: null }),
        WsActions.wsRequestSuccess(payload)
      ]))
      break

    case 'WS_MESSAGE_INFRARED_UPDATE':
      yield * put(SlavesAction.updateTemperature({ slaveId: payload?.id, temperature: payload?.temperature }))
      break

    case 'WS_MESSAGE_TEMPERATURE_UPDATE':
      yield * put(SlavesAction.updateTemperature({ slaveId: payload?.id, temperature: payload?.value }))
      break

    case 'WS_MESSAGE_CONSUMPTION':
      if (payload?.scope === 'ambient') {
        yield * put(ConsumptionActions.consumptionAddAmbientConsumption({
          id: payload?.id,
          value: payload?.value
        }))
      } else if (payload?.scope === 'slave') {
        yield * put(batchActions([
          SlavesAction.updateConsumption(payload),
          ConsumptionActions.updateSelectedThreePhaseConsumption(payload),
          ...(payload?.phases != null ? [ConsumptionActions.updatePotency(payload)] : [])
        ]))
      }
      break

    case 'WS_MESSAGE_VOLTAGE_UPDATE':
      yield * put(batchActions([
        ConsumptionActions.updateVoltage(payload),
        WsActions.wsRequestSuccess(payload)
      ]))
      break

    case 'WS_MESSAGE_CURRENT_UPDATE':
      yield * put(batchActions([
        ConsumptionActions.updateCurrent(payload),
        WsActions.wsRequestSuccess(payload)
      ]))
      break

    case 'WS_MESSAGE_CHANNEL_UPDATE':
      yield * put(batchActions([
        SlavesAction.toggleSlaveLoading({ slaveId: payload?.id, channel: null, type: 'off' }),
        SlavesAction.updateSlaves(payload),
        WsActions.wsRequestSuccess(payload)
      ]))
      break

    case 'WS_MESSAGE_ACTION_REPLY':
      yield * put(RemoteActions.setStatusButton(payload))
      break
  }
}

export default function * wsRootSaga () {
  yield * all([
    takeLatest([wsConnectionRequest, wsDisconnectionRequest], onWsConnectionRequest),
    takeEvery(takePrefix('WS_MESSAGE_'), legacyMessageHandler)
  ])
}

function * sendWebSocketMessage (ws: WebSocket, payload: WSOutboxMessage) {
  if (ws.readyState !== WebSocket.OPEN) throw new Error('Websocket is not open')
  const message = yield * call(JSON.stringify, payload)
  ws.send(message)
}

const createWebSocketConnection = (url: string) => new WebSocket(url)

const createWebSocketEventChannel = (ws: WebSocket) => {
  return eventChannel<string>(emit => {
    ws.onopen = () => {
      console.debug('[WebSocket]', 'opened')
      emit(WS_CONNECTED)
    }
    ws.onmessage = (event) => {
      emit(event.data)
    }
    ws.onclose = () => {
      console.debug('[WebSocket]', 'closed')
      emit(END)
    }
    ws.onerror = (event) => {
      console.debug('[WebSocket]', 'error', event)
      emit(END)
    }
    return () => {
      console.debug('[WebSocket]', 'event channel closed')
      if ([WebSocket.CLOSED, WebSocket.CLOSING].includes(ws.readyState)) {
        console.debug('[WebSocket]', 'is closed already, not going to .close() again')
        return
      }
      console.debug('[WebSocket]', '.close()')
      ws.close()
    }
  })
}

const takePrefix = (prefix: string) => ({ type }: AnyAction): boolean => type.startsWith(prefix)

const parseWsMessage = (m: string): WSInboxMessage => JSON.parse(m)

const mapMessageToAction = ({ message_type: type, ...payload }: WSInboxMessage): AnyAction => ({ type: `WS_MESSAGE_${type?.toUpperCase() ?? 'UNKNOWN'}`, payload })
