// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'styl... Remove this comment to see the full error message
import styled from 'styled-components/native'

const Left = styled.View`
  flex: 1;
  alignSelf: center;
  align-items: flex-start;
  flex-direction: row;
`

export default Left
