import { AnyAction, combineReducers } from 'redux'
import { all, call, debounce, put, race, select, take, takeEvery } from 'typed-redux-saga'
import { createSelector } from 'reselect'
import { createTable, createAction, mustSelectEntity, PropId } from 'robodux'
import { batchActions } from 'redux-batched-actions'
import { normalize } from 'normalizr'
import { filter, merge, orderBy, reduce } from 'lodash'

import { userLogout } from '#app/actions'
import { AddCentralUserFormData, CanIntent, Central, CentralUser, CentralUserFormData, CentralUserRole, State, User, UserCentral } from '#app/types'
import { associateUserResponse, getUserCentralsResponse, getUsersResponse, updateCentralUserResponse } from '#app/schema'
import { setLoaderStart, setLoaderError, setLoaderSuccess } from '#app/loader'
import { selectApi } from '#app/api'
import { moreTableSelectors } from '#app/util'
import { fetchAmbientPermissionsRequest, selectAllowedAmbientIdsByUserId, updateAmbientPermissionsError, updateAmbientPermissionsRequest, updateAmbientPermissionsSuccess } from '#app/ambient'
import { selectCurrentCentralId } from '#app/central'
import { addUsers, defaultUser, findUserById, patchUsers, selectUserByIdDefaulting, selectUsers } from '#app/user'
import moment from 'moment'
import { t } from 'ttag'

const defaultCentralUser: CentralUser = {
  id: '',
  userId: '',
  centralId: '',
  name: '',
  role: 'default',
  notify: false
}
export const defaultUserCentral = (p: Partial<UserCentral> = {}): UserCentral => merge({
  id: '',
  name: '',
  role: 'default',
  token: ''
}, p)

const createCentralUserSelector = mustSelectEntity(defaultCentralUser)
const createUserCentralSelector = mustSelectEntity(defaultUserCentral)

const centralUsers = createTable<CentralUser>({ name: 'centralusers' })
const centralUserIds = createTable<string[]>({ name: 'centraluserids' })
const userCentrals = createTable<UserCentral>({ name: 'usercentrals' })

export const {
  add: addCentralUsers,
  set: setCentralUsers,
  remove: removeCentralUsers,
  reset: resetCentralUsers,
  patch: patchCentralUsers
} = centralUsers.actions
export const {
  add: addCentralUserIds,
  remove: removeCentralUserIds
} = centralUserIds.actions
const {
  // add: addUserCentrals,
  set: setUserCentrals,
  // remove: removeUserCentrals,
  reset: resetUserCentrals
  // patch: patchUserCentrals
} = userCentrals.actions

export const reducer = combineReducers({
  centralusers: centralUsers.reducer,
  centraluserids: centralUserIds.reducer,
  usercentrals: userCentrals.reducer
})

const {
  selectTable: selectCentralUsers,
  selectById: selectCentralUserById
} = centralUsers.getSelectors<State>(state => state.centraluser.centralusers)
const {
  selectById: selectCentralUserIdsById
} = centralUserIds.getSelectors<State>(state => state.centraluser.centraluserids)
const {
  findByProps: findCentralUsersByProps
} = moreTableSelectors<CentralUser, State>(state => state.centraluser.centralusers)
const selectCentralUserByIdDefaulting = createCentralUserSelector(selectCentralUserById)
const {
  selectTable: selectUserCentrals,
  selectTableAsList: selectUserCentralsAsList,
  ...userCentralsSelectors
  // selectById: selectUserCentralById
} = userCentrals.getSelectors<State>(state => state.centraluser.usercentrals)
export const selectUserCentralById = createUserCentralSelector(userCentralsSelectors.selectById)
export const selectUserByCentralUserId = createSelector(
  (state: State) => state,
  selectCentralUserByIdDefaulting,
  (state, { userId: id }) => selectUserByIdDefaulting(state, { id })
)
export const selectAllowedAmbientIdsByCentralUserId = createSelector(
  (state: State) => state,
  selectCentralUserByIdDefaulting,
  (state, { userId: id }) => selectAllowedAmbientIdsByUserId(state, { id })
)
export const selectDefaultValuesAmbients = createSelector(
  selectAllowedAmbientIdsByCentralUserId,
  (allowed) => ({ allowed })
)
export const selectDefaultValuesExpiration = createSelector(
  selectCentralUserByIdDefaulting,
  ({ expiresAt }) => {
    const e = moment(expiresAt)
    const enabled = expiresAt != null && e.isValid()
    const date = enabled
      ? e.toDate()
      : moment().add(1, 'day').toDate()
    return { date, enabled }
  }
)
export const selectDefaultValues = createSelector(
  selectCentralUserByIdDefaulting,
  selectDefaultValuesExpiration,
  selectDefaultValuesAmbients,
  ({ name, role }, expiration, ambients) => ({
    central: { name },
    expiration,
    user: { role },
    ambients
  })
)
export const selectUserName = createSelector(
  selectUserByCentralUserId,
  ({ name }) => name
)
export const selectCentralUserForEdit = createSelector(
  selectDefaultValues,
  selectUserName,
  (defaultValues, title) => ({
    defaultValues,
    title
  })
)
export const selectCurrentUserCentral = createSelector(
  (s: State) => s,
  selectCurrentCentralId,
  (state, id) => selectUserCentralById(state, { id })
)
export const selectCan = createSelector(
  selectCurrentUserCentral,
  ({ role }) => (intent: CanIntent) => {
    switch (intent) {
      case 'view':
        return true
      case 'use':
        return ['default', 'admin'].includes(role)
      case 'edit':
        return role === 'admin'
    }
  }
)
export const selectCentralUsersByCentralId = createSelector(
  selectCentralUsers,
  (s: State, { id }: PropId) => id,
  (users, centralId) =>
    findCentralUsersByProps(users, { centralId })
)
export const selectCentralUsersList = createSelector(
  selectCentralUsersByCentralId,
  selectUsers,
  (cus, users) => orderBy(
    cus.map(({ id, userId }) => {
      const {
        name: title,
        email: subtitle
      } = findUserById(users, { id: userId }) ?? defaultUser
      return {
        id,
        title,
        subtitle
      }
    }),
    ['title'],
    ['asc']
  )
)
export const selectCentralUsersListSearch = createSelector(
  selectCentralUsersList,
  selectCentralUserIdsById,
  (data, ids) => ids == null ? data : data.filter(d => ids.includes(d.id))
)
export const selectUserCentralsByName = createSelector(
  selectUserCentrals,
  centralsMap => reduce<typeof centralsMap, Record<string, UserCentral>>(
    centralsMap,
    (acc, c) => ({ ...acc, ...(c != null ? { [c.name]: c } : {}) }),
    {}
  )
)
export const selectSearchCentralsByName = createSelector(
  [
    selectUserCentralsByName,
    (state: State, params: {term: string}) => params.term.toLowerCase()
  ],
  (centralsMap, term) => filter(centralsMap, c => c.name.toLowerCase().includes(term))
)
export const selectSearchCentralsByNameAsList = createSelector(
  selectSearchCentralsByName,
  centralsMap => Array.from(centralsMap)
)
export const selectUserCentralsCount = createSelector(
  selectUserCentralsAsList,
  c => c.length
)

export const fetchUserCentrals = createAction('FETCH_USER_CENTRALS')
export const fetchCentralUsers = createAction<{id: Central['id']}>('FETCH_CENTRAL_USERS')
export const addCentralUser = createAction<{id: Central['id'], data: AddCentralUserFormData}>('ADD_CENTRAL_USER')
export const removeCentralUser = createAction<{id: CentralUser['id']}>('REMOVE_CENTRAL_USER')
export const updateCentralUser = createAction<{id: CentralUser['id'], data: CentralUserFormData}>('UPDATE_CENTRAL_USER')
export const updateCentralUserRequest = createAction<{
  id: CentralUser['id']
  userId: User['id']
  centralId: Central['id']
  name: CentralUser['name']
  role: CentralUserRole
  expiresAt: string | null
}>('UPDATE_CENTRAL_USER_REQUEST')
export const updateCentralUserError = createAction<{id: CentralUser['id'], message: string}>('UPDATE_CENTRAL_USER_ERROR')
export const updateCentralUserSuccess = createAction<{id: CentralUser['id']}>('UPDATE_CENTRAL_USER_SUCCESS')
export const searchCentralUsers = createAction<{id: Central['id'], term: string}>('SEARCH_CENTRAL_USERS')

function * onFetchUserCentrals (action: ReturnType<typeof fetchUserCentrals>) {
  yield * put(setLoaderStart({ id: 'usercentrals' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.getUserCentrals)
  if (!res.ok) {
    yield * put(setLoaderError({ id: 'usercentrals' }))
    return
  }
  const { entities } = normalize(res.data, getUserCentralsResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'centrals':
        return [...acc, setUserCentrals(v)]
      case 'users':
        return [...acc, patchUsers(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield put(batchActions([...actions, setLoaderSuccess({ id: 'usercentrals' })]))
}

function * onFetchCentralUsers ({ payload: { id } }: ReturnType<typeof fetchCentralUsers>) {
  yield * put(batchActions([
    setLoaderStart({ id: 'centraluser' }),
    fetchAmbientPermissionsRequest({})
  ]))
  const api = yield * select(selectApi)
  const res = yield * call(api.getUsers, id)
  if (!res.ok) {
    yield * put(setLoaderError({ id: 'centraluser' }))
    return
  }
  const { entities } = normalize(res.data, getUsersResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'users':
        return [...acc, addUsers(v)]
      case 'centralusers':
        return [...acc, setCentralUsers(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield put(batchActions([...actions, setLoaderSuccess({ id: 'centraluser' })]))
}

const getErrorMessage = (err?: any) => {
  const errStr = String(err)
  switch (errStr) {
    case 'ERR_USER_NOT_FOUND':
      return t`This email is not associated with any myio user`
    case 'ERR_INVALID_ROLE':
      return t`Invalid user role`
    case 'ERR_USER_NOT_ASSOCIATED':
      return t`User is not associated`
    case '':
    case 'undefined':
      return t`An unknown error has occurred`
    default:
      return errStr
  }
}

export function * onAddCentralUser ({ payload: { id: centralId, data: { email } } }: ReturnType<typeof addCentralUser>) {
  yield * put(setLoaderStart({ id: 'centraluser/add' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.associateUser, centralId, email)
  if (!res.ok) {
    const message = getErrorMessage(res.data?.err)
    yield * put(setLoaderError({ id: 'centraluser/add', message }))
    return
  }
  const id = res.data?.id
  const { entities } = normalize(res.data, associateUserResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'centralusers':
        return [...acc, addCentralUsers(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield put(batchActions([...actions, setLoaderSuccess({ id: 'centraluser/add', meta: { id } })]))
}

export function * onRemoveCentralUser ({ payload: { id } }: ReturnType<typeof removeCentralUser>) {
  yield * put(setLoaderStart({ id: 'centraluser/update' }))
  const cu = yield * select(state => selectCentralUserById(state, { id }))
  if (cu == null) {
    const message = getErrorMessage('ERR_USER_NOT_FOUND')
    yield * put(setLoaderError({ id: 'centraluser/update', message }))
    return
  }
  const { centralId, userId } = cu
  const api = yield * select(selectApi)
  const res = yield * call(api.deleteCentralUser, centralId, userId)
  if (!res.ok) {
    const message = getErrorMessage(res.data?.err)
    yield * put(setLoaderError({ id: 'centraluser/update', message }))
    return
  }
  yield put(batchActions([
    removeCentralUsers([id]),
    setLoaderSuccess({ id: 'centraluser/update' })
  ]))
}

const getExpiresAt = (enabled: boolean, date?: Date) => enabled && date != null
  ? moment(date).toISOString()
  : null

export function * onUpdateCentralUserRequest ({ payload: { id, centralId, userId, name, expiresAt, role } }: ReturnType<typeof updateCentralUserRequest>) {
  const api = yield * select(selectApi)
  const res = yield * call(api.updateCentralUser, centralId, userId, name, expiresAt, role)
  if (!res.ok) {
    const message = getErrorMessage(res.data?.err)
    yield * put(updateCentralUserError({ id, message }))
    return
  }
  const { entities } = normalize(res.data, updateCentralUserResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'centralusers':
        return [...acc, addCentralUsers(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield * put(batchActions([...actions, updateCentralUserSuccess({ id })]))
}

export function * onUpdateCentralUser ({ payload: { id, data } }: ReturnType<typeof updateCentralUser>) {
  yield * put(setLoaderStart({ id: 'centraluser/update' }))
  const cu = yield * select(state => selectCentralUserById(state, { id }))
  if (cu == null) {
    const message = getErrorMessage('ERR_USER_NOT_FOUND')
    yield * put(setLoaderError({ id: 'centraluser/update', message }))
    return
  }
  const { centralId, userId } = cu
  const name = data.central.name
  const role = data.user.role
  const expiresAt = yield * call(getExpiresAt, data.expiration.enabled, data.expiration.date)
  const updateCentralUserRequestAction = updateCentralUserRequest({ id, centralId, userId, name, expiresAt, role })

  // yield * all([
  //   put(updateCentralUserRequestAction),
  //   ...(role !== 'admin'
  //     ? [
  //         put(updateAmbientPermissionsRequest({ id: userId, ambientIds: data.ambients.allowed }))
  //       ]
  //     : []
  //   )
  // ])

  yield * put(
    role !== 'admin' && data.ambients.allowed != null
      ? batchActions([
        updateCentralUserRequestAction,
        updateAmbientPermissionsRequest({ id: userId, ambientIds: data.ambients.allowed })
      ])
      : updateCentralUserRequestAction
  )

  // TODO: local timeout
  const { cloud } = yield * all({
    cloud: race({
      success: take(updateCentralUserSuccess),
      error: take(updateCentralUserError)
    }),
    ...(role !== 'admin' && {
      ap: race({
        success: take(updateAmbientPermissionsSuccess),
        error: take(updateAmbientPermissionsError)
      })
    })
  })

  if (cloud.error != null) {
    const message = cloud.error.payload.message
    yield * put(setLoaderError({ id: 'centraluser/update', message }))
    return
  }

  // TODO: ambient permission error handling

  yield * put(setLoaderSuccess({ id: 'centraluser/update' }))
}

function * onSearchCentralUsers ({ payload: { id, term } }: ReturnType<typeof searchCentralUsers>) {
  const data = yield * select(state => selectCentralUsersList(state, { id }))
  const res = data.filter(d => d.title.toLowerCase().includes(term.toLowerCase()) || d.subtitle.toLowerCase().includes(term.toLowerCase())).map(d => d.id)
  yield * put(addCentralUserIds({ [id]: res }))
}

function * onUserLogout (action: ReturnType<typeof userLogout>) {
  yield * put(batchActions([
    resetUserCentrals(),
    resetCentralUsers()
  ]))
}

export function * saga () {
  yield all([
    takeEvery(fetchUserCentrals, onFetchUserCentrals),
    takeEvery(fetchCentralUsers, onFetchCentralUsers),
    takeEvery(addCentralUser, onAddCentralUser),
    takeEvery(removeCentralUser, onRemoveCentralUser),
    takeEvery(updateCentralUser, onUpdateCentralUser),
    takeEvery(updateCentralUserRequest, onUpdateCentralUserRequest),
    debounce(500, searchCentralUsers, onSearchCentralUsers),
    takeEvery(userLogout, onUserLogout)
  ])
}
