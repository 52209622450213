import React, { useCallback } from 'react'
import { View, Text, FlatList, ImageBackground } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import AmbientBox from '../components/AmbientBox'
import SlaveBox from '../components/SlaveBox'
import SceneBox from '../components/Scenes/SceneBox'
import TopConsumptionBox from '../components/Consumption/TopConsumptionBox'
import { gettext, t } from 'ttag'
import { getFavoriteSceneIds } from '#app/scene'
import { useAppTheme } from '#app/theme'
import { selectFavoriteAmbientIds } from '#app/ambient'
import { LoginButton } from '#app/components'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { ScreenProps } from '#app/types'

const Home = ({ navigation, route }: ScreenProps<'Home'>) => {
  const dispatch = useAppDispatch()

  const theme = useAppTheme()
  const { loading, favorites: { energy, temperature, channels, devices } } = useAppSelector((state) => state.favorites)
  const scenes = useAppSelector(getFavoriteSceneIds)
  const ambients = useAppSelector(selectFavoriteAmbientIds)
  const getError = useAppSelector(state => state.slaves.getError)

  const onRefresh = useCallback(() => {
    dispatch({ type: 'APP_LOAD_DATA' })
  }, [dispatch])

  const data = [
    {
      title: gettext('Ambients'),
      key: 'ambients',
      data: []
    },
    {
      title: gettext('Scenes'),
      key: 'scenes',
      data: []
    },
    {
      title: gettext('Consumption'),
      key: 'consumption',
      data: []
    },
    {
      title: gettext('Devices'),
      key: 'devices',
      data: []
    }
  ]

  const _renderTitle = (title: any) => <Text style={{ color: theme.colors.white, marginLeft: 15, textAlignVertical: 'center', margin: 1, fontSize: 14, fontWeight: 'bold' }}>
    {title}
  </Text>

  const renderItemByType = (param: any) => {
    switch (param.item.key) {
      case 'ambients':
        return (
          <View>
            {_renderTitle(param.item.title)}
            <AmbientBox ambients={ambients} />
          </View>
        )
      case 'scenes':
        return (
          <View>
            {_renderTitle(param.item.title)}
            <SceneBox scenes={scenes} />
          </View>
        )
      case 'consumption':
        return (
          <View>
            {_renderTitle(param.item.title)}
            <TopConsumptionBox energy={energy} temperature={temperature} />
          </View>
        )
      case 'devices':
        return (
          <View>
            {_renderTitle(param.item.title)}
            <SlaveBox channels={channels} devices={devices} />
          </View>
        )
      default:
        return (<></>)
    }
  }

  return (
    <ImageBackground
      source={theme.backgroundImage}
      style={{ flex: 1, backgroundColor: 'rgb(0,0,0)' }}
      imageStyle={{ opacity: 0.6 }}
    >
      {getError
        ? (
            <View style={{ flex: 1, justifyContent: 'center' }}>
              <View style={[theme.form.area, { alignItems: 'center' }]}>
                <MaterialCommunityIcons name="cloud-alert" size={32} color={theme.colors.white} style={{ marginBottom: 15 }} />
                <Text style={{ color: theme.colors.white, fontWeight: 'bold', fontSize: 16, marginBottom: 15, textAlign: 'center' }}>
                  {t`Unable to fetch the latest data. Please check your internet connection and try again.\n\nContact support if this issue persists.`}
                </Text>
                  <View style={{ height: 30 }} />
                <LoginButton onPress={onRefresh} title={t`Try again`} />
              </View>
            </View>
          )
        : <FlatList
          data={data}
          refreshing={loading}
          onRefresh={onRefresh}
          // @ts-expect-error ts-migrate(2365) FIXME: Operator '+' cannot be applied to types '{ title: ... Remove this comment to see the full error message
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          keyExtractor={(item, index) => item + index}
          renderItem={(data) => renderItemByType(data)}
          style={{ paddingTop: 15 }}
        />
      }
    </ImageBackground>
  )
}

export default Home
