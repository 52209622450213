import { AnyAction } from 'redux'
import { Channel, channel } from 'redux-saga'
import { select, all, call, put, take, fork, takeLatest } from 'typed-redux-saga'
import OneSignal from 'react-onesignal'
import { selectCurrentUser, selectIsAuthenticated } from '#app/user'
import { setCurrentCentral } from '#app/central'
import { selectApi } from '#app/api'
import { userLogin, userLogout } from '#app/actions'

const appId = process.env.REACT_NATIVE_ONESIGNAL_APP_ID ?? 'cff2d63a-0b04-4c1f-984f-a3c0756a6448'
const safariWebId = process.env.REACT_NATIVE_ONESIGNAL_SAFARI_WEB_ID ?? 'web.onesignal.auto.32a023df-3e37-4c19-843f-3978a63a946e'

type RedirectChannel = Channel<AnyAction>

interface AppNotification {
  centralUUID?: string
}

export function * initApp (redirectChannel: RedirectChannel) {
  yield call(OneSignal.init, {
    appId,
    safari_web_id: safariWebId,
    welcomeNotification: {
      disable: true
    },
    showCredit: false,
    ...(__DEV__ && {
      allowLocalhostAsSecureOrigin: true
    })
  })
  yield call(OneSignal.addListenerForNotificationOpened, ({ data }) => {
    const { centralUUID }: AppNotification = data
    if (centralUUID != null) {
      redirectChannel.put({ type: 'ONCLICK_ALARM_NOTIFICATION', payload: { centralUUID } })
    }
  })
}

export default function * rootSaga () {
  const redirectChannel: RedirectChannel = yield call(channel)
  yield * call(initApp, redirectChannel)
  yield * call(initUser)
  yield * all([
    takeLatest(userLogin, handleUserLogin),
    takeLatest(userLogout, handleUserLogout),
    takeLatest(setCurrentCentral, handleCentralSelected),
    fork(watchRedirectChannel, redirectChannel)
  ])
}

function * watchRedirectChannel (redirectChannel: RedirectChannel) {
  while (true) {
    const action: AnyAction = yield take(redirectChannel)
    yield put(action)
  }
}

function * handleCentralSelected ({ payload }: ReturnType<typeof setCurrentCentral>) {
  yield * call(OneSignal.showSlidedownPrompt)
}

function * handleUserLogin () {
  yield * call(initUser)
}

function * handleUserLogout () {
  yield * call(OneSignal.getTags, (tags) => { // Force cache refresh
    OneSignal.setSubscription(false)
      .catch(err => console.error(err))
  })
}

function * fetchPushAuth () {
  const api = yield * select(selectApi)
  const res = yield * call(api.getPushAuth)
  if (!res.ok || res.data == null) {
    throw new Error('Could not fetch push auth')
  }
  return res.data
}

function * initUser () {
  try {
    const isUserAuthenticated = yield * select(selectIsAuthenticated)
    if (!isUserAuthenticated) return
    const { id, email, name } = yield * select(selectCurrentUser)
    const { externalUserIdAuthHash, emailAuthHash } = yield * call(fetchPushAuth)
    yield * call(sendUser, {
      externalUserId: id,
      externalUserIdAuthHash,
      email,
      emailAuthHash,
      name
    })
  } catch (err) {
    console.error(err)
  }
}

function * sendUser ({
  externalUserId,
  externalUserIdAuthHash,
  email,
  emailAuthHash,
  name
}: sendUserProps) {
  yield * call(OneSignal.getTags, (tags) => { // Force cache refresh
    OneSignal.setEmail(email, { emailAuthHash })
      .then(() => {
        OneSignal.setExternalUserId(externalUserId, externalUserIdAuthHash)
          .then(() => {
            OneSignal.sendTags({
              uuid: externalUserId, // TODO: remove this tag after cloud migration
              email: email, // TODO: remove this tag after cloud migration
              real_name: name
            })
              .catch(err => console.error(err))
            OneSignal.setSubscription(true)
              .catch(err => console.error(err))
          })
          .catch(err => console.error(err))
      })
      .catch((err) => console.error(err))
  })
}

interface sendUserProps {
  externalUserId: string
  externalUserIdAuthHash: string
  email: string
  emailAuthHash: string
  name: string
}
