import { createSelector } from 'reselect'
import { createCachedSelector } from 're-reselect'
import { filter, find, isEqual, map } from 'lodash'
import { RootState } from '../store'
import { PickerItem } from '#app/picker'

export const getScene = (state: RootState, id: number) => find(state.scene.scenes, (s) => s.id === id) ?? {}

export const getAllScenes = (state: RootState) => state.scene.scenes

export const getAllSceneIds = createSelector(
  [getAllScenes],
  scenes => map(scenes, a => a.id)
)

export const getFavoriteSceneIds = createSelector(
  getAllSceneIds,
  (state: RootState) => state.favorites.favorites.scenes,
  (all, favorites) => filter(favorites, f => all.includes(f))
)

export const getSceneLoadingStatus = createCachedSelector(
  (state: RootState, id: number) => state.scene.activedScene.sceneId === id,
  (state: RootState) => state.scene.activedScene.loading,
  (isActivated, isLoading) => isActivated && isLoading
)(
  (state, id: number) => id
)

export const getSceneData = createCachedSelector(
  getScene,
  getSceneLoadingStatus,
  ({ name }, loading) => ({
    name,
    loading
  })
)(
  (state, id: number) => id
)
export const selectScenesForPicker = createSelector(
  getAllScenes,
  (ss): PickerItem[] => ss.map(({ id, name: title }) => ({ id: String(id), title })),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual
    }
  }
)
