import _, { difference } from 'lodash'
import moment from 'moment'
import { t } from 'ttag'
import { ChannelType, getChannelKind } from '#app/channel'

const datesAreOnSameDay = (first: any, second: any) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

function parseSlaves (payload: any) {
  const data = {
    slaves: [] as any[],
    channels: [] as any[],
    devices: [] as any[],
    favoriteChannels: [] as any[],
    favoriteDevices: [] as any[],
    lookUp: {}
  }

  const lookUp = {}
  data.slaves = _.map(payload, (slave) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    lookUp[slave.id] = {}

    const pulses = slave.pulses

    slave.channels = _.map(slave.channels_list, (channel) => {
      // console.log('channel', channel)

      if (channel.value !== undefined && channel.value !== null) {
        const value = channel.value
        channel.value = value
      } else if (getChannelKind(channel.type as ChannelType) === 'sensor') {
        channel.value = channel.input
      } else {
        // console.log('caiu no output')
        channel.value = channel.output
      }

      channel.status = slave.status
      channel.loading = false

      if (channel.config === null) channel.config = { confirm: false }

      if (data.favoriteChannels.includes(channel.id)) {
        channel.favorite = true
      } else {
        channel.favorite = false
      }

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (pulses && channel.type === 'flow_sensor') {
        const channelPulses = _.find(pulses, { id: channel.channel })

        const pulseCount = _.reduce(channelPulses.last_pulses, (acc, pulse) => {
          const date = moment.utc(pulse.timestamp).toDate()

          if (!datesAreOnSameDay(new Date(), date)) {
            return acc
          }

          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          return acc + pulse.value
        }, 0)

        channel.value = pulseCount
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      lookUp[slave.id][`channel_${channel.id}`] = channel

      return channel
    })

    slave.devices = _.map(slave.devices, (device) => {
      device.status = slave.status

      if (data.favoriteDevices.includes(device.id)) {
        device.favorite = true
      } else {
        device.favorite = false
      }

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      lookUp[slave.id][`device_${device.id}`] = device

      return device
    })

    return slave
  })

  data.channels = (_.chain(data.slaves).map('channels').flatten() as any).value()

  data.devices = (_.chain(data.slaves).map('devices').flatten() as any).value()

  data.lookUp = lookUp

  return data
}

function formatSceneItem (command: any, lookup: any) {
  const slave = lookup[command.id]
  let cmd

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (slave) {
    if (command.command.command !== 'transmit') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      cmd = _.clone(slave[`channel_${command.accessory_id}`])
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      cmd = _.clone(slave[`device_${command.accessory_id}`])
    }
  } else {
    cmd = undefined
  }

  return {
    name: command.name,
    command: cmd,
    command_type: command.command_type,
    complete: command
  }
}

function propertyExists (data: any, name: any) {
  let item; let i = 0
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, no-cond-assign
  while (item = data[i++]) { if (Object.prototype.hasOwnProperty.call(item, name)) return true }
  return false
}

const getYearList = () => {
  const year = (new Date()).getUTCFullYear()
  const array = []

  for (let i = year; i >= (year - 10); i--) {
    array.push({ label: `${i}`, value: `${i}` })
  }

  return array
}

export default {
  parseSlaves,
  formatSceneItem,
  getYearList,
  propertyExists
}

export const getFavoriteData = (
  type: 'energy' | 'temperature' | 'channels' | 'devices',
  favorites: number[],
  id: number
) => {
  const isFavorite = favorites.includes(id)
  const favoriteOption = isFavorite ? t`Remove from Favorites` : t`Add to Favorites`
  const ids = isFavorite
    ? difference(favorites, [id])
    : [...favorites, id]
  const favoriteAction = { type: 'UPDATE_FAVORITE_DEVICE_LIST', payload: { type, ids } }
  return { favoriteOption, favoriteAction }
}
