import React, { useEffect, useCallback, useMemo } from 'react'
import { ActivityIndicator, FlatList, Text } from 'react-native'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { t } from 'ttag'
import { ReplacementSlaveStep, selectReplacementSlaveSteps, configReplacementSlave } from '#app/slave'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { selectLoaderById, useLoaderSuccess } from '#app/loader'

import { ScreenProps } from '#app/types'
import { ItemPress, Right, TextList } from '#app/components/List'

const SwapSlaveStepReplacementConfig = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const dispatch = useAppDispatch()

  const data = useAppSelector(selectReplacementSlaveSteps)
  const loader = useAppSelector(state => selectLoaderById(state, { id: 'slave/replacementConfig' }))
  const { status, message } = loader
  const onSuccess = useCallback(() => {
    navigation.popToTop()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)

  useEffect(() => {
    dispatch(configReplacementSlave({ id }))
  }, [dispatch, id])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        switch (status) {
          case 'loading':
            return <ActivityIndicator />
          case 'success':
          case 'error':
            return (
              <HeaderButtons>
                <Item
                  title={t`Next`}
                  onPress={onSuccess}
                />
              </HeaderButtons>
            )
          default:
            return <></>
        }
      }
    })
  }, [navigation, onSuccess, status])

  return (
    <FlatList
      contentInsetAdjustmentBehavior="automatic"
      ListFooterComponent={<Text>{message}</Text>}
      data={data}
      renderItem={({ item }) => <Step {...item} />}
    />
  )
}

export default SwapSlaveStepReplacementConfig

const Step = React.memo(({ id, title, action }: ReplacementSlaveStep) => {
  const dispatch = useAppDispatch()
  const { isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'slave/replacementConfig' }))
  const { status, isError } = useAppSelector(state => selectLoaderById(state, { id }))
  const detail = useMemo(() => {
    switch (status) {
      case 'loading':
        return <ActivityIndicator />
      case 'error':
        return isLoading
          ? <Text>❌</Text>
          : <Text>🔄</Text>
      case 'success':
        return <Text>✅</Text>
      default:
        return <></>
    }
  }, [status, isLoading])
  const onPress = useCallback(() => {
    dispatch(action)
  }, [dispatch, action])

  return (
    <ItemPress onPress={onPress} disabled={isLoading || !isError}>
      <TextList text={title} />
      <Right>{detail}</Right>
    </ItemPress>
  )
})
