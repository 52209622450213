import React, { useCallback } from 'react'
import { useAppTheme } from '#app/theme'
import ActionButton, { ActionButtonProps } from './ActionButton'
import { useNavigation } from '@react-navigation/native'

const DeleteButton = ({ ...props }: DeleteButtonProps) => {
  const theme = useAppTheme()
  const navigation = useNavigation()
  const onSuccess = useCallback(() => {
    navigation.getParent()?.goBack()
  }, [navigation])

  return (
    <ActionButton
      color={theme.colors.danger}
      onSuccess={onSuccess}
      {...props}
    />
  )
}

export default React.memo(DeleteButton)

export interface DeleteButtonProps extends Omit<ActionButtonProps, 'color'|'onSuccess'> {}
