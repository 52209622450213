import React from 'react'
import { StyleSheet, View, Text, Modal, ActivityIndicator } from 'react-native'
import { gettext } from 'ttag'

const styles = StyleSheet.create({
  activityIndicator: {
    flex: 1
  },
  background: {
    alignItems: 'center',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  container: {
    backgroundColor: 'rgba(0, 0, 0, 0.80)',
    bottom: 0,
    flex: 1,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  textContainer: {
    alignItems: 'center',
    bottom: 0,
    flex: 1,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0
  },
  textContent: {
    color: '#FFF',
    fontSize: 20,
    fontWeight: 'bold',
    height: 50,
    top: 80
  }
})

const Loader = () => (
    <Modal
      animationType="none"
      supportedOrientations={['landscape', 'portrait']}
      transparent
    >
      <View
        style={styles.container}
        key={`spinner_${Date.now()}`}
      >
        <View style={styles.background}>
          <ActivityIndicator
            color="white"
            size="large"
            style={styles.activityIndicator}
          />
          <View style={styles.textContainer}>
            <Text style={styles.textContent}>
              {gettext('Loading...')}
            </Text>
          </View>
        </View>
      </View>
    </Modal>
)

export default Loader
