import React from 'react'
import GenericButton from '../GenericButton'

function getValue (value: any) {
  const temperature = isNaN(value) ? '-' : value.toFixed(2)

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return `${temperature} °C`
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof TemperatureSensor.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'offline' does not exist on type 'Props'.
export default function TemperatureSensor ({ onPress, offline, value, text, disable, removeButton, removeButtonPress, connectionStatus, onLongPress, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon="thermometer"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; status: string; o... Remove this comment to see the full error message
      text={text}
      status={getValue(value)}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      on={!offline}
      offline={offline}
      disable={disable}
      removeButton={removeButton}
      removeButtonPress={removeButtonPress}
      connectionStatus={connectionStatus}
      onLongPress={onLongPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

TemperatureSensor.defaultProps = {
  children: null,
  onPress: () => { }
}
