import React, { Ref } from 'react'
import { Picker, PickerItemProps, PickerProps } from '@react-native-picker/picker'
import { StyleSheet, TextStyle } from 'react-native'
import { useStyle } from 'react-native-style-utilities'
import { useAppTheme } from '#app/theme'
import Right from './Right'
import Left from './Left'
import Item from './Item'
import TextList from './TextList'

const SelectCell = React.forwardRef(<T,>({ title, style, items, ...props }: SelectCellProps<T>, ref: Ref<Picker<T>>) => {
  const theme = useAppTheme()
  const defaultStyle = useStyle(() => [
    theme.form.inputTouchable,
    { color: theme.textColor, flexGrow: 1, fontSize: 15, height: 'auto', padding: 10 }
  ], [theme.form.inputTouchable, theme.textColor])

  return (
    <Item>
      <Left>
        <TextList text={title}/>
      </Left>
      <Right style={styles.right}>
        <Picker
          ref={ref}
          style={StyleSheet.compose<TextStyle>(defaultStyle, style)}
          {...props}
        >
          {items.map((item, idx) => <Picker.Item key={`${idx}`} {...item} />)}
        </Picker>
      </Right>
    </Item>
  )
})

export default SelectCell

const styles = StyleSheet.create({
  right: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})

export interface SelectCellProps<T> extends PickerProps<T> {
  title: string
  items: PickerItemProps[]
}
