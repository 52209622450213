import React from 'react'
import { ColorValue, StyleSheet, Text, TextProps, View, ViewStyle } from 'react-native'

const Banner = ({ color = '#2194f2', style, ...props }: BannerProps) => (
  <View style={StyleSheet.compose<ViewStyle>([styles.container, { backgroundColor: color }], style)}>
    <Text style={styles.text} {...props} />
  </View>
)

export default Banner

const styles = StyleSheet.create({
  container: {
    borderRadius: 5,
    padding: 20
  },
  text: {
    color: '#fff',
    textAlign: 'center'
  }
})

export interface BannerProps extends Omit<TextProps, 'style'> {
  children: string
  color?: ColorValue
  style?: ViewStyle
}
