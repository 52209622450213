import React, { useState, useEffect, useCallback } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { colors } from '#app/theme/colors'
import { Separator, TextList, ItemPress, Icon } from '../components/List'
import { useNavigation } from '@react-navigation/native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { t, gettext } from 'ttag'
import Search from '../components/Search'
import { cloneDeep } from 'lodash'
import { useAppTheme } from '#app/theme'

export default function SlavePicker () {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const theme = useAppTheme()
  const slaves = useAppSelector(state => state.slaves.allSlaves.filter((s: any) => s.is_slave))
  const defaultSlaves = cloneDeep(slaves)

  // const [loading, setLoading] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])

  useEffect(() => {
    navigation.setOptions({
      title: gettext('Devices')
    })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const setIcon = useCallback((type) => {
    let icon = ''
    switch (type) {
      case 'plug':
        icon = 'power-plug'
        break
      case 'lamp':
        icon = 'lightbulb-on'
        break
      case 'remote':
        icon = 'remote'
        break
      case 'pulse_up':
        icon = 'pulse'
        break
      case 'presence_sensor':
        icon = 'run-fast'
        break
      case 'ac':
        icon = 'snowflake'
        break
      case 'tv':
        icon = 'youtube-tv'
        break
      default:
        icon = 'alert-circle'
    }

    return <Icon name={icon} size={20} colorIcon={colors.white} colorBg={colors.purple} />
  }, [])

  const checkType = useCallback((data) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data.device) {
      // setLoading(true)
      dispatch({
        type: 'GET_AND_SET_SLAVE_IR_REQUEST',
        item: {
          slave: data.slave,
          device: data.device
        }
      })
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data.channel) {
      const obj = {
        slave: data.slave,
        channel: data.channel
      }

      if (data.channel.type === 'pulse_up' || data.channel.type === 'pulse_down') {
        dispatch({
          type: 'DATA_INSERT_SLAVE_TO_LIST',
          selectedSlave: data.channel
        })
      } else {
        dispatch({
          type: 'DATA_SET_SELECTED_SLAVE',
          item: obj
        })
      }
    }

    // setLoading(false)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const onTrashPress = useCallback(() => {
    setSearchTerm('')
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSearchResults(slaves)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!searchTerm) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setSearchResults(slaves)
      return
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSearchResults(handleSearch())
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [searchTerm])

  const handleSearch = () => {
    const text = searchTerm.toLowerCase()

    const array = defaultSlaves.map((slave: any) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!slave.name.toLowerCase().includes(text)) {
        slave.devices = slave.devices.filter((d: any) => d.name.toLowerCase().includes(text))
        slave.channels_list = slave.channels_list.filter((c: any) => c.name.toLowerCase().includes(text))
      }

      return slave
    }
    )// TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      .filter((item: any) => item.devices.length || item.channels_list.length || item.name.toLowerCase().includes(text))

    return array
  }

  return (
    <>
        <Search placeholder={t`Search...`} placeholderTextColor={theme.formForm.placeholderTextColor} style={[styles.searchBox, { backgroundColor: theme.formForm.search }]} value={searchTerm} valueTextColor={theme.textColor} onChangeText={setSearchTerm} onTrashPress={onTrashPress} iconColor={theme.formForm.searchIconColor}/>
        <ScrollView>
          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
          {searchResults.map(slave => <View key={`${(slave as any).id}`}>
            <Separator text={(slave as any).name}/>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {(slave as any).channels_list && (slave as any).channels_list.map((channel: any) => <ItemPress onPress={() => checkType({ slave, channel })} key={channel.id}>
                {setIcon(channel.type)}
                <TextList text={channel.name}/>
              </ItemPress>)}

            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {(slave as any).devices && (slave as any).devices.map((device: any) => <ItemPress onPress={() => checkType({ slave, device })} key={device.id}>
                {setIcon(device.category)}
                <TextList text={device.name}/>
              </ItemPress>)}
          </View>)}
        </ScrollView>
      </>
  )
}

const styles = StyleSheet.create({
  searchBox: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    height: 40
  }
})
