import React, { useCallback } from 'react'
import { Text, View, FlatList, ListRenderItemInfo } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { useAppDispatch, useAppSelector } from '#app/hooks'
import { Left, ItemPress, Right } from '#app/components/List'
import { useAppTheme } from '#app/theme'
import { fetchEnvironment, selectVariablesAsList } from '#app/environment'
import { EnvironmentVariable } from '#app/types'
import { selectLoaderById } from '#app/loader'

const Environment = () => {
  const data = useAppSelector(selectVariablesAsList)
  const { isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'environment' }))
  const dispatch = useAppDispatch()
  const onRefresh = useCallback(() => {
    dispatch(fetchEnvironment())
  }, [dispatch])
  useFocusEffect(onRefresh)
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      refreshing={isLoading}
      onRefresh={onRefresh}
    />
  )
}

export default Environment

const renderItem = ({ item }: ListRenderItemInfo<EnvironmentVariable>) => <Item {...item} />

const Item = ({ name, value }: EnvironmentVariable) => {
  const theme = useAppTheme()
  const navigation = useNavigation()
  const onPress = useCallback(() => {
    navigation.navigate('EditEnvironment', { name })
  }, [navigation, name])
  return (
    <ItemPress onPress={onPress}>
      <Left>
        <Text style={{ color: theme.textColor }}>{name}</Text>
      </Left>
      <Right>
        <View style={{ flexDirection: 'row' }}>
          <Text style={{ color: theme.textColor }}>{value}</Text>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
        </View>
      </Right>
    </ItemPress>
  )
}
