import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  bottomSheetShow: ['payload'],
  bottomSheetHide: null
})

export const INITIAL_STATE = {
  active: false,
  currentComponent: null,
  currentTitle: null,
  currentRightButton: null
}

export default Creators

function show (state: any, action: any) {
  const {
    currentComponent,
    currentTitle,
    currentRightButton
  } = action.payload

  return {
    ...state,
    active: true,
    currentComponent,
    currentTitle,
    currentRightButton
  }
}

function hide (state: any) {
  return {
    ...state,
    active: false
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BOTTOM_SHEET_SHOW]: show,
  [Types.BOTTOM_SHEET_HIDE]: hide
})
