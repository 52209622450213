
import React, { useState, useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Platform, View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import { ECharts } from '../components/ECharts'
import moment from 'moment'
import 'moment-timezone'
import TitleLoading from '../components/TitleLoading'
import { Right } from '../components/List'
import Icon from '../components/Icon'
import Utils from '../redux/utils'
import BlurView from '../components/BlurView'
import MyioDatePicker from '../components/MyioDatePicker'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'

export default function Energy () {
  const years = Utils.getYearList()
  const dataInterval = [{ label: gettext('Day'), value: 'hour' }, { label: gettext('Month'), value: 'day' }, { label: gettext('Year'), value: 'month' }]
  const months = [
    { label: gettext('JANUARY'), value: '01' },
    { label: gettext('FEBRUARY'), value: '02' },
    { label: gettext('MARCH'), value: '03' },
    { label: gettext('APRIL'), value: '04' },
    { label: gettext('MAY'), value: '05' },
    { label: gettext('JUNE'), value: '06' },
    { label: gettext('JULY'), value: '07' },
    { label: gettext('AUGUST'), value: '08' },
    { label: gettext('SEPTEMBER'), value: '09' },
    { label: gettext('OCTOBER'), value: '10' },
    { label: gettext('NOVEMBER'), value: '11' },
    { label: gettext('DECEMBER'), value: '12' }
  ]

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  const theme = useAppTheme()
  const consumptionHourlyLoading = useAppSelector(state => state.consumption.consumptionReport.consumptionHourlyLoading)
  const consumptionHourlyLabelValue = useAppSelector(state => state.consumption.consumptionReport.consumptionHourlyLabelValue)

  const [selectedInterval, setSelectedInterval] = useState({ label: gettext('Day'), value: 'hour' })
  const [month, setMonth] = useState({ label: gettext('JANUARY'), value: '01' })
  const [year, setYear] = useState({ label: '2021', value: '2021' })
  const [date, setDate] = useState(moment().set({ hour: 0, minutes: 0, seconds: 0 }).toDate())
  const [showPickerDate, setShowPickerDate] = useState(false)
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    loadData()
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    loadData()
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedInterval])

  const loadData = () => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; value: string; ... Remove this comment to see the full error message
    setMonth(months.find(m => m.value === moment().format('MM')))
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: string; value: string; ... Remove this comment to see the full error message
    setYear(years.find(m => m.value === moment().format('YYYY')))

    _changeInterval(selectedInterval)
  }

  const _getDate = () => {
    return moment(date).startOf('day').tz('America/Sao_Paulo').format('DD/MM/YYYY').toString()
  }

  const _onChangeDate = useCallback((selectedDate) => {
    setShowPickerDate(false)
    setDate(selectedDate)

    if (moment(selectedDate, 'DD/MM/YYYY', true).isValid()) {
      _changeDate(selectedInterval, selectedDate, selectedDate)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [isValid, date])

  const _changeInterval = (interval: any) => {
    if (interval.value === 'hour') {
      _changeDate(interval, date, date)
    } else if (interval.value === 'day') {
      _changeMonth(interval, month, year)
    } else if (interval.value === 'month') {
      _changeYear(interval, month, year)
    }
  }

  const _changeDate = (interval: any, dateStart: any, dateEnd: any) => {
    const startDate = moment(dateStart, 'DD/MM/YYYY').startOf('day').format('YYYY-MM-DD 03:00:00')
    const endDate = moment(dateEnd, 'DD/MM/YYYY').endOf('day').add(1, 'days').format('YYYY-MM-DD 02:59:00')

    dispatch({
      type: 'CONSUMPTION_GET_ALL_HOURLY_REQUEST',
      interval: interval.value,
      startDate,
      endDate,
      typeReport: 'report'
    })
  }

  const _changeMonth = (interval: any, _month: any, year: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const startDate = moment(`${year.value}-${_month.value}-01`).startOf('month').format('YYYY-MM-DD 03:00:00')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const endDate = moment(`${year.value}-${_month.value}-01`).endOf('month').add(1, 'days').format('YYYY-MM-DD 02:59:00')

    dispatch({
      type: 'CONSUMPTION_GET_ALL_HOURLY_REQUEST',
      interval: interval.value,
      startDate,
      endDate,
      typeReport: 'report'
    })
  }

  const _changeYear = (interval: any, _month: any, year: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const startDate = moment(`${year.value}-${month.value}-01`).startOf('year').format('YYYY-MM-DD 03:00:00')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const endDate = moment(`${year.value}-${month.value}-01`).endOf('year').add(1, 'days').format('YYYY-MM-DD 02:59:00')

    dispatch({
      type: 'CONSUMPTION_GET_ALL_HOURLY_REQUEST',
      interval: interval.value,
      startDate,
      endDate,
      typeReport: 'report'
    })
  }

  const _getChartDf = () => {
    let df = consumptionHourlyLabelValue.map((c: any) => {
      return {
        value: [c.label, parseFloat(c.value)]
      }
    })

    if (selectedInterval.value !== 'hour') {
      df = consumptionHourlyLabelValue.map((c: any) => {
        return {
          value: [moment.utc(c.label).format('YYYY-MM-DD'), parseFloat(c.value)]
        }
      })
    }

    return df
  }

  const _getChartTooltipFormatter = () => {
    let formatter = (params: any) => {
      const date = new Date(params[0].value[0])
      const value = parseFloat(params[0].value[1]).toFixed(2)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const dt = ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2)

      return `${dt}</br>${value}kWh`
    }

    if (selectedInterval.value === 'day') {
      formatter = (params) => {
        const date = new Date(params[0].value[0])
        const value = parseFloat(params[0].value[1]).toFixed(2)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const dt = ('0' + date.getUTCDate()).substr(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).substr(-2) + '/' + ('0' + date.getUTCFullYear()).substr(-2)

        return `${dt}</br>${value}kWh`
      }
    } if (selectedInterval.value === 'month') {
      formatter = (params) => {
        const date = new Date(params[0].value[0])
        const value = parseFloat(params[0].value[1]).toFixed(2)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const dt = ('0' + (date.getUTCMonth() + 1)).substr(-2) + '/' + ('0' + date.getUTCFullYear()).substr(-2)

        return `${dt}</br>${value}kWh`
      }
    }

    return formatter
  }

  const _getChartAxisLabelFormatter = () => {
    let formatter = (value: any, index: any) => {
      const dt = new Date(value)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return ('0' + dt.getHours()).substr(-2) + 'H'
    }

    if (selectedInterval.value === 'day') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any, index: any) => number' is not a... Remove this comment to see the full error message
      formatter = (value, index) => {
        const dt = new Date(value)
        return dt.getUTCDate()
      }
    } else if (selectedInterval.value === 'month') {
      formatter = (value, index) => {
        const dt = new Date(value)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return ('0' + dt.getUTCMonth()).substr(-2)
      }
    }

    return formatter
  }

  const renderDatePicker = () => {
    if (selectedInterval.value === 'hour') {
      if (Platform.OS !== 'web') {
        return (
          <View style={styles.flexPicker}>
            <TouchableOpacity
              onPress={() => { setShowPickerDate(!showPickerDate) }}
              style={[theme.form.inputTouchable, theme.border]}
            >
              <Text style={{ fontSize: 15, color: theme.textColor }}>{_getDate()}</Text>
              <Right>
                <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
              </Right>
            </TouchableOpacity>

            {showPickerDate && (
              <MyioDatePicker date={date} theme={theme} isOnValid={setIsValid} onDateChange={(value: any) => _onChangeDate(value)} />
            )}
          </View>
        )
      } else {
        return (
          <View style={styles.flexPicker}>
            <MyioDatePicker date={date} theme={theme} isOnValid={setIsValid} onDateChange={(value: any) => _onChangeDate(value)} />
          </View>
        )
      }
    }
  }

  return (
      <>

        <View style={[theme.form.formGroup, theme.pb0, { marginBottom: 0, flexDirection: 'row' }]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Period'),
                data: dataInterval,
                value: selectedInterval,
                search: false,
                multiple: false,
                required: true,
                onPressOk: (data: any) => {
                  setSelectedInterval(data.selectedItem)
                }
              })
            }}
            style={[theme.form.inputTouchable, styles.flexPicker, theme.border]}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>{selectedInterval.label}</Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        <View style={[theme.form.formGroup, theme.pb0, { marginBottom: 0, flexDirection: 'row' }]}>
          {renderDatePicker()}

          {selectedInterval.value === 'day' &&
            <View style={[styles.flexPicker, {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }]}
            >
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ModalPicker', {
                    name: gettext('Select an month...'),
                    data: months,
                    value: month,
                    search: true,
                    multiple: false,
                    required: true,
                    onPressOk: (data: any) => {
                      setMonth(data.selectedItem)
                      _changeMonth(selectedInterval, data.selectedItem, year)
                    }
                  })
                }}
                style={[theme.form.inputTouchable, theme.border, {
                  justifyContent: 'center',
                  width: '50%',
                  borderTopEndRadius: 0,
                  borderBottomEndRadius: 0,
                  borderRightWidth: 0
                }]}
              >
                <Text style={{ fontSize: 15, color: theme.textColor }}>{month.label}</Text>
                <Right>
                  <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                </Right>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ModalPicker', {
                    name: gettext('Select a year...'),
                    data: years,
                    value: year,
                    search: true,
                    multiple: false,
                    required: true,
                    onPressOk: (data: any) => {
                      setYear(data.selectedItem)
                      _changeMonth(selectedInterval, month, data.selectedItem)
                    }
                  })
                }}
                style={[theme.form.inputTouchable, theme.border, {
                  justifyContent: 'center',
                  width: '50%',
                  borderTopStartRadius: 0,
                  borderBottomStartRadius: 0,
                  borderLeftWidth: 0
                }]}
              >
                <Text style={{ fontSize: 15, color: theme.textColor }}>{year.label}</Text>
                <Right>
                  <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                </Right>
              </TouchableOpacity>
            </View>}

          {selectedInterval.value === 'month' &&
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('ModalPicker', {
                  name: gettext('Select a year...'),
                  data: years,
                  value: year,
                  search: true,
                  multiple: false,
                  required: true,
                  onPressOk: (data: any) => {
                    setYear(data.selectedItem)
                    _changeYear(selectedInterval, month, data.selectedItem)
                  }
                })
              }}
              style={[theme.form.inputTouchable, styles.flexPicker, theme.border]}
            >
              <Text style={{ fontSize: 15, color: theme.textColor }}>{year.label}</Text>
              <Right>
                <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
              </Right>
            </TouchableOpacity>}
        </View>

        <View style={[theme.mb3, { flex: 1, flexDirection: 'column', alignItems: 'stretch', display: 'flex', padding: 5 }]}>
          <BlurView tint={theme.blurView.tint} intensity={theme.blurView.intensity} style={[theme.border, theme.p3, { height: '100%' }]}>
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '(string &... Remove this comment to see the full error message */}
            <TitleLoading loading={consumptionHourlyLoading} text={t`Consumption until now`} />
            <View style={{ flex: 1 }}>
              <ECharts
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ option: { xAxis: { show: boolean; type: st... Remove this comment to see the full error message
                option={{
                  xAxis: {
                    show: true,
                    type: 'category',
                    maxInterval: 3600 * 1000 * 24,
                    axisLine: {
                      lineStyle: {
                        color: theme.textColor
                      }
                    },
                    axisLabel: {
                      formatter: _getChartAxisLabelFormatter()
                    }
                  },
                  yAxis: {
                    show: true,
                    type: 'value',
                    axisLine: {
                      lineStyle: {
                        color: theme.textColor
                      }
                    },
                    splitLine: {
                      show: false
                    }
                  },
                  tooltip: {
                    show: true,
                    trigger: 'axis',
                    formatter: _getChartTooltipFormatter(),
                    axisPointer: {
                      type: 'none'
                    }
                  },
                  series: [{
                    data: _getChartDf(),
                    type: 'bar',
                    itemStyle: { normal: { color: theme.colors.default } }
                  }],
                  grid: {
                    left: 25,
                    top: 15,
                    right: 25,
                    bottom: 25
                  }
                }}
                backgroundColor="transparent"
              />
            </View>
          </BlurView>
        </View>
      </>
  )
}

const styles = StyleSheet.create({
  flexPicker: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    margin: 5
  }
})
