import React from 'react'
import { Text, StyleSheet, Dimensions, ActivityIndicator, Pressable, PressableProps } from 'react-native'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getSceneData } from '#app/scene'
import BlurView from '../BlurView'
import { useAppTheme } from '#app/theme'

export function calculateButtonWidth () {
  const screenWidth = Dimensions.get('window').width

  const size = ((screenWidth / 3) - 15)

  if (size > 220) {
    return 220
  }

  return size
}

export function calculateButtonHeight () {
  const size = (calculateButtonWidth() / 2)

  if (size > 55) {
    return 55
  }

  return size
}

export interface SceneProps extends PressableProps {
  sceneId: number
}

const Scene = ({ sceneId }: SceneProps) => {
  const dispatch = useAppDispatch()
  const theme = useAppTheme()
  const { name, loading } = useAppSelector(state => getSceneData(state, sceneId))

  const onPress = () => {
    dispatch({ type: 'UI_SCENE_PRESS', payload: { sceneId } })
  }

  const onLongPress = () => {
    dispatch({ type: 'UI_SCENE_LONGPRESS', payload: { sceneId } })
  }

  return (
    <Pressable onPress={onPress} onLongPress={onLongPress} disabled={loading}>
      <BlurView
        style={[styles.container, theme.border]}
        tint={theme.scene.blurView.tint}
        intensity={theme.scene.blurView.intensity}
      >
        <Text style={[styles.text, { color: theme.scene.textColor }]} numberOfLines={2}>{name}</Text>
        {/* <Text style={styles.status}>{status}</Text> */}
        {loading && (<ActivityIndicator color={theme.titleLoading.indicatorColor} size="small" />)}
      </BlurView>
    </Pressable>
  )
}

export default React.memo(Scene)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: calculateButtonWidth(),
    height: calculateButtonHeight(),
    marginLeft: 6,
    marginRight: 6,
    marginTop: 12,
    borderRadius: 12,
    borderWidth: 0,
    padding: 18
  },
  text: {
    fontSize: 13,
    fontWeight: 'bold'
  },
  status: {
    marginTop: 4,
    fontSize: 12
  }
})
