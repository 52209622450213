import React, { Component } from 'react'
import { View, StyleSheet } from 'react-native'
import { connect } from 'react-redux'
import { colors } from '#app/theme/colors'
import { Button } from '../components/Utils'
import DeviceSelector from '../components/DeviceSelector'
import { t, gettext } from 'ttag'

type State = any

class SlavePicker extends Component<{}, State> {
  constructor (props: {}) {
    super(props)
    this.state = {
      active: false,
      text: gettext('Off')
    }
  }

  componentDidMount () {
    (this.props as any).navigation.setOptions({
      title: gettext('Status')
    })
  }

  onButtonPress () {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    (this.props as any).updateStateValue(!this.state.active ? 100 : 0)

    this.setState((prevState: any) => ({
      active: prevState.active === false,
      text: prevState.text === gettext('Off') ? gettext('On') : gettext('Off')
    }))
  }

  sendButton () {
    (this.props as any).addSlaveToList((this.props as any).selectedSlave)
  }

  render () {
    return (<View style={styles.container}>

        <DeviceSelector device={{
          ...(this.props as any).selectedSlave,
          is_channel: true,
          value: this.state.active === true ? 100 : 0
        }} onPress={this.onButtonPress.bind(this)}/>

        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ text: string; bgColor: string; textColor: ... Remove this comment to see the full error message */}
        <Button text={t`Add`} bgColor={colors.green} textColor={colors.white} onPress={() => this.sendButton()}/>

      </View>)
  }
}

function mapDispatchToProps (dispatch: any) {
  return {
    updateStateValue: (value: any) => {
      dispatch({
        type: 'DATA_UPDATE_VALUE_SELECTED_SLAVE', value
      })
    },
    addSlaveToList: (selectedSlave: any) => {
      dispatch({
        type: 'DATA_INSERT_SLAVE_TO_LIST',
        selectedSlave
      })
    }
  }
}

function mapStateToProps (state: any) {
  return {
    selectedSlave: state.scene.selectedSlave
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SlavePicker)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 15
  },
  buttonOutside: {
    marginTop: 50,
    marginBottom: 50,
    width: 250,
    height: 250,
    borderRadius: 250,
    backgroundColor: '#212121',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonInside: {
    width: 200,
    height: 200,
    borderRadius: 250,
    backgroundColor: '#2B2B2B',
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4
  },
  btnIcon: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 5
  },
  text: {
    alignSelf: 'center'
  },
  btnIconOff: {
    color: colors.grey
  },
  btnIconOn: {
    color: colors.green
  }
})
