import React from 'react'
import { TextInput, TextInputProps } from 'react-native'
import { t } from 'ttag'
import LoginTextInput from './LoginTextInput'

const LoginPasswordInput = React.forwardRef<TextInput, TextInputProps>((props, ref) =>
  <LoginTextInput
    ref={ref}
    autoCapitalize="none"
    autoCorrect={false}
    placeholder={t`Password`}
    secureTextEntry
    textContentType="password"
    autoCompleteType="password"
    {...props}
  />
)

export default LoginPasswordInput
