import React, { createRef } from 'react'
import 'react-native-gesture-handler'
import FlashMessage from 'react-native-flash-message'
import { ActionSheetProvider } from '@expo/react-native-action-sheet'
import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux'
import { store, persistor } from './store'
import { AppNavigationContainer } from '#app/routes'
import * as Sentry from '@sentry/react-native'
import { Platform } from 'react-native'

if (!__DEV__) {
  Sentry.init({
    environment: process.env.REACT_NATIVE_SENTRY_ENVIRONMENT ?? process.env.NODE_ENV,
    dsn: 'https://bfec59960c59486db4b0d5d867b5d6b5@o483041.ingest.sentry.io/5534158'
  })
}

export const actionSheetRef = createRef<ActionSheetProvider>()

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ActionSheetProvider ref={actionSheetRef}>
            <>
              <AppNavigationContainer />
              <FlashMessage position="top" />
            </>
          </ActionSheetProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  )
}

// @ts-expect-error
export default Platform.select({
  web: App,
  default: Sentry.wrap(App)
})
