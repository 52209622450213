import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import Energy from '../Switch/Energy'
import TemperatureSensor from '../Remote/TemperatureSensor'
import { useActionSheet } from '@expo/react-native-action-sheet'
import NoContent from '../Utils/NoContent'
import { useAppDispatch, useAppSelector } from '../../hooks'
import ConsumptionActions from '../../redux/consumption'
import { gettext } from 'ttag'
import { compact, find, map } from 'lodash'
import { getFavoriteData } from '../../redux/utils'
import { selectCan } from '#app/centraluser'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'

const TopConsumptionBox = ({ energy = [], temperature = [] }: TopConsumptionBoxProps) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const theme = useAppTheme()
  const { showActionSheetWithOptions } = useActionSheet()
  const can = useAppSelector(selectCan)
  const canUse = can('use')

  const allSlaves = useAppSelector((state) => state.slaves.allSlaves)
  const mapE = map(energy, id => {
    const slave = find(allSlaves, { id, is_slave: true })
    if (slave == null) return
    return { ...slave, is_energy: true }
  })
  const mapT = map(temperature, id => {
    const slave = find(allSlaves, { id, is_slave: true })
    if (slave == null) return
    return { ...slave, is_temperature: true }
  })
  const items = compact([...mapE, ...mapT])

  const onClickEnergyThreePhase = (device: any) => {
    dispatch(ConsumptionActions.setSelectedThreePhase(device))
    navigation.navigate('EnergyThreePhase')
  }

  const onLongPressSlave = useCallback((device) => {
    const isEnergy = device.is_energy === true
    const type = isEnergy ? 'energy' : 'temperature'
    const favorites = isEnergy ? energy : temperature
    const { favoriteOption, favoriteAction } = getFavoriteData(type, favorites, device.id)
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 2,
        options: [gettext('Product Configuration'), favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 1:
            dispatch(favoriteAction)
            break
          case 2:
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, energy, temperature, canUse])

  const renderItem = (slave: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (slave.is_energy) {
      return (
        <Energy
          key={slave.id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; text: any; value: any; onLongPre... Remove this comment to see the full error message
          text={slave.name}
          value={slave.lastConsumption}
          onLongPress={() => onLongPressSlave(slave)}
          onPress={() => onClickEnergyThreePhase(slave)}
        />
      )
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (slave.is_temperature) {
      return (
        <TemperatureSensor
          key={slave.id}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: any; text: any; value: any; onLongPre... Remove this comment to see the full error message
          text={slave.name}
          value={slave.temperature}
          onLongPress={() => onLongPressSlave(slave)}
        />
      )
    }
  }

  return (
    <View style={styles.box}>
      <View style={[styles.box]}>
        {items.length === 0
          ? (<NoContent type="consumption" theme={theme} style={{ marginRight: 35 }} />)
          : (<View style={styles.topConsumptions}>
            {items.map(renderItem)}
            </View>)}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 10
  },
  topConsumptions: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    flexWrap: 'wrap'
  }
})

export default TopConsumptionBox
export interface TopConsumptionBoxProps {
  energy?: number[]
  temperature?: number[]
}
