import { AnyAction } from 'redux'
import { select, put, call, delay, take, race, all, takeLeading, takeLatest } from 'redux-saga/effects'
import SceneActions from '../redux/scene'
import * as RootNavigation from '../navigation/RootNavigation'
import WsActions from '../redux/ws'
import { gettext } from 'ttag'
import { showMessage } from 'react-native-flash-message'
import { selectCan } from '#app/centraluser'
import { selectApi } from '#app/api'

export const SCENE_EXECUTION_STEP_TIMEOUT = 5000
const MESSAGE_DURATION_TIME = 4000

export default function * rootSaga () {
  yield all([
    takeLeading('SCENE_EXECUTION_TRIGGER', sceneExecutionTriggerHandler),
    takeLatest('SCENE_EXECUTION_REQUEST', sceneExecutionRequestHandler),
    takeLatest('SCENE_EXECUTION_SUCCESS', sceneExecutionSuccessHandler),
    takeLatest('SCENE_EXECUTION_TIMEOUT', sceneExecutionTimeoutHandler),
    takeLatest('SCENE_EXECUTION_ERROR', sceneExecutionErrorHandler)
  ])
}

export function * getScenes () {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const currentCentral = yield select(state => state.central.currentCentral)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getScenes, currentCentral)

    const scenes = response.data.map((s: any) => {
      if (s.config === null) s.config = { confirm: false }
      try {
        if (typeof s.json === 'string') {
          s.json = JSON.parse(s.json)
          console.warn('broken scene repaired')
        }
      } catch (err) {
        console.error(err)
        s.json = []
      }
      return s
    })

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SceneActions.dataGetScenesRequestSuccess(scenes))
    } else {
      yield put(SceneActions.dataGetScenesRequestFailure())
    }
  } catch (e) {
    yield put(SceneActions.dataGetScenesRequestFailure())
  }
}

export function * createScene () {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const selectedScene = yield select(state => state.scene.selectedScene)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.createScene, selectedScene)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SceneActions.createSceneRequestSuccess())
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      yield getScenes(api)
      RootNavigation.pop()
    } else {
      yield put(SceneActions.createSceneRequestFailure())
    }
  } catch (e) {
    yield put(SceneActions.createSceneRequestFailure())
  }
}

export function * deleteScene (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(api.deleteScene, action.sceneId)

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (response.ok) {
    yield put(SceneActions.deleteSceneRequestSuccess())
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    yield getScenes(api)
    RootNavigation.pop()
  } else {
    yield put(SceneActions.deleteSceneRequestFailure())
  }
}

export function * updateScene () {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const selectedScene = yield select(state => state.scene.selectedScene)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.updateScene, selectedScene)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SceneActions.updateSceneRequestSuccess())
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      yield getScenes(api)
      RootNavigation.pop()
    } else {
      yield put(SceneActions.updateSceneRequestFailure())
    }
  } catch (e) {
    yield put(SceneActions.updateSceneRequestFailure())
  }
}

export function * setSlave () {
  RootNavigation.navigate('StatePicker')
}

export function * setScene (action: any) {
  RootNavigation.navigate('SceneDetails')
}

export function * insertSlaveToList () {
  RootNavigation.navigate('SceneDetails')
}

export function * getAndSetSlaveIr (action: any) {
  const { device } = action.item
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getRemotes, device.id)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      const output = response.data
      output[0].id = device.id

      yield put(SceneActions.getAndSetSlaveIrRequestSuccess(output))
      RootNavigation.navigate('IrPicker')
    } else {
      yield put(SceneActions.getAndSetSlaveIrRequestFailure())
    }
  } catch (e) {
    yield put(SceneActions.getAndSetSlaveIrRequestFailure())
  }
}

export function * findCommandIr (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const state = yield select()

  const output = {
    name: action.item.name,
    id: state.scene.selectedSlaveIr.id,
    accessory_id: state.scene.selectedSlaveIr.id,
    command: {
      command: 'transmit',
      id: state.scene.selectedSlaveIr.id,
      rfir_command_id: action.item.rfir_command_id,
      type: 'slave'
    },
    command_type: 'accessory'
  }

  yield put(SceneActions.insertSlaveIrToList(output))

  yield call(RootNavigation.pop, 2)
}

export function * sceneExecutionTriggerHandler ({ payload }: AnyAction) {
  const can: ReturnType<typeof selectCan> = yield select(selectCan)
  const canUse: boolean = yield call(can, 'use')
  if (!canUse) return

  yield all([
    put({ type: 'SCENE_EXECUTION_REQUEST', payload }),
    take('SCENE_EXECUTION_FULFILL')
  ])
}

export function * sceneExecutionRequestHandler ({ payload }: AnyAction) {
  try {
    yield put(SceneActions.activateScene({ loading: true, sceneId: payload.id }))
    yield put(WsActions.wsConnectionSendMessage({ type: 'scene', command: 'activate', id: payload.id }))
    let timeoutReached = false
    while (true) {
      const { status, timeout } = yield race({
        status: take('WS_MESSAGE_SCENE_STATUS'),
        timeout: delay(SCENE_EXECUTION_STEP_TIMEOUT)
      })
      switch (status?.payload.status) {
        case 'finished':
          yield put({ type: 'SCENE_EXECUTION_SUCCESS', payload })
          return
        case 'error':
          throw new Error()
        case 'ok':
          timeoutReached = false
      }
      if (timeoutReached) {
        return
      }
      if (timeout != null) {
        timeoutReached = true
        yield put({ type: 'SCENE_EXECUTION_TIMEOUT', payload })
      }
    }
  } catch {
    yield put({ type: 'SCENE_EXECUTION_ERROR', payload })
  } finally {
    yield put({ type: 'SCENE_EXECUTION_FULFILL', payload })
  }
}

export function * sceneExecutionTimeoutHandler () {
  yield put(SceneActions.finishedScene())
  yield call(showMessage, {
    description: gettext('Scene execution may not have completed'),
    message: gettext('Please check the logs before trying again.'),
    type: 'warning',
    duration: MESSAGE_DURATION_TIME
  })
}

export function * sceneExecutionSuccessHandler () {
  yield put(SceneActions.finishedScene())
  yield call(showMessage, {
    description: gettext('Scene executed'),
    message: gettext('Done...'),
    type: 'success',
    duration: MESSAGE_DURATION_TIME
  })
}

export function * sceneExecutionErrorHandler () {
  yield put(SceneActions.errorExecutingScene({ loading: false, error: true, errorMessage: gettext('error-message') }))
  yield call(showMessage, {
    description: gettext('Scene execution failed'),
    message: gettext('Error...'),
    type: 'danger',
    duration: MESSAGE_DURATION_TIME
  })
}
