import { combineReducers } from 'redux'

import { reducer as ambient } from '#app/ambient'
import { reducer as api } from '#app/api'
import { reducer as central } from '#app/central'
import { reducer as centralambient } from '#app/centralambient'
import { reducer as centraluser } from '#app/centraluser'
import { reducer as environment } from '#app/environment'
import { reducer as loader } from '#app/loader'
import { reducer as picker } from '#app/picker'
import { reducer as user } from '#app/user'
import { reducer as theme } from '#app/theme'
import { reducer as slave } from '#app/slave'
import { reducer as alertsReducer } from './alerts'
import { reducer as ambientsReducer } from './ambients'
import { reducer as bottomSheetReducer } from './bottomSheet'
import { reducer as consumptionReducer } from './consumption'
import { reducer as logsReducer } from './logs'
import { reducer as lookupReducer } from './lookUp'
import { reducer as remoteReducer } from './remote'
import { reducer as sceneReducer } from './scene'
import { reducer as schedulesReducer } from './schedules'
import { reducer as slavesReducer } from './slaves'
import { reducer as wsReducer } from './ws'
import { reducer as alarmsReducer } from './alarms'
import { reducer as favoritesReducer } from './favorites'

export const rootReducer = combineReducers({
  ambient,
  api,
  central,
  centralambient,
  centraluser,
  environment,
  loader,
  picker,
  user,
  theme,
  slave,
  alarms: alarmsReducer,
  alerts: alertsReducer,
  ambients: ambientsReducer,
  bottomSheet: bottomSheetReducer,
  consumption: consumptionReducer,
  favorites: favoritesReducer,
  logs: logsReducer,
  lookup: lookupReducer,
  remote: remoteReducer,
  scene: sceneReducer,
  schedules: schedulesReducer,
  slaves: slavesReducer,
  ws: wsReducer
})

export type RootState = ReturnType<typeof rootReducer>
