import React from 'react'
import { NavigationContainer, NavigatorScreenParams, ParamListBase, StackActions } from '@react-navigation/native'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { OverflowMenuProvider, HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { enableScreens } from 'react-native-screens'

import { selectIsCentralSelected } from '#app/central'
import { selectCurrentUserCentral } from '#app/centraluser'
import { selectIsAuthenticated, selectIsSuperadmin } from '#app/user'
import { useAppSelector, useCentralConnectivityStatus } from '#app/hooks'
import { useAppNavigationTheme } from '#app/theme'

import { navigationRef } from '../navigation/RootNavigation'
import Icon from '../components/Icon'

import AlarmForm from '../pages/AlarmForm'
import AlarmList from '../pages/AlarmList'
import Ambient from '../pages/Ambient'
import AmbientAddSlaves from '../pages/AmbientAddSlaves'
import AmbientDetail from '../pages/AmbientDetail'
import Ambients from '../pages/Ambients'
import CentralPicker from '../pages/CentralPicker'
import CreateOrUpdateChannel from '../pages/CreateOrUpdateChannel'
import AddCentralUser from '../pages/AddCentralUser'
import EditCentralUser from '../pages/EditCentralUser'
import Dashboard from '../pages/Dashboard'
import NodeRed from '../pages/NodeRed'
import EnergyThreePhase from '../pages/EnergyThreePhase'
import Favorites from '../pages/Home'
import FilterAmbients from '../pages/FilterAmbients'
import FilterLogs from '../pages/FilterLogs'
import InOutPage from '../pages/InOutPage'
import CreateOrUpdateRfIrDevice from '../pages/CreateOrUpdateRfIrDevice'
import IrPicker from '../pages/IrPicker'
import Login from '../pages/Login'
import Logs from '../pages/Logs'
import ModalPicker from '../pages/ModalPicker'
import ModalPickerSection from '../pages/ModalPickerSection'
import NewSlaveStepCode from '../pages/newSlave/NewSlaveStepCode'
import NewSlaveStepReplacementConfig from '../pages/newSlave/NewSlaveStepReplacementConfig'
import NewSlaveStepOnboard from '../pages/newSlave/NewSlaveStepOnboard'
import NewSlaveStepSuccess from '../pages/newSlave/NewSlaveStepSuccess'
import Notifications from '../pages/Notifications'
import Register from '../pages/Register'
import RemoteButton from '../pages/RemoteButton'
import RemoteConfig from '../pages/RemoteConfig'
import RemoteControl from '../pages/RemoteControl'
import ResetPassword from '../pages/ResetPassword'
import SceneDetails from '../pages/SceneDetails'
import SceneList from '../pages/SceneList'
import ScheduleDetails from '../pages/ScheduleDetails'
import Schedules from '../pages/Schedules'
import Settings from '../pages/Settings'
import SlaveClamp from '../pages/SlaveClamp'
import SlaveConfig from '../pages/SlaveConfig'
import SlaveDetail from '../pages/SlaveDetail'
import SlaveChannelList from '../pages/SlaveChannelList'
import SlaveRfIrDeviceList from '../pages/SlaveRfIrDeviceList'
import SlaveList from '../pages/SlaveList'
import SlavePicker from '../pages/SlavePicker'
import SlaveTemperatureTolerance from '../pages/SlaveTemperatureTolerance'
import StatePicker from '../pages/StatePicker'
import CentralUsers from '../pages/CentralUsers'
import SuperadminHome from '../pages/SuperadminHome'
import SuperadminCentrals from '../pages/SuperadminCentrals'
import SuperadminCentralDetail from '../pages/SuperadminCentralDetail'
import SuperadminCentralEdit from '../pages/SuperadminCentralEdit'
import {
  Environment,
  EditEnvironment
} from '#app/pages'
import { PickerItem, PickerScreen } from '#app/picker'
import { brandPrimary } from '#app/theme/colors'
import { useApiData } from '#app/api'
import { Central, CentralUser, ScreenProps } from '#app/types'
import { createSelector } from 'reselect'
import { selectSlaveName } from '#app/slave'
import { PropId } from 'robodux'
import SwapSlaveStepCode from '#app/pages/newSlave/SwapSlaveStepCode'

enableScreens()

const Stack = createNativeStackNavigator<RootStackParamList>()
const BottomTab = createBottomTabNavigator()

const SettingsTab = () => (
  <Stack.Navigator>
    <Stack.Screen name="Settings" component={Settings} options={{ title: t`Settings` }} />
    <Stack.Screen name="Schedules" component={Schedules} options={{ title: t`Schedules` }} />
    <Stack.Screen name="SceneList" component={SceneList} options={{ title: t`Scenes` }} />
    <Stack.Screen name="SlaveList" component={SlaveList} options={{ title: t`Products` }} />
    <Stack.Screen name="Environment" component={Environment} options={{ title: t`Environment` }} />
      <Stack.Screen name="CentralUsers" component={CentralUsers} options={{ title: t`Users` }} getId={({ params }) => params.id} />
      <Stack.Screen name="EditCentralUser" component={EditCentralUser} options={{ title: '' }} getId={({ params }) => params.id} />
      <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params }) => params.id} />
    <Stack.Screen name="NodeRed" component={NodeRed} options={{ title: 'Node-RED' }} />
  </Stack.Navigator>
)

const FavoritesTab = () => {
  const { name: title } = useAppSelector(selectCurrentUserCentral)
  return (
    <Stack.Navigator>
      <Stack.Screen name="Favorites" component={Favorites} options={{ title }} />
    </Stack.Navigator>
  )
}

const AmbientsTab = () => (
  <Stack.Navigator>
    <Stack.Screen name="Ambients" component={Ambients} options={{ title: t`Ambients` }} />
  </Stack.Navigator>
)

const NotificationsTab = () => (
  <Stack.Navigator>
    <Stack.Screen name="Notifications" component={Notifications} options={{ title: t`Notifications` }} />
  </Stack.Navigator>
)

const DashboardTab = () => (
  <Stack.Navigator>
    <Stack.Screen name="Dashboard" component={Dashboard} options={{ title: '' }} />
  </Stack.Navigator>
)

const NewSlaveNavigator = () => (
  <Stack.Navigator >
    <Stack.Group screenOptions={{
      gestureEnabled: false,
      headerLeft: () => <></>
    }}>
      <Stack.Screen name="NewSlaveStepCode" component={NewSlaveStepCode}
        options={({ route: { params } }) => ({
          headerLeft: (params?.isCancelable ?? true)
            ? () => <CancelButton />
            : () => <></>,
          title: t`Product Code`
        })}
      />
      <Stack.Screen name="NewSlaveStepOnboard" component={NewSlaveStepOnboard} options={{ title: '' }} />
      <Stack.Screen name="NewSlaveStepSuccess" component={NewSlaveStepSuccess} options={{ title: t`Success` }} />
    </Stack.Group>
    <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params }) => params.id} />
  </Stack.Navigator>
)

const SlaveDetailNavigator = ({ route: { params } }: ScreenProps<'SlaveDetail'>) => {
  const title = useAppSelector(state => selectSlaveName(state, params))
  return (
    <Stack.Navigator>
      <Stack.Screen initialParams={params} name="SlaveDetailIndex" component={SlaveDetail} options={{ headerLeft: () => <CloseButton />, title }} />
      <Stack.Screen initialParams={params} name="SlaveConfig" component={SlaveConfig} options={{ title: '' }} />
      <Stack.Screen initialParams={params} name="InOutPage" component={InOutPage} options={({ route: { params: { channel } } }) => {
        const n = channel + 1
        return { title: t`Channel ${n}` }
      }} getId={({ params: { channel } }) => String(channel)} />
      <Stack.Screen initialParams={params} name="SlaveClamp" component={SlaveClamp} options={{ title: t`Current Transformer (CT)` }}/>
      <Stack.Screen initialParams={params} name="SlaveTemperatureTolerance" component={SlaveTemperatureTolerance} options={{ title: t`Temperature Update Threshold` }} />
      <Stack.Screen initialParams={params} name="SlaveChannelList" component={SlaveChannelList} options={{ title: t`Devices` }} />
      <Stack.Screen initialParams={params} name="SlaveRfIrDeviceList" component={SlaveRfIrDeviceList} options={{ title: t`Devices` }} />
      <Stack.Screen initialParams={params} name="SwapSlaveStepCode" component={SwapSlaveStepCode} options={{ title: t`Product Code` }}/>
      <Stack.Screen initialParams={params} name="SwapSlaveConfig" component={NewSlaveStepReplacementConfig} options={{
        title: '',
        gestureEnabled: false,
        headerLeft: () => <></>
      }}/>
      <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params: { id } }) => id} />
    </Stack.Navigator>
  )
}

const CreateOrUpdateChannelNavigator = ({ route: { params } }: ScreenProps<'CreateChannel'> | ScreenProps<'ChannelConfig'>) => {
  return (
  <Stack.Navigator>
    <Stack.Screen initialParams={params} name="CreateOrUpdateChannelIndex" component={CreateOrUpdateChannel} options={{ headerLeft: () => <CloseButton />, title: '' }} />
    <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params: { id } }) => id} />
  </Stack.Navigator>
  )
}

const CreateOrUpdateRfIrDeviceNavigator = ({ route: { params } }: ScreenProps<'CreateOrUpdateRfIrDevice'>) => {
  return (
  <Stack.Navigator>
    <Stack.Screen initialParams={params} name="CreateOrUpdateRfIrDeviceIndex" component={CreateOrUpdateRfIrDevice} options={{ headerLeft: () => <CloseButton />, title: '' }} />
    <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params: { id } }) => id} />
  </Stack.Navigator>
  )
}

const Main = () => {
  useApiData()
  useCentralConnectivityStatus()

  return (
  <BottomTab.Navigator screenOptions={{ headerShown: false }}>
    <BottomTab.Screen
      name="FavoritesTab"
      component={FavoritesTab}
      options={{
        title: t`Favorites`,
        tabBarIcon: ({ color, size }) => <Icon name="star" size={size} color={color} />
      }}
    />
    <BottomTab.Screen
      name="AmbientsTab"
      component={AmbientsTab}
      options={{
        title: t`Ambients`,
        tabBarIcon: ({ color, size }) => <Icon name="grid" size={size} color={color} />
      }}
    />
    <BottomTab.Screen
      name="NotificationsTab"
      component={NotificationsTab}
      options={{
        title: t`Notifications`,
        tabBarIcon: ({ color, size }) => <Icon name="notifications" size={size} color={color} />
      }}
    />
    <BottomTab.Screen
      name="DashboardTab"
      component={DashboardTab}
      options={{
        title: t`Dashboard`,
        tabBarIcon: ({ color, size }) => <Icon name="trending-up" size={size} color={color} />
      }}
    />
    <BottomTab.Screen
      name="SettingsTab"
      component={SettingsTab}
      options={{
        title: t`Settings`,
        tabBarIcon: ({ color, size }) => <Icon name="cog-outline" size={size} color={color} />
      }}
    />
  </BottomTab.Navigator>
  )
}

const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={32} />

const selectDocumentTitle = createSelector(
  selectIsCentralSelected,
  selectCurrentUserCentral,
  (isSelected, { name }) =>
    isSelected ? `myio — ${name}` : 'myio'
)

export const AppNavigationContainer = () => {
  const theme = useAppNavigationTheme()
  const isAuthed = useAppSelector(selectIsAuthenticated)
  const isCentralSelected = useAppSelector(selectIsCentralSelected)
  const documentTitle = useAppSelector(selectDocumentTitle)
  const isSuperadmin = useAppSelector(selectIsSuperadmin)

  return (
  <NavigationContainer
    documentTitle={{ formatter: () => documentTitle }}
    ref={navigationRef}
    theme={theme}
  >
    <OverflowMenuProvider>
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {!isAuthed
          ? (<Stack.Group screenOptions={{ statusBarStyle: 'light', contentStyle: { backgroundColor: brandPrimary } }}>
              <Stack.Screen name="Login" component={Login} />
              <Stack.Screen name="Register" component={Register} />
              <Stack.Screen name="ResetPassword" component={ResetPassword} />
            </Stack.Group>)
          : !isCentralSelected
              ? (
                  <>
                    <Stack.Screen name="CentralPicker" component={CentralPicker} options={{ statusBarStyle: 'light' }}/>
                    {isSuperadmin &&
                      <Stack.Group screenOptions={{ headerShown: true }}>
                        <Stack.Screen name="SuperadminHome" component={SuperadminHome} />
                        <Stack.Screen name="SuperadminCentrals" component={SuperadminCentrals} />
                        <Stack.Screen name="SuperadminCentralDetail" component={SuperadminCentralDetail} />
                        <Stack.Screen name="SuperadminCentralEdit" component={SuperadminCentralEdit} />
                        <Stack.Screen name="CentralUsers" component={CentralUsers} options={{ title: t`Users` }} getId={({ params }) => params.id} />
                        <Stack.Screen name="EditCentralUser" component={EditCentralUser} options={{ title: '' }} getId={({ params }) => params.id} />
                        <Stack.Screen name="PickerScreen" component={PickerScreen} options={({ route }) => ({ title: route.params.title ?? '' })} getId={({ params }) => params.id} />
                        {/* Modal Setup */}
                        <Stack.Group screenOptions={{
                          presentation: 'modal',
                          headerShown: true,
                          headerLeft: () => <CancelButton />
                        }}>
                          <Stack.Screen name="AddCentralUser" component={AddCentralUser} options={{ title: t`Add User` }} />
                        </Stack.Group>
                      </Stack.Group>
                    }
                  </>
                )
              : (
                  <>
                    <Stack.Screen name="Main" component={Main} options={{ animationTypeForReplace: 'push' }} />
                    <Stack.Group screenOptions={{ headerShown: true }}>
                      <Stack.Screen
                        name="AmbientDetail"
                        component={AmbientDetail}
                        getId={({ params }) => String(params.id)}
                      />
                      <Stack.Screen name="EnergyThreePhase" component={EnergyThreePhase} />
                      <Stack.Screen name="Logs" component={Logs} options={{ title: t`Logs` }} />
                    </Stack.Group>
                    {/* Modal Navigators */}
                    <Stack.Group screenOptions={{ presentation: 'modal' }}>
                      <Stack.Screen name="SlaveDetail" component={SlaveDetailNavigator} getId={({ params: { id } }) => String(id)} />
                      <Stack.Screen name="NewSlave" component={NewSlaveNavigator} />
                      <Stack.Screen name="CreateChannel" component={CreateOrUpdateChannelNavigator} />
                      <Stack.Screen name="ChannelConfig" component={CreateOrUpdateChannelNavigator} getId={({ params: { id } }) => id } />
                      <Stack.Screen name="CreateRfIrDevice" component={CreateOrUpdateRfIrDeviceNavigator} />
                      <Stack.Screen name="IrAccessory" component={CreateOrUpdateRfIrDeviceNavigator} getId={({ params: { id } }) => id} />
                    </Stack.Group>
                    {/* Modal Setup */}
                    <Stack.Group screenOptions={{
                      presentation: 'modal',
                      headerShown: true,
                      headerLeft: () => <CancelButton />
                    }}>
                      <Stack.Screen name="AddCentralUser" component={AddCentralUser} options={{ title: t`Add User` }} />
                    </Stack.Group>
                    {/* Modal Screens */}
                    <Stack.Group screenOptions={{
                      presentation: 'modal',
                      headerShown: true,
                      headerLeft: () => <CloseButton />
                    }}>
                      {/* Alerts */}
                      <Stack.Screen name="AlarmList" component={AlarmList} />
                      <Stack.Screen name="AlarmForm" component={AlarmForm} getId={({ params }) => String(params?.id)} />
                      {/* Ambients */}
                      <Stack.Screen name="Ambient" component={Ambient} options={{ title: t`New Ambient` }} />
                      <Stack.Screen name="FilterAmbients" component={FilterAmbients} />
                      <Stack.Screen name="AmbientAddSlaves" component={AmbientAddSlaves} />
                      {/* Remotes */}
                      <Stack.Screen name="RemoteControl" component={RemoteControl} />
                      <Stack.Screen name="RemoteConfig" component={RemoteConfig} options={{ presentation: 'fullScreenModal' }} />
                      <Stack.Screen name="RemoteButton" component={RemoteButton} />
                      {/* Scenes */}
                      <Stack.Screen name="SceneDetails" component={SceneDetails} />
                      <Stack.Screen name="SlavePicker" component={SlavePicker} />
                      <Stack.Screen name="StatePicker" component={StatePicker} />
                      <Stack.Screen name="IrPicker" component={IrPicker} />

                      <Stack.Screen name="ModalPicker" component={ModalPicker} />
                      <Stack.Screen name="ModalPickerSection" component={ModalPickerSection} />

                      <Stack.Screen name="ScheduleDetails" component={ScheduleDetails} />
                      <Stack.Screen name="EditEnvironment" component={EditEnvironment} />
                      <Stack.Screen name="FilterLogs" component={FilterLogs} />
                    </Stack.Group>
                  </>
                )
        }
      </Stack.Navigator>
    </OverflowMenuProvider>
  </NavigationContainer>
  )
}

const CloseButton = () => (
  <HeaderButtons left={true} HeaderButtonComponent={HeaderIconButton}>
    <Item
      title={t`Close`}
      iconName="close"
      onPress={() => navigationRef.dispatch(StackActions.pop())}
    />
  </HeaderButtons>
)

const CancelButton = () => (
  <HeaderButtons left={true}>
    <Item
      title={t`Cancel`}
      onPress={() => navigationRef.dispatch(StackActions.pop())}
    />
  </HeaderButtons>
)

export interface RootStackParamList extends ParamListBase {
  AlarmForm: { id: number } | undefined
  Ambient: { typeForm: 'devices' | 'wizard' }
  AmbientAddSlaves: { ambient: any }
  AmbientDetail: {
    id: string
    editing?: boolean
  }
  Ambients: { editing?: boolean } | undefined
  EditEnvironment: { name: string }
  ModalPicker: {
    name: string
    data: Array<{ label: string | number, value: any }>
    value: any
    search: boolean
    multiple: boolean
    required: boolean
    // FIXME: non-serializable value in navigation state
    onPressOk: (data: any) => void
  }
  ModalPickerSection: {
    name: string
    data: Array<{
      title: string
      data: Array<{ label: string | number, value: any }>
    }>
    value: any
    search: boolean
    multiple: boolean
    required: boolean
    // FIXME: non-serializable value in navigation state
    onPressOk: (data: any) => void
  }
  ScheduleDetails: {new: boolean}
  RemoteControl: { remote: any }

  Login: { email: string } | undefined
  Register: { email: string } | undefined
  ResetPassword: { email: string } | undefined

  CentralPicker: undefined
  Main: NavigatorScreenParams<{
    NotificationsTab: undefined
  }>
  EnergyThreePhase: undefined
  NewSlave: undefined
  AlarmList: undefined
  FilterAmbients: undefined
  RemoteConfig: undefined
  RemoteButton: undefined
  SceneDetails: undefined
  SlavePicker: undefined
  StatePicker: undefined
  IrPicker: undefined
  FilterLogs: undefined
  LoadingOverlay: undefined
  PickerScreen: {
    id: string
    items: PickerItem[]
    value?: string | string[] | null
    title?: string
    mode?: 'multiple' | 'single'
  }
  CentralUsers: { id: Central['id'] }
  AddCentralUser: { id: Central['id'] }
  EditCentralUser: { id: CentralUser['id'] }

  SuperadminHome: undefined
  SuperadminCentrals: undefined
  SuperadminCentralDetail: { id: Central['id'] }
  SuperadminCentralEdit: { id: Central['id'] }

  NewSlaveStepCode: { isCancelable?: boolean } | undefined

  SlaveDetail: { id: string }
  InOutPage: { id: string, channel: number }
  ChannelConfig: PropId
  CreateChannel: { slaveId: string }
  IrAccessory: PropId
  CreateRfIrDevice: { slaveId: string }
}
