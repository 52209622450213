import { createAction, PropId } from 'robodux'
import { createSelector } from 'reselect'
import { find, filter, map, merge } from 'lodash/fp'
import { c, t } from 'ttag'

import { Channel, ChannelFormData, PropIdOrSlaveId, State } from '#app/types'
import { PickerItem, sortPickerItems } from '#app/picker'
import { all, call, put, retry, select, takeLeading } from 'typed-redux-saga'
import { selectApi } from '#app/api'
import { oldFetchSlaves } from '#app/slave'
import { errorProtoString } from '#app/services/api'
import { setLoaderError, setLoaderStart, setLoaderSuccess } from '#app/loader'
import { selectPropId, formatError } from '#app/util'

const channelTypes = [
  ['lamp', t`Lamp`],
  ['dimmer', t`Dimmer`],
  ['plug', t`Plug`],
  ['pulse_up', t`Pulse Up`],
  ['pulse_down', t`Pulse Down`],
  ['inverted_actionable', t`Inverted actionable`],

  ['presence_sensor', t`Presence Sensor`],
  ['light_sensor', t`Light Sensor`],
  ['door_sensor', t`Door Sensor`],
  ['smoke_sensor', t`Smoke Sensor`],
  ['water_level', t`Water Level`],
  ['water_level_inverted', t`Water Level Inverted`],

  ['flow_sensor', t`Flow Sensor`],

  ['remote', c('slave-outlet').t`Remote Button`],
  ['threeway', t`ThreeWay`]
] as const
export type ChannelType = typeof channelTypes[number][0]
export const channelTypeList = channelTypes.map(([v]) => v)

const channelKinds = [
  ['actionable', t`Actionable`],
  ['sensor', t`Sensor`],
  ['counter', t`Counter`],
  ['other', t`Other`]
] as const
export type ChannelKind = typeof channelKinds[number][0]
export const channelKindList = channelKinds.map(([v]) => v)

export const getChannelKind = (type: ChannelType): ChannelKind => {
  switch (type) {
    case 'lamp':
    case 'dimmer':
    case 'plug':
    case 'pulse_up':
    case 'pulse_down':
    case 'inverted_actionable':
      return 'actionable'

    case 'presence_sensor':
    case 'light_sensor':
    case 'door_sensor':
    case 'smoke_sensor':
    case 'water_level':
    case 'water_level_inverted':
      return 'sensor'

    case 'flow_sensor':
      return 'counter'

    case 'remote':
    case 'threeway':
    default:
      return 'other'
  }
}

export const defaultChannel = (p: Partial<Channel> = {}): Channel =>
  merge({
    id: 0,
    name: '',
    type: 'lamp',
    slave_id: 0,
    channel: 0,
    channel_id: null,
    scene_up_id: null,
    scene_down_id: null,
    config: {}
  }, p)

export const filterActionableChannels = filter<Channel>(ch => getChannelKind(ch.type) === 'actionable')
export const mapChannelAsPickerItem = map<Channel, PickerItem>(({ id, name: title }) => ({ id: String(id), title }))

const selectChannelsAsList = (state: State) => state.slaves.allSlaves.filter(chan => chan.is_channel)
const selectSlaveChannelsAsList = createSelector(
  selectChannelsAsList,
  (s: State, p: PropId) => Number(p.id),
  (cs, slaveId) => filter({ slaveId }, cs)
)
export const selectSlaveChannelsForFlatList = createSelector(
  selectSlaveChannelsAsList,
  cs => cs.map(chan => ({ id: String(chan.id) }))
)
export const selectChannelById = createSelector(
  selectChannelsAsList,
  (s: State, p: PropId) => Number(p.id),
  (cs, id) => defaultChannel(find({ id }, cs))
)
export const selectActionableChannels = createSelector(
  selectChannelsAsList,
  filterActionableChannels
)
export const selectActionableChannelsAsPickerItems = createSelector(
  selectActionableChannels,
  mapChannelAsPickerItem
)
export const selectChannelsForThreewayPicker = createSelector(
  selectActionableChannelsAsPickerItems,
  sortPickerItems
)
export const selectChannelName = createSelector(
  selectChannelById,
  ch => ch.name
)
export const selectChannelTypesForPicker = createSelector(
  (s: any) => channelTypes,
  (types) => types.map(([value, label]) => ({ label, value }))
)
export const selectChannelIconName = (type: string) => {
  switch (type) {
    case 'lamp':
      return 'lightbulb-on'
    case 'plug':
    case 'pulse_up':
    case 'pulse_down':
      return 'power-plug'
    case 'door_sensor':
      return 'door-open'
    case 'smoke_sensor':
      return 'smoke-detector'
    case 'light_sensor':
      return 'alarm-light'
    case 'flow_sensor':
      return 'waves'
    default:
      // return 'crop-square'
      return 'alpha-x-circle'
  }
}
export const selectChannelSlaveId = createSelector(
  selectChannelById,
  (chan): number|undefined => typeof chan?.slave_id === 'number'
    ? chan.slave_id
    : undefined
)
const selectChannelByMaybeId = createSelector(
  (s: State) => s,
  selectPropId,
  selectChannelById
)
const selectChannelDefaultValues = createSelector(
  selectChannelByMaybeId,
  (s: State, p: PropIdOrSlaveId) => p,
  (channel, p) => ({
    channel: {
      ...channel,
      ...('slaveId' in p && { slave_id: Number(p.slaveId) })
    }
  })
)
export const selectChannelForCreateOrUpdate = createSelector(
  selectPropId,
  selectChannelDefaultValues,
  ({ id }, defaultValues) => ({
    id,
    defaultValues
  })
)

export const createChannel = createAction<{data: ChannelFormData}>('CREATE_CHANNEL')
export const deleteChannel = createAction<PropId>('DELETE_CHANNEL')
export const updateChannel = createAction<{id: string, data: ChannelFormData}>('UPDATE_CHANNEL')

export function * onCreateChannel ({ payload: { data: { channel } } }: ReturnType<typeof createChannel>) {
  yield * put(setLoaderStart({ id: 'channel/create' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.createChannel, channel)
  if (!res.ok || res.data == null) {
    const message = errorProtoString(res)
    yield * put(setLoaderError({ id: 'channel/create', message }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'channel/create', meta: { id: String(res.data.id) } }))
  }
}

export function * onDeleteChannel ({ payload: { id } }: ReturnType<typeof deleteChannel>) {
  yield * put(setLoaderStart({ id: 'channel/delete' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.deleteChannel, Number(id))
  if (!res.ok && res.status !== 404) {
    const message = errorProtoString(res)
    yield * put(setLoaderError({ id: 'channel/delete', message }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'channel/delete' }))
  }
}

export function * onUpdateChannel ({ payload: { id, data } }: ReturnType<typeof updateChannel>) {
  try {
    yield * put(setLoaderStart({ id: 'channel/update' }))
    yield * call(apiPatchChannel, id, data)
    yield * put(setLoaderSuccess({ id: 'channel/update' }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: 'channel/update', message: formatError(err) }))
  }
}

export function * apiPatchChannel (id: string, data: ChannelFormData) {
  const currentChannel = yield * select(state => selectChannelById(state, { id }))
  const config = merge(currentChannel.config, data.channel.config)
  const channel = { ...data.channel, ...(data.channel.config != null && { config }) }
  const api = yield * select(selectApi)
  const res = yield * call(api.patchChannel, Number(id), channel)
  if (!res.ok || res.data == null) {
    throw new Error(errorProtoString(res))
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } catch (e) {
    console.error('patchChannel', e)
  }
}

export function * saga () {
  yield all([
    takeLeading(createChannel, onCreateChannel),
    takeLeading(deleteChannel, onDeleteChannel),
    takeLeading(updateChannel, onUpdateChannel)
  ])
}
