import { channel } from 'redux-saga'
import { select, put, call, all, takeLatest, take } from 'redux-saga/effects'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import AmbientsActions from '../redux/ambients'
import { get, set, map, isEqual, pick } from 'lodash'
import { gettext } from 'ttag'
import { launchCamera, launchImageLibrary } from 'react-native-image-picker'
import { alert } from '../components/Utils'
import { selectApi } from '#app/api'
import { fetchAmbients, selectAmbientById, selectAmbientsPositions, updateAmbient } from '#app/ambient'

export default function * rootSaga () {
  yield all([
    takeLatest('AMBIENT_BACKGROUND_CHANGE_TRIGGER', backgroundChangeTriggerHandler),
    takeLatest('UPLOAD_AMBIENT_IMAGE_REQUEST_SUCCESS', backgroundChangeSuccessHandler)
  ])
}

export function * updatePositionRequest (action: any) {
  const { payload } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  const oldData: ReturnType<typeof selectAmbientsPositions> = yield select(selectAmbientsPositions)

  try {
    if (isEqual(oldData, payload)) return
    const denormalizedData = map(payload, a => pick(a, ['id', 'order']))

    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.patchAmbients, denormalizedData)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!response.ok) throw new Error(response.err)

    yield put(AmbientsActions.updatePositionRequestSuccess(response.data))
  } catch (e) {
    yield put(AmbientsActions.updatePositionRequestFailure())
  } finally {
    yield put(AmbientsActions.updatePositionRequestFulfill())
  }
}

export function * uploadImage ({ payload: { image, id } }: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(api.getImageUploadUrl,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    `.${image.uri.split('.')[1]}`,
    image.type
  )

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (response.ok) {
    const signedUrl = response.data.uploadURL

    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const uploadResponse = yield call(async () => {
      const response = await fetch(Platform.OS === 'android' ? image.uri : image.uri.replace('file://', ''))
      const imgBlob = await response.blob()

      return await fetch(signedUrl, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/octet-stream' },
        body: imgBlob
      })
    })

    if (get(uploadResponse, 'status') === 200) {
      const imageUrl = signedUrl.split('?')[0]

      yield put(AmbientsActions.uploadAmbientImageRequestSuccess({ imageUrl, id }))
    }
  } else {
    yield put(AmbientsActions.uploadAmbientImageRequestFailure())
  }
}

export function * saveAmbientFilter (action: any) {
  const { ambientId, key, name, value } = action

  yield put(AmbientsActions.setAmbientFilter({ key, name, value }))

  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const state = yield select()
  const filters = state.ambients.filters

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  yield AsyncStorage.setItem(`@ambient_filter_${ambientId}`, JSON.stringify(filters), null)
}

export function * clearAmbientFilters (action: any) {
  const { ambientId } = action

  yield put(AmbientsActions.clearAmbientFilter())
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  yield AsyncStorage.removeItem(`@ambient_filter_${ambientId}`)
}

export function * hideDeviceAmbient (action: any) {
  const { payload } = action
  const ambient = { ...payload.ambient }

  // ambient.config.hide_devices_v1 = []

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  set(ambient, 'config.hide_devices_v1', [...(get(ambient, 'config.hide_devices_v1') || []), payload.data])

  yield put(updateAmbient({ data: ambient }))
}

export function * getImageFrom (source: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const chan = yield call(channel)
  const options = {
    mediaType: 'photo',
    maxWidth: 800,
    maxHeight: 600,
    quality: 0.3
  }
  const callback = (response: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.didCancel) {
      console.debug('getImageFrom', 'canceled by user')
      return chan.close()
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.errorCode) {
      switch (response.errorCode) {
        case 'camera_unavailable':
          alert(gettext('Camera unavailable'))
          break
        case 'permission':
          alert(gettext('Permission denied'), gettext('Ensure this app is allowed to access media on this device and try again.'))
          break
        default:
          console.error('getImageFrom', response.errorMessage)
          break
      }
      return chan.close()
    }
    const { assets } = response
    chan.put(assets[0])
  }

  switch (source) {
    case 'camera':
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      yield call(launchCamera, options, callback)
      break
    default:
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      yield call(launchImageLibrary, options, callback)
  }

  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const image = yield take(chan)
  return image
}

export function * backgroundChangeTriggerHandler ({
  payload: { source, id }
}: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const image = yield call(getImageFrom, source)
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!image) {
    return
  }
  yield put(AmbientsActions.uploadAmbientImageRequest({ image, id }))
}

export function * backgroundChangeSuccessHandler ({
  payload: { imageUrl, id }
}: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const ambient = yield select(state => selectAmbientById(state, { id }))

  yield call(api.updateAmbient, { ...ambient, image: imageUrl })

  yield put(fetchAmbients())
}
