import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  setLookup: ['payload'],
  setLookupSuccess: ['payload'],
  clearLookupState: null
})

export const INITIAL_STATE = []

export default Creators

function clearLookupState () {
  return { ...INITIAL_STATE }
}

function setLookup (state: any, action: any) {
  return action.payload
}

function setLookupSuccess (state: any, action: any) {
  return state
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_LOOKUP]: setLookup,
  [Types.SET_LOOKUP_SUCCESS]: setLookupSuccess,
  [Types.CLEAR_LOOKUP_STATE]: clearLookupState
})
