import { createLoaderTable, LoadingState } from 'robodux'
import { State } from '#app/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import { createSelector } from 'reselect'
import { isEqual } from 'lodash'

const loaders = createLoaderTable({ name: 'loaders' })

export const {
  loading: setLoaderStart,
  success: setLoaderSuccess,
  error: setLoaderError,
  remove: removeLoader,
  resetById: resetLoaderById,
  resetAll: resetAllLoaders
} = loaders.actions

export const reducer = loaders.reducer

export const selectors = loaders.getSelectors((state: State) => state.loader)
export const { selectByIds: selectLoadersByIds } = selectors

// WORKAROUND: lack of referential equality when trying to select inexistent id
export const selectLoaderById = createSelector(
  selectors.selectById,
  l => l,
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual
    }
  }
)
// export const {
//   selectById: selectLoaderById
// } = loaders.getSelectors((state: State) => state.loader)

// export function useLoaderSuccess (cur: LoadingState, success: () => void) {
//   const [prev, setPrev] = useState(cur)
//   useEffect(() => {
//     const curSuccess = !cur.isLoading && cur.isSuccess
//     if (prev.isLoading && curSuccess) success()
//     setPrev(cur)
//   }, [cur.isSuccess, cur.isLoading])
// }

export const useLoaderSuccess = (cur: LoadingState, onSuccess: () => void) => {
  const wasLoading = useRef(cur.isLoading)
  useEffect(() => {
    const curSuccess = !cur.isLoading && cur.isSuccess
    if (wasLoading.current && curSuccess) onSuccess()
    wasLoading.current = cur.isLoading
  }, [cur.isSuccess, cur.isLoading, onSuccess])
}

export const useLoaderError = (cur: LoadingState) => {
  const wasLoading = useRef(cur.isLoading)
  const [showError, setShowError] = useState(false)
  const onError = useCallback(() => {
    setShowError(true)
    setTimeout(() => {
      setShowError(false)
    }, 4000)
  }, [])
  useEffect(() => {
    const curError = !cur.isLoading && cur.isError
    if (wasLoading.current && curError) onError()
    wasLoading.current = cur.isLoading
  }, [cur.isError, cur.isLoading, onError])
  return showError
}
