import React, { useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { Switch, TextInput, ScrollView, View, ActivityIndicator } from 'react-native'
import { TextList, Right, Item, PickerCell, Separator } from '../components/List'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { selectAmbientsForPicker, selectSlaveAmbientIds } from '#app/ambient'
import { ScreenProps, SlaveConfigFormData, SlaveType } from '#app/types'
import {
  selectSlaveForEdit,
  slaveTypeList,
  unpairSlave,
  updateSlave
} from '#app/slave'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import { createSelector } from 'reselect'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import DeleteButton from '#app/components/Form/DeleteButton'

const selectDefaultValues = createSelector(
  selectSlaveForEdit,
  selectSlaveAmbientIds,
  (slave, ambients) => ({ slave, ambients })
)

const SlaveConfig = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const theme = useAppTheme()

  const ambients = useAppSelector(selectAmbientsForPicker)
  const defaultValues = useAppSelector(state => selectDefaultValues(state, { id }))
  const { control, handleSubmit, formState: { isDirty }, watch } = useForm<SlaveConfigFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<SlaveConfigFormData>>((data) => {
    dispatch(updateSlave({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'slave/update' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        isLoading
          ? <ActivityIndicator />
          : isDirty
            ? <HeaderButtons>
                <HeaderItem
                  title={t`Save`}
                  iconName="save"
                  onPress={handleSubmit(onSubmit)}
                />
              </HeaderButtons>
            : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      {showError && <Error>{message}</Error>}

      <View style={theme.form.area}>
        <Controller
          control={control}
          name="slave.name"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <TextInput
              style={theme.form.input}
              placeholder={t`Name`}
              placeholderTextColor={theme.formForm.placeholderTextColor} autoCapitalize="words"
              autoCorrect={false}
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
            />
          }
        />
      </View>
      <Separator text=""/>
      {['outlet', 'three_phase_sensor'].includes(watch('slave.type')) &&
        <Item>
          <TextList text={t`Agregate Consumption`}/>
          <Right>
            <Controller
              control={control}
              name="slave.aggregate"
              render={({ field: { ref, onChange, onBlur, value } }) =>
                <Switch
                  ref={ref}
                  onValueChange={onChange}
                  value={value}
                />
              }
            />
          </Right>
        </Item>
      }

      <Controller
        control={control}
        name="ambients"
        render={({ field: { ref, onChange, onBlur, value } }) =>
          <PickerCell
            id="ambients"
            title={t`Ambients`}
            items={ambients}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        }
      />
      <Separator text=""/>
      <View style={theme.form.area}>
        <DeleteButton
          id={id}
          title={t`Unpair product`}
          loader="slave/unpair"
          creator={unpairSlave}
        />
      </View>
    </ScrollView>
  )
}

export default SlaveConfig

const schema: Yup.SchemaOf<SlaveConfigFormData> = Yup.object({
  slave: Yup.object({
    type: Yup.mixed()
      .oneOf(slaveTypeList)
      .required(),
    name: Yup.string().required(),
    aggregate: Yup.boolean().when('slave.type', {
      is: (type: SlaveType) => ['three_phase_sensor', 'outlet'].includes(type),
      then: Yup.boolean().required()
    })
  }).required(),
  ambients: Yup.array().of(Yup.string().required()).required()
}).required()
