import React, { useCallback, useEffect } from 'react'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { RootStackParamList } from '#app/routes'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { AddCentralUserFormData } from '#app/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { addCentralUser } from '#app/centraluser'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { ActivityIndicator, ScrollView, StyleSheet, TextInput } from 'react-native'
import { Error } from '../components/Utils'
import { useAppTheme } from '#app/theme'

const AddCentralUser = ({ navigation, route: { params: { id } } }: Props) => {
  const form = useForm<AddCentralUserFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const { handleSubmit, formState: { isDirty }, setFocus, control } = form

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<AddCentralUserFormData>>((data) => {
    dispatch(addCentralUser({ id, data }))
  }, [dispatch, id])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'centraluser/add' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.navigate('EditCentralUser', { id: String(loader.meta?.id) })
  }, [navigation, loader.meta?.id])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : <HeaderButtons>
            <HeaderItem
              buttonStyle={styles.add}
              title={t`Add`}
              iconName="add"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>

    })
  }, [navigation, handleSubmit, onSubmit, isLoading])

  useEffect(() => {
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  useEffect(() => {
    setFocus('email')
  }, [setFocus])

  const theme = useAppTheme()

  return (
    <ScrollView
      contentInsetAdjustmentBehavior="automatic"
      centerContent={true}
      contentContainerStyle={styles.container}
    >
      {showError && <Error>{message}</Error>}
      <Controller
        name="email"
        control={control}
        render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
          <>
            <TextInput
              ref={ref}
              style={theme.form.input}
              onBlur={onBlur}
              placeholder={t`Email`}
              keyboardType="email-address"
              textContentType="emailAddress"
              autoCapitalize="none"
              autoCorrect={false}
              onChangeText={onChange}
              value={value}
              onSubmitEditing={handleSubmit(onSubmit)}
            />
            {error?.message != null && <Error>{error.message}</Error> }
          </>
        )}
      />
    </ScrollView>
  )
}

export default AddCentralUser

const defaultValues = { email: '' }

const schema: Yup.SchemaOf<AddCentralUserFormData> = Yup.object({
  email: Yup.string()
    .email()
    .required()
}).required()

type Props = NativeStackScreenProps<RootStackParamList, 'AddCentralUser'>

const styles = StyleSheet.create({
  container: { padding: 15 },
  add: { fontWeight: '600' }
})
