import { EffectCallback, useEffect } from 'react'
import { Comparator, curry } from 'lodash'
import { LoadingState } from 'robodux'
import { useFreshRef, usePrevious } from './util'

export const useLoaderEffect = curry((comp: Comparator<LoadingState>, callback: EffectCallback, cur: LoadingState) => {
  const prev = usePrevious(cur)
  const savedCallback = useFreshRef(callback)
  useEffect(() => {
    if (comp(prev, cur)) {
      return savedCallback.current()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prev, cur])
})

// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLoaderError = useLoaderEffect((prev, cur) => ((prev.isLoading && !cur.isLoading) && cur.isError))
// eslint-disable-next-line react-hooks/rules-of-hooks
export const useLoaderSuccess = useLoaderEffect((prev, cur) => ((prev.isLoading && !cur.isLoading) && cur.isSuccess))
