import { PageInfo, State } from '#app/types'
import { combineReducers } from 'redux'
import { createSelector } from 'reselect'
import { createAssign, createTable, mustSelectEntity } from 'robodux'
import * as apiService from '#app/services/api'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { wsConnectionRequest, wsDisconnectionRequest } from '#app/actions'

const baseUrl = createAssign({ name: 'baseUrl', initialState: 'https://server.myio.com.br' })
const pageInfo = createTable<PageInfo>({ name: 'pageInfo' })

export const {
  set: setApiBaseUrl,
  reset: resetApiBaseUrl
} = baseUrl.actions
export const {
  add: addPageInfo,
  set: setPageInfo,
  remove: removePageInfo,
  reset: resetPageInfo,
  patch: patchPageInfo
} = pageInfo.actions

export const reducer = combineReducers({
  baseUrl: baseUrl.reducer,
  pageInfo: pageInfo.reducer
})

const selectToken = (state: State) => state.user.token
const selectUserId = (state: State) => state.user.currentUser
const selectCentralId = (state: State) => state.central.currentCentral
const selectUserCentral = createSelector(
  (state: State) => state,
  selectCentralId,
  (state, id) => state.centraluser.usercentrals[id]
)
export const selectCentralToken = createSelector(
  selectUserCentral,
  c => c?.token
)
export const selectApi = createSelector(
  (state: State) => state.api.baseUrl,
  selectToken,
  selectUserId,
  selectCentralId,
  selectCentralToken,
  (baseURL, cloudToken, userId, centralId, centralToken) => apiService.create({
    baseURL,
    userId,
    cloudToken,
    centralId,
    centralToken
  })
)
const pageInfoSelectors = pageInfo.getSelectors<State>(state => state.api.pageInfo)
const defaultPageInfo: PageInfo = {
  hasNextPage: false,
  hasPreviousPage: false,
  startCursor: '',
  endCursor: ''
}
const createPageInfoSelector = mustSelectEntity(defaultPageInfo)
export const selectPageInfoById = createPageInfoSelector(pageInfoSelectors.selectById)

export const useApiData = () => {
  const dispatch = useDispatch()
  const centralId = useSelector(selectCentralId)
  const centralToken = useSelector(selectCentralToken)
  useEffect(() => {
    if (centralToken != null) {
      dispatch({ type: 'APP_LOAD_DATA' })
      dispatch(wsConnectionRequest({
        url: 'wss://server.myio.com.br/websocket' + `?central_id=${centralId}&central_token=${centralToken}`,
        centralId,
        centralToken
      }))
    }
    return () => {
      dispatch({ type: 'APP_RESET_DATA' })
      dispatch(wsDisconnectionRequest())
    }
  }, [dispatch, centralId, centralToken])
}
