import React, { Component } from 'react'
import { StyleSheet, TouchableOpacity, View } from 'react-native'

const DEFAULT_SIZE_MULTIPLIER = 0.7
const DEFAULT_OUTER_BORDER_WIDTH_MULTIPLIER = 0.2

interface OwnProps {
  size?: number
  innerColor?: string
  outerColor?: string
  isSelected?: boolean
  onPress?: (...args: any[]) => any
}

type Props = OwnProps & typeof RadioButton.defaultProps

export default class RadioButton extends Component<Props> {
  static defaultProps = {
    size: 12,
    innerColor: '#673ab6',
    outerColor: '#673ab6',
    isSelected: false,
    onPress: () => null
  }

  render () {
    const { size, innerColor, outerColor, isSelected, onPress } = this.props
    const outerStyle = {
      borderColor: outerColor,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      width: size + size * DEFAULT_SIZE_MULTIPLIER,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      height: size + size * DEFAULT_SIZE_MULTIPLIER,
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      borderRadius: (size + size * DEFAULT_SIZE_MULTIPLIER) / 2,
      borderWidth: size * DEFAULT_OUTER_BORDER_WIDTH_MULTIPLIER
    }

    const innerStyle = {
      width: size,
      height: size,
      borderRadius: size / 2,
      backgroundColor: innerColor
    }

    return (
      (<TouchableOpacity style={[styles.radio, outerStyle, isSelected === false ? styles.false : null]} onPress={onPress}>
        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {isSelected ? <View style={innerStyle} {...this.props} /> : null}
      </TouchableOpacity>)
    )
  }
}

const styles = StyleSheet.create({
  radio: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  false: {
    borderColor: '#909090'
  }
})
