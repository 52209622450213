import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ActivityIndicator, Text, View } from 'react-native'
import Slider from '@react-native-community/slider'
import { Error } from '../components/Utils'
import { t, jt } from 'ttag'
import { useAppTheme } from '#app/theme'
import { ScreenProps, SlaveConfigTemperatureUpdateThresholdFormData } from '#app/types'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import {
  selectSlaveTemperatureToleranceForEdit,
  formatTemperatureTolerance,
  updateSlaveTemperatureUpdateThreshold
} from '#app/slave'

const SlaveTemperatureTolerance = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const theme = useAppTheme()

  const { defaultValues } = useAppSelector(state => selectSlaveTemperatureToleranceForEdit(state, { id }))
  const { control, handleSubmit, formState: { isDirty } } = useForm<SlaveConfigTemperatureUpdateThresholdFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<SlaveConfigTemperatureUpdateThresholdFormData>>((data) => {
    dispatch(updateSlaveTemperatureUpdateThreshold({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'slave/updateTemperatureUpdateThreshold' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        isLoading
          ? <ActivityIndicator />
          : isDirty
            ? <HeaderButtons>
                  <HeaderItem
                    title={t`Save`}
                    iconName="save"
                    onPress={handleSubmit(onSubmit)}
                  />
                </HeaderButtons>
            : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  return (
    <View style={theme.form.area}>
      {showError &&
        <View style={theme.form.formGroup}>
          <Error>{message}</Error>
        </View>
      }

      <Controller
        control={control}
        name="value"
        render={({ field: { ref, onChange, onBlur, value } }) => {
          const formattedValue = <Text key="formattedValue" style={{ fontWeight: 'bold' }}>{formatTemperatureTolerance(value)}</Text>
          return (
            <>
              <Slider
                style={{ width: '100%', marginTop: 30, marginBottom: 30 }}
                step={1}
                value={value}
                onValueChange={onChange}
                minimumValue={0}
                maximumValue={100}
                minimumTrackTintColor={theme.colors.purple}
                maximumTrackTintColor={theme.colors.grey}
              />
              <Text style={{ color: theme.textColor }}>
                {jt`Report changes in temperature once the difference between the last reported measurement is grater than ${formattedValue}.`}
              </Text>
            </>
          )
        }}
      />
    </View>
  )
}

export default SlaveTemperatureTolerance

const schema: Yup.SchemaOf<SlaveConfigTemperatureUpdateThresholdFormData> = Yup.object({
  value: Yup.number().required()
}).required()
