import { AnyAction } from 'redux'
import { put, call, delay, retry, throttle } from 'redux-saga/effects'
import { all, race, select, take, takeLatest } from 'typed-redux-saga'

import LookupActions from '../redux/lookUp'
import SlavesAction from '../redux/slaves'
import WsActions from '../redux/ws'
import ReduxUtils from '../redux/utils'
import { concat } from 'lodash'
import { RootState } from '../store'
import { selectIsCentralSelected } from '#app/central'
import { selectApi } from '#app/api'

export const SLAVES_QUERY_RETRY_ATTEMPTS = 4
export const SLAVES_QUERY_RETRY_INTERVAL = 1000
export const SLAVES_QUERY_THROTTLE = 10000

export function * getSlaves () {
  const currentCentral = yield * select((state: RootState) => state.central.currentCentral)
  const api = yield * select(selectApi)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getSlaves, currentCentral)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      const data = ReduxUtils.parseSlaves(response.data)

      const allSlaves = concat(
        data.slaves.map((s) => { s.is_slave = true; s.slave_id = s.id; return s }),
        data.channels.map((c) => { (c).is_channel = true; return c }),
        data.devices.map((d) => { (d).is_device = true; return d })
      )

      yield put(SlavesAction.dataGetSlavesRequestSuccess(allSlaves))
      yield put(LookupActions.setLookup(data.lookUp))
      yield put({ type: 'SLAVES_QUERY_TRIGGER' })
    } else {
      console.log('FAILED: ', response)
      yield put(SlavesAction.dataGetSlavesRequestFailure())
    }
  } catch (e) {
    yield put(SlavesAction.dataGetSlavesRequestFailure())
  }
}

export function * setSlavesToFilter (action: AnyAction) {
  const { slaves } = action
  yield put(SlavesAction.setSlavesToFilter(slaves))
}

export function * slavesQueryTriggerSaga () {
  const isCentralSelected = yield * select(selectIsCentralSelected)
  if (!isCentralSelected) return
  yield put({ type: 'SLAVES_QUERY_REQUEST' })
}

export function * slavesQueryRequestSaga () {
  try {
    yield retry(SLAVES_QUERY_RETRY_ATTEMPTS, SLAVES_QUERY_RETRY_INTERVAL, slavesQueryWorkerSaga)
    yield put({ type: 'SLAVES_QUERY_SUCCESS' })
  } catch (error) {
    yield put({ type: 'SLAVES_QUERY_ERROR', payload: { error } })
  }
}

export function * slavesQueryWorkerSaga () {
  yield put(WsActions.wsConnectionSendMessage({ type: 'query', ids: [] }))
  const { timeout } = yield * race({
    take: take('WS_MESSAGE_STATE_UPDATE'),
    timeout: delay(SLAVES_QUERY_RETRY_INTERVAL)
  })
  if (timeout != null) throw new Error('did not receive state_update')
}

export default function * slavesRootSaga () {
  yield all([
    throttle(
      SLAVES_QUERY_THROTTLE,
      ['SLAVES_QUERY_TRIGGER', 'APP_STATE_ACTIVE'],
      slavesQueryTriggerSaga
    ),
    takeLatest('SLAVES_QUERY_REQUEST', slavesQueryRequestSaga)
  ])
}
