
import { createAction, PropId } from 'robodux'
import { all, call, put, retry, select, takeLeading } from 'typed-redux-saga'
import { createSelector } from 'reselect'
import { filter, find, merge } from 'lodash/fp'
import { c, t } from 'ttag'

import { RfIrDevice, RfIrDeviceFormData, State } from '#app/types'
import { PickerItem } from '#app/picker'
import { setLoaderError, setLoaderStart, setLoaderSuccess } from '#app/loader'
import { errorProtoString } from '#app/services/api'
import { selectApi } from '#app/api'
import { oldFetchSlaves } from '#app/slave'
import { selectPropId, formatError } from '#app/util'

const rfirDeviceCategories = [
  ['ac', t`A/C`],
  ['tv', t`TV`],
  ['other', t`Other`]
] as const
export type RfIrDeviceCategory = typeof rfirDeviceCategories[number][0]
export const rfirDeviceCategoryList = rfirDeviceCategories.map(([v]) => v)
const rfirDeviceOutputs = [
  ['intern', c('output').t`Internal`],
  ['extern', c('output').t`External`],
  ['both', c('output').t`Both`]
]
export type RfIrDeviceOutput = typeof rfirDeviceOutputs[number][0]
export const rfirDeviceOutputList = rfirDeviceOutputs.map(([v]) => v)
const rfirDeviceTypes = [
  ['rf', t`Radio frequency (RF)`],
  ['ir', t`Infrared (IR)`]
]
export type RfIrDeviceType = typeof rfirDeviceTypes[number][0]
export const rfirDeviceTypeList = rfirDeviceTypes.map(([v]) => v)

export const defaultRfIrDevice = (p: Partial<RfIrDevice> = {}): RfIrDevice =>
  merge({
    id: 0,
    slave_id: 0,
    type: 'ir',
    category: 'other',
    output: 'both',
    name: '',
    commandOnId: null,
    commandOffId: null
  }, p)

export const selectRfIrDevicesAsList = (state: State) => state.slaves.allSlaves.filter(d => d.is_device)
export const selectRfIrDeviceById = createSelector(
  selectRfIrDevicesAsList,
  (s: State, p: PropId) => Number(p.id),
  (ds, id) => defaultRfIrDevice(find({ id }, ds))
)
const selectSlaveIrDevicesAsList = createSelector(
  selectRfIrDevicesAsList,
  (s: State, p: PropId) => Number(p.id),
  (ds, slaveId) => filter({ slaveId }, ds)
)
export const selectSlaveIrDevicesForFlatList = createSelector(
  selectSlaveIrDevicesAsList,
  ds => ds.map(d => ({ id: String(d.id) }))
)
export const selectRfIrDeviceCategoriesForPicker = createSelector(
  (s: any) => rfirDeviceCategories,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectRfIrDeviceOutputsForPicker = createSelector(
  (s: any) => rfirDeviceOutputs,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectRfIrDeviceTypesForPicker = createSelector(
  (s: any) => rfirDeviceTypes,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectRfIrDeviceName = createSelector(
  selectRfIrDeviceById,
  d => d.name
)
export const selectRfIrDeviceButtons = createSelector(
  selectRfIrDeviceById,
  (d: any): any[] => Array.isArray(d?.buttons)
    ? d.buttons
    : []
)
export const selectRfIrCommandsForPicker = createSelector(
  selectRfIrDeviceButtons,
  (buttons): PickerItem[] => buttons.map(b => ({
    title: String(b.name),
    id: String(b.rfirCommandId)
  }))
)
const selectRfIrDeviceByMaybeId = createSelector(
  (s: State) => s,
  selectPropId,
  selectRfIrDeviceById
)
const selectRfIrDeviceDefaultValues = createSelector(
  selectRfIrDeviceByMaybeId,
  (s: State, p: PropId | { slaveId: string }) => p,
  (rfirDevice, props) =>
    'slaveId' in props
      ? { rfirDevice: { ...rfirDevice, slave_id: Number(props.slaveId) } }
      : { rfirDevice }
)
export const selectRfIrDeviceForCreateOrUpdate = createSelector(
  selectPropId,
  selectRfIrDeviceDefaultValues,
  ({ id }, defaultValues) => ({ id, defaultValues })
)

export const createRfIrDevice = createAction<{data: RfIrDeviceFormData}>('CREATE_RFIR_DEVICE')
export const deleteRfIrDevice = createAction<PropId>('DELETE_RFIR_DEVICE')
export const updateRfIrDevice = createAction<{id: string, data: RfIrDeviceFormData}>('UPDATE_RFIR_DEVICE')

export function * onCreateRfIrDevice ({ payload: { data: { rfirDevice } } }: ReturnType<typeof createRfIrDevice>) {
  yield * put(setLoaderStart({ id: 'rfirDevice/create' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.createDevice, rfirDevice)
  if (!res.ok || res.data == null) {
    const message = errorProtoString(res)
    yield * put(setLoaderError({ id: 'rfirDevice/create', message }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'rfirDevice/create', meta: { id: String(res.data.id) } }))
  }
}

export function * onDeleteRfIrDevice ({ payload: { id } }: ReturnType<typeof deleteRfIrDevice>) {
  yield * put(setLoaderStart({ id: 'rfirDevice/delete' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.deleteDevice, Number(id))
  if (!res.ok && res.status !== 404) {
    const message = errorProtoString(res)
    yield * put(setLoaderError({ id: 'rfirDevice/delete', message }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'rfirDevice/delete' }))
  }
}

export function * onUpdateRfIrDevice ({ payload: { id, data } }: ReturnType<typeof updateRfIrDevice>) {
  try {
    yield * put(setLoaderStart({ id: 'rfirDevice/update' }))
    yield * call(apiPatchRfIrDevice, id, data)
    yield * put(setLoaderSuccess({ id: 'rfirDevice/update' }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: 'rfirDevice/update', message: formatError(err) }))
  }
}

export function * apiPatchRfIrDevice (id: string, { rfirDevice }: RfIrDeviceFormData) {
  const api = yield * select(selectApi)
  const res = yield * call(api.patchDevice, Number(id), rfirDevice)
  if (!res.ok || res.data == null) {
    throw new Error(errorProtoString(res))
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } catch (e) {
    console.error('patchRfIrDevice', e)
  }
}

export function * saga () {
  yield all([
    takeLeading(createRfIrDevice, onCreateRfIrDevice),
    takeLeading(deleteRfIrDevice, onDeleteRfIrDevice),
    takeLeading(updateRfIrDevice, onUpdateRfIrDevice)
  ])
}
