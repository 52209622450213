import React from 'react'
import { TouchableOpacity, View, Text, StyleSheet } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { get, map } from 'lodash'
import { gettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { selectChannelIconName } from '#app/channel'

export default function Alarm ({
  type,
  name,
  slave,
  channel,
  extra,
  onPress
}: any) {
  const WEEK_DAYS = {
    0: gettext('Mon'),
    1: gettext('Tue'),
    2: gettext('Wed'),
    3: gettext('Thu'),
    4: gettext('Fri'),
    5: gettext('Sat'),
    6: gettext('Sun')
  }

  const TITLES = {
    CONSUMPTION_ZERO: gettext('Device without consumption'),
    CONSUMPTION_USE_HOURS: gettext('Consumption during unauthorized hours'),
    USE_HOURS: gettext('Action in unauthorized time'),
    CONSUMPTION_OVER: gettext('Consumption Over Default'),
    CONSUMPTION_UNDER: gettext('Consumption Under Default'),
    NO_COMM: gettext('No Communication'),
    SENSOR_DETECTED: gettext('Sensor Detection')
  }

  function getIcon (type: any, slave: any, channel: any, iconColor = '#B22222') {
    if (type === 'SENSOR_DETECTED' || type === 'USE_HOURS') {
      const name = selectChannelIconName(get(channel, 'type'))

      return (
        <MaterialCommunityIcons
          name={name}
          size={24}
          color={iconColor}
        />
      )
    } else if (type === 'NO_COMM') {
      return (
        <MaterialCommunityIcons
          name="close-circle"
          size={24}
          color={iconColor}
        />
      )
    } else if (type === 'NO_COMM') {
      return (
        <MaterialCommunityIcons
          name="wifi-strength-alert-outline"
          size={24}
          color={iconColor}
        />
      )
    } else {
      return (
        <MaterialCommunityIcons
          name="alert-circle-outline"
          size={24}
          color={iconColor}
        />
      )
    }
  }

  function getName (type: any, slave: any, channel: any) {
    const slaveName = get(slave, 'name')
    const channelName = get(channel, 'name')
    if (type === 'SENSOR_DETECTED' || type === 'USE_HOURS') {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/strict-boolean-expressions
      return `${slaveName} - ${channelName || ''}`
    } else {
      return slaveName
    }
  }

  function getWeekDays (extra: any) {
    const weekDaysList = get(extra, 'weekDays')
    const weekDays = map(weekDaysList, (day) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return WEEK_DAYS[day.week_day]
    })

    return weekDays.join(',')
  }

  const theme = useAppTheme()
  const iconColor = theme.name === 'light' ? theme.colors.grey : theme.colors.white

  return (<TouchableOpacity onPress={onPress}>
      <>
        <View style={styles.container}>
          <View style={styles.left}>
            {getIcon(type, slave, channel, iconColor)}
          </View>
          <View style={styles.right}>
            <View>
              <Text style={[theme.list.textList, styles.title]}>
                {name} - {getName(type, slave, channel)}
              </Text>
            </View>
            <View>
              <Text style={[theme.list.textList, styles.description]} numberOfLines={1}>
                {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {TITLES[type]}
              </Text>
            </View>
            <View>
              <Text style={[theme.list.textList, styles.description]} numberOfLines={1}>
                {getWeekDays(extra)}
              </Text>
            </View>
          </View>
        </View>
        <View style={theme.list.borderBottom}/>
      </>
    </TouchableOpacity>)
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 15,
    paddingTop: 10,
    paddingBottom: 10
  },
  left: {
    width: 48,
    padding: 8
  },
  right: {
    flex: 1,
    paddingRight: 16
  },
  title: {
    alignSelf: 'flex-start',
    fontWeight: 'bold'
  },
  description: {
    alignSelf: 'flex-start'
  },
  new: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  newTag: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  duration: {
    width: 50,
    justifyContent: 'center',
    alignItems: 'center'
  },
  newTagText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 10
  }
})
