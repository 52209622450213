import React, { useState, useEffect, useCallback } from 'react'
import { Right } from '../components/List'
import Icon from '../components/Icon'
import Potency from '../components/ThreePhase/Potency'
import { useAppDispatch, useAppSelector } from '../hooks'
import {
  View,
  TouchableOpacity,
  Text,
  StyleSheet, Platform, SafeAreaView
} from 'react-native'
import WsActions from '../redux/ws'
import moment from 'moment'
import TitleLoading from '../components/TitleLoading'
import { ECharts } from '../components/ECharts'
import { HeaderButton, HeaderButtons, Item } from 'react-navigation-header-buttons'
import { get } from 'lodash'
import Utils from '../redux/utils'
import BlurView from '../components/BlurView'
import { t, gettext } from 'ttag'
import MyioDatePicker from '../components/MyioDatePicker'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'

const IoniconsHeaderButton = (props: any) => {
  return (
    <HeaderButton
      {...props}
      IconComponent={Icon}
      iconSize={23}
      color={props.color}
    />
  )
}

export default function EnergyThreePhase () {
  const dispatch = useAppDispatch()
  const [type] = useState([{ label: gettext('Power'), value: 'potency' }, { label: gettext('Current'), value: 'current' }, { label: gettext('Voltage'), value: 'voltage' }])
  const [selectedType, setSelectedType] = useState({ label: gettext('Power'), value: 'potency' })

  const [period] = useState([{ label: gettext('Day'), value: 'hour' }, { label: gettext('Month'), value: 'day' }, { label: gettext('Year'), value: 'month' }])
  const [selectedPeriod, setSelectedPeriod] = useState({ label: gettext('Day'), value: 'hour' })

  const navigation = useNavigation()
  const theme = useAppTheme()

  const { consumptionThreePhase } = useAppSelector(state => state.consumption)

  const years = Utils.getYearList()

  const months = [
    { label: gettext('JANUARY'), value: '01' },
    { label: gettext('FEBRUARY'), value: '02' },
    { label: gettext('MARCH'), value: '03' },
    { label: gettext('APRIL'), value: '04' },
    { label: gettext('MAY'), value: '05' },
    { label: gettext('JUNE'), value: '06' },
    { label: gettext('JULY'), value: '07' },
    { label: gettext('AUGUST'), value: '08' },
    { label: gettext('SEPTEMBER'), value: '09' },
    { label: gettext('OCTOBER'), value: '10' },
    { label: gettext('NOVEMBER'), value: '11' },
    { label: gettext('DECEMBER'), value: '12' }
  ]

  const [showPickerDate, setShowPickerDate] = useState(false)
  const [date, setDate] = useState(moment().set({ hour: 0, minutes: 0, seconds: 0 }).toDate())
  const [month, setMonth] = useState(months.find(m => m.value === moment().format('MM')))
  const [year, setYear] = useState(years.find(m => m.value === moment().format('YYYY')))

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_CONSUMPTION_THREE_PHASE' })
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    dispatch({ type: 'RESET_CONSUMPTION_THREE_PHASE' })
    updateWs()
    updateData()
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedType])

  useEffect(() => {
    updateData()
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [date, month, year, selectedPeriod, dispatch])

  const updateWs = useCallback(() => {
    if (get(consumptionThreePhase, 'selectedThreePhase.type') !== 'three_phase_sensor') return
    subscribe()
    if (selectedType.value === 'potency') {
      checkSelectedType('check_potency')
    } else if (selectedType.value === 'current') {
      checkSelectedType('check_current')
    } else if (selectedType.value === 'voltage') {
      checkSelectedType('check_voltage')
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedType, dispatch])

  const updateData = useCallback(() => {
    let payload = {}

    if (selectedPeriod.value === 'hour') {
      payload = { period: 'hour', date: moment(date, 'DD/MM/YYYY').toDate() }
    } else if (selectedPeriod.value === 'day') {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      payload = { period: 'day', month: month.value, year: year.value }
    } else if (selectedPeriod.value === 'month') {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      payload = { period: 'month', year: year.value }
    }

    if (moment(date, 'DD/MM/YYYY', true).isValid()) {
      dispatch({ type: 'GET_THREE_PHASE_CONSUMPTION_REQUEST', payload })
    }
  }, [date, month, year, selectedPeriod, dispatch])

  useEffect(() => {
    navigation.setOptions({
      title: get(consumptionThreePhase, 'selectedThreePhase.name'),
      headerRight: () => {
        return (
          <HeaderButtons HeaderButtonComponent={IoniconsHeaderButton}>
            <Item
              title={t`Refresh`}
              iconName="refresh"
              onPress={() => {
                dispatch({ type: 'RESET_CONSUMPTION_THREE_PHASE' })
                updateWs()
                updateData()
              }}
            />
          </HeaderButtons>
        )
      }
    })
  },
  [navigation, dispatch, updateWs, updateData, consumptionThreePhase])

  const subscribe = useCallback(() => {
    dispatch(WsActions.wsConnectionSendMessage({
      command: 'subscribe',
      id: get(consumptionThreePhase, 'selectedThreePhase.slave_id'),
      scope: 'slave',
      type: 'consumption'
    }))
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const checkSelectedType = useCallback((command) => {
    dispatch(WsActions.wsConnectionSendMessage({
      type: 'slave',
      id: get(consumptionThreePhase, 'selectedThreePhase.slave_id'),
      command
    }))
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const getDate = () => {
    return moment(date).startOf('day').tz('America/Sao_Paulo').format('DD/MM/YYYY').toString()
  }

  // const onChangeDate = (selectedDate) => {
  //   setShowPickerDate(false)

  //   if (!selectedDate) {
  //     return
  //   }

  //   setDate(selectedDate)
  // }

  const getChartDf = () => {
    let df = consumptionThreePhase.consumptionData.values.map((c: any) => {
      return {
        value: [c.label, parseFloat(c.value)]
      }
    })

    if (selectedPeriod.value !== 'hour') {
      df = consumptionThreePhase.consumptionData.values.map((c: any) => {
        return {
          value: [moment.utc(c.label).format('YYYY-MM-DD'), parseFloat(c.value)]
        }
      })
    }

    return df
  }

  const getChartTooltipFormatter = () => {
    let formatter = (params: any) => {
      const date = new Date(params[0].value[0])
      const value = parseFloat(params[0].value[1]).toFixed(2)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const dt = ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2)
      return `${dt}</br>${value}kWh`
    }

    if (selectedPeriod.value === 'day') {
      formatter = (params) => {
        const date = new Date(params[0].value[0])
        const value = parseFloat(params[0].value[1]).toFixed(2)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const dt = ('0' + date.getUTCDate()).substr(-2) + '/' + ('0' + (date.getUTCMonth() + 1)).substr(-2) + '/' + ('0' + date.getUTCFullYear()).substr(-2)
        return `${dt}</br>${value}kWh`
      }
    } if (selectedPeriod.value === 'month') {
      formatter = (params) => {
        const date = new Date(params[0].value[0])
        const value = parseFloat(params[0].value[1]).toFixed(2)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        const dt = ('0' + (date.getUTCMonth() + 1)).substr(-2) + '/' + ('0' + date.getUTCFullYear()).substr(-2)
        return `${dt}</br>${value}kWh`
      }
    }

    return formatter
  }

  const getChartAxisLabelFormatter = () => {
    let formatter = (value: any, index: any) => {
      const dt = new Date(value)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return ('0' + dt.getHours()).substr(-2) + 'H'
    }

    if (selectedPeriod.value === 'day') {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(value: any, index: any) => number' is not a... Remove this comment to see the full error message
      formatter = (value, index) => {
        const dt = new Date(value)
        return dt.getUTCDate()
      }
    } else if (selectedPeriod.value === 'month') {
      formatter = (value, index) => {
        const dt = new Date(value)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return ('0' + dt.getUTCMonth()).substr(-2)
      }
    }

    return formatter
  }

  const getChartOptions = () => {
    return {
      xAxis: {
        show: true,
        type: 'category',
        maxInterval: 3600 * 1000 * 24,
        axisLine: {
          lineStyle: {
            color: theme.textColor
          }
        },
        axisLabel: {
          formatter: getChartAxisLabelFormatter()
        }
      },
      yAxis: {
        show: true,
        type: 'value',
        axisLine: {
          lineStyle: {
            color: theme.textColor
          }
        },
        splitLine: {
          show: false
        }
      },
      series: [{
        data: getChartDf(),
        type: 'bar',
        itemStyle: { normal: { color: theme.colors.default } }
      }],
      grid: {
        left: 25,
        top: 15,
        right: 25,
        bottom: 25
      },
      tooltip: {
        show: true,
        trigger: 'axis',
        formatter: getChartTooltipFormatter(),
        axisPointer: {
          type: 'none'
        }
      }
    }
  }

  const getChart = () => {
    if (consumptionThreePhase?.consumptionData?.values == null) return <></>
    const options = getChartOptions()
    return (
      <View style={{ flex: 2 }}>
        <ECharts
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ option: { xAxis: { show: boolean; type: st... Remove this comment to see the full error message
          option={options}
          backgroundColor="transparent"
        />
      </View>
    )
  }

  const formatConsumption = (consumption: any, hour: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (consumptionThreePhase.timeoutWsRequest) { return '-' }

    if (consumption < 1000) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/strict-boolean-expressions
      return Math.ceil(consumption) + ' W' + (hour ? 'h' : '')
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return (consumption / 1000).toFixed(2) + ' kW' + (hour ? 'h' : '')
    }
  }

  const formatAmperage = (amperage: any, hour: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (consumptionThreePhase.timeoutWsRequest) { return '-' }

    if (amperage < 1000) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands, @typescript-eslint/strict-boolean-expressions
      return Math.ceil(amperage) + ' mA' + (hour ? 'h' : '')
    } else {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return (amperage / 1000).toFixed(2) + ' A' + (hour ? 'h' : '')
    }
  }

  const formatVoltage = (voltage: any, suffix: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (consumptionThreePhase.timeoutWsRequest) { return '-' }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return suffix === true ? voltage + ' V' : voltage
  }

  const verifyLoading = (loading: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (consumptionThreePhase.timeoutWsRequest) { return false }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (loading) { return true }
  }

  const renderDatePicker = () => {
    if (selectedPeriod.value === 'hour') {
      if (Platform.OS !== 'web') {
        return (
          <View style={styles.flexPicker}>
            <TouchableOpacity
              onPress={() => { setShowPickerDate(!showPickerDate) }}
              style={[theme.form.inputTouchable, theme.border]}
            >
              <Text style={{ fontSize: 15, color: theme.textColor }}>{getDate()}</Text>
              <Right>
                <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
              </Right>
            </TouchableOpacity>

            {showPickerDate && (
              <MyioDatePicker date={date} theme={theme} onDateChange={setDate} />
            )}
          </View>
        )
      } else {
        return (
          <View style={styles.flexPicker}>
            <MyioDatePicker date={date} theme={theme} onDateChange={setDate} />
          </View>
        )
      }
    }
  }

  return (
      <SafeAreaView style={{ flex: 1 }}>
        <View>
          <View style={[theme.form.formGroup, theme.pb0, { marginBottom: 0, flexDirection: 'row' }]}>

            {get(consumptionThreePhase, 'selectedThreePhase.type') === 'three_phase_sensor' &&
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ModalPicker', {
                    name: gettext('Select a scene...'),
                    data: type,
                    value: selectedType,
                    search: true,
                    multiple: false,
                    required: true,
                    onPressOk: (data: any) => {
                      setSelectedType(data.selectedItem)
                    }
                  })
                }} style={[theme.form.inputTouchable, styles.flexPicker, theme.border]}
              >
                <Text style={{ fontSize: 15, color: theme.textColor }}>{selectedType.label}</Text>
                <Right>
                  <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                </Right>
              </TouchableOpacity>}

            <TouchableOpacity
              onPress={() => {
                navigation.navigate('ModalPicker', {
                  name: gettext('Period'),
                  data: period,
                  value: selectedPeriod,
                  search: true,
                  multiple: false,
                  required: true,
                  onPressOk: (data: any) => {
                    setSelectedPeriod(data.selectedItem)
                  }
                })
              }}
              style={[theme.form.inputTouchable, styles.flexPicker, theme.border]}
            >
              <Text style={{ fontSize: 15, color: theme.textColor }}>{selectedPeriod.label}</Text>
              <Right>
                <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
              </Right>
            </TouchableOpacity>

          </View>

          <View style={[theme.form.formGroup, theme.pb0, { marginBottom: 0, flexDirection: 'row' }]}>
            {renderDatePicker()}

            {selectedPeriod.value === 'day' &&
              <View style={[styles.flexPicker, {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }]}
              >
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('ModalPicker', {
                      name: gettext('Select an month...'),
                      data: months,
                      value: month,
                      search: true,
                      multiple: false,
                      required: true,
                      onPressOk: (data: any) => {
                        setMonth(data.selectedItem)
                      }
                    })
                  }}
                  style={[theme.form.inputTouchable, theme.border, {
                    justifyContent: 'center',
                    width: '50%',
                    borderTopEndRadius: 0,
                    borderBottomEndRadius: 0,
                    borderRightWidth: 0
                  }]}
                >
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  <Text style={{ fontSize: 15, color: theme.textColor }}>{month.label}</Text>
                  <Right>
                    <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                  </Right>
                </TouchableOpacity>

                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('ModalPicker', {
                      name: gettext('Select a year...'),
                      data: years,
                      value: year,
                      search: true,
                      multiple: false,
                      required: true,
                      onPressOk: (data: any) => {
                        setYear(data.selectedItem)
                      }
                    })
                  }}
                  style={[theme.form.inputTouchable, theme.border, {
                    justifyContent: 'center',
                    width: '50%',
                    borderTopStartRadius: 0,
                    borderBottomStartRadius: 0,
                    borderLeftWidth: 0
                  }]}
                >
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  <Text style={{ fontSize: 15, color: theme.textColor }}>{year.label}</Text>
                  <Right>
                    <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                  </Right>
                </TouchableOpacity>
              </View>}

            {selectedPeriod.value === 'month' &&
              <TouchableOpacity
                onPress={() => {
                  navigation.navigate('ModalPicker', {
                    name: gettext('Select a year...'),
                    data: years,
                    value: year,
                    search: true,
                    multiple: false,
                    required: true,
                    onPressOk: (data: any) => {
                      setYear(data.selectedItem)
                    }
                  })
                }}
                style={[theme.form.inputTouchable, styles.flexPicker, theme.border]}
              >
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                <Text style={{ fontSize: 15, color: theme.textColor }}>{year.label}</Text>
                <Right>
                  <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                </Right>
              </TouchableOpacity>}
          </View>

          {get(consumptionThreePhase, 'selectedThreePhase.type') === 'three_phase_sensor' &&
            <View>
              {selectedType.value === 'potency' &&
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Current Power`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.potency.consumption))}
                      value={formatConsumption(consumptionThreePhase.potency.consumption, false)}
                      style={styles.flexPicker}
                    />
                    <Potency
                      label={`${gettext('Consumption of')} ${selectedPeriod.label}`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean; value: st... Remove this comment to see the full error message
                      loading={isNaN(consumptionThreePhase.consumptionData.totalValue)}
                      value={formatConsumption(consumptionThreePhase.consumptionData.totalValue, true)}
                      style={styles.flexPicker}
                    />
                  </View>

                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Phase A`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.potency.phases.a))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatConsumption(consumptionThreePhase.potency.phases.a)}
                    />
                    <Potency
                      label={t`Phase B`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.potency.phases.b))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatConsumption(consumptionThreePhase.potency.phases.b)}
                    />
                    <Potency
                      label={t`Phase C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.potency.phases.c))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatConsumption(consumptionThreePhase.potency.phases.c)}
                    />
                  </View>
                </View>}

              {selectedType.value === 'current' &&
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Actual Current`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.current.totalCurrent))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatAmperage(consumptionThreePhase.current.totalCurrent)}
                    />
                  </View>

                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Phase A`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.current.phases.a))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatAmperage(consumptionThreePhase.current.phases.a)}
                    />
                    <Potency
                      label={t`Phase B`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.current.phases.b))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatAmperage(consumptionThreePhase.current.phases.b)}
                    />
                    <Potency
                      label={t`Phase C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.current.phases.c))}
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
                      value={formatAmperage(consumptionThreePhase.current.phases.c)}
                    />
                  </View>
                </View>}

              {selectedType.value === 'voltage' &&
                <View>
                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Phase A`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.a))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.a, true)}
                    />
                    <Potency
                      label={t`Phase B`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.b))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.b, true)}
                    />
                    <Potency
                      label={t`Phase C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.c))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.c, true)}
                    />
                  </View>

                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Power Factor A`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.fp.a))}
                      value={formatVoltage(consumptionThreePhase.voltage.fp.a, false)}
                    />
                    <Potency
                      label={t`Power Factor B`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.fp.b))}
                      value={formatVoltage(consumptionThreePhase.voltage.fp.b, false)}
                    />
                    <Potency
                      label={t`Power Factor C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.fp.c))}
                      value={formatVoltage(consumptionThreePhase.voltage.fp.c, false)}
                    />
                  </View>

                  <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <Potency
                      label={t`Phase A/B`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.ab))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.ab, true)}
                    />
                    <Potency
                      label={t`Phase B/C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.bc))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.bc, true)}
                    />
                    <Potency
                      label={t`Phase A/C`}
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean | undefine... Remove this comment to see the full error message
                      loading={verifyLoading(isNaN(consumptionThreePhase.voltage.phases.ac))}
                      value={formatVoltage(consumptionThreePhase.voltage.phases.ac, true)}
                    />
                  </View>
                </View>}
            </View>}

          {get(consumptionThreePhase, 'selectedThreePhase.type') !== 'three_phase_sensor' &&
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Potency
                label={t`Current consumption`}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean; value: st... Remove this comment to see the full error message
                loading={isNaN(get(consumptionThreePhase, 'selectedThreePhase.lastConsumption'))}
                // TODO: Fix this the next time the file is edited.
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                value={`${get(consumptionThreePhase, 'selectedThreePhase.lastConsumption')} W`}
                style={styles.flexPicker}
              />
              <Potency
                label={`${gettext('Consumption of')} ${selectedPeriod.label}`}
                // @ts-expect-error ts-migrate(2322) FIXME: Type '{ label: string; loading: boolean; value: st... Remove this comment to see the full error message
                loading={isNaN(consumptionThreePhase.consumptionData.totalValue)}
                value={formatConsumption(consumptionThreePhase.consumptionData.totalValue, true)}
                style={styles.flexPicker}
              />

            </View>}

        </View>

        <View style={[theme.mb3, {
          flex: 1,
          flexDirection: 'column',
          alignItems: 'stretch',
          display: 'flex',
          padding: 5
        }]}
        >
          <BlurView
            tint={theme.blurView.tint} intensity={theme.blurView.intensity}
            style={[theme.border, theme.p3, { height: '100%' }]}
          >
            {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '(string &... Remove this comment to see the full error message */}
            <TitleLoading loading={consumptionThreePhase.loading} text={t`Consumption unitl now`} style={{ flex: 1 }} />
            {getChart()}
          </BlurView>
        </View>
      </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  flexPicker: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    margin: 5
  }
})
