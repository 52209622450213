import Separator from './Separator'
import Left from './Left'
import Right from './Right'
import ItemPress from './ItemPress'
import Item from './Item'
import Icon from './Icon'
import SlaveIcon from './SlaveIcon'
import ListItem from './ListItem'
import TextList from './TextList'

export {
  Separator,
  Left,
  Right,
  ItemPress,
  Item,
  Icon,
  SlaveIcon,
  ListItem,
  TextList
}

export { default as PickerCell } from './PickerCell'
export { default as SelectCell } from './SelectCell'
export { default as ChannelIcon } from './ChannelIcon'
export { default as RfIrDeviceIcon } from './RfIrDeviceIcon'

const ITEM_HEIGHT = 56
export const getItemLayout = (data: any, index: number) => (
  { length: ITEM_HEIGHT, offset: ITEM_HEIGHT * index, index }
)
