import React, { useEffect, useCallback } from 'react'
import { Separator, Item, ItemPress, ListItem, TextList, Right } from '../components/List'
import { Switch, View, TextInput, Text, StyleSheet, TouchableOpacity } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useAppDispatch, useAppSelector } from '../hooks'
import { t, gettext } from 'ttag'
import { useNavigation } from '@react-navigation/native'
import { HeaderButtons, HeaderButton, Item as Item2 } from 'react-navigation-header-buttons'
import { useAppTheme } from '#app/theme'

export default function FilterAmbients () {
  const { ambient, filters } = useAppSelector(state => state.ambients)
  const theme = useAppTheme()

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions(
      {
        title: gettext('Filter'),
        headerRight: () => {
          return (
            <HeaderButtons HeaderButtonComponent={HeaderButton}>
              <Item2
                title={t`Clean Filter`}
                onPress={() => clearAmbientFilters()}
              />
            </HeaderButtons>
          )
        }
      }
    )
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const clearAmbientFilters = useCallback(() => {
    dispatch({ type: 'CLEAR_AMBIENT_FILTERS', ambientId: ambient.id })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onSwitch = useCallback((key, name, value) => {
    dispatch({ type: 'SAVE_AMBIENT_FILTER', ambientId: ambient.id, key, name, value: value })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const updateConsumptionInput = useCallback((key, name, value) => {
    if (/^(\s*|\d+)$/.test(value)) {
      dispatch({ type: 'SAVE_AMBIENT_FILTER', ambientId: ambient.id, key, name, value: value })
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  return (
    (<ListItem>
      <Separator text={t`Remotes`}/>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {Object.keys(filters.remotes).map((item, idx) => <ItemPress key={idx} onPress={() => onSwitch('remotes', item, !filters.remotes[item].value)}>
            <TextList text={filters.remotes[item].label}/>
            <Right>
              <Switch onValueChange={(value) => onSwitch('remotes', item, value)} value={filters.remotes[item].value}/>
            </Right>
          </ItemPress>)}
      <Separator text={t`Meters`}/>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {Object.keys(filters.energyMeters).map((item, idx) => <ItemPress key={idx} onPress={() => onSwitch('energyMeters', item, !filters.energyMeters[item].value)}>
            <TextList text={filters.energyMeters[item].label}/>
            <Right>
              <Switch onValueChange={(value) => onSwitch('energyMeters', item, value)} value={filters.energyMeters[item].value}/>

            </Right>
          </ItemPress>)}
      <Separator text={t`Consumption`}/>
      {Object.keys(filters.consumption).map((item, idx) => <Item key={idx} onPress={() => onSwitch('consumption', item, filters.consumption[item].value)}>
            <TextList text={filters.consumption[item].label}/>
            <Right>
              <View style={{
                backgroundColor: theme.form.input.backgroundColor,
                flexDirection: 'row',
                borderRadius: 50,
                width: '55%',
                paddingLeft: 20
              }}>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
                <TextInput keyboardType="number-pad" returnKeyType="go" style={{ flex: 1, fontSize: 15, height: 40, width: '40%', color: theme.formForm.placeholderTextColor }} placeholderTextColor={theme.formForm.placeholderTextColor} autoCorrect={false} placeholder="Consumo" onChangeText={(value) => updateConsumptionInput('consumption', item, value)} value={`${filters.consumption[item].value}`}/>
                <View style={styles.revealPasswordWrapper}>
                  <Text style={{ marginRight: 10, justifyContent: 'center', fontSize: 16, fontWeight: 'bold', color: theme.formForm.placeholderTextColor }}>
                    W
                  </Text>

                  <TouchableOpacity onPress={() => updateConsumptionInput('consumption', item, '')}>
                    <FontAwesome color={(theme.formForm.placeholderTextColor)} size={20} name="trash"/>
                  </TouchableOpacity>
                </View>
              </View>
            </Right>
          </Item>)}
      <Separator text={t`Categories`}/>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {Object.keys(filters.category).map((item, idx) => <ItemPress key={idx} onPress={() => onSwitch('category', item, !filters.category[item].value)}>
            <TextList text={filters.category[item].label}/>
            <Right>
              <Switch onValueChange={(value) => onSwitch('category', item, value)} value={filters.category[item].value}/>
            </Right>
          </ItemPress>)}
      <Separator text={t`Lightning Status`}/>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {Object.keys(filters.stateLight).map((item, idx) => <ItemPress key={idx} onPress={() => onSwitch('stateLight', item, !filters.stateLight[item].value)}>
            <TextList text={filters.stateLight[item].label}/>
            <Right>
              <Switch onValueChange={(value) => onSwitch('stateLight', item, value)} value={filters.stateLight[item].value}/>
            </Right>
          </ItemPress>)}
      <Separator text={t`Sensors Status`}/>
      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {Object.keys(filters.stateSensor).map((item, idx) => <ItemPress key={idx} onPress={() => onSwitch('stateSensor', item, !filters.stateSensor[item].value)}>
            <TextList text={filters.stateSensor[item].label}/>
            <Right>
              <Switch onValueChange={(value) => onSwitch('stateSensor', item, value)} value={filters.stateSensor[item].value}/>
            </Right>
          </ItemPress>)}
      {/* <Separator text='Estado Iluminação' />
    {
      Object.keys(filters.stateLight).map((item, idx) =>
        <ItemPress key={idx} onPress={() => onSwitch('stateLight', item, !filters.stateLight[item].value)}>
          <TextList text={filters.stateLight[item].label} />
          <Right>
            <Switch
              onValueChange={(value) => onSwitch('state', item, value)}
              value={filters.stateLight[item].value}
            />
          </Right>
        </ItemPress>
      )
    }

    <Separator text='Estado Sensores' />
    {
      Object.keys(filters.stateSensor).map((item, idx) =>
        <ItemPress key={idx} onPress={() => onSwitch('stateSensor', item, !filters.stateSensor[item].value)}>
          <TextList text={filters.stateSensor[item].label} />
          <Right>
            <Switch
              onValueChange={(value) => onSwitch('state', item, value)}
              value={filters.stateSensor[item].value}
            />
          </Right>
        </ItemPress>
      )
    } */}
    </ListItem>)
  )
}

const styles = StyleSheet.create({
  revealPasswordWrapper: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginRight: 20
  },
  input: {
    flex: 1,
    fontSize: 15,
    height: 40
  },
  formContainer: {
    backgroundColor: '#7446C6',
    flexDirection: 'row',
    borderRadius: 50,
    width: '55%',
    paddingLeft: 20,
    paddingRight: 20
  }
})
