import { combineReducers } from 'redux'
import { useColorScheme } from 'react-native'
import { createAssign, createAction } from 'robodux'

import { useAppSelector } from '#app/hooks'
import { SelectedTheme, State } from '#app/types'
import { themeLight, themeDark } from './theme'
import { DarkTheme, DefaultTheme } from './navigation'

export const toggleSelectedTheme = createAction('TOGGLE_SELECTED_THEME')

const selectedTheme = createAssign<SelectedTheme>({
  name: 'selectedTheme',
  initialState: 'system',
  extraReducers: {
    [String(toggleSelectedTheme)]: (state: SelectedTheme) => {
      switch (state) {
        case 'system':
          return 'dark'
        case 'dark':
          return 'light'
        default:
          return 'system'
      }
    }
  }
})

export const {
  set: setSelectedTheme,
  reset: resetSelectedTheme
} = selectedTheme.actions

export const reducer = combineReducers({
  selectedTheme: selectedTheme.reducer
})

export const selectSelectedTheme = (state: State) => state.theme.selectedTheme

export const useAppThemeName = () => {
  const cs = useColorScheme()
  const st = useAppSelector(selectSelectedTheme)
  switch (st) {
    case 'system':
      return cs ?? 'light'
    default:
      return st
  }
}

export const useAppTheme = () => {
  const tn = useAppThemeName()
  return tn === 'dark' ? themeDark : themeLight
}

export const useAppNavigationTheme = () => {
  const tn = useAppThemeName()
  return tn === 'dark' ? DarkTheme : DefaultTheme
}
