import React from 'react'
import { StyleSheet, Text, TouchableOpacity } from 'react-native'
import { colors } from '#app/theme/colors'

interface Props {
  text: string
  bgColor: string
  textColor: string
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onPress' does not exist on type 'Props'.
export default function Button ({ onPress, bgColor, textColor, text }: Props) {
  return (
    <TouchableOpacity onPress={onPress} style={[styles.button, { backgroundColor: bgColor }]}>
      <Text style={[styles.buttonText, { color: textColor }]}>
        {text}
      </Text>
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  button: {
    width: '100%',
    height: 46,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  buttonText: {
    color: colors.white,
    fontSize: 14
  }
})
