import React from 'react'
import { ImageBackground, Text, View, StyleSheet, PressableProps, Pressable, useWindowDimensions } from 'react-native'
import { useAppDispatch } from '#app/hooks'
import { selectAmbientData } from '#app/ambient'
import BlurView from './BlurView'
import { msgid, ngettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { connect } from 'react-redux'
import { useNavigation } from '@react-navigation/native'

export const getNumColumns = (width: number) => {
  if (width <= 385) {
    return 2
  } else if (width > 385 && width <= 600) {
    return 3
  } else if (width > 600 && width <= 1000) {
    return 4
  } else {
    return Math.floor(width / 180)
  }
}

export const calculateButtonSize = (width: number, columns: number) => {
  const size = (width / columns) - 20

  if (size > 160) {
    return 160
  }

  return size
}

// const renderTemperatureText = (value: number) => {
//   const temperature = `${value} °C`
//   return <Text style={styles.temperature}>{temperature}</Text>
// }

const renderDevicesText = (n: number) => (
  <Text style={styles.devices}>{ngettext(msgid`${n} device`, `${n} devices`, n)}</Text>
)
export interface AmbientProps extends PressableProps{
  id: string
  name: string
  devicesCount: number
  image?: string
  spacer?: boolean
}

const Ambient = ({ id, name, image, devicesCount, spacer = false, ...props }: AmbientProps) => {
  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const theme = useAppTheme()

  const { width } = useWindowDimensions()
  const size = calculateButtonSize(width, getNumColumns(width))
  const sizeStyles = {
    width: size,
    height: size
  }

  if (spacer) return <View style={[styles.container, sizeStyles]} />

  const source = image != null ? { uri: image } : {}

  const onPress = () => {
    navigation.navigate('AmbientDetail', { id })
  }

  const onLongPress = () => {
    dispatch({ type: 'UI_AMBIENT_LONGPRESS', payload: { ambientId: id } })
  }

  return (
    <Pressable onPress={onPress} onLongPress={onLongPress} {...props}>
      <BlurView
        tint={theme.blurView.tint}
        intensity={theme.blurView.intensity}
        style={[styles.container, sizeStyles, theme.border]}
      >
        <ImageBackground style={{ position: 'absolute', left: 0, right: 0, height: '100%' }} source={source} />

        <View style={styles.top}>
          {/* {temperature != null && renderTemperatureText(temperature)} */}
        </View>

        <View style={styles.bottom}>
          <Text style={styles.name} numberOfLines={2} ellipsizeMode="tail">{name}</Text>
          {devicesCount != null && renderDevicesText(devicesCount)}
        </View>
      </BlurView>
    </Pressable>
  )
}

export default connect(selectAmbientData)(Ambient)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    // aspectRatio: 1 / 1,
    marginLeft: 7.5,
    marginRight: 7.5,
    marginTop: 15,
    borderRadius: 15,
    overflow: 'hidden'
    // backgroundColor: colors.purple
  },
  top: {
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    flex: 3
  },
  bottom: {
    flex: 1,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    justifyContent: 'center',
    padding: 12,
    paddingBottom: 6,
    borderBottomEndRadius: 15,
    borderBottomStartRadius: 10
  },
  temperature: {
    fontSize: 12,
    color: 'white',
    padding: 12
  },
  name: {
    fontSize: 12,
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'left'
  },
  devices: {
    color: 'white',
    fontSize: 11,
    textAlign: 'left'
  }
})
