import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Text, TextInput, View, ScrollView, Switch, TouchableOpacity } from 'react-native'
import { Separator, Item as ItemList, TextList, ListItem, Right } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { TextInputMask } from 'react-native-masked-text'
import { useNavigation, useRoute } from '@react-navigation/native'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import Icon from '../components/Icon'
import ButtonForm from '../components/Form/ButtonForm'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'

export default function SchedulesDetails () {
  const dispatch = useAppDispatch()

  const weekDays = [{
    name: gettext('Monday'),
    value: 1,
    selected: false
  }, {
    name: gettext('Tuesday'),
    value: 2,
    selected: false
  }, {
    name: gettext('Wednesday'),
    value: 3,
    selected: false
  }, {
    name: gettext('Thursday'),
    value: 4,
    selected: false
  }, {
    name: gettext('Friday'),
    value: 5,
    selected: false
  }, {
    name: gettext('Saturday'),
    value: 6,
    selected: false
  }, {
    name: gettext('Sunday'),
    value: 0,
    selected: false
  }]

  const [selectedWeekDays, setSelectedWeekDays] = useState(weekDays)
  const [scheduleInfo, setScheduleInfo] = useState({ id: '', name: '', hour: '' })
  const [scenesFilter, setScenesFilter] = useState([])
  const [selectedScene, setSelectedScene] = useState({})

  const [errorName, setErrorName] = useState({ show: false, message: gettext('Name is required.') })
  const [errorScene, setErrorScene] = useState({ show: false, message: gettext('Scene is required.') })
  const [errorHour, setErrorHour] = useState({ show: false, message: gettext('Invalid hour.') })
  const [errorDays, setErrorDays] = useState({ show: false, message: gettext('Choose at least one day.') })

  const schedule = useAppSelector(state => state.schedules.selectedSchedule)
  const scenesState = useAppSelector(state => state.scene.scenes)
  const theme = useAppTheme()

  const route = useRoute()
  const navigation = useNavigation()

  const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={23} color={theme.colors.success} />

  useEffect(() => { navigation.setOptions({ title: gettext('Schedule') }) })

  useEffect(() => {
    navigation.setOptions({
      headerRight: (state: any) => {
        return (
          <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
            <Item
              title={t`Save`}
              onPress={saveSchedule}
            />
          </HeaderButtons>
        )
      }
    })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [scheduleInfo, selectedWeekDays, selectedScene])

  useEffect(() => {
    const scenesFilter = scenesState.map((s: any) => {
      return { label: s.name, value: s.id }
    })

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: any; value: any; }[]' i... Remove this comment to see the full error message
    setScenesFilter(scenesFilter)
  }, [scenesState])

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!(route.params as any).new && schedule.id) {
      const cron = schedule.cron.split(' ')
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const datetime = `${cron[1]}:${cron[0]}`
      const days = cron[4].split(',').map((day: any) => parseInt(day, 10))

      const newWeekDays = weekDays.map((day) => {
        if (days.indexOf(day.value) > -1) day.selected = true
        return day
      })

      const scene = scenesState.find((s: any) => s.id === schedule.action.id)

      setSelectedWeekDays(newWeekDays)
      setScheduleInfo({ id: schedule.id, name: schedule.name, hour: datetime })

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (scene) {
        setSelectedScene({ label: scene.name, value: scene.id })
      } else {
        setSelectedScene({ label: gettext('Invalid Scene'), value: -1 })
      }
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const onSwitchChangeDay = useCallback((state, value) => {
    setSelectedWeekDays(selectedWeekDays.map(day => day.value === value ? { ...day, selected: state } : day))
  }, [selectedWeekDays])

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deleteSchedule = useCallback(() => {
    dispatch({ type: 'DELETE_SCHEDULE_REQUEST', shceduleId: schedule.id })
  })

  const saveSchedule = useCallback(() => {
    const payload = { scheduleInfo, selectedWeekDays, selectedScene }
    const isFormValidated = validateForm()

    if (isFormValidated) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if ((route.params as any).new) {
        dispatch({ type: 'CREATE_SCHEDULE_REQUEST', payload })
      } else {
        dispatch({ type: 'UPDATE_SCHEDULE_REQUEST', payload })
      }
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [scheduleInfo, selectedWeekDays, selectedScene, dispatch])

  const validateForm = () => {
    let valid = true
    const validDays = selectedWeekDays.filter((d) => !d.selected).length
    const validHourRegex = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(scheduleInfo.hour)

    if (scheduleInfo.name.length === 0) {
      setErrorName({ ...errorName, show: true })
      valid = false
    } else {
      setErrorName({ ...errorName, show: false })
    }

    if (!validHourRegex) {
      setErrorHour({ ...errorHour, show: true })
      valid = false
    } else {
      setErrorHour({ ...errorHour, show: false })
    }

    if (Object.keys(selectedScene).length === 0) {
      setErrorScene({ ...errorScene, show: true })
      valid = false
    } else {
      setErrorScene({ ...errorScene, show: false })
    }

    if (validDays === 7) {
      setErrorDays({ ...errorDays, show: true })
      valid = false
    } else {
      setErrorDays({ ...errorDays, show: false })
    }

    return valid
  }

  return (
        <ScrollView style={[{ backgroundColor: theme.backgroundColor }]}>
          <Separator text={t`Informations`}/>
          <View style={[theme.form.area]}>
            <View style={[theme.form.formGroup, { marginBottom: 15 }]}>
              <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Name`}</Text>
              <TextInput style={[theme.form.input]} placeholder={t`Name`} value={scheduleInfo.name} onChangeText={(text) => { setScheduleInfo({ ...scheduleInfo, name: text }) }}/>
              {errorName.show && <Text style={{ color: theme.textColor }}>{errorName.message}</Text>}
            </View>

            <View style={[theme.form.formGroup, { marginBottom: 15 }]}>
              <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Scene`}</Text>
              <TouchableOpacity onPress={() => {
                navigation.navigate('ModalPicker', {
                  name: gettext('Select a scene...'),
                  data: scenesFilter,
                  value: selectedScene,
                  search: true,
                  multiple: false,
                  required: true,
                  onPressOk: (data: any) => {
                    setSelectedScene(data.selectedItem)
                  }
                })
              }} style={theme.form.inputTouchable}>
                <Text style={{ fontSize: 15, color: theme.textColor }}>{(selectedScene as any).label}</Text>
                <Right>
                  <MaterialCommunityIcons name="chevron-down" size={20} color={theme.colors.grey}/>
                </Right>
              </TouchableOpacity>
              {errorScene.show && <Text style={{ color: theme.textColor }}>{errorScene.message}</Text>}
            </View>

            <View style={[theme.form.formGroup, { marginBottom: 0 }]}>
              <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Hour`}</Text>
              <TextInputMask type="custom" placeholder="00:00" options={{ mask: '99:99' }} value={scheduleInfo.hour} onChangeText={(text) => { setScheduleInfo({ ...scheduleInfo, hour: text }) }} style={[theme.form.input]}/>
            </View>
            {errorHour.show && <Text style={{ color: theme.textColor }}>{errorHour.message}</Text>}
          </View>
          <Separator text={t`Repeat`}/>
          {errorDays.show && <Text style={{ color: theme.textColor }}>{errorDays.message}</Text>}
          <ListItem>
            {selectedWeekDays.map((item, idx) => <ItemList key={idx}>
                  <TextList text={item.name}/>
                  <Right>
                    <Switch onValueChange={(state) => onSwitchChangeDay(state, item.value)} value={item.selected}/>
                  </Right>
                </ItemList>)}
          </ListItem>

          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {!(route.params as any).new && schedule.id && (<View style={[theme.form.area]}>
                <ButtonForm title={t`Delete Schedule`} btnStyle={theme.form.btn} color={theme.colors.grey} onPress={deleteSchedule}/>
              </View>)}
        </ScrollView>
  )
}
