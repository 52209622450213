import React, { Component } from 'react'
import { Platform, View } from 'react-native'
import { WebView } from 'react-native-webview'

// @ts-expect-error TS7016
import { getMinifiedEChartsFramework } from './chartconfig'
// @ts-expect-error TS7016
import * as jsBuilder from './jsBuilder'

interface OwnProps {
  onData?: (...args: any[]) => any
  legacyMode?: boolean
  canvas?: boolean
  onLoadEnd?: (...args: any[]) => any
  backgroundColor?: string
  customTemplatePath?: string
}

type Props = OwnProps & typeof ECharts.defaultProps

class ECharts extends Component<Props> {
  static defaultProps = {
    onData: () => { },
    legacyMode: false,
    canvas: false,
    onLoadEnd: () => { },
    backgroundColor: 'rgba(0, 0, 0, 0)'
  }

  callbacks: any
  html: any
  onGetHeight: any
  webview: any

  constructor (props: Props) {
    super(props)
    this.onGetHeight = null
    this.callbacks = {}
  }

  handleMessage (e: any) {
    try {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!e) { return null }

      const { onData } = this.props

      const data = JSON.parse(unescape(unescape(e.nativeEvent.data)))

      if (data.types === 'DATA') {
        onData(data.payload)
      } else if (data.types === 'CALLBACK') {
        /* eslint-disable no-case-declarations */
        const { uuid } = data
        /* eslint-enable no-case-declarations */
        this.callbacks[uuid](data.payload)
      }
    } catch (error) {
    }
  }

  postMessage (data: any) {
    this.webview.postMessage(jsBuilder.convertToPostMessageString(data))
  }

  ID () {
    return `_${Math.random().toString(36).substr(2, 9)}`
  }

  setBackgroundColor (color: any) {
    const data = {
      types: 'SET_BACKGROUND_COLOR',
      color
    }
    this.postMessage(data)
  }

  getOption (callback: any, properties = undefined) {
    const uuid = this.ID()
    this.callbacks[uuid] = callback
    const data = {
      types: 'GET_OPTION',
      uuid,
      properties
    }

    this.postMessage(data)
  }

  setOption (option: any, notMerge: any, lazyUpdate: any) {
    const data = {
      types: 'SET_OPTION',
      payload: {
        option,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        notMerge: notMerge || false,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        lazyUpdate: lazyUpdate || false
      }
    }

    this.postMessage(data)
  }

  clear () {
    const data = {
      types: 'CLEAR'
    }

    this.postMessage(data)
  }

  getWebViewRef (ref: any) {
    this.webview = ref
  }

  onLoadEnd () {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (this.webview) {
      this.webview.injectJavaScript(jsBuilder.getJavascriptSource(this.props))
    }

    this.props.onLoadEnd()
  }

  render () {
    let source = {}

    this.html = `
    <!DOCTYPE html>
      <html lang='de'>
        <head>
          <meta http-equiv='content-type' content='text/html; charset=utf-8'>
            <meta name='viewport' content='initial-scale=1, maximum-scale=3, minimum-scale=1, user-scalable=no'>
              <style type='text/css'>
                html,body {
                  height: 100%;
                  width: 100%;
                  margin: 0;
                  padding: 0;
                  background-color: ${this.props.backgroundColor};
                }
                #main {
                  height: 100%;
                  width: 100%;
                  background-color: ${this.props.backgroundColor};
                }
              </style>

              <script>
                ${getMinifiedEChartsFramework()}
              </script>
              <script>
                document.addEventListener('DOMContentLoaded', function(event) {
                  ${jsBuilder.getJavascriptSource(this.props)}
                });
              </script>

            </head>

              <body>
                <div id='main'>
                </div>
              </body>

          </html>`

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (this.props.customTemplatePath) {
      source = {
        uri: this.props.customTemplatePath
      }
    } else {
      source = {
        html: this.html,
        baseUrl: ''
      }
    }

    let view = (
      <View style={{ flex: 1 }}>
        <WebView
          ref={this.getWebViewRef}
          originWhitelist={['*']}
          scrollEnabled={false}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          source={source}
          onMessage={this.handleMessage.bind(this)}
          allowFileAccess
          allowUniversalAccessFromFileURLs
          mixedContentMode="always"
          onLoadEnd={this.onLoadEnd.bind(this)}
          androidHardwareAccelerationDisabled
          style={{
            backgroundColor: this.props.backgroundColor,
            opacity: 0.99
          }}
        />
      </View>
    )

    if (Platform.OS === 'web') {
      const src = encodeURIComponent((source as any).html)
      const contentHtml = `<iframe width='100%' height='100%'
                          style='background: ${this.props.backgroundColor}; position: absolute; left: 0; right: 0; top: 0; bottom: 0;'
                          src="data:text/html,${src}" frameborder='0'
                          allow='autoplay; encrypted-media' allowfullscreen></iframe>`
      view = <p dangerouslySetInnerHTML={{ __html: contentHtml }} />
    }

    return view
  }
}

export { ECharts }
