import React from 'react'
import GenericButton from '../GenericButton'

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof FlowSensor.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onLongPress' does not exist on type 'Pro... Remove this comment to see the full error message
export default function FlowSensor ({ onPress, onLongPress, offline, text, value, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon="waves"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; iconOnColor: stri... Remove this comment to see the full error message
      text={text}
      iconOnColor="#365b69"
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      status={`${value} L`}
      on
      onLongPress={onLongPress}
      offline={offline}
      onPress={onPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

FlowSensor.defaultProps = {
  children: null,
  onPress: () => { }
}
