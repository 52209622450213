import { all, call, put, select, takeEvery } from 'typed-redux-saga'
import { createAction, createAssign, createTable, mustSelectEntity } from 'robodux'
import { batchActions } from 'redux-batched-actions'

import { selectApi } from '#app/api'
import { moreTableSelectors } from '#app/util'
import { State, User } from '#app/types'
import { setLoaderError, setLoaderStart, setLoaderSuccess } from '#app/loader'
import { combineReducers } from 'redux'
import { userLogin, userLogout } from '#app/actions'
import { createSelector } from 'reselect'
import { pick } from 'lodash'

export const defaultUser: User = {
  id: '',
  name: '',
  email: '',
  role: 'default'
}
const createUserSelector = mustSelectEntity(defaultUser)

const token = createAssign({ name: 'token', initialState: '' })
const users = createTable<User>({ name: 'users' })
const currentUser = createAssign({ name: 'currentUser', initialState: '' })

export const {
  set: setToken,
  reset: resetToken
} = token.actions
export const {
  add: addUsers,
  set: setUsers,
  remove: removeUsers,
  reset: resetUsers,
  patch: patchUsers
} = users.actions
export const {
  set: setCurrentUser,
  reset: resetCurrentUser
} = currentUser.actions

export const reducer = combineReducers({
  token: token.reducer,
  users: users.reducer,
  currentUser: currentUser.reducer
})

export const selectIsAuthenticated = (state: State) => state.user.token !== '' && state.user.currentUser !== ''
export const {
  selectTable: selectUsers,
  selectTableAsList: selectUsersAsList,
  selectById: selectUserById,
  findById: findUserById
} = users.getSelectors<State>(state => state.user.users)
export const {
  selectByProps: selectUsersByProps,
  selectOneByProps: selectUserByProps,
  findByProps: findUsersByProps
} = moreTableSelectors<User, State>(state => state.user.users)
export const selectUserByIdDefaulting = createUserSelector(selectUserById)
export const selectCurrentUserId = (state: State) => state.user.currentUser
export const selectCurrentUser = createSelector(
  [
    (state: State) => state,
    (state: State) => ({ id: selectCurrentUserId(state) })
  ],
  selectUserByIdDefaulting
)
export const selectIsSuperadmin = createSelector(
  selectCurrentUser,
  u => u.role === 'admin'
)

export const fetchUserAuth = createAction<{email: string, password: string}>('FETCH_USER_AUTH')
export const fetchUserRegister = createAction<{name: string, email: string, password: string}>('FETCH_USER_REGISTER')
export const fetchUserPasswordReset = createAction<{email: string}>('FETCH_USER_PASSWORD_RESET')

function * onFetchUserAuth ({ payload: { email, password } }: ReturnType<typeof fetchUserAuth>) {
  yield * put(setLoaderStart({ id: 'user/auth' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.auth, email, password)
  const newToken = res.data?.token
  const newUser = res.data?.user
  if (newToken == null || newUser == null) {
    yield * put(setLoaderError({ id: 'user/auth' }))
    return
  }
  yield * put(batchActions([
    setToken(newToken),
    addUsers({
      [newUser.UUID]: {
        ...pick(newUser, ['name', 'role', 'email']),
        id: newUser.UUID
      }
    }),
    setCurrentUser(newUser.UUID),
    setLoaderSuccess({ id: 'user/auth' })
  ]))
  yield * put(userLogin())
}

function * onFetchUserRegister ({ payload: { name, email, password } }: ReturnType<typeof fetchUserRegister>) {
  yield * put(setLoaderStart({ id: 'user/register' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.register, { name, email, password })
  if (!res.ok) {
    const message = res.data?.err
    yield * put(setLoaderError({ id: 'user/register', message }))
    return
  }
  yield * put(setLoaderSuccess({ id: 'user/register' }))
}

function * onFetchUserPasswordReset ({ payload: { email } }: ReturnType<typeof fetchUserPasswordReset>) {
  yield * put(setLoaderStart({ id: 'user/passwordReset' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.forgotPassword, email)
  if (!res.ok) {
    const message = res.data?.err
    yield * put(setLoaderError({ id: 'user/passwordReset', message }))
    return
  }
  yield put(setLoaderSuccess({ id: 'user/passwordReset' }))
}

function * onUserLogout (action: ReturnType<typeof userLogout>) {
  yield put(batchActions([
    resetToken(),
    resetUsers(),
    resetCurrentUser()
  ]))
}

export function * saga () {
  yield all([
    takeEvery(fetchUserAuth, onFetchUserAuth),
    takeEvery(fetchUserRegister, onFetchUserRegister),
    takeEvery(fetchUserPasswordReset, onFetchUserPasswordReset),
    takeEvery(userLogout, onUserLogout)
  ])
}
