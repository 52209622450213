import { AppState, AppStateStatus } from 'react-native'
import { eventChannel } from 'redux-saga'
import { put, fork, all } from 'redux-saga/effects'
import { call, take, cancelled, takeLatest } from 'typed-redux-saga'
import { batchActions } from 'redux-batched-actions'

import AlarmsActions from '../redux/alarms'
import AlertsActions from '../redux/alerts'
import AmbientsActions from '../redux/ambients'
import ConsumptionActions from '../redux/consumption'
import FavoritesActions from '../redux/favorites'
import LogsActions from '../redux/logs'
import LookupActions from '../redux/lookUp'
import SceneActions from '../redux/scene'
import SchedulesAction from '../redux/schedules'
import SlavesAction from '../redux/slaves'
import WsActions from '../redux/ws'
import { fetchAmbients } from '#app/ambient'

export function * loadData () {
  yield put(batchActions([
    fetchAmbients(),
    AlarmsActions.alarmsGetRequest(),
    AlertsActions.alertsGetAlertsHistory(),
    FavoritesActions.getFavoritesRequest(),
    SceneActions.dataGetScenesRequest(),
    SchedulesAction.getSchedulesRequest(),
    SlavesAction.dataGetSlavesRequest()
  ]))
}

export function * resetData () {
  yield put(batchActions([
    AlarmsActions.clearAlarmsState(),
    AlertsActions.clearAlertsState(),
    AmbientsActions.clearAmbientState(),
    ConsumptionActions.clearConsumptionState(),
    FavoritesActions.clearFavoritesState(),
    LogsActions.clearLogs(),
    LookupActions.clearLookupState(),
    SceneActions.clearSceneState(),
    SlavesAction.clearSlaveState(),
    WsActions.clearWsState(),
    WsActions.wsConnectionCloseRequest()
  ]))
}

export function * appStateListenerSaga () {
  const appStateChannel = yield * call(createAppStateChannel)
  try {
    while (true) {
      const state = yield * take(appStateChannel)
      const type = `APP_STATE_${state.toUpperCase()}`
      yield put({ type })
    }
  } finally {
    if (yield * cancelled()) {
      appStateChannel.close()
    }
  }
}

export default function * appRootSaga () {
  yield all([
    fork(appStateListenerSaga),
    takeLatest('APP_LOAD_DATA', loadData),
    takeLatest('APP_RESET_DATA', resetData)
  ])
}

const createAppStateChannel = () => (
  eventChannel((emit: (state: AppStateStatus) => void) => {
    AppState.addEventListener('change', emit)
    return () => {
      AppState.removeEventListener('change', emit)
    }
  })
)
