import React, { useCallback } from 'react'
import { Text, View, FlatList, ListRenderItemInfo } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useFocusEffect, useNavigation } from '@react-navigation/native'

import { useAppDispatch, useAppSelector } from '#app/hooks'
import { Left, ItemPress, Right, getItemLayout } from '#app/components/List'
import { useAppTheme } from '#app/theme'
import { Central } from '#app/types'
import { resetLoaderById, selectLoaderById } from '#app/loader'
import moment from 'moment'
import { fetchCentrals, fetchMoreCentrals, selectCentrals } from '#app/central'
import { selectUserCentralById } from '#app/centraluser'

const SuperadminCentrals = () => {
  const dispatch = useAppDispatch()
  const data = useAppSelector(selectCentrals)

  const { isInitialLoading, isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'centrals' }))
  const onRefresh = useCallback(() => {
    dispatch(fetchCentrals())
  }, [dispatch])
  const onEndReached = useCallback(() => {
    dispatch(fetchMoreCentrals())
  }, [dispatch])
  useFocusEffect(useCallback(() => {
    if (isInitialLoading) {
      dispatch(fetchCentrals())
    }
    return () => {
      resetLoaderById('centrals')
    }
  }, [dispatch, isInitialLoading]))

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      refreshing={isInitialLoading && isLoading}
      onRefresh={onRefresh}
      onEndReached={onEndReached}
      // stickyHeaderIndices={[0]}
      // ListHeaderComponent={ListHeaderComponent}
      getItemLayout={getItemLayout}
    />
  )
}

export default SuperadminCentrals

const renderItem = ({ item }: ListRenderItemInfo<Central>) => <Item {...item} />

const Item = React.memo(({ id, name: canonicalName, createdAt }: Central) => {
  const theme = useAppTheme()
  const navigation = useNavigation()
  const onPress = useCallback(() => {
    navigation.navigate('SuperadminCentralDetail', { id })
  }, [navigation, id])
  const uc = useAppSelector(state => selectUserCentralById(state, { id }))
  const centralName = canonicalName === '' ? `No Name <${id}>` : canonicalName
  const name = uc.name !== '' ? `${centralName} (${uc.name})` : centralName

  return (
    <ItemPress onPress={onPress}>
      <Left style={{ flexDirection: 'column', flexGrow: 1 }}>
        <Text style={{ color: theme.textColor }}>{name}</Text>
        <Text style={{ color: theme.textColor }}>{moment(createdAt).toLocaleString()}</Text>
      </Left>
      <Right>
        <View style={{ flexDirection: 'row' }}>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
        </View>
      </Right>
    </ItemPress>
  )
})

// const ListHeaderComponent = () =>
//   <View style={{
//     justifyContent: 'center',
//     alignItems: 'flex-start',
//     width: '100%',
//     borderBottomWidth: 1,
//     padding: 8
//     // backgroundColor: navTheme.colors.card,
//     // borderTopColor: navTheme.colors.border,
//     // paddingTop: 8,
//     // paddingBottom: insets.bottom + 8,
//     // paddingLeft: insets.left + 8,
//     // paddingRight: insets.right + 8
//   }}
//   >
//     <Pressable
//       onPress={() => {}}
//       hitSlop={10}
//     >
//       <MaterialCommunityIcons
//         name="filter-variant"
//         size={24}
//         // color={navTheme.colors.text}
//       />
//     </Pressable>
//   </View>
