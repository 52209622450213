import React, { useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { t } from 'ttag'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'

import { useAppTheme } from '#app/theme'
import { ScreenProps } from '#app/types'
import { useAppSelector } from '#app/hooks'
import { selectNewSlaveId } from '#app/slave'
import ButtonForm from '#app/components/Form/ButtonForm'

const NewSlaveStepSuccess = ({ navigation, route }: ScreenProps<'NewSlaveStepSuccess'>) => {
  const theme = useAppTheme()

  const slaveId = useAppSelector(selectNewSlaveId)

  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: true })
  }, [navigation])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        <HeaderButtons>
          <HeaderItem
            title={t`Close`}
            onPress={() => navigation.getParent()?.goBack()}
          />
        </HeaderButtons>
    })
  }, [navigation])

  return (
    <View style={styles.container}>

      <View style={theme.form.area}>
        <View style={theme.form.formGroup}>
          <ButtonForm title={t`Pair another product`} onPress={() => navigation.replace('NewSlave')} color={theme.colors.grey} />
        </View>
        <View style={theme.form.formGroup}>
          {slaveId != null && <ButtonForm title={t`Continue setting up this product`} onPress={() => navigation.replace('SlaveDetail', { id: String(slaveId) })} color={theme.colors.success} />}
        </View>
      </View>
    </View>
  )
}

export default NewSlaveStepSuccess

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center'
  }
})
