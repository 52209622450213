import React, { useEffect, useState, useCallback } from 'react'
import { useNavigation, useRoute } from '@react-navigation/native'
import { StyleSheet, View, Text, FlatList, TouchableOpacity } from 'react-native'
import { Separator, ItemPress, TextList } from '../components/List'
import Icon from '../components/Icon'
import Search from '../components/Search'
import { t } from 'ttag'
import { remove, clone } from 'lodash'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { useAppTheme } from '#app/theme'

export default function ModalPicker () {
  const route = useRoute()
  const navigation = useNavigation()

  // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<o... Remove this comment to see the full error message
  const { name, data, value, search, multiple, required } = route.params

  const theme = useAppTheme()

  const [selectedItem, setSelectedItem] = useState({})
  const [selectedItems, setSelectedItems] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [requiredError, setRequiredError] = useState(false)

  useEffect(() => {
    navigation.setOptions({
      title: '',
      headerRight: (state: any) => {
        return (
          <HeaderButtons>
            <Item
              title={t`Save`}
              iconName="add"
              onPress={goBackAndSave}
            />
          </HeaderButtons>
        )
      }
    })
  })

  useEffect(() => {
    setSearchResults(data)
  }, [data])

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    multiple ? setSelectedItems(value) : setSelectedItem(value)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [value])

  useEffect(() => {
    if (selectedItems.length === data.length) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedItems])

  const goBackAndSave = useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (required && multiple && selectedItems.length === 0) {
      setRequiredError(true)
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (required && !multiple && (selectedItem as any).value === null) {
      setRequiredError(true)
    } else {
      setRequiredError(false)
      navigation.goBack()

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (multiple) {
        (route.params as any).onPressOk({ selectedItems })
      } else {
        (route.params as any).onPressOk({ selectedItem })
      }
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedItem, selectedItems])

  const _onPressItem = useCallback((item) => {
    setRequiredError(false)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (multiple) {
      const newSelectedList = clone(selectedItems)
      const exists = selectedItems.some(selected => (selected as any).value === item.value)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      exists ? remove(newSelectedList, (e) => (e as any).value === item.value) : newSelectedList.push(item)
      setSelectedItems(newSelectedList)
    } else {
      setSelectedItem(item)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedItems, selectedItem])

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTrashPress = useCallback(() => {
    setSearchTerm('')
    setSearchResults(data)
  })

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!searchTerm) {
      setSearchResults(data)
      return
    }

    const results = data.filter((item: any) => item.label.toLowerCase().includes(searchTerm.toLowerCase())
    )

    setSearchResults(results)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [searchTerm])

  const isSelected = useCallback((item) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (multiple) {
      return !!selectedItems.some(selected => (selected as any).value === item.value)
    } else {
      return item.value === (selectedItem as any).value
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedItems, selectedItem])

  const _onPressAll = useCallback(() => {
    if (checkAll) {
      setCheckAll(false)
      setSelectedItems([])
    } else {
      setRequiredError(false)
      setCheckAll(true)
      setSelectedItems(data)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [checkAll])

  const renderPressAll = () => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (multiple && searchTerm.length === 0) {
      return (
        <ItemPress onPress={() => _onPressAll()}>
          <TextList text={t`All Items`} />
          <View style={{ flex: 1, alignSelf: 'center', alignItems: 'flex-end' }}>
            {checkAll && <Icon name="checkmark" size={20} color={theme.textColor} />}
          </View>
        </ItemPress>
      )
    }
    return (<></>)
  }

  const renderItem = ({
    item
  }: any) => {
    return (
      <ItemList item={item} onPress={() => _onPressItem(item)} />
    )
  }

  const ItemList = ({
    item,
    onPress,
    style
  }: any) => (
    <>
      <TouchableOpacity style={[theme.list.container, style]} onPress={onPress}>
        <Text style={theme.list.textList}>{item.label}</Text>
        <View style={{ flex: 1, alignSelf: 'center', alignItems: 'flex-end' }}>
          {isSelected(item) && <Icon name="checkmark" size={20} color={theme.textColor} />}
        </View>
      </TouchableOpacity>
      <View style={theme.list.borderBottom} />
    </>
  )

  return (
      <View style={[styles.wrapper]}>
        {requiredError && (
          <View style={{ backgroundColor: '#C45A5A', padding: 10 }}>
            <Text style={{ color: '#fff', textAlign: 'center' }}>
              {t`Select at least one item!`}
            </Text>
          </View>
        )}

        {/* TODO: Fix this the next time the file is edited. */}
        {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
        {search && (
          <Search
            placeholder={t`Search...`}
            placeholderTextColor="white"
            style={[styles.searchBox, { backgroundColor: theme.formForm.search }]}
            value={searchTerm}
            valueTextColor={theme.textColor}
            onChangeText={setSearchTerm}
            onTrashPress={onTrashPress}
            iconColor={theme.formForm.searchIconColor}
          />
        )}
        <Separator text={name} />
        <FlatList
          data={searchResults}
          renderItem={renderItem}
          keyExtractor={(item, index) => `${index}`}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          extraData={multiple ? selectedItems : selectedItem}
          ListHeaderComponent={renderPressAll}
        />
      </View>
  )
}

const styles = StyleSheet.create({
  searchBox: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    height: 40
  },
  searchWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 7.5,
    paddingBottom: 7.5
  },
  wrapper: {
    width: '100%',
    height: '100%'
  }
})
