import React, { useEffect, useRef } from 'react'
import Icon from '#app/components/Icon'
import { t } from 'ttag'
import { WebView } from 'react-native-webview'
import { HeaderButton, HeaderButtons, Item } from 'react-navigation-header-buttons'
import { useNavigation } from '@react-navigation/native'
import { Platform, View } from 'react-native'

let uniqueId = 0
const getUniqueId = () => uniqueId++
export const useComponentId = () => {
  const idRef = useRef<number>()
  if (idRef.current === undefined) {
    idRef.current = getUniqueId()
  }
  return idRef.current
}

const IoniconsHeaderButton = (props: any) => {
  return (
    <HeaderButton
      {...props}
      IconComponent={Icon}
      iconSize={23}
      color={props.color}
    />
  )
}

const WebViewScreen = ({ uri }: WebViewScreenProps) => {
  const navigation = useNavigation()
  const ref = useRef<WebView>(null)
  const id = String(useComponentId())

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        <HeaderButtons HeaderButtonComponent={IoniconsHeaderButton}>
          <Item
            title={t`Refresh`}
            iconName="refresh"
            onPress={() => {
              if (Platform.OS === 'web') {
                // @ts-expect-error
                // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
                document.getElementById(id).src += ''
                return
              }
              const redirectTo = `window.location = "${uri}"`
              ref.current?.injectJavaScript(redirectTo)
            }}
          />
        </HeaderButtons>
    })
  }, [navigation, uri, id])

  if (Platform.OS === 'web') {
    const contentHtml = `
      <iframe
        id="${id}"
        width='100%'
        height='100%'
        style='position: absolute; left: 0; right: 0; top: 0; bottom: 0;'
        src="${uri}"
        frameborder='0'
        allow='autoplay; encrypted-media' allowfullscreen>
        </iframe>
    `
    return <p dangerouslySetInnerHTML={{ __html: contentHtml }} />
  }

  return (
    <View style={{ flex: 1 }}>
      <WebView
        ref={ref}
        // containerStyle={{ flex: 1 }}
        // style={{ flex: 1 }}
        source={{ uri }}
        originWhitelist={['*']}
        mixedContentMode="always"
      />
    </View>
  )
}

export default WebViewScreen

export interface WebViewScreenProps {
  uri: string
}
