import React from 'react'

import LightSwitch from '../components/Switch/LightSwitch'
import Outlet from '../components/Switch/Outlet'
import Energy from '../components/Switch/Energy'
import FlowSensor from '../components/Switch/FlowSensor'
import DoorSensor from '../components/Switch/DoorSensor'
import SmokeSensor from '../components/Switch/SmokeSensor'
import PresenceSensor from '../components/Switch/PresenceSensor'
import LightSensor from '../components/Switch/LightSensor'
import RemoteWidget from '../components/Switch/RemoteWidget'
import TemperatureSensor from '../components/Remote/TemperatureSensor'
import WaterLevel from '../components/Switch/WaterLevel'

const getDeviceType = (device: any, onPress: any, onLongPress: any, onRemove: any) => {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (device.is_channel) {
    if (device.type === 'lamp') {
      return (
        <LightSwitch
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
          text={device.name}
          on={device.value > 0}
          onPress={onPress}
        />
      )
    } else if (device.type === 'plug') {
      return (
        <Outlet
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
          text={device.name}
          on={device.value > 0}
          onPress={onPress}
        />
      )
    } else if (device.type === 'water_level_inverted') {
      return (
        <WaterLevel
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onPres... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          onPress={onPress}
          inverted
        />
      )
    } else if (device.type === 'water_level') {
      return (
        <WaterLevel
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onPres... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          onPress={onPress}
        />
      )
    } else if (device.type === 'flow_sensor') {
      return (
        <FlowSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onPres... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          onPress={onPress}
        />
      )
    } else if (device.type === 'door_sensor') {
      return (
        <DoorSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          on={device.value === 0}
          onPress={onPress}
        />
      )
    } else if (device.type === 'smoke_sensor') {
      return (
        <SmokeSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
          text={device.name}
          value={device.value} on={device.value > 0}
          onPress={onPress}
        />
      )
    } else if (device.type === 'presence_sensor') {
      return (
        <PresenceSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          on={device.value > 0}
          onPress={onPress}
        />
      )
    } else if (device.type === 'light_sensor') {
      return (
        <LightSensor
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onPres... Remove this comment to see the full error message
          text={device.name}
          value={device.value}
          onPress={onPress}
        />
      )
    } else {
      return (
        <Outlet
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`channel_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
          text={device.name}
          on={device.value > 0}
          onPress={onPress}
        />
      )
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  } else if (device.is_device) {
    return (
      <RemoteWidget
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key={`device_${device.id}`}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; category: any; on:... Remove this comment to see the full error message
        text={device.name}
        category={device.category}
        on={100}
        onPress={onPress}
        onLongPress={onLongPress}
      />
    )
  }

  if (device.type === 'outlet' || device.type === 'light_switch' || device.type === 'three_phase_sensor') {
    return (
      <Energy
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key={`slave_${device.id}`}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: true; value: a... Remove this comment to see the full error message
        text={device.name} on
        value={device.lastConsumption}
        removeButton={false}
        removeButtonPress={onRemove}
      />
    )
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  } else if (device.type === 'infrared' && device.temperature) {
    return (
      <TemperatureSensor
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key={`slave_${device.id}`}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: string; on: true; value... Remove this comment to see the full error message
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        text={`${device.name}`}
        on
        value={device.temperature}
        removeButton={false}
        removeButtonPress={onRemove}
      />
    )
  }

  console.log('Returning null for device: ', device)
  return null
}

export default function DeviceSelector ({
  device,
  onPress
}: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
  return getDeviceType(device, onPress, onPress)
}
