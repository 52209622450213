import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { View, ScrollView, ActivityIndicator } from 'react-native'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { ScreenProps, SlaveConfigChannelFormData } from '#app/types'
import {
  selectSlaveConfigChannelForEdit,
  selectSlaveConfigChannelTypesForPicker,
  selectSlaveConfigChannelPulsesForPicker,
  selectSlaveConfigChannelOutputsForPicker,
  updateSlaveChannel,
  slaveConfigChannelTypeList,
  slaveConfigChannelPulsesList,
  slaveConfigChannelOutputList,
  SlaveConfigChannelType
} from '#app/slave'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { SelectCell } from '#app/components/List'

const InOutPage = ({ navigation, route: { params: { id, channel } } }: ScreenProps<'InOutPage'>) => {
  const theme = useAppTheme()

  const types = useAppSelector(selectSlaveConfigChannelTypesForPicker)
  const pulses = useAppSelector(selectSlaveConfigChannelPulsesForPicker)
  const outputs = useAppSelector(selectSlaveConfigChannelOutputsForPicker)
  const defaultValues = useAppSelector(state => selectSlaveConfigChannelForEdit(state, { id, channel }))
  const { control, handleSubmit, formState: { isDirty }, watch } = useForm<SlaveConfigChannelFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<SlaveConfigChannelFormData>>((data) => {
    dispatch(updateSlaveChannel({ id, channel, data }))
  }, [dispatch, id, channel])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: `slave/updateChannel/${channel}` }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        isLoading
          ? <ActivityIndicator />
          : <HeaderButtons>
              <HeaderItem
                title={t`Save`}
                iconName="save"
                onPress={handleSubmit(onSubmit)}
              />
            </HeaderButtons>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading])

  const type = watch('type')
  const showOutputSignal = !(excludeOutputSignal.includes(type))
  const showPulses = type !== 'IGNORE_INPUT'

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      {showError &&
        <View style={theme.form.area}>
          <View style={theme.form.formGroup}>
            <Error>{message}</Error>
          </View>
        </View>
      }

      <Controller
        control={control}
        name="type"
        render={({ field: { ref, onChange, onBlur, value } }) =>
          <SelectCell
            ref={ref}
            onBlur={onBlur}
            selectedValue={value}
            onValueChange={onChange}
            title={t`Operation Mode`}
            items={types}
          />
        }
      />

      {showPulses &&
        <Controller
          control={control}
          name="pulses"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <SelectCell
              ref={ref}
              onBlur={onBlur}
              selectedValue={value === null ? '' : value}
              onValueChange={v => onChange(v === '' ? null : Number(v))}
              title={t`Pulse Threshold`}
              items={pulses}
            />
          }
        />
      }

      {showOutputSignal &&
        <Controller
          control={control}
          name="output"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <SelectCell
              ref={ref}
              onBlur={onBlur}
              selectedValue={value}
              onValueChange={onChange}
              title={t`Output Signal`}
              items={outputs}
            />
          }
        />
      }
    </ScrollView>
  )
}

export default InOutPage

const excludeOutputSignal = [
  'REMOTE_INPUT',
  'REPLICATED',
  'NOT_REPLICATED',
  'IGNORE_INPUT',
  'PULSE_ON_CHANGE',
  'PULSE_ON_POWER',
  'PULSE_ON_POWER_OFF'
]

const schema: Yup.SchemaOf<SlaveConfigChannelFormData> = Yup.object({
  type: Yup.mixed()
    .oneOf(slaveConfigChannelTypeList)
    .required(),
  pulses: Yup.mixed()
    .when('type', {
      is: (type: SlaveConfigChannelType) => type !== 'IGNORE_INPUT',
      then: Yup.mixed()
        .oneOf(slaveConfigChannelPulsesList)
        .required()
    }),
  output: Yup.mixed()
    .when('type', {
      is: (type: SlaveConfigChannelType) => !(excludeOutputSignal.includes(type)),
      then: Yup.mixed()
        .oneOf(slaveConfigChannelOutputList)
        .required()
    })
}).required()
