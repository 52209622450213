import React, { useState, useCallback, useEffect } from 'react'
import { FlatList } from 'react-native'
import { useAppSelector } from '../hooks'
import { orderBy, find, get } from 'lodash'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import Alarm from '../components/Alarm'
import { t, gettext } from 'ttag'
import { useNavigation } from '@react-navigation/native'

export default function AlarmList () {
  const alarms = useAppSelector(state => state.alarms.alarms)
  const loading = useAppSelector(state => state.alarms.loading)
  const slaves = useAppSelector(state => state.slaves.allSlaves)
  const [sortedAlarms, setSortedAlarms] = useState([])

  const navigation = useNavigation()

  useEffect(() => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSortedAlarms(orderBy(alarms, ['id'], ['desc']))
  }, [alarms])

  useEffect(() => {
    navigation.setOptions({
      title: gettext('Alarms'),
      headerRight: (state: any) => {
        return (
          <HeaderButtons>
            <Item
              title={t`Add`}
              iconName="add"
              onPress={() => {
                navigation.navigate('AlarmForm')
              }}
            />
          </HeaderButtons>
        )
      }
    })
  })

  const onAlarmPress = useCallback((id) => {
    navigation.navigate('AlarmForm', { id })
  }, [navigation])

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderItemCall = useCallback(({ item }) => {
    const slave = find(slaves, { id: item.slave_id })
    const channel = find(get(slave, 'channels_list'), {
      channel: get(item, 'extra.channel')
    })

    return (
      <Alarm
        type={item.type}
        name={item.name}
        slave={slave}
        channel={channel}
        extra={item.extra}
        onPress={() => onAlarmPress(item.id)}
      />
    )
  })

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  return <FlatList data={sortedAlarms} refreshing={loading} renderItem={renderItemCall} keyExtractor={item => `${(item as any).id}`}/>
}
