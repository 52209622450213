import React, { useCallback } from 'react'
import { StyleSheet, View } from 'react-native'
import RemoteWidget from './Switch/RemoteWidget'
import LightSwitch from './Switch/LightSwitch'
import PulseUp from './Switch/PulseUp'
import PulseDown from './Switch/PulseDown'
import Outlet from './Switch/Outlet'
import FlowSensor from './Switch/FlowSensor'
import DoorSensor from './Switch/DoorSensor'
import SmokeSensor from './Switch/SmokeSensor'
import PresenceSensor from './Switch/PresenceSensor'
import LightSensor from './Switch/LightSensor'
import WaterLevel from './Switch/WaterLevel'
import NoContent from './Utils/NoContent'
import { useAppDispatch, useAppSelector } from '../hooks'
import { get, map, find, compact } from 'lodash'
import WsActions from '../redux/ws'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { gettext } from 'ttag'
import { getFavoriteData } from '../redux/utils'
import { selectCan } from '#app/centraluser'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'

const SlaveBox = ({ channels = [], devices = [] }: SlaveBoxProps) => {
  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const theme = useAppTheme()
  const { showActionSheetWithOptions } = useActionSheet()
  const can = useAppSelector(selectCan)
  const canUse = can('use')

  const allSlaves = useAppSelector((state) => state.slaves.allSlaves)
  const mapC = map(channels, id => find(allSlaves, { id, is_channel: true }))
  const mapD = map(devices, id => find(allSlaves, { id, is_device: true }))
  const items = compact([...mapC, ...mapD])

  const onLongPressChannel = useCallback((device) => {
    const { favoriteOption, favoriteAction } = getFavoriteData('channels', channels, device.id)
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 3,
        options: [gettext('Device Configuration'), gettext('Product Configuration'), favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            navigation.navigate('ChannelConfig', { id: String(device.id) })
            break
          case 1:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 2:
            dispatch(favoriteAction)
            break
          case 3:
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, channels, canUse])

  const onLongPressRemote = useCallback((device) => {
    const { favoriteOption, favoriteAction } = getFavoriteData('devices', devices, device.id)
    if (canUse) {
      showActionSheetWithOptions({
        cancelButtonIndex: 4,
        options: [
          gettext('Edit Buttons'),
          gettext('Device Configuration'),
          gettext('Product Configurations'),
          favoriteOption,
          gettext('Cancel')
        ]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch({ type: 'REMOTE_SET_REMOTE', remote: { ...device } })
            navigation.navigate('RemoteConfig')
            break
          case 1:
            navigation.navigate('IrAccessory', { id: String(device.id) })
            break
          case 2:
            navigation.navigate('SlaveDetail', { id: String(device.slave_id) })
            break
          case 3:
            dispatch(favoriteAction)
            break
        }
      })
    } else {
      showActionSheetWithOptions({
        cancelButtonIndex: 1,
        options: [favoriteOption, gettext('Cancel')]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch(favoriteAction)
            break
          case 1:
            break
        }
      })
    }
  }, [navigation, dispatch, showActionSheetWithOptions, devices, canUse])

  const onClickLightSwitchChannel = useCallback((channel) => {
    if (canUse) {
      const value = channel.output === 0 ? 100 : 0
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'light_control',
        channel: channel.channel,
        value
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onClickPulseUp = useCallback((channel) => {
    if (canUse) {
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'pulse',
        channel: channel.channel,
        value: 100
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const onClickPulseDown = useCallback((channel) => {
    if (canUse) {
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: channel.slave_id,
        command: 'pulse',
        channel: channel.channel,
        value: 0
      }))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  const getDeviceType = (device: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device.is_channel) {
      if (['both_phases',
        'pulsed_actuation',
        'no_phase_actuation',
        'follows_phase',
        'negates_phase',
        'no_action',
        'count_pulses_on_variation',
        'count_pulses_on_no_phase'].includes(device.type)) {
        return (
          <LightSwitch
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onLon... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onLongPress={() => onLongPressChannel(device)}
            onPress={() => onClickLightSwitchChannel(device)}
            connectionStatus={device.status}
          />
        )
      }

      if (device.type === 'pulse_up') {
        return (
          <PulseUp
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onLon... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onLongPress={() => onLongPressChannel(device)}
            onPress={() => onClickPulseUp(device)}
            connectionStatus={device.status}
            confirm={get(device, 'config.confirm')}
          />
        )
      }

      if (device.type === 'pulse_down') {
        return (
          <PulseDown
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickPulseDown(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            confirm={get(device, 'config.confirm')}
          />
        )
      }

      if (device.type === 'lamp') {
        return (
          <LightSwitch
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
          />
        )
      } else if (device.type === 'plug') {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onPress={() => onClickLightSwitchChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            onLongPress={() => onLongPressChannel(device)}
            confirm={get(device, 'config.confirm')}
          />
        )
      } else if (device.type === 'inverted_actionable') {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onPre... Remove this comment to see the full error message
            text={device.name}
            on={device.output === 0}
            onPress={() => onClickLightSwitchChannel(device)}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
          />
        )
      } else if (device.type === 'flow_sensor') {
        return (
          <FlowSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onLong... Remove this comment to see the full error message
            text={device.name}
            value={device.value}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'water_level_inverted') {
        return (
          <WaterLevel
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; inverted: true; text: any; va... Remove this comment to see the full error message
            inverted
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'water_level') {
        return (
          <WaterLevel
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'door_sensor') {
        return (
          <DoorSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input === 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'smoke_sensor') {
        return (
          <SmokeSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input > 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'presence_sensor') {
        return (
          <PresenceSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; on: bo... Remove this comment to see the full error message
            text={device.name}
            on={device.input > 0}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else if (device.type === 'light_sensor') {
        return (
          <LightSensor
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; value: any; onLong... Remove this comment to see the full error message
            text={device.name}
            value={device.input}
            onLongPress={() => onLongPressChannel(device)}
            connectionStatus={device.status}
          />
        )
      } else {
        return (
          <Outlet
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            key={`channel_${device.id}`}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; on: boolean; onLon... Remove this comment to see the full error message
            text={device.name}
            on={device.output > 0}
            onLongPress={() => onLongPressChannel(device)}
            onPress={() => onClickLightSwitchChannel(device)}
            connectionStatus={device.status}
            loading={device.loading}
            confirm={get(device, 'config.confirm')}
          />
        )
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_device) {
      return (
        <RemoteWidget
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          key={`device_${device.id}`}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; text: any; category: any; on:... Remove this comment to see the full error message
          text={device.name}
          category={device.category}
          on={100}
          onPress={() => navigation.navigate('RemoteControl', { remote: device })}
          onLongPress={() => onLongPressRemote(device)}
          connectionStatus={device.status}
        />
      )
    }
  }

  return (
    <View style={[styles.box]}>
      {items.length === 0
        ? (<NoContent type="slaves" theme={theme} style={{ marginRight: 35 }} />)
        : (
          <View style={styles.topConsumptions}>
            <View style={[styles.sectionContainer, { display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }]}>
              {items.map(getDeviceType)}
            </View>
          </View>
          )}
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    flexDirection: 'column',
    width: '100%',
    marginLeft: 18,
    marginRight: 18,
    marginBottom: 30
  },
  sectionContainer: {
    paddingRight: 15,
    paddingBottom: 15
  },
  topConsumptions: {
    flexDirection: 'row',
    flex: 1,
    width: '100%',
    flexWrap: 'wrap'
  }
})

export default SlaveBox

export interface SlaveBoxProps {
  channels?: number[]
  devices?: number[]
}
