import React, { useCallback, useState, useEffect } from 'react'
import { Dimensions, View } from 'react-native'
import { DraggableGrid } from 'react-native-draggable-grid'
import { useAppDispatch, useAppSelector } from '../../hooks'
import GenericSmallButton from '../GenericSmallButton'
import { map, sortBy } from 'lodash'
import WsActions from '../../redux/ws'
import RemoteActions from '../../redux/remote'
import { selectCan } from '#app/centraluser'

const getNumColumns = (width: any) => {
  return 4
}

const RemoteControlGrid = ({
  remote,
  onDragStart,
  onDragRelease = () => { },
  onItemPress = () => { },
  enableDrag = true,
  clickDisable = false
}: RemoteControlGridProps) => {
  const dispatch = useAppDispatch()
  const activedButton = useAppSelector(state => state.remote.activedButton)
  const can = useAppSelector(selectCan)
  const canUse = can('use')

  const [buttons, setButtons] = useState(
    sortBy(
      map(remote.buttons, (button) => {
        return {
          ...button,
          key: button.order != null
            ? String(button.order).padStart(4, '0')
            : `${String(button.indexes).padStart(4, '0')} - ${String(button.id)}`
        }
      }),
      'key'
    )
  )

  useEffect(() => {
    if (activedButton.slaveId !== null) {
      setTimeout(() => dispatch(RemoteActions.setActivedButton({ slaveId: null, rfirCommandId: null })), 10000)
    }
  }, [dispatch, activedButton])

  const onPress = useCallback((button) => {
    if (canUse) {
      dispatch(RemoteActions.setActivedButton({ slaveId: remote.slaveId, rfirCommandId: button.rfirCommandId }))
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: remote.slaveId,
        command: 'transmit',
        rfir_command_id: button.rfirCommandId
      }))
    }
  }, [dispatch, remote.slaveId, canUse])

  useCallback(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!remote) { return }

    setButtons(sortBy(map(remote.buttons, (button) => {
      return {
        ...button,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        key: (button.order !== null && button.order !== undefined) ? button.order.toString().padStart(4, '0') : `${button.indexes.padStart(4, '0')} - ${button.id}`
      }
    }), // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    b => (b.order !== null && b.order !== undefined) ? b.order.toString().padStart(4, '0') : `${b.indexes.padStart(4, '0')} - ${b.id}`))
  }, [remote])

  const renderItem = (item: any, disabled: any, clickDisable: any) => {
    item.disabledDrag = disabled
    item.disabledReSorted = disabled
    let text = item.name
    let btnStyle = {}
    if (item.name === 'FAKE_BUTTON') {
      text = ''
      btnStyle = {
        opacity: 0
      }
    }

    return (
      <View key={item.key}>
        <GenericSmallButton text={text} loading={item.rfirCommandId === activedButton.rfirCommandId} onPress={() => onPress(item)} disable={clickDisable} btnStyle={btnStyle} />
      </View>
    )
  }

  return (
    <DraggableGrid
      numColumns={getNumColumns(Dimensions.get('window').width)}
      onDragStart={onDragStart}
      onDragRelease={(data) => {
        setButtons(data) // need reset the props data sort after drag release
        onDragRelease(data)
      }}
      renderItem={(item) => renderItem(item, !enableDrag, clickDisable)}
      onItemPress={(item) => onItemPress(item)}
      data={buttons}
    />
  )
}

export default RemoteControlGrid

export interface RemoteControlGridProps {
  remote: any
  onDragStart?: (...args: any[]) => any
  onDragRelease?: (...args: any[]) => any
  onItemPress?: (...args: any[]) => any
  enableDrag?: boolean
  clickDisable?: boolean
}
