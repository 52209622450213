import React, { useEffect, useState } from 'react'
import { FlatList, StyleSheet, FlatListProps, useWindowDimensions } from 'react-native'
import Ambient from './Ambient'
import { range } from 'lodash'

function getNumColumns (width: any) {
  if (width <= 385) {
    return 2
  } else if (width > 385 && width <= 600) {
    return 3
  } else if (width > 600 && width <= 1000) {
    return 5
  } else {
    return Math.floor(width / 180)
  }
}

function addSpacer (items: any, numColumns: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!items) { return items }

  const missingItems = numColumns - (items.length % numColumns)

  return items.concat(range(missingItems).map(index => 'spacer'))
}

const renderItem = ({ item }: {item: string | 'spacer'}) =>
  item === 'spacer'
    ? <Ambient id={''} spacer />
    : <Ambient id={item} />

const keyExtractor = (item: string) => item
export interface AmbientListProps extends Omit<FlatListProps<any>, 'renderItem'> {
}

const AmbientList = ({ data, horizontal = false, ...props }: AmbientListProps) => {
  const { width } = useWindowDimensions()
  const [numColumns, setNumColumns] = useState(getNumColumns(width))

  useEffect(() => {
    setNumColumns(getNumColumns(width))
  }, [width])

  const defaultProps = horizontal === true
    ? {
        horizontal,
        data,
        showsHorizontalScrollIndicator: false,
        contentContainerStyle: styles.horizontalList
      }
    : {
        numColumns,
        key: numColumns,
        data: data != null && data.length > 0 ? addSpacer(data, numColumns) : [],
        contentContainerStyle: styles.list
      }

  return (
    <FlatList
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      {...defaultProps}
      {...props}
    />
  )
}

export default AmbientList

const styles = StyleSheet.create({
  list: {
    alignItems: 'center',
    paddingBottom: 20
  },
  horizontalList: {
    paddingLeft: 15,
    paddingRight: 15
  }
})
