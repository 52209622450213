import { combineReducers } from 'redux'
import { all, call, put, select, takeEvery } from 'typed-redux-saga'
import { createTable, createAction, mustSelectEntity } from 'robodux'
import { batchActions } from 'redux-batched-actions'
import { reduce } from 'lodash'

import { EnvironmentVariable, State } from '#app/types'
import { setLoaderStart, setLoaderError, setLoaderSuccess } from '#app/loader'
import { selectApi } from '#app/api'

// TODO: reset when current central changes to avoid displaying stale data
const variables = createTable<EnvironmentVariable>({ name: 'variables' })
const defaultVariable: EnvironmentVariable = { name: '', value: '' }

export const {
  add: addVariables,
  set: setVariables,
  remove: removeVariables,
  reset: resetVariables,
  patch: patchVariables
} = variables.actions
export const fetchEnvironment = createAction('FETCH_ENVIRONMENT')
export const updateEnvironment = createAction<EnvironmentVariable>('UPDATE_ENVIRONMENT_VARIABLE')

export const reducer = combineReducers({
  variables: variables.reducer
})

const createVariableSelector = mustSelectEntity(defaultVariable)
const selectors = variables.getSelectors<State>(state => state.environment.variables)
const selectVariableById = createVariableSelector(selectors.selectById)
export const {
  selectTable: selectVariables,
  selectTableAsList: selectVariablesAsList
} = selectors
export const selectVariableByName = (state: State, { name }: {name: string}) => selectVariableById(state, { id: name })

function * onFetchEnvironment (action: ReturnType<typeof fetchEnvironment>) {
  yield * put(setLoaderStart({ id: 'environment' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.getEnvironment)
  if (!res.ok || res.data == null) {
    yield * put(setLoaderError({ id: 'environment' }))
    return
  }
  const normalizedData = reduce(res.data, (acc, value, name) => ({
    ...acc,
    [name]: { name, value }
  }), {})
  yield put(batchActions([setVariables(normalizedData), setLoaderSuccess({ id: 'environment' })]))
}
function * onUpdateEnvironment ({ payload: { name, value } }: ReturnType<typeof updateEnvironment>) {
  yield * put(setLoaderStart({ id: 'updateEnvironment' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.putEnvironment, name, value)
  if (!res.ok) {
    yield * put(setLoaderError({ id: 'updateEnvironment' }))
    return
  }
  yield put(batchActions([patchVariables({ [name]: { value } }), setLoaderSuccess({ id: 'updateEnvironment' })]))
}
export function * saga () {
  yield all([
    takeEvery(fetchEnvironment, onFetchEnvironment),
    takeEvery(updateEnvironment, onUpdateEnvironment)
  ])
}
