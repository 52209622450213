import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { CentralUserFormData, CentralUserRole, ScreenProps } from '#app/types'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { updateCentralUser, selectCentralUserForEdit, removeCentralUser } from '#app/centraluser'
import { selectAmbientsForPicker } from '#app/ambient'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import ButtonForm from '../components/Form/ButtonForm'
import { Separator, Item, TextList, Right, PickerCell, SelectCell } from '#app/components/List'
import { Text, TextInput, View, ScrollView, Switch, ActivityIndicator } from 'react-native'
import { useAppTheme } from '#app/theme'
import DateTimePicker from '../components/DateTimePicker'

const EditCentralUser = ({ navigation, route: { params: { id = '' } } }: ScreenProps<'EditCentralUser'>) => {
  const ambients = useAppSelector(selectAmbientsForPicker)
  const { defaultValues, title } = useAppSelector(state => selectCentralUserForEdit(state, { id }))
  const { control, handleSubmit, formState: { isDirty }, watch } = useForm<CentralUserFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<CentralUserFormData>>((data) => {
    dispatch(updateCentralUser({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'centraluser/update' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.pop()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({ title: showError ? message : title })
  }, [navigation, title, showError, message])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : isDirty
          ? <HeaderButtons>
            <HeaderItem
              disabled={!isDirty}
              title={t`Save`}
              iconName="save"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>
          : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  const theme = useAppTheme()

  const onPressRevoke = useCallback(() => {
    dispatch(removeCentralUser({ id }))
  }, [dispatch, id])

  return (
    <ScrollView>
      <View style={theme.form.area}>
        <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Choose the display name of the myio central`}</Text>
        <Controller
          control={control}
          name="central.name"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <TextInput
              ref={ref}
              onChangeText={onChange}
              onBlur={onBlur}
              style={theme.form.input}
              placeholder={t`Central Name`}
              value={value}
            />
          }
        />
      </View>
      <Separator text=""/>

            <Item style={{ marginTop: 16, marginBottom: 16 }}>
              <TextList text={t`Should user access expire?`}/>
              <Right>
                <Controller
                  control={control}
                  name="expiration.enabled"
                  render={({ field: { ref, onChange, onBlur, value } }) =>
                    <Switch
                      ref={ref}
                      onValueChange={onChange}
                      value={value}
                    />
                  }
                />
              </Right>
            </Item>

            {watch('expiration.enabled') && (
              <Controller
                control={control}
                name="expiration.date"
                render={({ field: { ref, onChange, onBlur, value }, fieldState: { error } }) =>
                  <View style={{ marginTop: 16 }}>
                    <DateTimePicker
                      minimumDate={new Date()}
                      onDateChange={onChange}
                      date={value}
                      mode="datetime"
                      textColor={theme.textColor}
                      style={theme.form.inputTouchable}
                    />
                    {error != null && <Text style={{ color: theme.textColor }}>{error.message}</Text>}
                  </View>
                }
              />
            )}

          <Controller
            control={control}
            name="user.role"
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <SelectCell
                ref={ref}
                selectedValue={value}
                onValueChange={onChange}
                onBlur={onBlur}
                items={roles}
                title={t`Access Profile`}
              />
            }
          />

      {watch('user.role') !== 'admin' &&
        <Controller
          control={control}
          name="ambients.allowed"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <PickerCell
              id="ambients"
              title={t`Ambients`}
              items={ambients}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          }
        />
      }
      <Separator text=""/>
      <View style={theme.form.area}>
        <ButtonForm
          title={t`Revoke access`}
          btnStyle={theme.form.btn}
          color={theme.colors.grey}
          onPress={onPressRevoke}
        />
      </View>
    </ScrollView>
  )
}

export default EditCentralUser

const roles = [
  { label: t`Viewer`, value: 'viewer' },
  { label: t`User`, value: 'default' },
  { label: t`Administrator`, value: 'admin' }
]

const schema: Yup.SchemaOf<CentralUserFormData> = Yup.object({
  central: Yup.object({
    name: Yup.string().required()
  }),
  expiration: Yup.object({
    enabled: Yup.boolean()
      .required(),
    date: Yup.mixed().when('enabled', {
      is: true,
      then: Yup.date().min(new Date())
    })
  }).required(),
  user: Yup.object({
    role: Yup.mixed()
      .oneOf(['admin', 'default', 'viewer'])
      .required()
  }),
  ambients: Yup.object({
    allowed: Yup.array().when('user.role', {
      is: (role: CentralUserRole) => ['default', 'viewer'].includes(role),
      then: Yup.array().of(Yup.string()).required()
    })
  })
}).required()
