import { AnyAction, combineReducers } from 'redux'
import { all, call, delay, put, race, retry, select, take, takeLeading } from 'typed-redux-saga'
import { ActionWithPayload, createAction, createAssign, createList, createTable, excludesFalse, MapEntity, mustSelectEntity, PropId } from 'robodux'
import { batchActions } from 'redux-batched-actions'
import { createSelector, createStructuredSelector } from 'reselect'
import { find, filter, map, reduce, mapValues, mergeWith, isEqual, get, merge, set } from 'lodash'
import { t } from 'ttag'

import { RootState } from '#app/store'
import { AddSlaveFormData, NewSlaveFormData, SlaveChannel, SlaveConfigCurrentTransformerFormData, SlaveConfigChannelFormData, SlaveFormData, SlaveConfigTemperatureUpdateThresholdFormData, WSChannelUpdateMessage, Slave, WSStatusUpdateMessage, WSAdminSwapSlaveErrorMessage, WSAdminSwapSlaveSuccessMessage, WSAdminAddSlaveSuccessMessage, WSAdminAddSlaveErrorMessage } from '#app/types'
import { selectLoadersByIds, setLoaderError, setLoaderStart, setLoaderSuccess } from '#app/loader'
import { selectApi } from '#app/api'
import { wsAdmin, wsChannelUpdate, wsConfigChannelSuccess, wsConfigClampSuccess, wsConfigTemperatureSuccess, wsSendMessage, wsStatusUpdate, wsTimeout } from '#app/actions'
import oldActions, { Types as oldTypes } from '#app/redux/slaves'
import { errorProtoString } from '#app/services/api'
import { resetCurrentCentral, selectIsSlaveSwapEnabled } from '#app/central'
import {
  fetchAmbients,
  selectSlaveAmbientIds,
  selectAmbientsHavingSlavesAsList
} from '#app/ambient'
import { formatError, waitSaga, waitSaga2 } from '#app/util'
import { difference, uniq, map as mapFp, reduce as reduceFp } from 'lodash/fp'

const slaveTypes = [
  ['light_switch', t`myio light switch`],
  ['infrared', t`myio remote`],
  ['outlet', t`myio switch`],
  ['three_phase_sensor', t`myio three phase sensor`]
] as const
export type SlaveType = typeof slaveTypes[number][0]
export const slaveTypeList: SlaveType[] = slaveTypes.map(([v]) => v)

const slaveConfigClampTypes = [
  [null, t`No Clamp`],
  [0, '50A'],
  [1, '100A'],
  [2, '400A'],
  [3, '1000A'],
  [4, '2000A'],
  [5, '1600A/50mA'],
  [6, '1000A/50mA']
] as const
export type SlaveConfigClampType = typeof slaveConfigClampTypes[number][0]
export const slaveConfigClampTypeList: SlaveConfigClampType[] = slaveConfigClampTypes.map(([v]) => v)

const slaveConfigChannelTypes = [
  ['NORMAL', t`Normal operation`],
  ['REMOTE_INPUT', t`Remote input`],
  ['OTHER_OUTPUT', t`Operate on the other output`],
  ['AND_OUTPUT', t`AND output`],
  ['ON_POWER', t`Operate on power`],
  ['ON_POWER_OFF', t`Operate on power off`],
  ['REPLICATED', t`Replicate input state on output`],
  ['NOT_REPLICATED', t`Invert input state on output`],
  ['IGNORE_INPUT', t`Ignore input`],
  ['PULSE_ON_CHANGE', t`Count pulses on any power change`],
  ['PULSE_ON_POWER', t`Count pulses only on power`],
  ['PULSE_ON_POWER_OFF', t`Count pulses only on power off`],
  ['XOR_OUTPUT', t`XOR output`]
] as const
export type SlaveConfigChannelType = typeof slaveConfigChannelTypes[number][0]
export const slaveConfigChannelTypeList: SlaveConfigChannelType[] = slaveConfigChannelTypes.map(([v]) => v)

const slaveConfigChannelPulses = [
  [0, '0'],
  [1, '1'],
  [5, '5'],
  [10, '10'],
  [25, '25'],
  [50, '50'],
  [100, '100'],
  [200, '200']
] as const
export type SlaveConfigChannelPulses = typeof slaveConfigChannelPulses[number][0]
export const slaveConfigChannelPulsesList: SlaveConfigChannelPulses[] = slaveConfigChannelPulses.map(([v]) => v)

const slaveConfigChannelOutputs = [
  ['HOLDING', t`Holding`],
  ['PASSTHROUGH', t`Passthrough`]
] as const
export type SlaveConfigChannelOutput = typeof slaveConfigChannelOutputs[number][0]
export const slaveConfigChannelOutputList: SlaveConfigChannelOutput[] = slaveConfigChannelOutputs.map(([v]) => v)

const slaveStatuses = [
  ['online', t`Online`],
  ['offline', t`Offline`],
  ['bad', t`Weak signal`]
] as const
export type SlaveStatus = typeof slaveStatuses[number][0]
export const slaveStatusList: SlaveStatus[] = slaveStatuses.map(([v]) => v)

export const defaultSlave = (p: Partial<Slave> = {}): Slave =>
  merge({
    id: 0,
    addr_high: 0,
    addr_low: 0,
    code: '',
    name: '',
    type: 'outlet',
    status: 'offline',
    aggregate: false,
    config: {}
  }, p)

export const formatTemperatureTolerance = (internalUnit: number): string => `${internalUnit / 10} °C`
export const formatSlaveStatus = (status: SlaveStatus) =>
  (slaveStatuses.find(([s]) => s === status) ?? ['', status])[1]
export const formatSlaveClampType = (type: SlaveConfigClampType) =>
  (slaveConfigClampTypes.find(([ct]) => ct === type) ?? ['', String(type)])[1]
export const formatSlaveType = (type: SlaveType | string) =>
  (slaveTypes.find(([st]) => st === type) ?? ['', t`Others`])[1]

type SlaveChannelState = MapEntity<SlaveChannel>
const slaveChannels = createTable<SlaveChannelState>({
  name: 'slaveChannels',
  extraReducers: {
    [wsChannelUpdate.toString()]: (state: MapEntity<SlaveChannelState>, { id, channels }: WSChannelUpdateMessage) => {
      const srcRecord = { ...state?.[id] }
      const newRecord = Object.fromEntries(channels.map(c => [c.id, c]))
      // TODO: invert new and src, skip copying src
      // const updatedRecord = mergeWith(srcRecord, newRecord, (srcC: SlaveChannel, newC: SlaveChannel) => isEqual(srcC, newC)
      const updatedRecord = mergeWith(srcRecord, newRecord, (srcC: SlaveChannel, newC: SlaveChannel) => isEqual(srcC, newC)
        ? srcC
        : undefined)
      return { ...state, [id]: updatedRecord }
    },
    [resetCurrentCentral.toString()]: () => ({})
  }
})

interface SlaveStatusState {
  status: SlaveStatus
  averageRetries?: number
}
const defaultSlaveStatus = (p?: Partial<SlaveStatusState>): SlaveStatusState =>
  merge({ status: 'offline' }, p)
const createSlaveStatusSelector = mustSelectEntity(defaultSlaveStatus)
const slaveStatus = createTable<SlaveStatusState>({
  name: 'slaveStatus',
  extraReducers: {
    [wsStatusUpdate.toString()]: (state: MapEntity<SlaveStatusState>, { id, status, average_retries: averageRetries }: WSStatusUpdateMessage) => {
      const key = String(id)
      return {
        ...state,
        [key]: {
          ...state[key],
          ...(state[key]?.averageRetries !== averageRetries && { averageRetries }),
          ...(state[key]?.status !== status && { status })
        }
      }
    },
    [resetCurrentCentral.toString()]: () => ({})
  }
})

const newSlaveId = createAssign<string>({ name: 'newSlave', initialState: '' })
export interface ReplacementSlaveStep {
  id: string
  title: string
  action: AnyAction
}
const replacementSlaveSteps = createList<ReplacementSlaveStep[]>({ name: 'replacementSlaveSteps' })

export const reducer = combineReducers({
  slaveChannels: slaveChannels.reducer,
  slaveStatus: slaveStatus.reducer,
  newSlaveId: newSlaveId.reducer,
  replacementSlaveSteps: replacementSlaveSteps.reducer
})

const { set: setNewSlaveId } = newSlaveId.actions

const slaveStatusSelectors = slaveStatus.getSelectors<RootState>(state => state.slave.slaveStatus)
const selectSlaveStatusById = createSlaveStatusSelector(slaveStatusSelectors.selectById)
const {
  // selectTable: selectAllSlaveChannels,
  selectById: selectSlaveChannelsById
} = slaveChannels.getSelectors<RootState>(state => state.slave.slaveChannels)
export const selectSlaveChannelsByIdAsList = createSelector(
  selectSlaveChannelsById,
  (data = {}): SlaveChannel[] => Object.values(data).filter(excludesFalse)
)
export const getAllSlaves = (state: RootState) => filter(state.slaves.allSlaves, s => s.is_slave)
export const getSlave = createSelector(
  getAllSlaves,
  (state: RootState, id?: number) => id,
  (slaves, id) => defaultSlave(find(slaves, s => s.id === id))
)
export const getAllSlaveIds = createSelector(
  [getAllSlaves],
  slaves => map(slaves, s => s.id)
)

export const getByTypeSlaveIds = createSelector(
  [getAllSlaves],
  (slaves) => reduce<any, SlaveIdsByType>(slaves, (acc, s) => ({
    ...acc,
    [s.type]: [
      ...acc[s.type as SlaveType] ?? [],
      s.id
    ]
  }), {})
)

export const selectSlavesByStatusIds = createSelector(
  getAllSlaves,
  (slaves) => slaves.reduce<SlaveIdsByStatus>((acc, s) => ({
    ...acc,
    [s.status]: [
      ...acc[s.status as SlaveStatus] ?? [],
      s.id
    ]
  }), { online: [], offline: [], bad: [] })
)

export const selectNewSlaveId = (state: RootState) => state.slave.newSlaveId
export const selectSlavesByTypeForSectionList = createSelector(
  getByTypeSlaveIds,
  slaves => map(slaves, (v, k) => ({
    key: k,
    title: formatSlaveType(k),
    data: map(v, s => ({ id: s, key: String(s) }))
  }))
)
const selectSlavesWithinAmbientsIds = createSelector(
  selectAmbientsHavingSlavesAsList,
  as => uniq(as.flatMap(a => a.slaves))
)
const selectSlavesWithoutAmbientsIds = createSelector(
  getAllSlaveIds,
  selectSlavesWithinAmbientsIds,
  (allSlaves, withinAmbients) => difference(allSlaves, withinAmbients)
)
const selectOrphanedSlavesSection = createSelector(
  selectSlavesWithoutAmbientsIds,
  (slaves) => slaves.length > 0
    ? [{
        title: t`No Ambient`,
        id: 'orphanedSlaves',
        data: slaves.map(s => ({ id: s, key: String(s) }))
      }]
    : []
)
const selectSlavesByAmbientSections = createSelector(
  selectAmbientsHavingSlavesAsList,
  as => as.map(({ id, name: title, slaves }) => ({
    id,
    title,
    data: slaves.map(s => ({ id: s, key: String(s) }))
  }))
)
export const selectSlavesByAmbientForSectionList = createSelector(
  selectOrphanedSlavesSection,
  selectSlavesByAmbientSections,
  (orphaned, byAmbient) => [
    ...orphaned,
    ...byAmbient
  ]
)
export const selectSlaveSummary = createSelector(
  (state: RootState) => getAllSlaveIds(state).length,
  (state: RootState) => mapValues(selectSlavesByStatusIds(state), v => v.length),
  (total, byStatus) => ({ total, ...byStatus })
)
export const selectSlaveById = createSelector(
  (s: RootState) => s,
  (s: RootState, { id }: PropId) => Number(id),
  getSlave
)
export const selectSlaveAddress = createSelector(
  selectSlaveById,
  s => `${s.addr_high} ${s.addr_low}`
)
export const selectSlaveType = createSelector(
  selectSlaveById,
  s => s.type
)
export const selectSlaveChannelCount = createSelector(
  selectSlaveType,
  (type) => {
    switch (type) {
      case 'three_phase_sensor':
        return 3
      case 'outlet':
      case 'light_switch':
        return 2
      default:
        return 0
    }
  }
)
export const selectSlaveCode = createSelector(
  selectSlaveById,
  s => s.code
)
export const selectSlaveStatusAverageRetries = createSelector(
  selectSlaveStatusById,
  s => s.averageRetries
)
const selectSlaveStatus = createSelector(
  selectSlaveStatusById,
  s => s.status
)
export const selectSlaveStatusFormatted = createSelector(
  selectSlaveStatus,
  formatSlaveStatus
)
export const selectSlaveClampType = createSelector(
  selectSlaveById,
  (s): SlaveConfigClampType => s?.config?.config_clamp?.value ?? null
)
export const selectSlaveClampTypeFormatted = createSelector(
  selectSlaveClampType,
  formatSlaveClampType
)
export const selectSlaveTemperatureTolerance = createSelector(
  selectSlaveById,
  (s): number => s?.config?.config_temperature?.value ?? 0
)
export const selectSlaveTemperatureToleranceFormatted = createSelector(
  selectSlaveTemperatureTolerance,
  formatTemperatureTolerance
)
export const selectSlaveName = createSelector(
  selectSlaveById,
  s => s.name
)
export const selectSlaveAggregate = createSelector(
  selectSlaveById,
  s => s.aggregate
)
export const selectSlaveConfigClampTypesForPicker = createSelector(
  () => slaveConfigClampTypes,
  (types) => types.map(([value, label]) => ({ label, value: value === null ? '' : value }))
)
export const selectSlaveClampTypeForEdit = createSelector(
  selectSlaveClampType,
  selectSlaveConfigClampTypesForPicker,
  (clampType, clampTypes) =>
    ({
      defaultValues: { clampType },
      clampTypes
    })
)
export const selectSlaveTemperatureToleranceForEdit = createSelector(
  selectSlaveTemperatureTolerance,
  (value) =>
    ({ defaultValues: { value } })
)
export const selectSlaveConfigChannelType = createSelector(
  selectSlaveById,
  (state: RootState, { channel }: PropId & {channel: number}) => channel,
  (s, channel): SlaveConfigChannelType =>
    get(s, `config.channelConfig.channel${channel}.channel_type`, 'NORMAL')
)
export const selectSlaveConfigChannelPulses = createSelector(
  selectSlaveById,
  (state: RootState, { channel }: PropId & {channel: number}) => channel,
  (s, channel): SlaveConfigChannelPulses =>
    get(s, `config.channelConfig.channel${channel}.pulses`, 1)
)
export const selectSlaveConfigChannelOutput = createSelector(
  selectSlaveById,
  (state: RootState, { channel }: PropId & {channel: number}) => channel,
  (s, channel): SlaveConfigChannelOutput =>
    get(s, `config.channelConfig.channel${channel}.output`, 'HOLDING')
)
export const selectSlaveConfigChannelTypesForPicker = createSelector(
  (s: any) => slaveConfigChannelTypes,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectSlaveConfigChannelPulsesForPicker = createSelector(
  (s: any) => slaveConfigChannelPulses,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectSlaveConfigChannelOutputsForPicker = createSelector(
  (s: any) => slaveConfigChannelOutputs,
  (items) => items.map(([value, label]) => ({ label, value }))
)
export const selectSlaveConfigChannelForEdit = createSelector(
  selectSlaveConfigChannelType,
  selectSlaveConfigChannelPulses,
  selectSlaveConfigChannelOutput,
  (type, pulses, output) => ({ type, pulses, output })
)
export const selectSlaveChannelsForPicker = createSelector(
  selectSlaveChannelCount,
  (length) => Array.from(
    { length },
    (_, value) => {
      const n = value + 1
      return { label: t`Channel ${n}`, value }
    }
  )
)
export const selectSlaveForEdit = createSelector(
  selectSlaveName,
  selectSlaveAggregate,
  selectSlaveType,
  (name, aggregate, type) => ({ name, aggregate, type })
)
export const selectSlavesForReplacement = createSelector(
  getAllSlaves,
  selectSlaveById,
  (slaves, { id, type }) =>
    slaves.filter(s => s.type === type && s.id !== id)
)
export const selectSlavesForReplacementList = createSelector(
  selectSlavesForReplacement,
  slaves => slaves.map(s => ({ id: String(s.id), title: s.name })),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual
    }
  }
)
export const selectReplacementSlaveSteps = (state: RootState) => state.slave.replacementSlaveSteps

const selectReplacementSlaveStepsError1 = createSelector(
  selectReplacementSlaveSteps,
  mapFp('id')
)
const selectReplacementSlaveStepsError2 = createStructuredSelector({
  ids: selectReplacementSlaveStepsError1
})
const selectReplacementSlaveStepsError3 = createSelector(
  (state: RootState) => state,
  selectReplacementSlaveStepsError2,
  selectLoadersByIds
)
const selectReplacementSlaveStepsError4 = createSelector(
  selectReplacementSlaveStepsError3,
  mapFp('isError')
)
const selectReplacementSlaveStepsError = createSelector(
  selectReplacementSlaveStepsError4,
  reduceFp((prev, curr) => prev || curr, false)
)

export const addSlave = createAction<{ data: AddSlaveFormData }>('ADD_SLAVE')
export const onboardNewSlave = createAction<{ data: NewSlaveFormData }>('ONBOARD_NEW_SLAVE')
export const swapSlave = createAction<{ id: string, data: AddSlaveFormData }>('SWAP_SLAVE')
export const configReplacementSlave = createAction<PropId>('CONFIG_REPLACEMENT_SLAVE')
export const unpairSlave = createAction<PropId>('UNPAIR_SLAVE')
export const updateSlave = createAction<{id: string, data: SlaveFormData}>('UPDATE_SLAVE')
export const updateSlaveCurrentTransformer = createAction<{id: string, data: SlaveConfigCurrentTransformerFormData}>('UPDATE_SLAVE_CURRENT_TRANSFORMER')
export const updateSlaveTemperatureUpdateThreshold = createAction<{id: string, data: SlaveConfigTemperatureUpdateThresholdFormData}>('UPDATE_SLAVE_TEMPERATURE_UPDATE_THRESHOLD')
export const updateSlaveChannel = createAction<{id: string, channel: number, data: SlaveConfigChannelFormData}>('UPDATE_SLAVE_CHANNEL')

export const resetConfigReplacementSlave = () =>
  batchActions([
    setLoaderStart({ id: 'slave/replacementConfig', message: t`Setting up replacement product...` }),
    replacementSlaveSteps.actions.reset()
  ])

export function * onAddSlave (action: ReturnType<typeof addSlave>) {
  const swapEnabled = yield * select(selectIsSlaveSwapEnabled)
  const callSaga = swapEnabled ? onAddSlave2 : onAddSlave1
  return yield * call(callSaga, action)
}

const addSlaveSuccessPattern = (code: number[]) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'add_slave' && a.payload.status === 'success' && isEqual(a.payload.factory_address, code)

const addSlaveErrorPattern = (code: number[]) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'add_slave' && a.payload.status === 'error' && isEqual(a.payload.factory_address, code)

export function * onAddSlave2 ({ payload: { data } }: ReturnType<typeof addSlave>) {
  yield * put(setLoaderStart({ id: 'slave/add' }))

  const code = data.code.map(v => v.value)
  const successPattern = addSlaveSuccessPattern(code)
  const errorPattern = addSlaveErrorPattern(code)

  yield * put(wsSendMessage({
    type: 'admin',
    command: 'add_slave',
    factory_address: code
  }))

  const { error, success } = yield * waitSaga2<ActionWithPayload<WSAdminAddSlaveSuccessMessage>, ActionWithPayload<WSAdminAddSlaveErrorMessage>>(successPattern, errorPattern)

  if (error != null) {
    const { message } = error.payload
    yield * put(setLoaderError({ id: 'slave/add', message }))
    return
  }
  if (success == null) {
    yield * put(setLoaderError({ id: 'slave/add', message: t`Timed out while waiting for a response from the central` }))
    return
  }
  const id = String(success.payload.id)
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(batchActions([
      setNewSlaveId(id),
      setLoaderSuccess({ id: 'slave/add' })
    ]))
  }
}

export function * onAddSlave1 ({ payload: { data } }: ReturnType<typeof addSlave>) {
  yield * put(setLoaderStart({ id: 'slave/add' }))
  const code = data.code.map(v => v.value)

  yield * put(wsSendMessage({
    type: 'admin',
    command: 'add_slave',
    factory_address: code
  }))

  {
    const { res } = yield * race({
      res: take(wsAdmin),
      timeout: delay(20000)
    })
    if (res == null) {
      yield * put(setLoaderError({ id: 'slave/add', message: t`Timed out while waiting for a response from the central` }))
      return
    }
    const { payload } = res
    switch (payload.command) {
      case 'push_button':
        yield * put(batchActions([
          wsSendMessage({
            type: 'admin',
            command: 'buttonless_register',
            idh: payload.address[0],
            idl: payload.address[1],
            slave_type: getButtonlessRegisterFromCode(code)
          })
        ]))
        break
      case 'error':
        yield * put(setLoaderError({ id: 'slave/add', message: t`No product in pairing mode matching the given code could be found` }))
        return
      default:
        yield * put(setLoaderError({ id: 'slave/add', message: t`Unexpected response received. Your app may be out of date` }))
        return
    }
  }

  const { res } = yield * race({
    res: take<ReturnType<typeof wsAdmin>>((a: AnyAction) => a.type === wsAdmin.toString() && a.payload.command !== 'push_button'),
    timeout: delay(20000)
  })
  if (res == null) {
    yield * put(setLoaderError({ id: 'slave/add', message: t`Timed out while waiting for a response from the central` }))
    return
  }
  const { payload } = res
  if (payload.command !== 'success') {
    yield * put(setLoaderError({ id: 'slave/add', message: t`Pairing failed` }))
    return
  }
  const {
    address,
    channels,
    slave_type: type
  } = payload

  const api = yield * select(selectApi)
  const res2 = yield * call(api.addSlave, {
    version: '6.0.0',
    addr_low: address[1],
    addr_high: address[0],
    code: code.map(c => String(c).padStart(3, '0')).join('-'),
    channels,
    type
  })
  if (!res2.ok || res2.data == null) {
    yield * put(setLoaderError({ id: 'slave/add', message: 'API ERROR' }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(batchActions([
      setNewSlaveId(String(res2.data.id)),
      setLoaderSuccess({ id: 'slave/add' })
    ]))
  }
}

export function * onOnboardNewSlave ({ payload: { data: slave } }: ReturnType<typeof onboardNewSlave>) {
  yield * put(setLoaderStart({ id: 'slave/onboard' }))
  const id = yield * select(selectNewSlaveId)
  try {
    yield * call(apiPatchSlave, String(id), { slave })
    yield * put(setLoaderSuccess({ id: 'slave/onboard' }))
  } catch (err: any) {
    yield * put(setLoaderError({ id: 'slave/onboard', message: formatError(err) }))
  }
}

const swapSlaveSuccessPattern = (id: string) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'swap_slave' && a.payload.status === 'success' && a.payload.id === Number(id)

const swapSlaveErrorPattern = (id: string) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'swap_slave' && a.payload.status === 'error' && a.payload.id === Number(id)

export function * onSwapSlave ({ payload: { id, data } }: ReturnType<typeof swapSlave>) {
  const code = data.code.map(v => v.value)
  const successPattern = swapSlaveSuccessPattern(id)
  const errorPattern = swapSlaveErrorPattern(id)

  yield * put(batchActions([
    setLoaderStart({ id: 'slave/swap' }),
    wsSendMessage({
      type: 'admin',
      command: 'swap_slave',
      factory_address: code,
      id: Number(id)
    })
  ]))

  const { error, timeout } = yield * waitSaga2<ActionWithPayload<WSAdminSwapSlaveSuccessMessage>, ActionWithPayload<WSAdminSwapSlaveErrorMessage>>(successPattern, errorPattern)
  if (error != null) {
    const { message } = error.payload
    yield * put(setLoaderError({ id: 'slave/swap', message }))
    return
  }
  if (timeout != null) {
    yield * put(setLoaderError({ id: 'slave/swap', message: t`Timed out while waiting for a response from the central` }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(batchActions([
      resetConfigReplacementSlave(),
      setLoaderSuccess({ id: 'slave/swap' })
    ]))
  }
}

export function * onConfigReplacementSlaveCT (id: string) {
  const clampType = yield * select(state => selectSlaveClampType(state, { id }))
  const action = updateSlaveCurrentTransformer({ id, data: { clampType } })
  yield * put(batchActions([
    replacementSlaveSteps.actions.add([{
      id: 'slave/updateCurrentTransformer',
      title: t`Current Transformer`,
      action
    }]),
    action
  ]))
  try {
    yield * call(
      waitSaga,
      (a: AnyAction) => a.type === setLoaderSuccess.toString() && a.payload.id === 'slave/updateCurrentTransformer',
      (a: AnyAction) => a.type === setLoaderError.toString() && a.payload.id === 'slave/updateCurrentTransformer'
    )
  } catch (error) {}
}

export function * onConfigReplacementSlave ({ payload: { id } }: ReturnType<typeof configReplacementSlave>) {
  const slaveType = yield * select(state => selectSlaveType(state, { id }))
  switch (slaveType) {
    case 'outlet':
      yield * call(onConfigReplacementSlaveCT, id)
      {
        const value = yield * select(state => selectSlaveTemperatureTolerance(state, { id }))
        const action = updateSlaveTemperatureUpdateThreshold({ id, data: { value } })
        yield * put(batchActions([
          replacementSlaveSteps.actions.add([{
            id: 'slave/updateTemperatureUpdateThreshold',
            title: t`Temperature Update Threshold`,
            action
          }]),
          action
        ]))
        try {
          yield * call(
            waitSaga,
            (a: AnyAction) => a.type === setLoaderSuccess.toString() && a.payload.id === 'slave/updateTemperatureUpdateThreshold',
            (a: AnyAction) => a.type === setLoaderError.toString() && a.payload.id === 'slave/updateTemperatureUpdateThreshold'
          )
        } catch (error) {}
      }
      {
        const channels = yield * select(state => selectSlaveChannelCount(state, { id }))
        for (let channel = 0; channel < channels; channel++) {
          const data = yield * select(state => selectSlaveConfigChannelForEdit(state, { id, channel }))
          const n = channel + 1
          const action = updateSlaveChannel({ id, channel, data })
          yield * put(batchActions([
            replacementSlaveSteps.actions.add([{
              id: `slave/updateChannel/${channel}`,
              title: t`Channel ${n}`,
              action
            }]),
            action
          ]))
          try {
            yield * call(
              waitSaga,
              (a: AnyAction) => a.type === setLoaderSuccess.toString() && a.payload.id === `slave/updateChannel/${channel}`,
              (a: AnyAction) => a.type === setLoaderError.toString() && a.payload.id === `slave/updateChannel/${channel}`
            )
          } catch (error) {}
        }
      }
      break
    case 'three_phase_sensor':
      yield * call(onConfigReplacementSlaveCT, id)
      break
    case 'infrared':
    default:
      break
  }
  const isError = yield * select(selectReplacementSlaveStepsError)
  if (isError) {
    yield * put(setLoaderError({ id: 'slave/replacementConfig', message: t`There was one or more issues while setting up the replacement product` }))
    return
  }
  yield * put(setLoaderSuccess({ id: 'slave/replacementConfig', message: t`Replacement product setup finished successfully` }))
}

export function * onUnpairSlave (action: ReturnType<typeof unpairSlave>) {
  const swapEnabled = yield * select(selectIsSlaveSwapEnabled)
  const callSaga = swapEnabled ? onUnpairSlave2 : onUnpairSlave1
  return yield * call(callSaga, action)
}

const removeSlaveSuccessPattern = (id: string) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'remove_slave' && a.payload.status === 'success' && a.payload.id === Number(id)

const removeSlaveErrorPattern = (id: string) =>
  (a: AnyAction) =>
    a.type === wsAdmin.toString() && a.payload.command === 'remove_slave' && a.payload.status === 'error' && a.payload.id === Number(id)

export function * onUnpairSlave2 ({ payload: { id } }: ReturnType<typeof unpairSlave>) {
  const successPattern = removeSlaveSuccessPattern(id)
  const errorPattern = removeSlaveErrorPattern(id)
  yield * put(batchActions([
    setLoaderStart({ id: 'slave/unpair' }),
    wsSendMessage({
      type: 'admin',
      command: 'remove_slave',
      id: Number(id)
    })
  ]))
  const { error, timeout } = yield * waitSaga2<ActionWithPayload<WSAdminSwapSlaveSuccessMessage>, ActionWithPayload<WSAdminSwapSlaveErrorMessage>>(successPattern, errorPattern)
  if (error != null) {
    const { message } = error.payload
    yield * put(setLoaderError({ id: 'slave/unpair', message }))
    return
  }
  if (timeout != null) {
    yield * put(setLoaderError({ id: 'slave/unpair', message: t`Timed out while waiting for a response from the central` }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'slave/unpair' }))
  }
}

export function * onUnpairSlave1 ({ payload: { id } }: ReturnType<typeof unpairSlave>) {
  yield * put(setLoaderStart({ id: 'slave/unpair' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.deleteSlave, id)
  if (!res.ok && res.status !== 404) {
    const message = errorProtoString(res)
    yield * put(setLoaderError({ id: 'slave/unpair', message }))
    return
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } finally {
    yield * put(setLoaderSuccess({ id: 'slave/unpair' }))
  }
}

export function * onUpdateSlave ({ payload: { id, data } }: ReturnType<typeof updateSlave>) {
  try {
    yield * put(setLoaderStart({ id: 'slave/update' }))
    yield * call(apiPatchSlave, id, data)
    yield * put(setLoaderSuccess({ id: 'slave/update' }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: 'slave/update', message: formatError(err) }))
  }
}

export function * onUpdateSlaveCurrentTransformer ({ payload: { id, data: { clampType } } }: ReturnType<typeof updateSlaveCurrentTransformer>) {
  try {
    yield * put(setLoaderStart({ id: 'slave/updateCurrentTransformer' }))
    yield * put(wsSendMessage({
      type: 'slave',
      id: Number(id),
      command: 'config_clamp',
      clamp_type: clampType
    }))
    yield * call(
      waitSaga,
      (a: AnyAction) => a.type === wsConfigClampSuccess.toString() && a.payload.id === Number(id),
      (a: AnyAction) => a.type === wsTimeout.toString() && a.payload.id === Number(id),
      5000
    )
    const data = {
      slave: {
        config: {
          config_clamp: {
            confirmed: true,
            value: clampType
          }
        }
      }
    }
    yield * call(apiPatchSlave, id, data)
    yield * put(setLoaderSuccess({ id: 'slave/updateCurrentTransformer' }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: 'slave/updateCurrentTransformer', message: formatError(err) }))
  }
}

export function * onUpdateSlaveTemperatureUpdateThreshold ({ payload: { id, data: { value } } }: ReturnType<typeof updateSlaveTemperatureUpdateThreshold>) {
  try {
    yield * put(setLoaderStart({ id: 'slave/updateTemperatureUpdateThreshold' }))
    yield * put(wsSendMessage({
      type: 'slave',
      id: Number(id),
      command: 'config_temperature',
      value
    }))
    yield * call(
      waitSaga,
      (a: AnyAction) => a.type === wsConfigTemperatureSuccess.toString() && a.payload.id === Number(id),
      (a: AnyAction) => a.type === wsTimeout.toString() && a.payload.id === Number(id),
      5000
    )
    const data = {
      slave: {
        config: {
          config_temperature: {
            confirmed: true,
            value
          }
        }
      }
    }
    yield * call(apiPatchSlave, id, data)
    yield * put(setLoaderSuccess({ id: 'slave/updateTemperatureUpdateThreshold' }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: 'slave/updateTemperatureUpdateThreshold', message: formatError(err) }))
  }
}

export function * onUpdateSlaveChannel ({ payload: { id, channel, data: { type, pulses, output } } }: ReturnType<typeof updateSlaveChannel>) {
  try {
    yield * put(setLoaderStart({ id: `slave/updateChannel/${channel}` }))
    yield * put(wsSendMessage({
      type: 'slave',
      command: 'config_channel',
      id: Number(id),
      channel,
      channel_type: type,
      pulses,
      output
    }))
    /**
     * WORKAROUND: MC-101
     * Assume the lack of error as success
     * but finish early if a success message is received
     */
    const { error } = yield * race({
      success: race([
        take((a: AnyAction) => a.type === wsConfigChannelSuccess.toString() && a.payload.id === Number(id)),
        delay(1000)
      ]),
      error: take((a: AnyAction) => a.type === wsTimeout.toString() && a.payload.id === Number(id))
    })
    if (error != null) throw new Error('Error')
    const slave = set({}, `config.channelConfig.channel${channel}`, {
      slaveId: Number(id),
      channel,
      channel_type: type,
      pulses,
      output
    })
    yield * call(apiPatchSlave, id, { slave })
    yield * put(setLoaderSuccess({ id: `slave/updateChannel/${channel}` }))
  } catch (err: any) {
    console.error(err)
    yield * put(setLoaderError({ id: `slave/updateChannel/${channel}`, message: formatError(err) }))
  }
}

export function * apiPatchSlave (id: string, data: SlaveFormData) {
  const api = yield * select(selectApi)
  const currentSlave = yield * select(state => selectSlaveById(state, { id }))
  const config = merge({}, currentSlave?.config, data.slave.config)
  const slaveAmbients = yield * select(state => selectSlaveAmbientIds(state, { id }))
  const ambients = data.ambients ?? slaveAmbients
  const slave = { ...data.slave, ...(data.slave.config != null && { config }) }
  const res = yield * call(api.patchSlave, Number(id), slave, ambients.map(a => Number(a)))
  if (!res.ok || res.data == null) {
    throw new Error(errorProtoString(res))
  }
  try {
    yield * retry(3, 50, oldFetchSlaves)
  } catch (e) {
    console.error('patchSlave', e)
  }
  yield * put(fetchAmbients())
}

export function * oldFetchSlaves () {
  yield * all([
    put(oldActions.dataGetSlavesRequest()),
    call(waitSaga, oldTypes.DATA_GET_SLAVES_REQUEST_SUCCESS, oldTypes.DATA_GET_SLAVES_REQUEST_FAILURE)
  ])
}

export function * saga () {
  yield all([
    takeLeading(addSlave, onAddSlave),
    takeLeading(onboardNewSlave, onOnboardNewSlave),
    takeLeading(swapSlave, onSwapSlave),
    takeLeading(configReplacementSlave, onConfigReplacementSlave),
    takeLeading(unpairSlave, onUnpairSlave),
    takeLeading(updateSlave, onUpdateSlave),
    takeLeading(updateSlaveCurrentTransformer, onUpdateSlaveCurrentTransformer),
    takeLeading(updateSlaveTemperatureUpdateThreshold, onUpdateSlaveTemperatureUpdateThreshold),
    takeLeading(updateSlaveChannel, onUpdateSlaveChannel)
  ])
}

const getButtonlessRegisterFromCode = (code: number[]): string => {
  switch (code[3]) {
    case 15:
      return 'three_phase_sensor'
    case 14:
      return 'ir'
    case 11:
    case 12:
    case 13:
    case 17:
    default:
      return 'retrofit'
  }
}

export type SlaveIdsByType = Record<SlaveType, number[]> | Record<string, never>

export type SlaveIdsByStatus = Record<SlaveStatus, number[]>
