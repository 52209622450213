import React, { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { ScrollView, View, TextInput, Text, TouchableOpacity, Switch } from 'react-native'
import { colors } from '#app/theme/colors'
import { ListItem, Item as ItemList, Separator, Left, ItemPress, TextList, Right } from '../components/List'
import ButtonForm from '../components/Form/ButtonForm'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { get } from 'lodash'
import { t, gettext } from 'ttag'
import { selectCan } from '#app/centraluser'
import { useAppTheme } from '#app/theme'
import { selectAmbientsAsList } from '#app/ambient'
import { useNavigation } from '@react-navigation/native'
import { createSelector } from 'reselect'
import { State } from '#app/types'
import ReduxUtils from '#app/redux/utils'

const selectSceneCommands = createSelector(
  (state: State) => state.scene.selectedScene.json,
  (state: State) => state.lookup,
  (json, lookup) =>
    json.map(item => ReduxUtils.formatSceneItem(item, lookup))
)

export default function SceneDetails () {
  const AMBIENT_NULL = { label: gettext('No Ambient'), value: '' }

  const [mappedAmbients, setMappedAmbients] = useState([AMBIENT_NULL])
  const [selectedAmbient, setSelectedAmbient] = useState(AMBIENT_NULL)
  const [favoritedScenes, setFavoritedScenes] = useState([])

  const scene = useAppSelector(state => state.scene.selectedScene)
  const theme = useAppTheme()
  const commands = useAppSelector(selectSceneCommands)
  const ambients = useAppSelector(selectAmbientsAsList)
  const favorites = useAppSelector(state => state.favorites.favorites)
  const can = useAppSelector(selectCan)
  const canEdit = can('edit')

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number[]' is not assignable to p... Remove this comment to see the full error message
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    setFavoritedScenes(get(favorites, 'scenes') || null)
  }, [favorites])

  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  useEffect(() => { navigation.setOptions({ title: scene.name }) })

  useEffect(() => {
    const amb = ambients.find(a => a.id === scene.ambientId)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (amb) {
      setSelectedAmbient({ label: amb.name, value: amb.id })
    } else {
      setSelectedAmbient(AMBIENT_NULL)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [scene])

  useEffect(() => {
    setMappedAmbients(ambients.map((item) => {
      return { label: item.name, value: item.id }
    }).concat(AMBIENT_NULL))
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [ambients])

  const submit = useCallback(() => {
    if (scene.id == null) {
      dispatch({ type: 'CREATE_SCENE_REQUEST' })
      return
    }
    dispatch({ type: 'UPDATE_SCENE_REQUEST' })
  }, [dispatch, scene.id])

  useEffect(() => {
    dispatch({ type: 'LOAD_COMMANDS' })
    if (canEdit) {
      navigation.setOptions({
        headerRight: () => (
          <HeaderButtons>
            <Item title={t`Save`} onPress={submit} />
          </HeaderButtons>
        )
      })
    }
  }, [dispatch, navigation, theme, canEdit, submit])

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeScene = useCallback(() => {
    dispatch({ type: 'DELETE_SCENE_REQUEST', sceneId: scene.id })
  })

  const updateNameSelectedScene = (name: any) => {
    dispatch({ type: 'DATA_UPDATE_NAME_SELECTED_SCENE', name })
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const removeCommand = useCallback((command) => {
    dispatch({ type: 'REMOVE_COMMAND', command })
  })

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSwitchChangeValue = useCallback((value) => {
    const config = { confirm: value }
    dispatch({ type: 'UPDATE_CONFIG_SCENE', config })
  })

  const switchFavorite = () => {
    let fav = []
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (favoritedScenes.includes(scene.id)) {
      fav = favoritedScenes.filter((s) => (s !== scene.id))
      setFavoritedScenes(fav)
    } else {
      fav = [...favoritedScenes, scene.id]
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setFavoritedScenes(fav)
    }

    dispatch({ type: 'UPDATE_FAVORITE_DEVICE_LIST', payload: { type: 'scenes', ids: fav } })
  }

  return (
      <ScrollView>
        <Separator text={t`Informations`} />
        <View style={[theme.form.area]}>
          <View style={[theme.form.formGroup, { marginBottom: 15 }]}>
            <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Name`}</Text>
            <TextInput
              style={[theme.form.input]}
              placeholder={t`Scene name...`}
              placeholderTextColor={theme.formForm.placeholderTextColor}
              autoCapitalize="none"
              autoCorrect={false}
              editable={canEdit}
              onChangeText={updateNameSelectedScene}
              value={scene.name}
            />
          </View>

          <View style={[theme.form.formGroup, { marginBottom: 0 }]}>
            <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Ambient`}</Text>
            <TouchableOpacity
              disabled={!canEdit}
              onPress={() => {
                navigation.navigate('ModalPicker', {
                  name: gettext('Select an ambient...'),
                  data: mappedAmbients,
                  value: selectedAmbient,
                  search: true,
                  multiple: false,
                  required: true,
                  onPressOk: (data: any) => {
                    dispatch({ type: 'DATA_UPDATE_AMBIENT_SELECTED_SCENE', ambientId: data.selectedItem.value })
                    setSelectedAmbient(data.selectedItem)
                  }
                })
              }}
              style={theme.form.inputTouchable}
            >
              <Text style={{ fontSize: 15, color: theme.textColor }}>{selectedAmbient.label}</Text>
              <Right>
                <MaterialCommunityIcons name="chevron-down" size={20} color={theme.colors.grey} />
              </Right>
            </TouchableOpacity>
          </View>
        </View>

        <Separator text={t`Settings`} />
        <ListItem>
          <ItemList>
            <TextList text={t`Confirm action?`} />
            <Right>
              <Switch disabled={!canEdit} onValueChange={onSwitchChangeValue} value={get(scene, 'config.confirm')} />
            </Right>
          </ItemList>

          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {favorites && (
            <ItemList>
              <TextList text={t`Favorite scene?`} />
              <Right>
                {/* TODO: Fix this the next time the file is edited. */}
                {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null' is not assignable... Remove this comment to see the full error message */}
                <Switch onValueChange={switchFavorite} value={!!favorites.scenes.includes(scene.id)} />
              </Right>
            </ItemList>
          )}
        </ListItem>

        <Separator text={t`Actions`} />
        <View>
          {
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            scene && commands.map((command: any, key: any) =>
              <ItemPress key={key}>
                <Left style={{ flexDirection: 'column' }}>
                  <Text style={{ color: theme.textColor, fontSize: 16, fontWeight: 'bold' }}>{command.name}</Text>
                  <Text style={{ color: theme.textColor }}>{get(command, 'command.name')}</Text>
                </Left>

                {canEdit && (
                <Right>
                  <TouchableOpacity onPress={() => removeCommand(command)}>
                    <MaterialCommunityIcons name="trash-can-outline" size={20} color={colors.grey} />
                  </TouchableOpacity>
                </Right>
                )}
              </ItemPress>
            )
          }

          {canEdit && (
          <ItemPress onPress={() => navigation.navigate('SlavePicker')}>
            <Left>
              <TextList text={t`Add Action`} />
            </Left>

            <Right>
              <MaterialCommunityIcons name="chevron-right" size={20} color={colors.grey} />
            </Right>
          </ItemPress>
          )}
        </View>

        {canEdit && scene.id != null && (
          <View style={[theme.form.area]}>
            <ButtonForm
              title={t`Delete Scene`}
              btnStyle={theme.form.btn}
              color={theme.colors.grey}
              onPress={removeScene}
            />
          </View>
        )}
      </ScrollView>
  )
}
