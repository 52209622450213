import React, { useCallback } from 'react'
import {
  LoginContainer,
  LoginLogo,
  LoginFormArea,
  LoginEmailInput,
  LoginButton,
  LoginNav
} from '#app/components'
import { Success, Error } from '../components/Utils'
import { t } from 'ttag'
import { ScreenProps } from '#app/types'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { fetchUserPasswordReset } from '#app/user'
import { resetLoaderById, selectLoaderById } from '#app/loader'
import { useFocusEffect } from '@react-navigation/native'

const ResetPassword = ({ navigation, route }: ScreenProps<'ResetPassword'>) => {
  const dispatch = useAppDispatch()

  const { control, handleSubmit, getValues } = useForm<FormData>({
    defaultValues: route.params,
    resolver: yupResolver(schema)
  })
  const onSubmit = useCallback((data: FormData) => {
    dispatch(fetchUserPasswordReset(data))
  }, [dispatch])
  const onRecover = handleSubmit(onSubmit)

  const onLogin = useCallback(() => {
    navigation.navigate('Login', { email: getValues('email') })
  }, [navigation, getValues])

  const { isLoading, isError, isSuccess, message } = useAppSelector(state => selectLoaderById(state, { id: 'user/passwordReset' }))
  useFocusEffect(useCallback(() => {
    return () => {
      dispatch(resetLoaderById('user/passwordReset'))
    }
  }, [dispatch]))

  return (
    <LoginContainer>
      <LoginLogo />

      <LoginFormArea>
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <LoginEmailInput
              ref={ref}
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              returnKeyType="done"
              onSubmitEditing={onRecover}
            />
          }
        />
      </LoginFormArea>

      <LoginFormArea>
        {isError && <Error>{message}</Error>}
        {isSuccess &&
          <Success>
            {t`Link request to reset password has been successfully submitted. Please Check your inbox.`}
          </Success>
        }
      </LoginFormArea>

      <LoginButton title={t`Recover Password`} onPress={onRecover} isLoading={isLoading} />

      <LoginNav onPress={onLogin}>{t`Remember your password? Log in instead`}</LoginNav>
    </LoginContainer>
  )
}

export default ResetPassword

const schema: Yup.SchemaOf<FormData> = Yup.object({
  email: Yup.string()
    .required()
    .email()
}).required()

interface FormData {
  email: string
}
