import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet, Dimensions, ActivityIndicator } from 'react-native'

export function calculateButtonSize () {
  const screenWidth = Dimensions.get('window').width

  const size = (screenWidth / 4) - 18

  if (size > 160) {
    return 160
  }

  return size
}

const GenericSmallButton = ({ onPress, text, loading = false, disable = false }: GenericSmallButtonProps) => {
  return (
    <TouchableOpacity disabled={disable} onPress={onPress} delayPressIn={50}>
      <View style={styles.container}>
        {loading && (<ActivityIndicator style={{ position: 'absolute' }} color="black" size="small" />)}
        {!loading && (<Text style={styles.text} numberOfLines={2} ellipsizeMode="tail">{text}</Text>)}
      </View>
    </TouchableOpacity>
  )
}

export default React.memo(GenericSmallButton)

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    width: calculateButtonSize(),
    height: calculateButtonSize(),
    marginLeft: 6,
    marginRight: 6,
    marginTop: 15,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 1)'
  },
  top: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    paddingTop: 12,
    paddingLeft: 12,
    paddingRight: 12,
    justifyContent: 'space-between'
  },
  bottom: {
    flex: 3,
    padding: 12
  },
  text: {
    fontSize: 12,
    fontWeight: 'bold'
  },
  status: {
    marginTop: 4,
    fontSize: 12
  }
})

export interface GenericSmallButtonProps {
  onPress?: (...args: any[]) => any
  disable?: boolean
  loading?: boolean
  btnStyle?: any
  text: string
}
