import React from 'react'
import { Platform, StyleSheet, View } from 'react-native'

const styles = StyleSheet.create(
  Platform.select({
    web: {
      light: {
        '-webkit-backdrop-filter': 'blur(1rem) saturate(1.5) contrast(1.5)',
        'backdrop-filter': 'blur(1rem) saturate(1.5) contrast(1.5)',
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
      },
      dark: {
        '-webkit-backdrop-filter': 'blur(1rem) contrast(0.5)',
        'backdrop-filter': 'blur(1rem) contrast(0.5)',
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      }
    },
    default: {
      light: {
        backgroundColor: 'rgba(255, 255, 255, 0.8)'
      },
      dark: {
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
      }
    }
  })
)

export default ({
  tint,
  intensity,
  style,
  ...props
}: any) => (
  <View
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    style={[styles[tint], style]}
    {...props}
  />
)
