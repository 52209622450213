import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { FlatList, ListRenderItem, View } from 'react-native'
import { TextList, ItemPress, Right, RfIrDeviceIcon } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import Icon from '../components/Icon'
import { t } from 'ttag'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'
import { ScreenProps } from '#app/types'
import { PropId } from 'robodux'
import { selectSlaveIrDevicesForFlatList, selectRfIrDeviceById } from '#app/rfirdevice'

const SlaveRfIrDeviceList = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const data = useAppSelector(state => selectSlaveIrDevicesForFlatList(state, { id }))
  const loading = useAppSelector(state => state.slaves.loading)

  const dispatch = useAppDispatch()
  const insets = useSafeAreaInsets()

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
          <Item
            title={t`Add`}
            iconName="add"
            onPress={() => navigation.navigate('CreateRfIrDevice', { slaveId: id })}
          />
        </HeaderButtons>
      )
    })
  }, [navigation, id])

  const onRefresh = useCallback(() => {
    dispatch({ type: 'DATA_GET_SLAVES_REQUEST' })
  }, [dispatch])

  return (
    <View style={{ maxHeight: '100%', flexBasis: 1, flexGrow: 1 }}>
      <FlatList
        renderItem={renderItem}
        data={data}
        contentContainerStyle={{ paddingBottom: insets.bottom }}
        refreshing={loading}
        onRefresh={onRefresh}
      />
    </View>
  )
}

export default SlaveRfIrDeviceList

const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={28} />

const ListItem = ({ id }: PropId) => {
  const navigation = useNavigation()
  const theme = useAppTheme()
  const { category, name } = useAppSelector(state => selectRfIrDeviceById(state, { id }))
  const onPress = useCallback(() => {
    navigation.navigate('IrAccessory', { id })
  }, [navigation, id])

  return (
    <ItemPress onPress={onPress}>
      <RfIrDeviceIcon category={category} />
      <TextList text={name}/>
      <Right>
        <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
      </Right>
    </ItemPress>
  )
}

const renderItem: ListRenderItem<PropId> = ({ item }) => <ListItem id={item.id} />
