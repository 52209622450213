import React, { useState, useEffect, useCallback } from 'react'
import { Separator, Right } from '../components/List'
import { View, ScrollView, Text, TouchableOpacity } from 'react-native'
import FontAwesome from 'react-native-vector-icons/FontAwesome'
import { useAppDispatch, useAppSelector } from '../hooks'
import Icon from '../components/Icon'
import MyioDatePicker from '../components/MyioDatePicker'
import { HeaderButtons, HeaderButton, Item as Item2 } from 'react-navigation-header-buttons'
import moment from 'moment'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { selectUsersAsList } from '#app/user'
import { useNavigation } from '@react-navigation/native'

export default function FilterLogs () {
  const users = useAppSelector(selectUsersAsList).map(u => {
    return { label: u.name, value: u.id }
  })
  const slaves = useAppSelector(state => state.slaves.allSlaves)

  const labelify = (device: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device.is_slave) {
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `slave_${device.slave_id}`
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_channel) {
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `channel_${device.slave_id}_${device.channel}`
      }
    } else { // is_device
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `remote_${device.slave_id}_${device.id}`
      }
    }
  }

  const devices = [{
    title: gettext('Products'),
    data: slaves.filter((slave: any) => slave.is_slave).map(labelify)
  }, {
    title: gettext('Devices'),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    data: slaves.filter((slave: any) => slave.is_channel || slave.is_device).map(labelify)
  }]

  const filters = useAppSelector(state => state.logs.filters)
  const types = useAppSelector(state => state.logs.types)
  const actionTypes = useAppSelector(state => state.logs.action_types)
  const theme = useAppTheme()

  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedDevices, setSelectedDevices] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedActionTypes, setSelectedActionTypes] = useState([])

  const [showPickerDateStart, setShowPickerDateStart] = useState(false)
  const [showPickerDateEnd, setShowPickerDateEnd] = useState(false)

  const [dateStart, setDateStart] = useState(moment())
  const [dateEnd, setDateEnd] = useState(moment())

  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={FontAwesome} iconSize={23} />

  useEffect(() => {
    navigation.setOptions({
      title: gettext('Filter'),
      headerRight: () => {
        return (
          <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
            <Item2 title={t`Ok`} onPress={closeAndSave} />
          </HeaderButtons>
        )
      }
    })
  })

  const closeAndSave = useCallback(() => {
    dispatch({
      type: 'GET_LOGS_REQUEST',
      items: {
        dateStart: moment(dateStart, 'DD/MM/YYYY').startOf('day').toISOString(),
        dateEnd: moment(dateEnd, 'DD/MM/YYYY').endOf('day').toISOString(),
        type: 'date'
      }
    })
    navigation.goBack()
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dateStart, dateEnd])

  useEffect(() => {
    setDateStart(moment(filters.date_start))
    setDateEnd(moment(filters.date_end))
    setSelectedUsers(filters.users)
    setSelectedDevices(filters.devices)
    setSelectedTypes(filters.type)
    setSelectedActionTypes(filters.action_type)
  }, [filters])

  const _getDate = (param: any) => {
    return moment(param).tz('America/Sao_Paulo').format('DD/MM/YYYY').toString()
  }

  const toggleDatePickerStart = () => {
    if (showPickerDateEnd) setShowPickerDateEnd(false)
    setShowPickerDateStart(!showPickerDateStart)
  }

  const toggleDatePickerEnd = () => {
    if (showPickerDateStart) setShowPickerDateStart(false)
    setShowPickerDateEnd(!showPickerDateEnd)
  }

  const getModalLabel = (selected: any, original: any) => {
    if (selected.length === original.length) {
      return gettext('All')
    } else if (selected.length >= 2) {
      return `${t`${selected.length} selecteds`}`
    } else if (selected.length === 1) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${selected[0].label}`
    } else {
      return gettext('Select an item...')
    }
  }

  const getDevicesModalLabel = useCallback(() => {
    const selectedLength = selectedDevices.length
    const devicesLength = devices.reduce((acc, section) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      return acc + section.data.length
    }, 0)

    if (selectedLength === devicesLength) {
      return gettext('All')
    } else if (selectedLength >= 2) {
      return `${t`${selectedLength} selecteds`}`
    } else if (selectedLength === 1) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${(selectedDevices[0] as any).label}`
    } else {
      return gettext('Select an item...')
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [selectedDevices])

  const onDateStartChange = (value: any) => {
    setDateStart(value)
    if (moment(value, 'DD/MM/YYYY', true).isValid()) {
      setDateStart(moment(value, 'DD/MM/YYYY'))
    }
  }

  const onDateEndChange = (value: any) => {
    setDateEnd(value)
    if (moment(value, 'DD/MM/YYYY', true).isValid()) {
      setDateEnd(moment(value, 'DD/MM/YYYY'))
    }
  }

  return (
      <ScrollView>

        <Separator text={t`Period`} />
        <View style={[theme.form.formGroup, theme.m4, { flexDirection: 'column' }]}>
          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Initial Date`}</Text>
          {!showPickerDateStart && (
            <TouchableOpacity onPress={toggleDatePickerStart} style={[theme.form.inputTouchable, theme.border]}>
              <Text style={{ fontSize: 15, color: theme.textColor }}>{_getDate(dateStart)}</Text>
            </TouchableOpacity>
          )}

          {showPickerDateStart && (
            <MyioDatePicker date={dateStart} theme={theme} onDateChange={onDateStartChange} />
          )}

          <Text style={{ color: theme.textColor, marginBottom: 5 }}>{t`Final Date`}</Text>
          {!showPickerDateEnd && (
            <TouchableOpacity onPress={toggleDatePickerEnd} style={[theme.form.inputTouchable, theme.border]}>
              <Text style={{ fontSize: 15, color: theme.textColor }}>{_getDate(dateEnd)}</Text>
            </TouchableOpacity>
          )}

          {showPickerDateEnd && (
            <MyioDatePicker date={dateEnd} theme={theme} onDateChange={onDateEndChange} />
          )}
        </View>

        <Separator text={t`Users`} />
        <View style={[theme.form.formGroup, theme.m4]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Users'),
                data: users,
                value: selectedUsers,
                search: true,
                multiple: true,
                required: true,
                onPressOk: (data: any) => {
                  setSelectedUsers(data.selectedItems)
                  dispatch({ type: 'UPDATE_USERS_FILTER', items: data.selectedItems })
                }
              })
            }} style={[theme.form.inputTouchable, theme.border]}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {getModalLabel(selectedUsers, users)}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        <Separator text={t`Log Types`} />
        <View style={[theme.form.formGroup, theme.m4]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Type'),
                data: types,
                value: selectedTypes,
                search: true,
                multiple: true,
                required: true,
                onPressOk: (data: any) => {
                  setSelectedTypes(data.selectedItems)
                  dispatch({ type: 'UPDATE_TYPES_FILTER', items: data.selectedItems })
                }
              })
            }} style={[theme.form.inputTouchable, theme.border]}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {getModalLabel(selectedTypes, types)}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        <Separator text={t`Action Types`} />
        <View style={[theme.form.formGroup, theme.m4]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Select a scene...'),
                data: actionTypes,
                value: selectedActionTypes,
                search: true,
                multiple: true,
                required: true,
                onPressOk: (data: any) => {
                  setSelectedActionTypes(data.selectedItems)
                  dispatch({ type: 'UPDATE_ACTION_TYPES_FILTER', items: data.selectedItems })
                }
              })
            }} style={[theme.form.inputTouchable, theme.border]}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {getModalLabel(selectedActionTypes, actionTypes)}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        <Separator text={t`Products / Devices`} />
        <View style={[theme.form.formGroup, theme.m4]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPickerSection', {
                name: gettext('Select a scene...'),
                data: devices,
                value: selectedDevices,
                search: true,
                multiple: true,
                required: true,
                onPressOk: (data: any) => {
                  setSelectedDevices(data.selectedItems)
                  dispatch({ type: 'UPDATE_DEVICES_FILTER', items: data.selectedItems })
                }
              })
            }}
            style={[theme.form.inputTouchable, theme.border]}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 2. */}
              {getDevicesModalLabel(selectedDevices, devices)}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

      </ScrollView>
  )
}
