import { Central, CentralUserRole, User } from '#app/types'
import apisauce, { ApiResponse } from 'apisauce'

export const TIMEOUT = 10000

const generateCacheBuster = () => (Math.random().toString(20).substr(2, 8))

const generateCloudHeaders = ({ cloudToken, userId, centralId }: GenerateCloudHeaderProps) => {
  const headers = {
    'content-type': 'application/json',
    ...(cloudToken != null ? { Authorization: `Bearer ${cloudToken}` } : {}),
    ...(userId != null ? { 'user-id': userId, user_id: userId } : {}),
    ...(centralId != null ? { 'central-id': centralId } : {})
  }
  return headers
}

const generateCentralHeaders = ({ centralToken, centralId }: GenerateCentralHeaderProps) => {
  const headers = {
    'x-access-token': centralToken,
    'central-id': centralId,
    'content-type': 'application/json'
  }
  return headers
}

export const create = ({ baseURL, cloudToken, centralToken, centralId, userId }: CreateApiProps) => {
  const cloudApi = apisauce.create({
    baseURL,
    headers: generateCloudHeaders({ cloudToken, userId, centralId })
  })
  const centralApi = apisauce.create({
    baseURL,
    timeout: TIMEOUT,
    headers: generateCentralHeaders({ centralToken: centralToken ?? '', centralId: centralId ?? '' })
  })

  return {
    auth: async (email: string, password: string) => {
      return await cloudApi.post<{token: string, user: any}>('/users/auth', { email, password })
    },

    register: async (user: { name: string, email: string, password: string }) => {
      return await cloudApi.post<{
        UUID: string
        role: string
        name: string
        email: string
        updatedAt: string
        createdAt: string
        cpf: string | null
      }, { err: string }>('/users', user)
    },

    forgotPassword: async (email: string) => {
      return await cloudApi.post<{}, { err: string }>('/users/forgot_password', { email })
    },

    getCentrals: async (after?: string, before?: string) => {
      return await cloudApi.get<{
        totalCount: number
        pageInfo: {
          hasNextPage: boolean
          hasPreviousPage: boolean
          startCursor: string
          endCursor: string
        }
        edges: Array<{
          cursor: string
          node: {
            UUID: string
            registered: boolean
            serial: string
            name: string | null
            createdAt: string
            mac?: string
            yggdrasilIP?: string
          }
        }>
      }>('/central', { after, before })
    },

    patchCentral: async (central: Central['id'], data: Partial<{name: string}>) => {
      return await cloudApi.patch<{status: number, message: string}, {err?: string}>(
        `/central/${central}`,
        data
        // { headers: { central_id: central } }
      )
    },

    getUserCentrals: async () => {
      return await cloudApi.get('/users/centrals', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    getPushAuth: async () => {
      return await cloudApi.get<ApiPushAuthResponse, ApiError>('/users/push_auth')
    },

    getUsers: async (central: Central['id']) => {
      return await cloudApi.get(`/central/${central}/users`, { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    deleteCentralUser: async (central: Central['id'], user: User['id']) => {
      return await cloudApi.post<'ok', {err?: string}>(
        '/central/remove_user2',
        { UUID: user },
        { headers: { central_id: central } }
      )
    },

    updateCentralUser: async (central: Central['id'], user: User['id'], name?: string, expiresAt?: string | null, role?: CentralUserRole) => {
      return await cloudApi.post<any, {err?: string}>(
        '/central/update_central_user2',
        {
          UUID: user,
          expires_at: expiresAt,
          role,
          central_name: name
        },
        { headers: { central_id: central } }
      )
    },

    associateUser: async (id: Central['id'], email: string) => {
      return await cloudApi.post<{id: number}, {err?: string}>(
        '/central/associate_user2',
        { email },
        { headers: { central_id: id } }
      )
    },

    getImageUploadUrl: async (imageExtension: string, imageType: string) => {
      return await cloudApi.post('/central/image_upload', {
        imageExtension,
        imageType
      })
    },

    deleteAlarm: async (id: string) => {
      return await centralApi.delete('/alerts/' + id)
    },

    updateAlarm: async (id: string, data: any) => {
      return await centralApi.put('/alerts/' + id, data)
    },

    createAlarm: async (data: any) => {
      return await centralApi.post('/alerts', data)
    },

    getAlarms: async () => {
      return await centralApi.get('/alerts', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    getAlertsHistory: async () => {
      return await centralApi.get('/alerts_history', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    getAmbients: async () => {
      return await centralApi.get('/ambients', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    addAmbient: async (ambient: Partial<Omit<ApiAmbient, 'id'|'createdAt'|'updatedAt'>>) => {
      return await centralApi.post<ApiAmbient, ApiError>('/ambients', ambient)
    },

    updateAmbient: async ({ id, ...data }: Omit<ApiAmbient, 'createdAt'|'updatedAt'>) => {
      return await centralApi.put<ApiAmbient, ApiError>(`/ambients/${id}`, data)
    },

    deleteAmbient: async (ambientId: string) => {
      return await centralApi.delete<ApiGenericResponse>(`/ambients/${ambientId}`)
    },

    getSlaves: async () => {
      return await centralApi.get('/slaves', { 'cache-buster': generateCacheBuster() }, {
        data: null,
        timeout: 30000
      })
    },

    getEnvironment: async () => {
      return await centralApi.get<Record<string, string>>('/environment', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    putEnvironment: async (key: string, value: string) => {
      return await centralApi.put(`/environment/${key}`, value, {
        headers: {
          'content-type': 'text/plain'
        }
      })
    },

    addSlave: async (slave: Partial<ApiSlave>, ambients: Array<ApiAmbient['id']> = []) => {
      return await centralApi.post<ApiSlave, ApiError>('/slaves', { slave, ambients })
    },

    patchSlave: async (slaveId: ApiSlave['id'], slave: Partial<Omit<ApiSlave, 'id'>>, ambients: Array<ApiAmbient['id']> = []) => {
      return await centralApi.put<ApiSlave, ApiError>(`/slaves/${slaveId}`, { slave, ambients })
    },

    swapSlave: async (oldSlaveId: ApiSlave['id'], newSlaveId: ApiSlave['id']) => {
      return await centralApi.get<ApiSlave, ApiError>(
        `/slaves/${oldSlaveId}/swap/${newSlaveId}`,
        {
          'cache-buster': generateCacheBuster(),
          timeout: 30000
        }
      )
    },

    deleteSlave: async (slaveId: string) => {
      return await centralApi.delete<ApiGenericResponse, ApiError>(`/slaves/${slaveId}`, undefined, {
        timeout: 10000
      })
    },

    getScenes: async () => {
      return await centralApi.get('/scenes', { 'cache-buster': generateCacheBuster() }, {
        data: null,
        timeout: 30000
      })
    },

    createScene: async (scene: any) => {
      return await centralApi.post('/scenes', scene)
    },

    deleteScene: async (sceneId: string) => {
      return await centralApi.delete(`/scenes/${sceneId}`)
    },

    updateScene: async (scene: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return await centralApi.put(`/scenes/${scene.id}`, scene)
    },

    getRemotes: async (deviceId: string) => {
      return await centralApi.get(`/devices/${deviceId}/remotes`, { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    getAllConsumptionHourly: async (interval: string, startDate: string, endDate: string) => {
      return await centralApi.get(`/consumption/all/${interval}/${startDate}/${endDate}`, { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    addButton: async (button: any) => {
      return await centralApi.post('/rfir_buttons', button)
    },

    updateButton: async (id: string, button: any) => {
      return await centralApi.put(`/rfir_buttons/${id}`, button)
    },

    updateButtonsOrder: async (positions: any) => {
      return await centralApi.put('/rfir_buttons/order', positions)
    },

    deleteButton: async (id: string) => {
      return await centralApi.delete(`/rfir_buttons/${id}`)
    },

    deleteChannel: async (channelId: ApiChannel['id']) => {
      return await centralApi.delete<ApiGenericResponse, ApiError>(`/channels/${channelId}`)
    },

    patchChannel: async (channelId: ApiChannel['id'], channel: Partial<Omit<ApiChannel, 'id'>>) => {
      return await centralApi.put<ApiChannel, ApiError>(`/channels/${channelId}`, channel)
    },

    createChannel: async (channel: Partial<Omit<ApiChannel, 'id'>>) => {
      return await centralApi.post<ApiChannel, ApiError>('/channels', channel)
    },

    deleteDevice: async (deviceId: ApiRfIrDevice['id']) => {
      return await centralApi.delete<ApiGenericResponse, ApiError>(`/devices/${deviceId}`)
    },

    patchDevice: async (deviceId: ApiRfIrDevice['id'], device: Partial<Omit<ApiRfIrDevice, 'id'>>) => {
      return await centralApi.put<ApiRfIrDevice, ApiError>(`/devices/${deviceId}`, device)
    },

    createDevice: async (device: Partial<Omit<ApiRfIrDevice, 'id'>>) => {
      return await centralApi.post<ApiRfIrDevice, ApiError>('/devices', device)
    },

    getLogs: async (page: string) => {
      return await centralApi.get(`/metadata/user_action/${page}`, { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    getLogsV2: async (filters: any) => {
      return await centralApi.post('/logs', filters, {
        timeout: 50000
      })
    },

    getAmbientPermissions: async (targetUserId?: string) => {
      return await centralApi.get(
        targetUserId != null ? `/ambient_permissions/user/${targetUserId}` : '/ambient_permissions',
        { 'cache-buster': generateCacheBuster() },
        { data: null }
      )
    },

    patchAmbients: async (output: any) => {
      return await centralApi.post('/ambients/batch', output)
    },

    updateUserAmbientsPermissions: async (id: User['id'], ambients: number[], expires?: string | null) => {
      return await centralApi.post('/ambient_permissions', {
        userId: id,
        ambients,
        expires
      })
    },

    getSchedules: async () => {
      return await centralApi.get('schedules', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    updateSchedule: async (schedule: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return await centralApi.put(`/schedules/${schedule.id}`, schedule)
    },

    createSchedule: async (schedule: any) => {
      return await centralApi.post('/schedules', schedule)
    },

    deleteSchedule: async (scheduleId: string) => {
      return await centralApi.delete(`/schedules/${scheduleId}`)
    },

    getThreePhaseEnergy: async (threePhaseId: string, period: string, iniDate: string, endDate: string) => {
      return await centralApi.get(`/consumption/slave/${threePhaseId}/${period}/${iniDate}/${endDate}`, { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    },

    updateFavorites: async (data: any) => {
      return await centralApi.post('/favorites', data)
    },

    getFavorites: async () => {
      return await centralApi.get('/favorites', { 'cache-buster': generateCacheBuster() }, {
        data: null
      })
    }
  }
}

export const errorProtoString = ({ ok, data, status }: ApiResponse<any, ApiError>): string => {
  if (!ok && data != null && typeof data === 'object') {
    if ('message' in data && data.message != null) return data.message
    if ('err' in data) return data.err
  }
  switch (status) {
    case 408:
      return 'REQUEST_TIMEOUT'
  }
  return 'UNKNOWN_ERROR'
}

interface GenerateCloudHeaderProps {
  cloudToken?: string
  centralId?: string
  userId?: string
}

interface GenerateCentralHeaderProps {
  centralToken: string
  centralId: string
}

type GenerateHeaderProps = GenerateCloudHeaderProps & GenerateCentralHeaderProps

export type CreateApiProps = Partial<GenerateHeaderProps> &
{
  baseURL: string
}

export interface ApiAmbient {
  id: number
  name: string | null
  image: string | null
  order: number | null
  config: object | null
  createdAt?: string
  updatedAt?: string
  slaves?: unknown[]
}

export interface ApiGenericResponse {
  status: number
  message?: string
}

export type ApiError = ApiGenericResponse | {
  err: string
  log: string
}

export interface ApiSlave {
  id: number
  type: string | null
  addr_low: number | null
  addr_high: number | null
  channels: number | null
  name: string | null
  color: string | null
  code: string | null
  clamp_type: number | null
  aggregate: boolean | null
  version: string | null
  temperature_correction: number | null
  config: object | null
}

export interface ApiChannel {
  id: number
  slave_id: ApiSlave['id'] | null
  channel: number | null
  type: string | null
  name: string | null
  channel_id: number | null
  scene_up_id: number | null
  scene_down_id: number | null
  config: object | null
}

export interface ApiRfIrDevice {
  id: number
  slave_id: ApiSlave['id'] | null
  name: string | null
  type: string | null
  category: string | null
  output: string | null
  commandOnId: number | null
  commandOffId: number | null
}

export interface ApiPushAuthResponse {
  emailAuthHash: string
  externalUserIdAuthHash: string
}
