import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  clearAlarmsState: null,

  alarmsGetRequest: null,
  alarmsGetRequestSuccess: ['payload'],
  alarmsGetRequestFailure: null,

  alarmsSaveRequest: ['payload'],
  alarmsSaveRequestSuccess: null,
  alarmsSaveRequestFailure: null,

  alarmsUpdateRequest: ['payload'],
  alarmsUpdateRequestSuccess: null,
  alarmsUpdateRequestFailure: null,

  alarmsDeleteRequest: ['payload'],
  alarmsDeleteRequestSuccess: null,
  alarmsDeleteRequestFailure: null
})

export const INITIAL_STATE = {
  alarms: [],
  loading: false
}

export default Creators

function clearAlarmsState () {
  return { ...INITIAL_STATE }
}

function alarmsUpdateRequest (state: any) {
  return {
    ...state,
    loading: true
  }
}

function alarmsUpdateRequestSuccess (state: any) {
  return {
    ...state,
    loading: false
  }
}

function alarmsUpdateRequestFailure (state: any) {
  return {
    ...state,
    loading: false
  }
}

function alarmsSaveRequest (state: any) {
  return {
    ...state,
    loading: true
  }
}

function alarmsSaveRequestSuccess (state: any) {
  return {
    ...state,
    loading: false
  }
}

function alarmsSaveRequestFailure (state: any) {
  return {
    ...state,
    loading: false
  }
}

function alarmsGetRequest (state: any) {
  return {
    ...state,
    loading: true
  }
}

function alarmsGetRequestSuccess (state: any, action: any) {
  const { payload } = action

  return {
    ...state,
    alarms: payload,
    loading: false
  }
}

function alarmsGetRequestFailure (state: any) {
  return {
    ...state,
    loading: false
  }
}

function alarmsDeleteRequest (state: any) {
  return {
    ...state,
    loading: true
  }
}

function alarmsDeleteRequestSuccess (state: any, action: any) {
  const { payload } = action

  return {
    ...state,
    alarms: payload,
    loading: false
  }
}

function alarmsDeleteRequestFailure (state: any) {
  return {
    ...state,
    loading: false
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_ALARMS_STATE]: clearAlarmsState,

  [Types.ALARMS_GET_REQUEST]: alarmsGetRequest,
  [Types.ALARMS_GET_REQUEST_SUCCESS]: alarmsGetRequestSuccess,
  [Types.ALARMS_GET_REQUEST_FAILURE]: alarmsGetRequestFailure,

  [Types.ALARMS_SAVE_REQUEST]: alarmsSaveRequest,
  [Types.ALARMS_SAVE_REQUEST_SUCCESS]: alarmsSaveRequestSuccess,
  [Types.ALARMS_SAVE_REQUEST_FAILURE]: alarmsSaveRequestFailure,

  [Types.ALARMS_UPDATE_REQUEST]: alarmsUpdateRequest,
  [Types.ALARMS_UPDATE_REQUEST_SUCCESS]: alarmsUpdateRequestSuccess,
  [Types.ALARMS_UPDATE_REQUEST_FAILURE]: alarmsUpdateRequestFailure,

  [Types.ALARMS_DELETE_REQUEST]: alarmsDeleteRequest,
  [Types.ALARMS_DELETE_REQUEST_SUCCESS]: alarmsDeleteRequestSuccess,
  [Types.ALARMS_DELETE_REQUEST_FAILURE]: alarmsDeleteRequestFailure
})
