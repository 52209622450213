import React, { useEffect, useCallback } from 'react'
import { Platform, ActivityIndicator, View, Image, KeyboardAvoidingView } from 'react-native'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Error } from '../../components/Utils'
import hubotTomada from '#assets/ic_hubot_tomada.png'
import { t } from 'ttag'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { useAppTheme } from '#app/theme'
import { AddSlaveFormData, ScreenProps } from '#app/types'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { addSlave } from '#app/slave'
import { SlaveCodeInput } from '#app/components/Slave'
import { ActionCreator, AnyAction } from 'redux'
import { useNavigation } from '@react-navigation/native'

interface ComponentProps <A = AnyAction> {
  action: ActionCreator<A>
  loader: string
  goTo: string
}

export const Component = <T extends AnyAction,>({ action, loader: loaderId, goTo }: ComponentProps<T>) => {
  const navigation = useNavigation()
  const theme = useAppTheme()

  const form = useForm<AddSlaveFormData>({
    shouldUnregister: false,
    resolver: yupResolver(schema)
  })
  const { handleSubmit, formState: { isDirty }, setFocus, control } = form
  useFieldArray({ control, name: 'code' })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<AddSlaveFormData>>((data) => {
    navigation.setParams({ isCancelable: false })
    dispatch(action({ data }))
  }, [dispatch, navigation, action])

  const loader = useAppSelector(state => selectLoaderById(state, { id: loaderId }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.navigate(goTo)
  }, [navigation, goTo])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)
  useEffect(() => {
    if (showError) {
      navigation.setParams({ isCancelable: true })
    }
  }, [navigation, showError])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return isLoading
          ? <ActivityIndicator />
          : <HeaderButtons>
              <Item
                title={t`Next`}
                iconName="settings"
                onPress={handleSubmit(onSubmit)}
              />
            </HeaderButtons>
      }
    })
  }, [navigation, handleSubmit, onSubmit, isLoading])

  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  useEffect(() => {
    setFocus('code.0.value')
  }, [setFocus])

  return (
    <KeyboardAvoidingView
      enabled={(Platform.OS === 'ios') || (Platform.OS === 'android')}
      contentContainerStyle={{ justifyContent: 'center' }}
    >
      <View style={[theme.form.area, { justifyContent: 'center' }]}>
        <View style={[theme.form.formGroup, { alignItems: 'center' }]}>
          <Image source={hubotTomada} style={{ width: 103, height: 150 }} />
        </View>

        <View style={[theme.form.formGroup, { alignItems: 'center', justifyContent: 'space-between', marginTop: 24, flexDirection: 'row' }]}>
          <Controller
            name="code.0.value"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <SlaveCodeInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isError={error != null}
                onSubmitEditing={() => setFocus('code.1.value')}
              />
            )}
          />
          <Controller
            name="code.1.value"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <SlaveCodeInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isError={error != null}
                onSubmitEditing={() => setFocus('code.2.value')}
              />
            )}
          />
          <Controller
            name="code.2.value"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <SlaveCodeInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isError={error != null}
                onSubmitEditing={() => setFocus('code.3.value')}
              />
            )}
          />
          <Controller
            name="code.3.value"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <SlaveCodeInput
                ref={ref}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                isError={error != null}
                onSubmitEditing={handleSubmit(onSubmit)}
              />
            )}
          />
        </View>

        <View style={[theme.form.formGroup, { alignItems: 'center', marginTop: 24 }]}>
          {showError && <Error>{message}</Error>}
          {form.formState.errors.code != null && <Error>{t`Invalid pairing code`}</Error>}
        </View>

      </View>
    </KeyboardAvoidingView>
  )
}

const NewSlaveStepCode = ({ navigation, route }: ScreenProps<'NewSlaveStepCode'>) =>
  <Component goTo="NewSlaveStepOnboard" action={addSlave} loader="slave/add" />

export default NewSlaveStepCode

const schema: Yup.SchemaOf<AddSlaveFormData> = Yup.object({
  code: Yup.array()
    .of(
      Yup.object({
        value: Yup.number()
          .min(0)
          .max(255)
          .required()
      })
    ).length(4)
    .required()
}).required()
