import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { SectionList } from 'react-native'
import { ItemPress, Right, Separator, TextList, SlaveIcon } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { selectSlavesByTypeForSectionList, getSlave } from '#app/slave'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useAppTheme } from '#app/theme'
import { updateAmbient } from '#app/ambient'
import { ScreenProps } from '#app/types'

const AmbientAddSlaves = ({ navigation, route }: ScreenProps<'AmbientAddSlaves'>) => {
  const dispatch = useAppDispatch()
  const insets = useSafeAreaInsets()
  const sections = useAppSelector(selectSlavesByTypeForSectionList)
  const ambient = (route.params as any).ambient

  const [slavesSelecteds, setSlavesSelecteds] = useState([...ambient.slaves])

  useEffect(() => { navigation.setOptions({ title: ambient.name }) })

  const saveAmbient = useCallback(() => {
    navigation.goBack()
    dispatch(updateAmbient({ data: { ...ambient, slaves: slavesSelecteds } }))
  }, [dispatch, navigation, ambient, slavesSelecteds])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons>
          <Item
            title={t`Save`}
            onPress={saveAmbient}
          />
        </HeaderButtons>
      )
    })
  }, [navigation, saveAmbient])

  const getIsSelected = (slaveId: any) => {
    return slavesSelecteds.includes(slaveId)
  }

  const toggleSlave = (slaveId: any) => {
    if (getIsSelected(slaveId)) {
      setSlavesSelecteds(slavesSelecteds.filter(s => s !== slaveId))
      return
    }
    setSlavesSelecteds(slavesSelecteds.concat([slaveId]))
  }

  return (
    <SectionList
      renderSectionHeader={({ section: { title } }) => <Separator text={title} />}
      renderItem={({ item }) => <ListItem id={item.id} toggleSlave={toggleSlave} getIsSelected={getIsSelected} />}
      sections={sections}
      contentContainerStyle={{ paddingBottom: insets.bottom }}
    />
  )
}

const ListItem = ({ id, toggleSlave, getIsSelected }: {
  id: number
  toggleSlave: (slaveId: number) => void
  getIsSelected: (slaveId: number) => boolean
}) => {
  const theme = useAppTheme()
  const { type, name } = useAppSelector(state => getSlave(state, id))

  return (
    <ItemPress onPress={() => toggleSlave(id)}>
      <SlaveIcon type={type} />
      <TextList text={name}/>
      <Right>
        {getIsSelected(id) && <MaterialCommunityIcons name="check" size={20} color={theme.colors.grey} />}
      </Right>
    </ItemPress>
  )
}

export default AmbientAddSlaves
