import { AnyAction } from 'redux'
import { select, put, call } from 'redux-saga/effects'
import { flatten, map, merge, reduce } from 'lodash'
import FavoritesActions, { FavoritesState } from '../redux/favorites'
import { selectApi } from '#app/api'

export function * getFavorites (action: AnyAction) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getFavorites)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      const normalizedData = normalizeFavorites(response.data)
      yield put(FavoritesActions.getFavoritesRequestSuccess(normalizedData))
    } else {
      yield put(FavoritesActions.getFavoritesRequestFailure())
    }
  } catch (e) {
    yield put(FavoritesActions.getFavoritesRequestFailure())
  }
}

export function * updateFavorites ({ payload }: AnyAction) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  try {
    const denormalizedData = denormalizeFavorites(payload)
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.updateFavorites, denormalizedData)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      const normalizedData = normalizeFavorites(response.data)
      yield put(FavoritesActions.updateFavoritesRequestSuccess(normalizedData))
    } else {
      yield put(FavoritesActions.updateFavoritesRequestFailure())
    }
  } catch (e) {
    yield put(FavoritesActions.updateFavoritesRequestFailure())
  }
}

export function * updateFavoriteDeviceList ({ payload }: AnyAction) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const favorites = yield select(state => state.favorites.favorites)
  const newFavorites = {
    ...favorites,
    [payload.type]: payload.ids
  }
  yield put(FavoritesActions.updateFavoritesRequest(newFavorites))
}

const partitionFavorites = (favorites: any[], mapping: Record<string, string>) => reduce(
  favorites,
  (acc, f) => {
    const str = String(f)
    const splitted = str.split('_')
    if (splitted.length !== 2) return acc

    const [type, idStr] = splitted
    const id = parseInt(idStr, 10)
    if (isNaN(id)) return acc

    const types = Object.keys(mapping)

    return !types.includes(type)
      ? acc
      : merge({}, acc, { [mapping[type]]: [...acc[mapping[type]], id] })
  },
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  reduce(mapping, (acc, v) => ({ ...acc, [v]: [] }), {} as Record<string, number[]>)
)

const mergeFavorites = (records: Record<string, number[]>, mapping: Record<string, string>): string[] => flatten(
  map(
    records,
    (r, k) => map(r, (f) => `${mapping[k]}_${f}`)
  )
)

const reduceFavorites = (data?: any): number[] => {
  if (!Array.isArray(data)) return []
  return reduce(
    data,
    (acc, d) => {
      if (d == null) return acc
      const id = typeof d === 'number' ? d : parseInt(d, 10)
      if (isNaN(id)) return acc
      if (acc.includes(id)) return acc
      return [...acc, id]
    },
    [] as number[]
  )
}

const normalizeFavorites = (data: any): FavoritesState['favorites'] => {
  const { channels, devices } = partitionFavorites(data.devices, { channel: 'channels', remote: 'devices' })
  const { energy, temperature } = partitionFavorites(data.consumption, { energy: 'energy', temperature: 'temperature' })
  const ambients = reduceFavorites(data.ambients).map(a => String(a))
  const scenes = reduceFavorites(data.scenes)
  return {
    channels,
    devices,
    ambients,
    scenes,
    energy,
    temperature
  }
}

const denormalizeFavorites = ({
  channels,
  devices,
  ambients,
  scenes,
  energy,
  temperature
}: FavoritesState['favorites']) => ({
  ambients: ambients.map(a => Number(a)),
  scenes,
  devices: mergeFavorites({ channels, devices }, { channels: 'channel', devices: 'remote' }),
  consumption: mergeFavorites({ energy, temperature }, { energy: 'energy', temperature: 'temperature' })
})
