import React, { useCallback } from 'react'
import { Separator, ItemPress, ListItem, TextList, Right } from '../components/List'
import { Linking, View, Text } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { t, gettext } from 'ttag'
import { resetCurrentCentral } from '#app/central'
import { userLogout } from '#app/actions'
import { selectSelectedTheme, useAppTheme, toggleSelectedTheme } from '#app/theme'
import { selectCurrentUser } from '#app/user'
import { selectCan, selectCurrentUserCentral } from '#app/centraluser'
import { useNavigation } from '@react-navigation/native'

export default function Settings () {
  const THEME_TYPES = {
    system: gettext('System'),
    dark: gettext('Dark'),
    light: gettext('Light')
  }

  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const theme = useAppTheme()
  const user = useAppSelector(selectCurrentUser)
  const central = useAppSelector(selectCurrentUserCentral)
  const selectedTheme = useAppSelector(selectSelectedTheme)
  const can = useAppSelector(selectCan)
  const canEdit = can('edit')

  const changeCentral = useCallback(() => {
    dispatch(resetCurrentCentral())
  }, [dispatch])

  const logout = useCallback(() => {
    dispatch(userLogout())
  }, [dispatch])

  const onAccessoriesPress = useCallback(() => navigation.navigate('SlaveList'), [navigation])

  const onEnvironmentPress = useCallback(() => navigation.navigate('Environment'), [navigation])

  const onUsersPress = useCallback(() => {
    navigation.navigate('CentralUsers', { id: central.id })
  }, [navigation, central.id])

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSchedulesPress = useCallback(() => navigation.navigate('Schedules'), [])

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScenesPress = useCallback(() => navigation.navigate('SceneList'), [])

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onLogsPress = useCallback(() => navigation.navigate('Logs'), [])

  return (
    (<ListItem>
      <Separator text={`Central: ${central.name}`} />
      <ItemPress onPress={changeCentral}>
        <TextList text={t`Change Central`} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      <Separator text={t`Preferences`} />
      {canEdit && (
        <ItemPress onPress={onAccessoriesPress}>
          <TextList text={t`Products`} />
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </Right>
        </ItemPress>
      )}
      <ItemPress onPress={onUsersPress}>
        <TextList text={t`Users`} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      {canEdit && (
        <ItemPress onPress={onSchedulesPress}>
          <TextList text={t`Schedules`} />
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </Right>
        </ItemPress>
      )}
      <ItemPress onPress={onScenesPress}>
        <TextList text={t`Scenes`} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      {canEdit && <>
        <ItemPress onPress={onEnvironmentPress}>
          <TextList text={t`Environment`} />
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </Right>
        </ItemPress>
        <ItemPress onPress={() => navigation.navigate('NodeRed')}>
          <TextList text="Node-RED" />
          <Right>
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </Right>
        </ItemPress>
      </>}
      <ItemPress onPress={() => dispatch(toggleSelectedTheme())}>
        <TextList text={t`Theme`} />
        <View style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center' }}>
          <Text style={{ color: theme.textColor }}>{THEME_TYPES[selectedTheme]}</Text>
        </View>
      </ItemPress>
      <Separator text={t`Others`} />
      {
        canEdit && (
          <ItemPress onPress={onLogsPress}>
            <TextList text={t`Logs`} />
            <Right>
              <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
            </Right>
          </ItemPress>
        )
      }
      <Separator text={t`My Profile`} />
      <ItemPress onPress={() => { }}>
        <TextList text={user.name} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      <ItemPress onPress={async () => await Linking.openURL('mailto:suporte@myio.com.br')}>
        <TextList text={t`Email Support`} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      <ItemPress onPress={logout}>
        <TextList text={t`Logout`} />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
    </ListItem>)
  )
}
