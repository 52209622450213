import React from 'react'
import { ActivityIndicator, StyleSheet, Text } from 'react-native'
import { PressableScaleProps } from '..'
import { PressableScale } from '../PressableScale'

const LoginButton = ({
  isLoading = false,
  title,
  ...props
}: LoginButtonProps) => isLoading
  ? <ActivityIndicator color="#7446C6" style={styles.button} />
  : <PressableScale style={styles.button} {...props}>
      <Text style={styles.buttonText}>{title}</Text>
    </PressableScale>

export default LoginButton

const styles = StyleSheet.create({
  button: {
    alignSelf: 'stretch',
    height: 46,
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  },
  buttonText: {
    color: '#000',
    fontSize: 16
  }
})

export interface LoginButtonProps extends Omit<PressableScaleProps, 'style'> {
  isLoading?: boolean
  title: string
}
