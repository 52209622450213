import { put, call, select } from 'redux-saga/effects'
import { get } from 'lodash'
import LogsActions from '../redux/logs'
import moment from 'moment'
import { gettext } from 'ttag'
import { selectApi } from '#app/api'
import { selectAmbientsAsList } from '#app/ambient'
import { selectUsersAsList } from '#app/user'
import { Ambient, User } from '#app/types'

const STATUS_MAP = {
  light_switch: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  both_phases: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  pulsed_actuation: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  no_phase_actuation: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  follows_phase: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  negates_phase: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  no_action: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  count_pulses_on_variation: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  count_pulses_on_no_phase: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  lamp: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  plug: (value: any) => value > 0 ? gettext('Turn on') : gettext('Turn off'),
  door_sensor: (value: any) => value === 0 ? gettext('Detected') : gettext('No Detection'),
  smoke_sensor: (value: any) => value > 0 ? gettext('Detected') : gettext('No Detection'),
  presence_sensor: (value: any) => value > 0 ? gettext('Detected') : gettext('No Detection'),
  light_sensor: (value: any) => value === 0 ? gettext('Detected') : gettext('No Detection'),
  inverted_actionable: (value: any) => value > 0 ? gettext('Turn off') : gettext('Turn on')
}

export function * reloadLogs (action: any) {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const filter = yield select(state => state.logs.filters)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const slaves = yield select(state => state.slaves.allSlaves)
  const users: ReturnType<typeof selectUsersAsList> = yield select(selectUsersAsList)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const scenes = yield select(state => state.scene.scenes)
  const ambients: ReturnType<typeof selectAmbientsAsList> = yield select(selectAmbientsAsList)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const types = yield select(state => state.logs.types)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const actionTypes = yield select(state => state.logs.action_types)

  const enrichedFilter = {}

  if (action.items.type === 'first') {
    (enrichedFilter as any).users = 'all';
    (enrichedFilter as any).devices = 'all';
    (enrichedFilter as any).type = 'all';
    (enrichedFilter as any).action_type = 'all';
    (enrichedFilter as any).date_start = filter.date_start;
    (enrichedFilter as any).date_end = filter.date_end
  }

  if (action.items.type === 'date') {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (action.items.dateStart) {
      (enrichedFilter as any).date_start = action.items.dateStart;
      (enrichedFilter as any).date_end = action.items.dateEnd
    }

    if (_checkAll(filter.users) || filter.users.length === users.length) {
      (enrichedFilter as any).users = 'all'
    } else {
      (enrichedFilter as any).users = filter.users.map((u: any) => u.value)
    }

    if (_checkAll(filter.type) || filter.type.length === types.length) {
      (enrichedFilter as any).type = 'all'
    } else {
      (enrichedFilter as any).type = filter.type.map((u: any) => u.value)
    }

    if (_checkAll(filter.action_type) || filter.action_type.length === actionTypes.length) {
      (enrichedFilter as any).action_type = 'all'
    } else {
      (enrichedFilter as any).action_type = filter.action_type.map((u: any) => u.value)
    }

    if (_checkAll(filter.devices) || filter.devices.length === slaves.length) {
      (enrichedFilter as any).devices = 'all'
    } else {
      /*
       * item.value format is `type_slaveId_identifier`
       * example: channel_5_0
       *        : slave_5
       *        : remote_3_0
      */
      // @ts-expect-error ts-migrate(7022) FIXME: 'newDevices' implicitly has type 'any' because it ... Remove this comment to see the full error message
      const newDevices = filter.devices.map((device: any) => {
        const [type, slaveId, identifier] = device.value.split('_')
        if (type === 'slave') {
          return {
            slave_id: parseInt(slaveId)
          }
        }

        return {
          slave_id: parseInt(slaveId),
          [type]: parseInt(identifier)
        }
      })(// TODO: Fix this the next time the file is edited.
      // @ts-expect-error ts-migrate(2448) FIXME: Block-scoped variable 'newDevices' used before its... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
        enrichedFilter as any).devices = newDevices
    }
  }

  function _getScene (sceneId: any) {
    const scene = scenes.find((s: any) => s.id === sceneId)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return scene ? scene.name : null
  }

  function _getSlave ({
    slaveId,
    channelId
  }: any) {
    const slave = slaves.find((s: any) => s.slave_id === slaveId)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return slave ? slave.name : null
  }

  function _getChannel ({
    slaveId,
    channelId
  }: any) {
    const slave = slaves.find((s: any) => s.slave_id === slaveId)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!slave) { return null }

    const channel = slave.channels.find((c: any) => c.channel === channelId)
    return channel
  }

  function _getChannelName (channel: any) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return channel ? channel.name : null
  }

  function _getUser (id: User['id']) {
    const user = users.find(u => u.id === id)
    return user?.name ?? null
  }

  function _getAmbient (id: Ambient['id']) {
    const ambient = ambients.find(a => a.id === id)
    return ambient?.name ?? null
  }

  // console.log('enriched filter', enrichedFilter)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const response = yield call(api.getLogsV2, enrichedFilter)

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (response.ok) {
    yield put(LogsActions.updateDateFilter({ dateStart: action.items.dateStart }))
    yield put(LogsActions.updateDateFilter({ dateEnd: action.items.dateEnd }))

    const output = response.data.map((l: any) => {
      return {
        ...l,
        action_type: _getActionType(l.action_type),
        type: _getType(l.type),
        raw_type: _getRawType(l.type),
        channel_name: _getChannelName(_getChannel({ slaveId: l.slave_id, channelId: l.channel })),
        slave_name: _getSlave({ slaveId: l.slave_id, channelId: l.channel }),
        scene_name: _getScene(l.scene_id),
        user_name: _getUser(l.user),
        ambient_name: _getAmbient(l.ambient),
        timestamp: moment(l.timestamp).format('DD/MM/YYYY HH:mm:ss'),
        value: _getValue(_getChannel({ slaveId: l.slave_id, channelId: l.channel }), l.value)
      }
    })

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (action && action.items.type === 'update') {
      // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
      const lastLogs = yield select(({ logs }) => logs.logs)
      const newPayload = [...lastLogs, ...output]
      const newStatus = newPayload.length === 0 ? gettext('No logs found...') : ''

      yield put(LogsActions.getLogsRequestSuccess(newPayload, {
        status: newStatus
      }))
    } else {
      const newStatus = output.length === 0 ? gettext('No logs found...') : ''

      yield put(LogsActions.getLogsRequestSuccess(output, {
        status: newStatus
      }))
    }
  } else {
    yield put(LogsActions.getLogsRequestFailure())
  }
}

function _getValue (device: any, value: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (STATUS_MAP[get(device, 'type')]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return STATUS_MAP[device.type](value)
  }

  return null
}

function _getRawType (type: any) {
  switch (type) {
    case 'user_action':
      return 'user_action'
    case 'manual_action':
      return 'manual_action'
    case 'binary_sensor':
      return 'binary_sensor'
  }
}

function _getType (type: any) {
  switch (type) {
    case 'user_action':
      return gettext('User Action')
    case 'manual_action':
      return gettext('Manual Action')
    case 'binary_sensor':
      return gettext('Sensor Activate')
  }
}

function _getActionType (type: any) {
  switch (type) {
    case 'user_action':
      return gettext('User Action')
    case 'manual_action':
      return gettext('Manual Action')
    case 'binary_sensor':
      return gettext('Sensor Activate')
    case 'transmit':
      return gettext('IR/RF command')
    case 'activate_channel':
      return gettext('Device Activate')
    case 'activate_scene':
      return gettext('Scene Activate')
    case 'create_scene':
      return gettext('Create Scene')
    case 'create_channel':
      return gettext('Create Channel')
    case 'create_slave':
      return gettext('Create Slave')
    case 'create_ambient':
      return gettext('Create Ambient')
    case 'create_schedule':
      return gettext('Create Schedule')
    case 'update_scene':
      return gettext('Update Scene')
    case 'update_channel':
      return gettext('Update Channel')
    case 'update_slave':
      return gettext('Update Slave')
    case 'update_ambient':
      return gettext('Update Ambient')
    case 'update_schedule':
      return gettext('Update Schedule')
    case 'delete_scene':
      return gettext('Delete Scene')
    case 'delete_channel':
      return gettext('Delete Channel')
    case 'delete_slave':
      return gettext('Delete Slave')
    case 'delete_ambient':
      return gettext('Delete Ambient')
    case 'delete_schedule':
      return gettext('Delete Schedule')
  }
}

function _checkAll (array: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (array.some((u: any) => u.value === 'all') || array.length === 0) {
    return true
  } else {
    return false
  }
}
