import React from 'react'
import Icon from './Icon'
import { colors } from '#app/theme/colors'

const SlaveIcon = ({ type }: { type: string }) => <Icon name={getIconName(type)} size={20} colorIcon={colors.white} colorBg={colors.purple} />

export default SlaveIcon

const getIconName = (type: string) => {
  switch (type) {
    case 'light_switch':
      return 'light-switch'
    case 'infrared':
      return 'remote'
    case 'outlet':
      return 'dip-switch'
    case 'three_phase_sensor':
      return 'alpha-t-box-outline'
    default:
      return 'memory'
    //   return 'access-point'
    //   return 'crop-square'
  }
}
