import React, { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { SectionList, SectionListRenderItem, StyleSheet, Text, View } from 'react-native'
import { Separator, TextList, ItemPress, Right, SlaveIcon } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import Icon from '../components/Icon'
import { t, ngettext, msgid } from 'ttag'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import {
  getSlave,
  selectSlavesByTypeForSectionList,
  selectSlavesByAmbientForSectionList,
  selectSlaveSummary
} from '#app/slave'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'
import SegmentedControl from '@react-native-segmented-control/segmented-control'

const SlaveList = () => {
  const theme = useAppTheme()
  const slavesByAmbient = useAppSelector(selectSlavesByAmbientForSectionList)
  const slavesByType = useAppSelector(selectSlavesByTypeForSectionList)
  const loading = useAppSelector(state => state.slaves.loading)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const dispatch = useAppDispatch()
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
          <Item
            title={t`Add`}
            iconName="add"
            onPress={() => navigation.navigate('NewSlave')}
          />
        </HeaderButtons>
      )
    })
  }, [navigation])

  const onRefresh = useCallback(() => {
    dispatch({ type: 'DATA_GET_SLAVES_REQUEST' })
  }, [dispatch])

  return (
    <View style={{ maxHeight: '100%', flexBasis: 1, flexGrow: 1 }}>
      <SectionList
        ListHeaderComponent={
          <View style={[theme.form.formGroup, theme.m4]}>
            <SegmentedControl
              values={[t`Ambient`, t`Type`]}
              selectedIndex={selectedIndex}
              onChange={(event) => setSelectedIndex(event.nativeEvent.selectedSegmentIndex)}
            />
          </View>
        }
        renderSectionHeader={({ section: { title, data } }) => {
          const n = data.length
          return <Separator text={title} detail={ngettext(msgid`${n} product`, `${n} products`, n)} />
        }}
        renderItem={renderItem}
        sections={selectedIndex !== 0
          ? slavesByType
          : slavesByAmbient}
        contentContainerStyle={{ paddingBottom: insets.bottom }}
        refreshing={loading}
        onRefresh={onRefresh}
        ListFooterComponent={ListFooterComponent}
      />
    </View>
  )
}

export default SlaveList

const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={28} />

const formatTotal = (n: number) => ngettext(msgid`${n} product paired.`, `${n} products paired.`, n)
const formatOffline = (n: number) => ngettext(msgid`${n} product is offline.`, `${n} products are offline.`, n)
const formatBad = (n: number) => ngettext(msgid`${n} product has weak connectivity.`, `${n} products have weak connectivity.`, n)

const ListFooterComponent = () => {
  const theme = useAppTheme()
  const { total, offline, bad } = useAppSelector(selectSlaveSummary)
  const text = [
    formatTotal(total),
    ...(offline > 0
      ? [formatOffline(offline)]
      : []),
    ...(bad > 0
      ? ['\n', formatBad(bad)]
      : [])
  ].join(' ')

  return (
    <View style={[theme.form.area, theme.m4]}>
      <Text style={styles.summaryText}>{text}</Text>
    </View>
  )
}

const ListItem = ({ id }: ListItemProps) => {
  const navigation = useNavigation()
  const theme = useAppTheme()
  const { type, name } = useAppSelector(state => getSlave(state, id))
  const setSlaveId = useCallback(() => {
    navigation.navigate('SlaveDetail', { id: String(id) })
  }, [navigation, id])

  return (
    <ItemPress onPress={setSlaveId}>
      <SlaveIcon type={type}/>
      <TextList text={name}/>
      <Right>
        <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
      </Right>
    </ItemPress>
  )
}

const renderItem: SectionListRenderItem<ItemData> = ({ item }) => <ListItem id={item.id} />

interface ItemData {
  key: string
  id: number
}
type ListItemProps = Omit<ItemData, 'key'>

const styles = StyleSheet.create({
  summaryText: {
    fontWeight: 'bold',
    textAlign: 'center'
  }
})
