import { useNavigation } from '@react-navigation/native'
import { useEffect } from 'react'
import { hideMessage, showMessage } from 'react-native-flash-message'
import { t } from 'ttag'
import { useDebounce } from 'usehooks-ts'
import { useAppSelector } from './store'

export const useCentralConnectivityStatus = () => {
  const wsDisconnected = useDebounce(useAppSelector(state => state.ws.status === 'disconnected'))
  const navigation = useNavigation()
  useEffect(() => {
    if (wsDisconnected) {
      showMessage({
        hideOnPress: false,
        autoHide: false,
        message: t`Connecting...`,
        type: 'warning',
        onPress: () => navigation.navigate('FavoritesTab')
      })
    } else {
      hideMessage()
    }
  }, [navigation, wsDisconnected])
  useEffect(() => {
    return () => {
      hideMessage()
    }
  }, [])
}
