import { AnyAction, combineReducers } from 'redux'
import { all, call, put, select, takeEvery } from 'typed-redux-saga'
import { createSelector } from 'reselect'
import { createTable, createAction, mustSelectEntity, PropId } from 'robodux'
import { batchActions } from 'redux-batched-actions'
import { normalize } from 'normalizr'
import { filter, isEqual, orderBy, reduce } from 'lodash'
import { userLogout } from '#app/actions'
import {
  AddAmbientFormData,
  Ambient,
  AmbientFormData,
  AmbientPermission,
  State,
  User
} from '#app/types'
import {
  getAmbientsResponse,
  getAmbientPermissionsResponse,
  updateAmbientPermissionsResponse,
  addAmbientResponse,
  updateAmbientResponse
} from '#app/schema'
import { selectApi } from '#app/api'
import { setLoaderStart, setLoaderError, setLoaderSuccess } from '#app/loader'
import createCachedSelector from 're-reselect'
import { getAllScenes } from '#app/scene'
import { moreTableSelectors } from '#app/util'
import { shallowEqual } from 'react-redux'
import { PickerItem } from '#app/picker'
import { resetCurrentCentral } from '#app/central'
import { t } from 'ttag'
import SlavesAction from '#app/redux/slaves'

const defaultAmbient: Ambient = {
  id: '',
  name: '',
  order: 0,
  slaves: []
}
const createAmbientSelector = mustSelectEntity(defaultAmbient)

const ambients = createTable<Ambient>({ name: 'ambients' })
const permissions = createTable<AmbientPermission>({ name: 'permissions' })

export const {
  add: addAmbients,
  set: setAmbients,
  remove: removeAmbients,
  reset: resetAmbients,
  patch: patchAmbients,
  merge: mergeAmbients
} = ambients.actions
export const {
  add: addAmbientPermissions,
  set: setAmbientPermissions,
  remove: removeAmbientPermissions,
  reset: resetAmbientPermissions,
  patch: patchAmbientPermissions
} = permissions.actions

export const reducer = combineReducers({
  ambients: ambients.reducer,
  permissions: permissions.reducer
})

export const {
  selectTable: selectAmbients,
  selectTableAsList: selectAmbientsAsList,
  selectById: selectAmbientById
} = ambients.getSelectors<State>(state => state.ambient.ambients)
export const {
  selectByProps: selectAmbientsByProps
} = moreTableSelectors<Ambient, State>(state => state.ambient.ambients)
export const selectAmbientByIdDefaulting = createAmbientSelector(selectAmbientById)
export const {
  selectByProps: selectAmbientPermissionsByProps
} = moreTableSelectors<AmbientPermission, State>(state => state.ambient.permissions)
export const selectAmbientIds = createSelector(
  [selectAmbientsAsList],
  as => as.map(a => a.id)
)
export const selectAmbientsAsOrderedList = createSelector(
  [selectAmbientsAsList],
  as => orderBy(as, ['order'], ['asc'])
)
export const selectAmbientsAsNameRecords = createSelector(
  [selectAmbientsAsOrderedList],
  as => as.reduce<Record<string, string>>(
    (acc, a) => ({
      ...acc,
      ...(acc[a.name] != null
        ? { [a.name + String(a.id)]: a.id }
        : { [a.name]: a.id }
      )
    }),
    {}
  )
)
export const searchByNameAmbientIds = createSelector(
  selectAmbientsAsNameRecords,
  (state: State, term: string) => term,
  (as, term) => reduce<typeof as, string[]>(
    as,
    (res, id, name) => name.toLowerCase().includes(term.toLowerCase())
      ? [...res, id]
      : res,
    []
  )
)
export const selectAmbientsPositions = createSelector(
  [selectAmbientsAsOrderedList],
  as => as.map(
    ({ name, id, order }, idx) => ({
      name,
      id,
      key: id,
      order: order === null ? idx : order
    })
  )
)
export const selectAmbientIdsAsOrderedList = createSelector(
  [selectAmbientsAsOrderedList],
  as => as.map(a => a.id)
)
export const selectFavoriteAmbientIds = createSelector(
  selectAmbientIds,
  (state: State) => state.favorites.favorites.ambients,
  (as, favorites) => filter(favorites, f => as.includes(f))
)
export const selectAmbientDeviceCount = createCachedSelector(
  selectAmbientByIdDefaulting,
  ({ slaves }) => slaves.length
)(
  (state, { id }) => id
)
export const selectAmbientScenes = createCachedSelector(
  getAllScenes,
  (state: State, p: PropId) => p,
  (scenes, { id }) => scenes.reduce<number[]>(
    (acc, s) => String(s.ambientId) === id ? [...acc, s.id] : acc,
    []
  )
)(
  (state, { id }) => id
)
export const selectAmbientData = createCachedSelector(
  selectAmbientByIdDefaulting,
  selectAmbientDeviceCount,
  ({ id, name, image }, devicesCount) => ({
    id,
    name,
    devicesCount,
    image
  })
)(
  (state, { id }) => id
)
export const selectAmbientsHavingSlavesAsList = createSelector(
  [selectAmbientsAsList],
  as => as.filter(a => a.slaves.length > 0)
)
export const selectAmbientsForPicker = createSelector(
  selectAmbientsAsList,
  (as): PickerItem[] => as.map(({ id, name: title }) => ({ id, title })),
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual
    }
  }
)
export const selectAllowedAmbientIdsByUserId = createSelector(
  (state: State, { id }: PropId) => selectAmbientPermissionsByProps(state, { userId: id }),
  ap => ap.map(a => a.ambientId),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual
    }
  }
)
export const selectAmbientPermissionIdsByUserId = createSelector(
  (state: State, { id }: PropId) => selectAmbientPermissionsByProps(state, { userId: id }),
  ap => ap.map(a => a.id),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual
    }
  }
)
export const selectSlaveAmbientIds = createSelector(
  (state: State, { id }: PropId) => selectAmbientsByProps(state, { slaves: [Number(id)] }),
  as => as.map(a => a.id)
)

export const fetchAmbients = createAction('FETCH_AMBIENTS')
export const addAmbient = createAction<{ data: AddAmbientFormData }>('ADD_AMBIENT')
export const removeAmbient = createAction<{ id: Ambient['id']}>('REMOVE_AMBIENT')
export const updateAmbient = createAction<{ data: AmbientFormData}>('UPDATE_AMBIENT')
export const fetchAmbientPermissionsRequest = createAction<{id?: User['id']}>('FETCH_AMBIENT_PERMISSIONS_REQUEST')
export const fetchAmbientPermissionsError = createAction<{id?: User['id']}>('FETCH_AMBIENT_PERMISSIONS_ERROR')
export const fetchAmbientPermissionsSuccess = createAction<{id?: User['id']}>('FETCH_AMBIENT_PERMISSIONS_SUCCESS')
export const updateAmbientPermissionsRequest = createAction<{id: User['id'], ambientIds: Array<Ambient['id']>}>('UPDATE_AMBIENT_PERMISSIONS_REQUEST')
export const updateAmbientPermissionsError = createAction<{id: User['id']}>('UPDATE_AMBIENT_PERMISSIONS_ERROR')
export const updateAmbientPermissionsSuccess = createAction<{id: User['id']}>('UPDATE_AMBIENT_PERMISSIONS_SUCCESS')

function * onFetchAmbients (action: ReturnType<typeof fetchAmbients>) {
  yield * put(setLoaderStart({ id: 'ambients' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.getAmbients)
  if (!res.ok) {
    yield * put(setLoaderError({ id: 'ambients' }))
    return
  }
  const { entities } = normalize(res.data, getAmbientsResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'ambients':
        return [...acc, setAmbients(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield * put(batchActions([...actions, setLoaderSuccess({ id: 'ambients' })]))
}

const getErrorMessage = (data?: any) => {
  const errStr = String(data?.err ?? data?.message)
  switch (errStr) {
    case '':
    case 'undefined':
      return t`An unknown error has occurred`
    default:
      return errStr
  }
}

export function * onAddAmbient ({ payload: { data } }: ReturnType<typeof addAmbient>) {
  yield * put(setLoaderStart({ id: 'ambient/add' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.addAmbient, data)
  if (!res.ok || res.data == null) {
    const message = getErrorMessage(res.data)
    yield * put(setLoaderError({ id: 'ambient/add', message }))
    return
  }
  const id = String(res.data.id)
  const { entities } = normalize(res.data, addAmbientResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'ambients':
        return [...acc, addAmbients(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield put(batchActions([...actions, setLoaderSuccess({ id: 'ambient/add', meta: { id } })]))
}

export function * onRemoveAmbient ({ payload: { id } }: ReturnType<typeof removeAmbient>) {
  yield * put(setLoaderStart({ id: 'ambient/remove' }))
  const api = yield * select(selectApi)
  const res = yield * call(api.deleteAmbient, id)
  if (!res.ok && res.data?.status !== 404) {
    const message = getErrorMessage(res.data)
    yield * put(setLoaderError({ id: 'ambient/remove', message }))
    return
  }
  yield put(batchActions([
    removeAmbients([id]),
    setLoaderSuccess({ id: 'ambient/remove' })
  ]))
}

function * onUpdateAmbient ({ payload: { data } }: ReturnType<typeof updateAmbient>) {
  yield * put(setLoaderStart({ id: 'ambient/update' }))
  const api = yield * select(selectApi)
  // @ts-expect-error: TODO: de-normalize object
  const res = yield * call(api.updateAmbient, data)
  if (!res.ok || res.data == null) {
    const message = getErrorMessage(res.data)
    yield * put(setLoaderError({ id: 'ambient/update', message }))
    return
  }
  const fakeNews = !('slaves' in res.data)
  const { entities } = normalize(
    fakeNews
      ? { ...res.data, slaves: data.slaves }
      : res.data
    , updateAmbientResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'ambients':
        return [...acc, patchAmbients(v)]
      // TODO: update slaves slice
      case 'slaves':
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield * put(batchActions([
    ...actions,
    ...(fakeNews
      ? [SlavesAction.dataGetSlavesRequest()]
      : []),
    setLoaderSuccess({ id: 'ambient/update' })
  ]))
}

function * onUserLogout (action: ReturnType<typeof userLogout>) {
  yield put(batchActions([
    resetAmbients(),
    resetAmbientPermissions()
  ]))
}

function * onFetchAmbientPermissionsRequest ({ payload }: ReturnType<typeof fetchAmbientPermissionsRequest>) {
  const api = yield * select(selectApi)
  const res = yield * call(api.getAmbientPermissions, payload?.id)
  if (!res.ok) {
    yield * put(fetchAmbientPermissionsError(payload))
    return
  }
  const { entities } = normalize(res.data, getAmbientPermissionsResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'ambients':
        return [...acc, mergeAmbients(v)]
      case 'ambientpermissions':
        return [...acc, addAmbientPermissions(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  yield * put(batchActions([...actions, fetchAmbientPermissionsSuccess(payload)]))
}

function * onUpdateAmbientPermissionsRequest ({ payload: { id, ambientIds } }: ReturnType<typeof updateAmbientPermissionsRequest>) {
  const api = yield * select(selectApi)
  const res = yield * call(api.updateUserAmbientsPermissions, id, ambientIds.map(a => Number(a)))
  if (!res.ok) {
    yield * put(updateAmbientPermissionsError({ id }))
    return
  }
  const { entities } = normalize(res.data, updateAmbientPermissionsResponse)
  const actions = reduce(entities, (acc, v: any, k) => {
    switch (k) {
      case 'ambientpermissions':
        return [...acc, addAmbientPermissions(v)]
      default:
        return acc
    }
  }, [] as AnyAction[])
  const old = yield * select(state => selectAmbientPermissionIdsByUserId(state, { id }))
  yield * put(batchActions([
    ...actions,
    removeAmbientPermissions(old),
    updateAmbientPermissionsSuccess({ id })
  ]))
}

export function * saga () {
  yield all([
    takeEvery(fetchAmbients, onFetchAmbients),
    takeEvery(addAmbient, onAddAmbient),
    takeEvery(removeAmbient, onRemoveAmbient),
    takeEvery(updateAmbient, onUpdateAmbient),
    takeEvery(fetchAmbientPermissionsRequest, onFetchAmbientPermissionsRequest),
    takeEvery(updateAmbientPermissionsRequest, onUpdateAmbientPermissionsRequest),
    takeEvery([userLogout, resetCurrentCentral], onUserLogout)
  ])
}
