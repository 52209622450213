import { Alert, Platform } from 'react-native'

// TODO: workaround for https://github.com/necolas/react-native-web/issues/1026
const alertPolyfill = (title: any, description: any, options: any, extra: any) => {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'window'.
  const result = window.confirm([title, description].filter(Boolean).join('\n'))
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (result) {
    const confirmOption = options.find(({
      style
    }: any) => style !== 'destructive')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-optional-chain
    confirmOption && confirmOption.onPress()
  }
}

const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert

export default alert
