import { call } from 'typed-redux-saga'
import { getLocales, Locale, findBestAvailableLanguage } from 'react-native-localize'
import { addLocale, useLocales } from 'ttag'
import { reduce, each } from 'lodash'
import locales from '../locale'

const availableLocales = Object.keys(locales)

export default function * i18nRootSaga () {
  yield * call(loadLocales)
  const needsLocalization = yield * call(checkNeedsLocalization)
  if (!needsLocalization) return
  const preferredLocales = yield * call(getPreferredLocales)
  yield * call(useLocales, preferredLocales)
}

const loadLocales = () => each(locales, (data, locale) => addLocale(locale, data))

const getPreferredLocales = () => reduce<Locale, string[]>(getLocales(),
  (acc, l) => [
    ...acc,
    ...(availableLocales.includes(l.languageTag)
      ? [l.languageTag]
      : availableLocales.includes(l.languageCode) ? [l.languageCode] : [])
  ], [])

const checkNeedsLocalization = () => findBestAvailableLanguage(['en', ...availableLocales])?.languageTag !== 'en'
