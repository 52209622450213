import { selectApi } from '#app/api'
import { select, put, call } from 'redux-saga/effects'
import AlertsActions from '../redux/alerts'

export function * getAlertsHistory () {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getAlertsHistory)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(AlertsActions.alertsGetAlertsHistorySuccess(response.data))
    } else {
      yield put(AlertsActions.alertsGetAlertsHistoryFailure())
    }
  } catch (e) {
    yield put(AlertsActions.alertsGetAlertsHistoryFailure())
  }
}
