import React, { useCallback, useEffect } from 'react'
import { Text, View, FlatList, ListRenderItemInfo, Platform, StyleSheet } from 'react-native'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { ScreenProps } from '#app/types'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { fetchCentralUsers, searchCentralUsers, selectCentralUsersListSearch, removeCentralUserIds } from '#app/centraluser'
import { Left, ItemPress, Right } from '#app/components/List'
import { useAppTheme } from '#app/theme'
import { selectLoaderById } from '#app/loader'
import { HeaderButtons, HeaderButton, Item as HeaderItem } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import Icon from '../components/Icon'
import Search from '../components/Search'

const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={28} />

const CentralUsers = ({ navigation, route: { params: { id } } }: ScreenProps<'CentralUsers'>) => {
  const dispatch = useAppDispatch()
  const data = useAppSelector(state => selectCentralUsersListSearch(state, { id }))
  const { isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'centralusers' }))
  const onRefresh = useCallback(() => {
    dispatch(fetchCentralUsers({ id }))
  }, [dispatch, id])
  useFocusEffect(onRefresh)
  useEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
          <HeaderItem
            title={t`Add`}
            iconName="add"
            onPress={() => navigation.navigate('AddCentralUser', { id })}
          />
        </HeaderButtons>
      )
    })
  }, [navigation, id])
  useEffect(() => {
    dispatch(removeCentralUserIds([id]))
    navigation.setOptions({
      headerSearchBarOptions: {
        onChangeText: event => dispatch(searchCentralUsers({ id, term: event.nativeEvent.text })),
        onCancelButtonPress: () => dispatch(removeCentralUserIds([id]))
      }
    })
    return () => {
      dispatch(removeCentralUserIds([id]))
    }
  }, [navigation, dispatch, id])
  const theme = useAppTheme()
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      refreshing={isLoading}
      onRefresh={onRefresh}
      ListHeaderComponent={Platform.OS === 'web'
        ? <Search
            placeholder={t`Search`}
            placeholderTextColor={theme.formForm.placeholderTextColor}
            style={[styles.searchBox, { backgroundColor: theme.formForm.search }]}
            valueTextColor={theme.textColor}
            onChangeText={(term: string) => dispatch(searchCentralUsers({ id, term }))}
            onTrashPress={() => dispatch(removeCentralUserIds([id]))}
            iconColor={theme.formForm.searchIconColor}/>
        : undefined}
      contentInsetAdjustmentBehavior="automatic"
    />
  )
}

export default CentralUsers

const renderItem = ({ item }: ListRenderItemInfo<ListItem>) => <Item {...item} />

const Item = React.memo(({ id, title, subtitle }: ListItem) => {
  const theme = useAppTheme()
  const navigation = useNavigation()
  const onPress = useCallback(() => {
    navigation.navigate('EditCentralUser', { id })
  }, [navigation, id])
  return (
    <ItemPress onPress={onPress}>
      <Left style={{ flexDirection: 'column' }}>
        <Text style={{ color: theme.textColor }}>{title}</Text>
        <Text style={{ color: theme.textColor }}>{subtitle}</Text>
      </Left>
      <Right>
        <View style={{ flexDirection: 'row' }}>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
        </View>
      </Right>
    </ItemPress>
  )
})

const styles = StyleSheet.create({
  searchBox: {
    flexDirection: 'row',
    paddingLeft: 10,
    paddingRight: 10,
    height: 40
  }
})

interface ListItem {
  id: string
  title: string
  subtitle: string
}
