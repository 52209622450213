import { select, put, call } from 'redux-saga/effects'
import AlarmsActions from '../redux/alarms'
import { map } from 'lodash'
import * as RootNavigation from '../navigation/RootNavigation'
import { resetCurrentCentral, selectCurrentCentralId, setCurrentCentral } from '#app/central'
import { selectApi } from '#app/api'

export function * getAlarms () {
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const currentCentral = yield select(state => state.central.currentCentral)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getAlarms, currentCentral)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(AlarmsActions.alarmsGetRequestSuccess(response.data))
    } else {
      yield put(AlarmsActions.alarmsGetRequestFailure())
    }
  } catch (e) {
    yield put(AlarmsActions.alarmsGetRequestFailure())
  }
}

export function * deleteAlarm (action: any) {
  const { payload } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.deleteAlarm, payload.id)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(AlarmsActions.alarmsDeleteRequestSuccess())
      yield put(AlarmsActions.alarmsGetRequest())
      RootNavigation.pop()
    } else {
      yield put(AlarmsActions.alarmsDeleteRequestFailure())
    }
  } catch (e) {
    yield put(AlarmsActions.alarmsDeleteRequestFailure())
  }
}

function alarmPayload (payload: any) {
  return {
    type: payload.type,
    slave_id: payload.slave,
    name: payload.name,
    channel_id: payload.channel_id,
    extra: {
      channel: payload.channel,
      value: payload.value,
      users: payload.users,
      alarms: map(payload.alarmList, (item) => {
        return {
          week_days: item.weekDays,
          start_hour: item.startHour,
          offset_minutes: item.offsetMinutes,
          start_minute: item.startMinute,
          disabled: item.disabled
        }
      })
    }
  }
}

export function * updateAlarm (action: any) {
  const { payload } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  const data = alarmPayload(payload)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.updateAlarm, payload.id, data)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(AlarmsActions.alarmsUpdateRequestSuccess())
      yield put(AlarmsActions.alarmsGetRequest())
      RootNavigation.navigate('AlarmList')
    } else {
      yield put(AlarmsActions.alarmsUpdateRequestFailure())
    }
  } catch (e) {
    yield put(AlarmsActions.alarmsUpdateRequestFailure())
  }
}

export function * saveAlarm (action: any) {
  const { payload } = action
  // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
  const api = yield select(selectApi)

  const data = alarmPayload(payload)

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.createAlarm, data)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(AlarmsActions.alarmsSaveRequestSuccess())
      yield put(AlarmsActions.alarmsGetRequest())
      RootNavigation.navigate('AlarmList')
    } else {
      yield put(AlarmsActions.alarmsSaveRequestFailure())
    }
  } catch (e) {
    yield put(AlarmsActions.alarmsSaveRequestFailure())
  }
}

export function * onClickAlarmNotification ({ payload }: any) {
  const { centralUUID } = payload
  const currentCentralId: ReturnType<typeof selectCurrentCentralId> = yield select(selectCurrentCentralId)
  if (currentCentralId !== centralUUID) {
    yield put(resetCurrentCentral())
    yield put(setCurrentCentral(centralUUID))
  }
  RootNavigation.navigate('Main', { screen: 'NotificationsTab' })
}
