import React, { useState, useEffect, useCallback } from 'react'
import { View, StyleSheet, Text, Platform, Button } from 'react-native'
import AmbientList from '../components/AmbientList'
import { useAppDispatch, useAppSelector } from '../hooks'
import Search from '../components/Search'
import { useFocusEffect } from '@react-navigation/native'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import Icon from '../components/Icon'
import { t } from 'ttag'
import { selectCan } from '#app/centraluser'
import { RootStackParamList } from '../routes'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import DraggableAmbientList from '../components/DraggableAmbientList'
import { useAppTheme } from '#app/theme'
import AmbientsActions from '../redux/ambients'
import { map } from 'lodash'
import { selectAmbientsPositions, searchByNameAmbientIds, fetchAmbients } from '#app/ambient'
import { selectLoaderById } from '#app/loader'

const HeaderIconButton = (passMeFurther: any) => <HeaderButton {...passMeFurther} IconComponent={Icon} iconSize={28} />

const Ambients = ({ navigation, route }: Props) => {
  const { editing } = route.params ?? {}
  const dispatch = useAppDispatch()

  const theme = useAppTheme()
  const can = useAppSelector(selectCan)
  const canEdit = can('edit')
  const { isLoading, isError } = useAppSelector(state => selectLoaderById(state, { id: 'ambients' }))
  const oldData = useAppSelector(selectAmbientsPositions)
  const [searchTerm, setSearchTerm] = useState('')
  useFocusEffect(useCallback(() => () => setSearchTerm(''), [setSearchTerm]))
  const searchResults = useAppSelector(state => searchByNameAmbientIds(state, searchTerm))
  const [newData, setNewData] = useState<typeof oldData>([])

  const onPressEdit = useCallback(() => {
    setNewData(oldData)
    navigation.setParams({ editing: true })
  }, [navigation, oldData])

  const onDragRelease = useCallback((data: any) => {
    setNewData(map(data, (a: any, idx: number) => ({ ...a, order: idx })))
  }, [])

  const saveAmbientPosition = useCallback(() => {
    dispatch(AmbientsActions.updatePositionRequest(newData))
    navigation.setParams({ editing: false })
  }, [dispatch, navigation, newData])

  useEffect(() => {
    if (editing === true) {
      navigation.setOptions({
        headerLeft: Platform.select({
          default: () => (<HeaderButtons HeaderButtonComponent={HeaderIconButton}>
                          <Item
                            title={t`Close`}
                            iconName="close"
                            onPress={() => navigation.goBack()}
                          />
                      </HeaderButtons>),
          ios: () => <></>
        }),
        headerRight: () =>
          (<HeaderButtons HeaderButtonComponent={HeaderIconButton}>
          {Platform.select({
            default: <Item
                title={t`OK`}
                iconName="save"
                onPress={saveAmbientPosition}
              />,
            ios: <Item title={t`OK`} onPress={saveAmbientPosition} />
          })
          }
          </HeaderButtons>)
      })
    } else {
      navigation.setOptions({
        headerLeft: () => canEdit && (
          <HeaderButtons HeaderButtonComponent={HeaderIconButton}>
            <Item
              title={t`Add`}
              iconName="add"
              onPress={() => navigation.navigate('Ambient', { typeForm: 'wizard' })}
            />
          </HeaderButtons>
        ),
        headerRight: () => canEdit && Platform.select({
          default: (<HeaderButtons HeaderButtonComponent={HeaderIconButton}>
                    <Item
                      title={t`Edit`}
                      iconName="pencil"
                      onPress={onPressEdit}
                    />
                  </HeaderButtons>),
          ios: <HeaderButtons><Item title={t`Edit`} onPress={onPressEdit} /></HeaderButtons>
        })
      })
    }
  }, [navigation, theme, canEdit, onPressEdit, saveAmbientPosition, editing])

  const onRefresh = useCallback(() => {
    dispatch(fetchAmbients())
  }, [dispatch])

  const onTrashPress = useCallback(() => {
    setSearchTerm('')
  }, [])

  if (editing === true) {
    return <DraggableAmbientList onDragRelease={onDragRelease} data={newData} />
  }

  return (
    <>
            <View style={styles.searchWrapper}>
              <Search
                placeholder={t`Search ambients...`}
                placeholderTextColor={theme.formForm.placeholderTextColor}
                style={[styles.searchBox, { backgroundColor: theme.formForm.search }]}
                value={searchTerm}
                valueTextColor={theme.textColor}
                onChangeText={setSearchTerm}
                onTrashPress={onTrashPress}
                iconColor={theme.formForm.searchIconColor}
              />
            </View>
            <AmbientList data={searchResults} onRefresh={onRefresh} refreshing={isLoading} ListEmptyComponent={isError
              ? ListErrorComponent
              : ListEmptyComponent}
            />
  </>
  )
}

export default Ambients

const ListErrorComponent = () => {
  const theme = useAppTheme()
  const dispatch = useAppDispatch()
  const onRefresh = useCallback(() => {
    dispatch({ type: 'APP_LOAD_DATA' })
  }, [dispatch])
  return (
    <View style={[theme.form.area, { alignItems: 'center' }]}>
      <Text style={{ color: theme.textColor, alignItems: 'center' }}>
        {t`Unable to fetch ambients`}
      </Text>
      <Button onPress={onRefresh} title={t`Try again`} />
    </View>
  )
}

const ListEmptyComponent = () => {
  const theme = useAppTheme()
  const { isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'ambients' }))
  if (isLoading) {
    return <></>
  }
  return (
    <View style={[theme.form.area, { alignItems: 'center' }]}>
      <Text style={{ color: theme.textColor, alignItems: 'center' }}>{t`No ambient found.`}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  searchBox: {
    flexDirection: 'row',
    borderRadius: 15,
    paddingLeft: 10,
    paddingRight: 10,
    height: 40
  },
  searchWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    paddingTop: 7.5,
    paddingBottom: 7.5
  }
})

type Props = NativeStackScreenProps<RootStackParamList, 'Ambients'>
