import React from 'react'
import { Text, View } from 'react-native'
import { useAppTheme } from '#app/theme'
import { SafeAreaView } from 'react-native-safe-area-context'

interface Props {
  text: string
  detail?: string
}

const Separator = ({ text, detail }: Props) => {
  const theme = useAppTheme().list

  return (
    <View style={theme.separator}>
      <SafeAreaView edges={['left', 'right']}>
        <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={theme.separatorText}>
            {text}
          </Text>
          {detail != null && (
            <Text style={[theme.separatorText, { fontWeight: 'normal' }]}>
              {detail}
            </Text>
          )}
        </View>
      </SafeAreaView>
    </View>
  )
}

export default Separator
