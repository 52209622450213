import React, { useState, useCallback, useEffect } from 'react'
import { StyleSheet, Switch, Text, View, TouchableOpacity } from 'react-native'
import { TextInputMask } from 'react-native-masked-text'
import { isUndefined } from 'lodash'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { t } from 'ttag'

function MyioAlarm ({
  index,
  item,
  toggleDay,
  toggleSwitch,
  removeAlarm,
  onTimeChange,
  theme
}: any) {
  const [startTime, setStartTime] = useState('00:00')
  const [endTime, setEndTime] = useState('23:59')
  const [startRef, setStartRef] = useState(null)
  const [endRef, setEndRef] = useState(null)
  const [days] = useState([{ value: 0, label: 'S' }, { value: 1, label: 'T' }, { value: 2, label: 'Q' }, { value: 3, label: 'Q' }, { value: 4, label: 'S' }, { value: 5, label: 'S' }, { value: 6, label: 'D' }])

  useEffect(() => {
    onEndInputChange(endTime)
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [startTime, endTime])

  const onEndInputChange = useCallback((text) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-optional-chain
    if (startTime && endTime && (startRef && startRef.isValid()) && (endRef && endRef.isValid()) && startTime.length === 5 && endTime.length === 5) {
      const startTimeUTC = getUTCHours(startTime)
      const [startHoursUTC, startMinutesUTC] = startTimeUTC.split(':')

      onTimeChange(index, {
        startHour: parseInt(startHoursUTC, 10),
        startMinute: parseInt(startMinutesUTC, 10),
        offsetMinutes: calculateMinuteOffset(startTime, text)
      })
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [startTime, endTime])

  useEffect(() => {
    if (!isUndefined(item.startHour) && !isUndefined(item.startMinute)) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const parsedStartTime = `${item.startHour}`.padStart(2, '0') + ':' + `${item.startMinute}`.padStart(2, '0')

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const parsedOffsetMinutes = item.offsetMinutes || 0
      const startDateTime = new Date(`2020-01-01T${parsedStartTime}:00.000Z`)

      const startTimeHours = `${startDateTime.getHours()}`.padStart(2, '0')
      const startTimeMinutes = `${startDateTime.getMinutes()}`.padStart(2, '0')

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      const endTimeDate = new Date(startDateTime.setMinutes(startDateTime.getMinutes() + parsedOffsetMinutes))
      const endTimeHours = `${endTimeDate.getHours()}`.padStart(2, '0')
      const endTimeMinutes = `${endTimeDate.getMinutes()}`.padStart(2, '0')

      setStartTime(`${startTimeHours}:${startTimeMinutes}`)
      setEndTime(`${endTimeHours}:${endTimeMinutes}`)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [item.startHour])

  return (
    <View style={[theme.list.borderBottom, { marginBottom: 15 }]}>

      <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginBottom: 20 }}>
        <Text style={{ fontSize: 16, color: theme.textColor, justifyContent: 'center', alignSelf: 'center' }}>
          {t`Alarm`} #{index}
        </Text>

        <TouchableOpacity onPress={() => removeAlarm(index)}>
          <MaterialCommunityIcons name="trash-can" size={20} color={theme.textColor} />
        </TouchableOpacity>
      </View>

      <View style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
        <View style={{ justifyContent: 'flex-start', flexDirection: 'row' }}>
          <TextInputMask
            type="custom"
            placeholder="00:00"
            options={{ mask: '99:99' }}
            textAlign="center"
            value={startTime}
            onChangeText={setStartTime}
            style={[theme.form.input, { width: 100 }]}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            ref={setStartRef}
          />

          <Text style={{ fontSize: 28, color: theme.textColor, justifyContent: 'center', alignSelf: 'center' }}> - </Text>

          <TextInputMask
            type="custom"
            placeholder="00:00"
            options={{ mask: '99:99' }}
            textAlign="center"
            value={endTime}
            onChangeText={setEndTime}
            style={[theme.form.input, { width: 100 }]}
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            ref={setEndRef}
          />
        </View>

        <Switch
          style={{ alignSelf: 'center' }}
          onValueChange={(value) => toggleSwitch(!value, index)}
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          value={!item.disabled}
        />

      </View>

      <View style={styles.daysBox}>
        {
          days.map(d => (
            <TouchableOpacity
              key={d.value}
              // TODO: Fix this the next time the file is edited.
              // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
              style={[styles.daysCircle, theme.border, item.weekDays.includes(d.value) ? styles.daysCircleActivated : '']}
              onPress={() => toggleDay(d.value, index)}
            >
              <Text style={{ color: theme.textColor }}>{d.label}</Text>
            </TouchableOpacity>
          ))
        }
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  alarmBox: {
    borderBottomWidth: 1,
    borderBottomColor: 'rgba(255, 255, 255, 0.15)'
  },
  daysBox: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  daysCircle: {
    borderWidth: 2,
    width: 40,
    height: 40,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 100
  },
  daysCircleActivated: {
    backgroundColor: 'rgba(103, 58, 182, 0.55)'
  }
})

function timeToDateTime (time: any) {
  const [hours, minutes] = time.split(':')
  const dateTime = new Date()

  dateTime.setHours(parseInt(hours, 10))
  dateTime.setMinutes(parseInt(minutes, 10))

  return dateTime
}

function getUTCHours (time: any) {
  const parsedDateTimeUTC = timeToDateTime(time).toISOString()

  return parsedDateTimeUTC.split('T')[1].split('.')[0].substr(0, 5) // [00:00]:00
}

function sumDays (date: any, days: any) {
  const newDate = new Date(date)
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
  newDate.setDate(newDate.getDate() + days)

  return newDate
}

function calculateMinuteOffset (startTime: any, endTime: any) {
  const startDateTime = timeToDateTime(startTime)
  let endDateTime = timeToDateTime(endTime)

  /* We skipped a day because we are only dealing with
   * time. It's safe to sum one day if the end date is before
   * the start date. */
  if (endDateTime < startDateTime) endDateTime = sumDays(endDateTime, 1)

  // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  return Math.floor((((endDateTime - startDateTime) / 1000) / 60))
}

export default MyioAlarm
