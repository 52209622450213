import React, { useState, useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { FlatList, SectionList, RefreshControl } from 'react-native'
import { Separator } from '../components/List'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { map, find, get, groupBy } from 'lodash'
import { t, gettext } from 'ttag'
import Notification from '../components/Notification'
import { selectCan } from '#app/centraluser'
import { useNavigation } from '@react-navigation/native'

export default function Notifications () {
  // const STATUS = {
//   OPEN: gettext('Open'),
//   NEW: gettext('New'),
//   RESOLVED: gettext('Solved')
// }
  const loading = useAppSelector(state => state.alerts.loading)
  const alertsHistory = useAppSelector(state => state.alerts.alertsHistory)
  const slavesState = useAppSelector(state => state.slaves)
  const can = useAppSelector(selectCan)
  const canEdit = can('edit')

  const [data, setData] = useState([])

  const navigation = useNavigation()
  const dispatch = useAppDispatch()

  const onRefresh = useCallback(
    () => dispatch({
      type: 'ALERTS_GET_ALERTS_HISTORY'
    }), [dispatch]
  )

  useEffect(
    () => {
      const alertsGroups = groupBy(alertsHistory, (alert) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        const finalStatus = alert.viewed
          ? alert.status
          : (
              alert.status === gettext('Open') ? gettext('New') : gettext('Solved')
            )

        return finalStatus
      })

      const data = map(alertsGroups, (value, index) => {
        return {
          title: index,
          data: value
        }
      })

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ title: string; data: any[]; }[... Remove this comment to see the full error message
      setData(data)
    }, [alertsHistory]
  )

  const onRenderSectionHeader = ({
    section
  }: any) => {
    return (
      <Separator text={section.title} />
    )
  }

  const onRenderSection = ({
    section,
    index
  }: any) => {
    if (index !== 0) return null

    return (
      <FlatList
        data={section.data}
        renderItem={onRenderItem}
        keyExtractor={(item, index) => `${index}`}
      />
    )
  }

  const onRenderItem = ({
    item
  }: any) => {
    const slave = find(get(slavesState, 'allSlaves'), { id: item.slave_id })
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const channel = find(get(slave, 'channels_list'), { id: item.channel_id }) ||
    find(get(slave, 'channels_list'), { channel: item.channel_id }) // TODO: old payload compat

    return (
      <Notification
        type={item.type}
        status={item.status}
        slave={slave}
        channel={channel}
        viewed={item.viewed}
        duration={item.duration}
        date={item.date}
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        keyExtractor={(item: any) => `${item.id}`}
        onPress={() => { }}
      />
    )
  }

  useEffect(
    () => {
      navigation.setOptions({
        headerRight: () => canEdit && (
          <HeaderButtons>
            <Item
              title={t`Manage`}
              onPress={() => {
                navigation.navigate('AlarmList')
              }}
            />
          </HeaderButtons>
        )
      })

      dispatch({
        type: 'ALERTS_GET_ALERTS_HISTORY'
      })
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  return (
    <SectionList
      sections={data}
      renderItem={onRenderSection}
      renderSectionHeader={onRenderSectionHeader}
      refreshControl={
        <RefreshControl refreshing={loading} onRefresh={onRefresh} />
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      keyExtractor={item => `${item.id}`}
    />
  )
}
