
import { AnyAction } from 'redux'
import { Channel, channel } from 'redux-saga'
import { call, all, takeLeading, put, fork } from 'redux-saga/effects'
import { select, take } from 'typed-redux-saga'
import { t } from 'ttag'

import { actionSheetRef } from '../'
import { alert } from '../components/Utils'

import { getScene } from '#app/scene'
import { difference, map } from 'lodash'
import { removeAmbient, selectAmbientData, selectFavoriteAmbientIds } from '#app/ambient'
import { selectCan } from '#app/centraluser'
import * as RootNavigation from '#app/navigation/RootNavigation'

export function * uiAmbientLongpressResponder (c: RedirectChannel, { payload: { ambientId } }: AnyAction) {
  const showActionSheetWithOptions = actionSheetRef.current?.getContext().showActionSheetWithOptions
  if (showActionSheetWithOptions == null) return

  const { name } = yield * select(state => selectAmbientData(state, { id: ambientId }))

  const favorites = yield * select(selectFavoriteAmbientIds)
  const isFavorite = favorites.includes(ambientId)

  const can = yield * select(selectCan)
  const canEdit: boolean = yield call(can, 'edit')

  const options = [
    ...(canEdit
      ? [
          { text: t`Delete Ambient`, handler: onPressDeleteAmbient(c, ambientId, name) },
          {
            text: t`Edit Ambient`,
            handler: () => RootNavigation.navigate('AmbientDetail', { id: ambientId, editing: true })
          }
        ]
      : []),
    {
      text: isFavorite
        ? t`Remove from Favorites`
        : t`Add to Favorites`,
      handler: () => c.put({
        type: 'UPDATE_FAVORITE_DEVICE_LIST',
        payload: {
          type: 'ambients',
          ids: isFavorite
            ? difference(favorites, [ambientId])
            : [...favorites, ambientId]
        }
      })
    },
    { text: t`Cancel` }
  ]

  yield call(showActionSheetWithOptions,
    {
      cancelButtonIndex: options.length - 1,
      ...(canEdit && { destructiveButtonIndex: 0 }),
      options: map(options, (v) => v.text)
    },
    (idx) => options[idx]?.handler?.()
  )
}

function * uiScenePressResponder (c: RedirectChannel, { payload: { sceneId } }: AnyAction) {
  const triggerAction = { type: 'SCENE_EXECUTION_TRIGGER', payload: { id: sceneId } }
  const { name, config }: {name: string, config?: any} = yield * select(state => getScene(state, sceneId))
  const confirm: boolean = config?.confirm ?? false

  if (confirm) {
    alert(
      t`Execute scene "${name}"?`,
      undefined,
      [
        {
          text: t`No`,
          style: 'destructive'
        },
        { text: t`Yes`, onPress: () => c.put(triggerAction) }
      ],
      { cancelable: false }
    )
    return
  }

  yield put(triggerAction)
}

function * uiSceneLongpressResponder (c: RedirectChannel, { payload: { sceneId } }: AnyAction) {
  const scene = yield * select(state => getScene(state, sceneId))
  yield put({ type: 'DATA_SET_SELECTED_SCENE', data: { ...scene } })
}

function * watchRedirectChannel (c: RedirectChannel) {
  while (true) {
    const action = yield * take(c)
    yield put(action)
  }
}

export default function * uiRootSaga () {
  const c: RedirectChannel = yield call(channel)

  yield all([
    takeLeading('UI_AMBIENT_LONGPRESS', uiAmbientLongpressResponder, c),
    takeLeading('UI_SCENE_PRESS', uiScenePressResponder, c),
    takeLeading('UI_SCENE_LONGPRESS', uiSceneLongpressResponder, c),
    fork(watchRedirectChannel, c)
  ])
}

const onPressDeleteAmbient = (c: RedirectChannel, id: string, name: string) => () => {
  alert(
    t`Delete ambient "${name}"?`,
    t`This action cannot be undone`,
    [
      { text: t`Cancel`, style: 'destructive' },
      { text: t`Delete ambient`, onPress: () => c.put(removeAmbient({ id })) }
    ],
    { cancelable: false }
  )
}

type RedirectChannel = Channel<AnyAction>
