import React from 'react'
import GenericButton from '../GenericButton'
import { gettext } from 'ttag'

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof SmokeSensor.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onLongPress' does not exist on type 'Pro... Remove this comment to see the full error message
export default function SmokeSensor ({ onPress, onLongPress, on, offline, text, connectionStatus, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon="smoke-detector"
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; status: string; i... Remove this comment to see the full error message
      text={text}
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      status={on ? gettext('Detected') : ''}
      iconOnColor="#FF0000"
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      textColor={on ? { color: '#FF0000', fontWeight: 'bold' } : null}
      on={on}
      onLongPress={onLongPress}
      connectionStatus={connectionStatus}
      offline={offline}
      onPress={onPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

SmokeSensor.defaultProps = {
  children: null,
  onPress: () => { }
}
