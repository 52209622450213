import React from 'react'
import { Text, ActivityIndicator } from 'react-native'
import BlurView from '../BlurView'
import { useAppTheme } from '#app/theme'

interface Props {
  label: string
  value: string
}

export default function Potency (props: Props) {
  const theme = useAppTheme()

  return (
    (<BlurView tint={theme.blurView.tint} intensity={theme.blurView.intensity} style={[{ padding: 20, flexGrow: 1, flexShrink: 1, flexBasis: 0, margin: 5 }, { ...theme.border }]}>
      <Text numberOfLines={2} ellipsizeMode="tail" style={[{ textAlignVertical: 'center', margin: 1, fontSize: 14, fontWeight: 'bold' }, { color: theme.textColor }]}>
        {props.label}
      </Text>

      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {(props as any).loading && (<ActivityIndicator color={theme.titleLoading.indicatorColor} size="small" style={theme.m3}/>)}

      {/* TODO: Fix this the next time the file is edited. */}
      {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
      {!(props as any).loading && (<Text style={[{ fontSize: 22, textAlign: 'left' }, { color: theme.home.topConsumption.textColor }]}>
          {props.value}
        </Text>)}
    </BlurView>)
  )
}
