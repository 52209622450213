import React, { useState, useCallback } from 'react'
import { ScrollView, ActivityIndicator, View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { Right } from '../components/List'
import { RadioButton, Error } from '../components/Utils'
import Search from '../components/Search'
import logo from '#assets/logo.png'
import { t } from 'ttag'
import { setCurrentCentral } from '#app/central'
import { fetchUserCentrals, selectSearchCentralsByNameAsList, selectUserCentralsCount } from '#app/centraluser'
import { userLogout } from '#app/actions'
import { useAppTheme } from '#app/theme'
import { selectLoaderById } from '#app/loader'
import { useFocusEffect } from '@react-navigation/native'
import { LoginNav } from '#app/components'
import { ScreenProps } from '#app/types'
import { selectIsSuperadmin } from '#app/user'

const CentralPicker = ({ navigation }: ScreenProps<'CentralPicker'>) => {
  const [selectedCentral, setSelectedCentral] = useState('')
  const [searchTerm, setSearchTerm] = useState('')

  const dispatch = useAppDispatch()
  const searchResults = useAppSelector(state => selectSearchCentralsByNameAsList(state, { term: searchTerm }))
  const count = useAppSelector(selectUserCentralsCount)
  const theme = useAppTheme()
  const { isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'centrals' }))

  const changeSearchText = (text: any) => {
    setSearchTerm(text)
  }

  const dispatchFetchCentrals = useCallback(() => {
    dispatch(fetchUserCentrals())
  }, [dispatch])

  const onCentralSelected = useCallback((central) => {
    setSelectedCentral(central)
  }, [])

  const onLogoutPress = useCallback(() => {
    dispatch(userLogout())
  }, [dispatch])

  const onNext = useCallback(() => {
    dispatch(setCurrentCentral(selectedCentral))
  }, [dispatch, selectedCentral])

  const onTrashPress = useCallback(() => {
    setSearchTerm('')
  }, [])

  useFocusEffect(dispatchFetchCentrals)

  const isSuperAdmin = useAppSelector(selectIsSuperadmin)

  return (
    (<View style={styles.container}>
      <Image source={logo} style={styles.logo}/>

      {!isLoading && (count === 0) && (<View style={[theme.form.area, { justifyContent: 'center', alignItems: 'center' }]}>
          <Error>{t`No central found.`}</Error>
        </View>)}

      {isLoading && (<View style={styles.loadingWrapper}>
          <ActivityIndicator size="large" color="#ffffff"/>
        </View>)}

      {!isLoading && count > 0 && (<View style={styles.outBox}>
          <View style={styles.centralBox}>
            <Search placeholder={t`Search...`} placeholderTextColor="white" style={styles.searchBox} value={searchTerm} valueTextColor="white" onChangeText={changeSearchText} onTrashPress={onTrashPress} iconColor="white"/>

            <ScrollView style={styles.listCentral}>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {!searchResults && (<View style={styles.loadingWrapper}>
                  <ActivityIndicator size="large" color="#ffffff"/>
                </View>)}
              {searchResults.length === 0 && (<View style={styles.loadingWrapper}>
                  <Text style={{ color: 'white', alignItems: 'center' }}>{t`No central found.`}</Text>
                </View>)}

              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-optional-chain */}
              {searchResults && (searchResults.map((central) => (<View style={[{ justifyContent: 'space-between', flexDirection: 'row' }]} key={central.id}>
                    <TouchableOpacity onPress={() => onCentralSelected(central.id)} style={[{
                      flex: 1,
                      padding: 16,
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      borderBottomWidth: 1,
                      borderBottomColor: 'rgba(255, 255, 255, 0.17)'
                    }]}>
                      <Text style={{ alignSelf: 'center', color: 'white' }}>{central.name}</Text>
                      <Right>
                        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                        <RadioButton innerColor="white" outerColor="white" isSelected={selectedCentral === central.id} onPress={() => onCentralSelected(central.id)}/>
                      </Right>
                    </TouchableOpacity>
                    <View style={{ borderBottomWidth: 2, borderBottomColor: '#7446C6' }}/>
                  </View>)))}
            </ScrollView>
          </View>

          <TouchableOpacity onPress={onNext} style={[styles.button, selectedCentral == null ? styles.buttonDisabled : {}]} disabled={selectedCentral == null}>
            <Text style={{ color: '#000', fontSize: 16 }}>{t`Log in`}</Text>
          </TouchableOpacity>
        </View>)}
      <View style={{ flexDirection: 'row', alignContent: 'space-around', alignSelf: 'stretch', justifyContent: 'space-around' }}>
        <TouchableOpacity onPress={onLogoutPress} style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: '#FFF' }}>{t`Log out`}</Text>
        </TouchableOpacity>
        {isSuperAdmin && <LoginNav onPress={() => navigation.navigate('SuperadminHome')}>Superadmin area</LoginNav>}
      </View>
    </View>)
  )
}

export default CentralPicker

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#673AB7'
  },
  logo: {
    width: 150,
    height: 50
  },
  loadingWrapper: {
    marginTop: 0,
    height: 280,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 25
  },
  buttonDisabled: {
    opacity: 0.5
  },
  searchBox: {
    backgroundColor: '#7446C6',
    flexDirection: 'row',
    height: 50,
    paddingLeft: 8,
    paddingRight: 8
  },
  outBox: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centralBox: {
    maxHeight: 300,
    width: '75%',
    height: '30%',
    minHeight: 300,
    borderWidth: 5,
    borderColor: '#7446C6'
  },
  listCentral: {
    maxHeight: 240
  },
  button: {
    marginTop: 50,
    height: 46,
    width: '75%',
    backgroundColor: '#FFF',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10
  }
})
