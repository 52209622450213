import { createReducer, createActions } from 'reduxsauce'
import _ from 'lodash'

const { Types, Creators } = createActions({
  setRemote: ['payload'],
  setActionModal: ['payload'],
  setNewButton: null,
  setEditButton: ['payload'],
  updateButton: ['payload'],
  setStatusButton: ['payload'],
  saveButtonRequest: null,
  saveButtonRequestSuccess: ['payload'],
  saveButtonRequestFailure: null,
  saveFakeButtonRequest: null,
  deleteButtonRequest: null,
  deleteButtonRequestSuccess: ['payload'],
  deleteButtonRequestFailure: null,
  updateButtonsOrderRequest: null,
  updateButtonsOrderRequestSuccess: ['payload'],
  updateButtonsOrderRequestFailure: null,
  setActivedButton: ['payload']
})

export const INITIAL_STATE = {
  uuid_remote: undefined,
  remote: undefined,
  button: undefined,
  actionWidget: '',
  saving: false,
  savingButton: false,
  savingButtonError: false,
  savingButtonStages: {
    recordButton: 0,
    findingDevice: 1,
    waitingCommand: 2,
    error: 3,
    ok: 4,
    timeout: 5
  },
  savingButtonStage: 0,
  deletingButton: false,
  deletingButtonError: false,
  activedButton: {
    slaveId: null,
    rfirCommandId: null
  }
}

export default Creators

function setActivedButton (state: any, action: any) {
  return {
    ...state,
    activedButton: {
      slaveId: action.payload.slaveId,
      rfirCommandId: action.payload.rfirCommandId
    }
  }
}

function uuidv4 () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

function setRemote (state: any, action: any) {
  return {
    ...state,
    remote: action.payload
  }
}

function setActionModal (state: any, action: any) {
  return {
    ...state,
    actionWidget: action.payload
  }
}

function setNewButton (state: any) {
  let maxIndexes = 0
  let maxOrder = 0
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-optional-chain
  if (state.remote && state.remote.buttons && state.remote.buttons.length) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'b' implicitly has an 'any' type.
    maxIndexes = parseInt((_ as any).maxBy(state.remote.buttons, (b) => parseInt((b).indexes)).indexes) + 1
    try {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'b' implicitly has an 'any' type.
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      maxOrder = (_ as any).maxBy(state.remote.buttons, (b) => (b).order).order + 1
    } catch {
      console.log("outdated api doesn't care about order")
    }
  }

  console.log('ORDER', maxOrder)

  return {
    ...state,
    savingButtonStage: state.savingButtonStages.recordButton,
    button: {
      id: null,
      indexes: maxIndexes.toString(),
      order: maxOrder,
      color: false,
      name: '',
      rfirCommandId: null,
      rfirRemoteId: state.remote.id,
      single_send: false,
      type: null
    }
  }
}

function updateButton (state: any, action: any) {
  const button = action.payload
  state.remote.buttons = state.remote.buttons.map((b: any) => {
    if (b.id === button.id) {
      b = { b, ...button }
    }
    return { ...b }
  })

  return {
    ...state,
    button,
    savingButton: false,
    savingButtonError: false,
    uuid_remote: uuidv4()
  }
}

function setEditButton (state: any, action: any) {
  return {
    ...state,
    button: action.payload
  }
}

function setStatusButton (state: any, action: any) {
  const msg = action.payload
  const status = msg.status
  let stage = state.savingButtonStages.recordButton
  if (status === 'start_record') {
    stage = state.savingButtonStages.findingDevice
  } else if (status === 'slave_waiting') {
    stage = state.savingButtonStages.waitingCommand
  } else if (status === 'error') {
    stage = state.savingButtonStages.error
  } else if (status === 'timeout') {
    stage = state.savingButtonStages.timeout
  } else if (status === 'ok') {
    stage = state.savingButtonStages.ok
    state.button.rfirCommandId = msg.data.rfir_command_id
  }
  return {
    ...state,
    savingButtonStage: stage
  }
}

function saveButtonRequest (state: any) {
  return {
    ...state,
    savingButton: true,
    savingButtonError: false
  }
}

function saveButtonRequestSuccess (state: any, action: any) {
  const button = action.payload
  state.button = {
    ..._.cloneDeep(state.button),
    ...button
  }
  state.remote.buttons.push(_.cloneDeep(state.button))
  return {
    ...state,
    savingButton: false,
    savingButtonError: false,
    uuid_remote: uuidv4()
  }
}

function saveButtonRequestFailure (state: any) {
  return {
    ...state,
    savingButton: false,
    savingButtonError: true
  }
}

function saveFakeButtonRequest (state: any) {
  return {
    ...state,
    savingButton: true,
    savingButtonError: false
  }
}

function deleteButtonRequest (state: any) {
  return {
    ...state,
    savingButton: true,
    savingButtonError: false,
    deletingButton: true,
    deletingButtonError: false
  }
}

function deleteButtonRequestSuccess (state: any, action: any) {
  const buttonId = action.payload

  const buttons = state.remote.buttons.filter((b: any) => b.id !== buttonId)
  return {
    ...state,
    remote: {
      ...state.remote,
      buttons
    },
    savingButton: false,
    savingButtonError: false,
    deletingButton: false,
    deletingButtonError: false,
    uuid_remote: uuidv4()
  }
}

function deleteButtonRequestFailure (state: any) {
  return {
    ...state,
    saving: false,
    saveError: true,
    deletingButton: false,
    deletingButtonError: true
  }
}

function updateButtonsOrderRequest (state: any) {
  return {
    ...state,
    savingButton: true,
    savingButtonError: false
  }
}

function updateButtonsOrderRequestSuccess (state: any, action: any) {
  const positions = action.payload
  state.remote.buttons.forEach((b: any) => {
    b.order = positions.find((p: any) => p.id === b.id).order
  })

  return {
    ...state,
    savingButton: false,
    savingButtonError: false,
    uuid_remote: uuidv4()
  }
}

function updateButtonsOrderRequestFailure (state: any) {
  return {
    ...state,
    saving: false,
    saveError: true
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_REMOTE]: setRemote,
  [Types.SET_ACTION_MODAL]: setActionModal,
  [Types.SET_NEW_BUTTON]: setNewButton,
  [Types.SET_EDIT_BUTTON]: setEditButton,
  [Types.SET_STATUS_BUTTON]: setStatusButton,
  [Types.UPDATE_BUTTON]: updateButton,
  [Types.SAVE_BUTTON_REQUEST]: saveButtonRequest,
  [Types.SAVE_BUTTON_REQUEST_SUCCESS]: saveButtonRequestSuccess,
  [Types.SAVE_BUTTON_REQUEST_FAILURE]: saveButtonRequestFailure,
  [Types.SAVE_FAKE_BUTTON_REQUEST]: saveFakeButtonRequest,
  [Types.DELETE_BUTTON_REQUEST]: deleteButtonRequest,
  [Types.DELETE_BUTTON_REQUEST_SUCCESS]: deleteButtonRequestSuccess,
  [Types.DELETE_BUTTON_REQUEST_FAILURE]: deleteButtonRequestFailure,
  [Types.UPDATE_BUTTONS_ORDER_REQUEST]: updateButtonsOrderRequest,
  [Types.UPDATE_BUTTONS_ORDER_REQUEST_SUCCESS]: updateButtonsOrderRequestSuccess,
  [Types.UPDATE_BUTTONS_ORDER_REQUEST_FAILURE]: updateButtonsOrderRequestFailure,
  [Types.SET_ACTIVED_BUTTON]: setActivedButton
})
