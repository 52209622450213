import { createReducer, createActions } from 'reduxsauce'
import moment from 'moment'

const { Types, Creators } = createActions({
  getLogsRequest: null,
  getLogsRequestSuccess: ['payload', 'update'],
  getLogsRequestFailure: null,
  clearLogs: null,
  updateUsersFilter: ['payload'],
  updateTypesFilter: ['payload'],
  updateActionTypesFilter: ['payload'],
  updateDevicesFilter: ['payload'],
  updateDateFilter: ['payload'],
  setDefaultFilter: ['payload']
})

const TYPES = [
  { value: 'user_action', label: 'Ação de Usuário' },
  { value: 'manual_action', label: 'Acionamento Físico' },
  { value: 'binary_sensor', label: 'Acionamento de Sensor' }
]

const ACTION_TYPES = [
  { value: 'transmit', label: 'Envio de Comando IR/RF' },
  { value: 'activate_channel', label: 'Acionamento de Dispositivo' },
  { value: 'activate_scene', label: 'Acionamento de Cena' },
  { value: 'create_scene', label: 'Criação de Cena' },
  { value: 'create_channel', label: 'Criação de Canal' },
  { value: 'create_slave', label: 'Criação de Produto' },
  { value: 'create_ambient', label: 'Criação de Ambiente' },
  { value: 'create_schedule', label: 'Criação Agendamento' },
  { value: 'update_scene', label: 'Atualização de Cena' },
  { value: 'update_channel', label: 'Atualização de Canal' },
  { value: 'update_slave', label: 'Atualização de Produto' },
  { value: 'update_ambient', label: 'Atualização de Ambiente' },
  { value: 'update_schedule', label: 'Atualização Agendamento' },
  { value: 'delete_scene', label: 'Exclusão de Cena' },
  { value: 'delete_channel', label: 'Exclusão de Canal' },
  { value: 'delete_slave', label: 'Exclusão de Produto' },
  { value: 'delete_ambient', label: 'Exclusão de Ambiente' },
  { value: 'delete_schedule', label: 'Exclusão Agendamento' }
]

export const INITIAL_STATE = {
  filters: {
    users: [],
    devices: [],
    date_start: moment().startOf('day'),
    date_end: moment().endOf('day'),
    type: TYPES,
    action_type: ACTION_TYPES
  },
  types: TYPES,
  action_types: ACTION_TYPES,
  status: '',
  loading: false,
  logs: []
}

export default Creators

function clearLogs () {
  return { ...INITIAL_STATE }
}

function getLogsRequest (state: any, action: any) {
  return { ...state, loading: true, status: '' }
}

function getLogsRequestSuccess (state: any, action: any) {
  const { payload, status } = action

  return {
    ...state,
    status,
    logs: payload,
    loading: false
  }
}

function getLogsRequestFailure (state: any, action: any) {
  return {
    ...state,
    status: 'Nenhum log encontrado.',
    loading: false
  }
}

function setDefaultFilter (state: any, action: any) {
  return {
    ...state,
    filters: {
      ...state.filters,
      devices: action.payload.devices,
      users: action.payload.users
    }
  }
}

function updateUsersFilter (state: any, action: any) {
  return {
    ...state,
    filters: {
      ...state.filters,
      users: action.items
    }
  }
}

function updateTypesFiltler (state: any, action: any) {
  return {
    ...state,
    filters: {
      ...state.filters,
      type: action.items
    }
  }
}

function updateActionTypesFilter (state: any, action: any) {
  return {
    ...state,
    filters: {
      ...state.filters,
      action_type: action.items
    }
  }
}

function updateDevicesFilter (state: any, action: any) {
  return {
    ...state,
    filters: {
      ...state.filters,
      devices: action.items
    }
  }
}

function updateDateFilter (state: any, action: any) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const dateStart = action.payload.dateStart ? action.payload.dateStart : state.filters.date_start
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const dateEnd = action.payload.dateEnd ? action.payload.dateEnd : state.filters.date_end

  return {
    ...state,
    filters: {
      ...state.filters,
      date_start: dateStart,
      date_end: dateEnd
    }
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_LOGS_REQUEST]: getLogsRequest,
  [Types.GET_LOGS_REQUEST_SUCCESS]: getLogsRequestSuccess,
  [Types.GET_LOGS_REQUEST_FAILURE]: getLogsRequestFailure,
  [Types.CLEAR_LOGS]: clearLogs,
  [Types.UPDATE_USERS_FILTER]: updateUsersFilter,
  [Types.UPDATE_TYPES_FILTER]: updateTypesFiltler,
  [Types.UPDATE_ACTION_TYPES_FILTER]: updateActionTypesFilter,
  [Types.UPDATE_DEVICES_FILTER]: updateDevicesFilter,
  [Types.UPDATE_DATE_FILTER]: updateDateFilter,
  [Types.SET_DEFAULT_FILTER]: setDefaultFilter
})
