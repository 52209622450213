import React from 'react'
import { ScrollView } from 'react-native'

export default function ListItem ({
  children
}: any) {
  return (
    <ScrollView>
      {children}
    </ScrollView>
  )
}
