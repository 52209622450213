import React from 'react'
import { TextInputMask } from 'react-native-masked-text'
import moment from 'moment'

export default function MyioDatePicker ({
  date,
  onDateChange,
  theme
}: any) {
  const formatDate = (value: any) => {
    const dateTest = moment(value, 'DD/MM/YYYY', true)
    if (dateTest.isValid()) {
      return moment(value, 'DD/MM/YYYY').format('DD/MM/YYYY')
    } else {
      return value
    }
  }

  return (
    <TextInputMask
      type="custom"
      options={{ mask: '99/99/9999' }}
      value={formatDate(date)}
      onChangeText={onDateChange}
      style={theme.form.input}
    />
  )
}
