
import React, { useState, useEffect } from 'react'
import { t } from 'ttag'
import SegmentedControl from '@react-native-segmented-control/segmented-control'
import { ScreenProps } from '#app/types'
import Energy from './Energy'
import NodeRedUI from './NodeRedUI'

const Dashboard = ({ navigation, route }: ScreenProps<'Dashboard'>) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  useEffect(() => {
    navigation.setOptions({
      headerTitle: () =>
        <SegmentedControl style={{ width: 200, alignSelf: 'center' }}
            values={[t`Energy`, 'Node-RED']}
            selectedIndex={selectedIndex}
            onChange={(event) => setSelectedIndex(event.nativeEvent.selectedSegmentIndex)}
        />
    })
  })

  switch (selectedIndex) {
    case 1:
      return <NodeRedUI />
    default:
      return <Energy />
  }
}

export default Dashboard
