import React, { useCallback, useEffect } from 'react'
import { Separator, ItemPress, ListItem, TextList, Right } from '../components/List'
import { View } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useAppSelector } from '#app/hooks'
import { selectCentralById } from '#app/central'
import { useAppTheme } from '#app/theme'
import { useNavigation } from '@react-navigation/native'
import { ScreenProps } from '#app/types'
import Clipboard from '@react-native-clipboard/clipboard'
import moment from 'moment'
import ButtonForm from '../components/Form/ButtonForm'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'

const SuperadminCentralDetail = ({ route: { params: { id } } }: ScreenProps<'SuperadminCentralDetail'>) => {
  const navigation = useNavigation()
  const theme = useAppTheme()
  const { name, createdAt, serial, mac, yggdrasilIP } = useAppSelector(state => selectCentralById(state, { id }))
  useEffect(() => {
    navigation.setOptions({
      title: name,
      headerRight: () => (
        <HeaderButtons>
          <Item title="Edit" onPress={() => navigation.navigate('SuperadminCentralEdit', { id })} />
        </HeaderButtons>
      )
    })
  }, [navigation, id, name])

  const onUsersPress = useCallback(() => {
    navigation.navigate('CentralUsers', { id })
  }, [navigation, id])

  return (
    <ListItem>
      <Separator text="Information" />

      <ItemPress onPress={() => Clipboard.setString(name)}>
        <TextList text="Name" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={name} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>

      <ItemPress onPress={() => Clipboard.setString(moment(createdAt).toLocaleString())}>
        <TextList text="Creation Date" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={moment(createdAt).toLocaleString()} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>

      <ItemPress onPress={() => Clipboard.setString(id)}>
        <TextList text="UUID" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={id} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>

      <ItemPress onPress={() => Clipboard.setString(serial)}>
        <TextList text="Serial" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={serial} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>

      {mac != null && <ItemPress onPress={() => Clipboard.setString(mac)}>
        <TextList text="MAC Address" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={mac} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>}

      {yggdrasilIP != null && <ItemPress onPress={() => Clipboard.setString(yggdrasilIP)}>
        <TextList text="Yggdrasil IP" />
        <Right>
          <View style={{ flexDirection: 'row' }}>
            <TextList text={yggdrasilIP} />
            <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
          </View>
        </Right>
      </ItemPress>}

      <Separator text="Preferences" />
      <ItemPress onPress={onUsersPress}>
        <TextList text="Users" />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>

      <Separator text="Danger Zone" />
      <View style={theme.form.area}>
        <ButtonForm
          title="Replace Central"
          btnStyle={theme.form.btn}
          color={theme.colors.grey}
          onPress={() => {}}
        />
      </View>
      <View style={theme.form.area}>
        <ButtonForm
          title="Delete Central"
          btnStyle={theme.form.btn}
          color={theme.colors.danger}
          onPress={() => {}}
        />
      </View>
    </ListItem>
  )
}

export default SuperadminCentralDetail
