
import React, { useCallback, useContext } from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { useStyle } from 'react-native-style-utilities'
import { t } from 'ttag'

import BlurView from './BlurView'
import { SlaveChannel } from '#app/types'
import { useAppTheme } from '#app/theme'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { selectSlaveChannelsByIdAsList } from '#app/slave'
import { PropId } from 'robodux'
import { Error } from './Utils'
import { selectCan } from '#app/centraluser'
import { PressableScale } from './PressableScale'
import { wsSendMessage } from '#app/actions'

const SlaveIdContext = React.createContext('')

const StatusBox = ({ id }: PropId) => {
  const data = useAppSelector(state => selectSlaveChannelsByIdAsList(state, { id }))
  if (data.length === 0) {
    return <Error>{t`Channel state unavailable for this product`}</Error>
  }
  return (
    <SlaveIdContext.Provider value={id}>
      {data.map((item, idx) => [
        <Row key={String(item.id)} {...item} />,
        (idx < data.length - 1
          ? [<Separator key={`sep-${idx}`} />]
          : [])
      ])}
    </SlaveIdContext.Provider>
  )
}

export default React.memo(StatusBox)

const Row = React.memo(({ id, input, output }: SlaveChannel) => {
  const n = id + 1
  return (
    <View style={styles.row}>
      {input != null && <Cell title={t`Input ${n}`} value={input} />}
      {input != null && output != null && <Separator />}
      {output != null && <Cell title={t`Output ${n}`} value={output} channel={id} />}
    </View>
  )
}
)

const Cell = React.memo(({ channel, title, value }: { channel?: number, title: string, value: number }) => {
  const theme = useAppTheme()
  const isOn = value !== 0
  const containerStyle = useStyle(() => [styles.cellContainer, theme.border], [theme.border])
  const titleTextStyle = useStyle(() => [styles.titleText, { color: theme.textColor }], [theme.textColor])
  const statusTextStyle = useStyle(() => [
    styles.statusText,
    { color: isOn ? theme.colors.green : theme.colors.danger }
  ], [isOn, theme.colors.green, theme.colors.danger])
  const dispatch = useAppDispatch()
  const canUse = useAppSelector(selectCan)('use')
  const slaveId = useContext(SlaveIdContext)
  const onPress = useCallback(() => {
    if (channel != null && canUse) {
      dispatch(wsSendMessage({
        type: 'slave',
        id: Number(slaveId),
        command: 'light_control',
        channel: channel,
        value: isOn ? 0 : 100
      }))
    }
  }, [dispatch, canUse, slaveId, channel, isOn])

  return (
    <PressableScale disabled={channel == null} onPress={onPress} style={styles.pressable}>
      <BlurView tint={theme.blurView.tint} intensity={theme.blurView.intensity} style={containerStyle}>
        <Text style={titleTextStyle}>{title}</Text>
        <Text style={statusTextStyle}>
          {value === 0
            ? t`Off`
            : value < 0 || value > 100
              ? String(value)
              : t`On`
          }
        </Text>
      </BlurView>
    </PressableScale>
  )
})

const Separator = () => <View style={styles.separator} />

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  separator: {
    height: 10,
    width: 10
  },
  cellContainer: {
    padding: 20,
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0
  },
  titleText: {
    textAlignVertical: 'center',
    fontSize: 14,
    fontWeight: 'bold'
  },
  statusText: {
    fontSize: 22,
    textAlign: 'left'
  },
  pressable: {
    flex: 1,
    flexDirection: 'row'
  }
})
