import React, { useCallback } from 'react'
import { Text, TouchableOpacity } from 'react-native'
import BlurView from '../BlurView'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { gettext } from 'ttag'
import { useNavigation } from '@react-navigation/native'

export default function NoContent ({
  type,
  theme,
  style
}: any) {
  const navigation = useNavigation()

  const checkType = (type: any) => {
    switch (type) {
      case 'ambients':
        return gettext('Add Ambient')
      case 'scenes':
        return gettext('Add Scene')
      case 'consumption':
        return gettext('Add Consumption')
      case 'slaves':
        return gettext('Add Device')
    }
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPress = useCallback(() => {
    if (type === 'ambients') {
      navigation.navigate('Ambients')
    } else if (type === 'scenes') {
      navigation.navigate('SceneList')
    } else if (type === 'consumption') {
      navigation.navigate('Ambients')
    } else if (type === 'slaves') {
      navigation.navigate('Ambients')
    }
  })

  return (
    <TouchableOpacity onPress={onPress} style={{ ...style }}>
      <BlurView
        tint={theme.blurView.tint}
        intensity={theme.blurView.intensity}
        style={[{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 15,
          marginTop: 15,
          padding: 15,
          width: '100%'
        }, { ...theme.border }]}
      >
        <MaterialCommunityIcons name="plus-circle" size={30} color={theme.colors.green} />
        <Text style={{ color: theme.textColor, marginLeft: 10 }}>{checkType(type)}</Text>
      </BlurView>
    </TouchableOpacity>
  )
}
