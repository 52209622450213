import React, { useCallback, useEffect, useRef } from 'react'
import { TextInput, View } from 'react-native'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { t } from 'ttag'

import { useAppDispatch, useAppSelector } from '#app/hooks'
import { useAppTheme } from '#app/theme'
import { RootStackParamList } from '#app/routes'
import { selectVariableByName, updateEnvironment } from '#app/environment'

const EditEnvironment = ({ navigation, route }: Props) => {
  const dispatch = useAppDispatch()
  const { name } = route.params
  const theme = useAppTheme()
  const { value: previousValue } = useAppSelector(state => selectVariableByName(state, { name }))
  const valueRef = useRef(previousValue)

  useEffect(() => {
    navigation.setOptions({ title: name })
  }, [navigation, name])

  const onPressSave = useCallback(() => {
    const value = valueRef.current
    dispatch(updateEnvironment({ name, value }))
    navigation.goBack()
  }, [dispatch, navigation, name])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => {
        return (
          <HeaderButtons>
            <Item title={t`Save`} iconName="settings" onPress={onPressSave} />
          </HeaderButtons>
        )
      }
    })
  }, [navigation, name, onPressSave])

  const onChangeText = useCallback((text) => {
    valueRef.current = text
  }, [])

  return (
    <View style={{ backgroundColor: theme.backgroundColor }}>
      <View style={theme.form.area}>
        <View style={theme.form.formGroup}>
          <TextInput
            style={theme.form.input}
            placeholder={previousValue}
            placeholderTextColor={theme.formForm.placeholderTextColor}
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={onChangeText}
          />
        </View>
      </View>
    </View>
  )
}

export default EditEnvironment

type Props = NativeStackScreenProps<RootStackParamList, 'EditEnvironment'>
