import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getSchedulesRequest: null,
  getSchedulesRequestSuccess: ['payload'],
  getSchedulesRequestFailure: null,

  deleteScheduleRequest: null,
  deleteScheduleRequestSuccess: ['payload'],
  deleteScheduleRequestFailure: null,

  createScheduleRequest: null,
  createScheduleRequestSuccess: ['payload'],
  createScheduleRequestFailure: null,

  updateScheduleRequest: null,
  updateScheduleRequestSuccess: ['payload'],
  updateScheduleRequestFailure: null,

  setSelectedSchedule: ['payload'],
  clearScheduleState: null
})

export const INITIAL_STATE = {
  schedules: [],
  loading: false,
  failed: false,
  selectedSchedule: null
}

export default Creators

function clearScheduleState () {
  return { ...INITIAL_STATE }
}

function getSchedulesRequest (state: any) {
  return { ...state, loading: true }
}

function getSchedulesRequestSuccess (state: any, action: any) {
  return { ...state, schedules: action.payload }
}

function getSchedulesRequestFailure (state: any, action: any) {
  return { ...state, loading: false, failed: true }
}

function updateScheduleRequest (state: any) {
  return { ...state, loading: true, failed: false }
}

function updateScheduleRequestSuccess (state: any, action: any) {
  const { payload } = action

  const schedules = state.schedules.map((s: any) => {
    if (s.id === payload.id) {
      s.action = payload.action
      s.name = payload.name
      s.cron = payload.cron
      s.active = payload.active
    }

    return { ...s }
  })

  return { ...state, schedules, loading: false, failed: false }
}

function updateScheduleRequestFailure (state: any) {
  return { ...state, loading: false, failed: true }
}

function createScheduleRequest (state: any) {
  return { ...state, loading: true, failed: false }
}

function createScheduleRequestSuccess (state: any, action: any) {
  const schedules = state.schedules.concat(action.payload)
  return { ...state, schedules, loading: false, failed: false }
}

function createScheduleRequestFailure (state: any) {
  return { ...state, loading: false, failed: true }
}

function deleteScheduleRequest (state: any) {
  return { ...state, loading: true, failed: false }
}

function deleteScheduleRequestSuccess (state: any, action: any) {
  const schedules = state.schedules.filter((s: any) => s.id !== action.payload)
  return { ...state, schedules, loading: false, failed: false }
}

function deleteScheduleRequestFailure (state: any) {
  return { ...state, loading: false, failed: true }
}

function setSelectedSchedule (state: any, action: any) {
  return { ...state, selectedSchedule: action.schedule }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SCHEDULES_REQUEST]: getSchedulesRequest,
  [Types.GET_SCHEDULES_REQUEST_SUCCESS]: getSchedulesRequestSuccess,
  [Types.GET_SCHEDULES_REQUEST_FAILURE]: getSchedulesRequestFailure,
  [Types.SET_SELECTED_SCHEDULE]: setSelectedSchedule,
  [Types.CLEAR_SCHEDULE_STATE]: clearScheduleState,

  [Types.DELETE_SCHEDULE_REQUEST]: deleteScheduleRequest,
  [Types.DELETE_SCHEDULE_REQUEST_SUCCESS]: deleteScheduleRequestSuccess,
  [Types.DELETE_SCHEDULE_REQUEST_FAILURE]: deleteScheduleRequestFailure,

  [Types.CREATE_SCHEDULE_REQUEST]: createScheduleRequest,
  [Types.CREATE_SCHEDULE_REQUEST_SUCCESS]: createScheduleRequestSuccess,
  [Types.CREATE_SCHEDULE_REQUEST_FAILURE]: createScheduleRequestFailure,

  [Types.UPDATE_SCHEDULE_REQUEST]: updateScheduleRequest,
  [Types.UPDATE_SCHEDULE_REQUEST_SUCCESS]: updateScheduleRequestSuccess,
  [Types.UPDATE_SCHEDULE_REQUEST_FAILURE]: updateScheduleRequestFailure
})
