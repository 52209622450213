import React from 'react'
import { StyleSheet, TextInput, TextInputProps, TextStyle } from 'react-native'

const LoginTextInput = React.forwardRef<TextInput, TextInputProps>(({ style, ...props }, ref) =>
  <TextInput
    ref={ref}
    style={StyleSheet.compose<TextStyle>(styles.newInput, style)}
    placeholderTextColor="#fff"
    keyboardAppearance="dark"
    {...props}
  />
)

export default LoginTextInput

const styles = StyleSheet.create({
  newInput: {
    marginBottom: 15,
    padding: 15,
    fontSize: 16,
    color: '#fff',
    backgroundColor: '#7446C6',
    borderRadius: 10
  }
})
