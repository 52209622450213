import React, { useState, useEffect, useCallback } from 'react'
import { ScrollView, StyleSheet, ActivityIndicator } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import RemoteControlGrid from '../components/RemoteControl/RemoteControlGrid'
import WsActions from '../redux/ws'
import { useActionSheet } from '@expo/react-native-action-sheet'
import { HeaderButtons, HeaderButton, Item } from 'react-navigation-header-buttons'
import Icon from '../components/Icon'
import { get, map } from 'lodash'
import { t, gettext } from 'ttag'
import { alert } from '../components/Utils'
import { useNavigation } from '@react-navigation/native'

const IoniconsHeaderButton = (props: any) => {
  return (
    <HeaderButton
      {...props}
      IconComponent={Icon}
      iconSize={23}
    />
  )
}

export default function RemoteConfig () {
  const [scrollEnable, setScrollEnable] = useState(true)
  const [buttonsPosition, setButtonsPosition] = useState([])

  const remote = useAppSelector(state => state.remote)
  const saving = useAppSelector(state => state.remote.savingButton)

  const navigation = useNavigation()
  const dispatch = useAppDispatch()
  const { showActionSheetWithOptions } = useActionSheet()

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  useEffect(() => { navigation.setOptions({ title: get(remote, 'remote.name') || gettext('Device') }) })

  useEffect(
    () => {
      navigation.setOptions({
        headerRight: () => {
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          return saving
            ? <ActivityIndicator style={{ marginRight: 16 }} />
            : (
              <HeaderButtons HeaderButtonComponent={IoniconsHeaderButton}>
                <Item
                  title={t`Save`}
                  iconName="save"
                  onPress={onPressUpdatePositions}
                />
                <Item
                  title={t`Add`}
                  iconName="add"
                  onPress={onPressAddButton}
                />
              </HeaderButtons>
              )
        }
      })
    }, // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [saving, buttonsPosition]
  )

  const onPressUpdatePositions = useCallback(
    () => {
      if (buttonsPosition.length > 0) {
        dispatch({
          type: 'UPDATE_BUTTONS_ORDER_REQUEST',
          positions: buttonsPosition,
          slaveId: get(remote, 'remote.slaveId'),
          remoteId: get(remote, 'remote.id')
        })
      } else {
        console.log('no button pos', buttonsPosition)
      }
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [buttonsPosition]
  )

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPressAddButton = useCallback(
    () => {
      dispatch({
        type: 'REMOTE_SET_NEW_BUTTON'
      })

      navigation.navigate('RemoteButton')
    }
  )

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDragStart = useCallback(
    () => setScrollEnable(false)
  )

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDragRelease = useCallback(
    (data) => {
      const newButtonsPosition = map(data, (button, index) => {
        return {
          id: button.id,
          order: index
        }
      })

      console.log('drag release, new buttons: ', newButtonsPosition)

      setScrollEnable(true)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; order: string; }[]' i... Remove this comment to see the full error message
      setButtonsPosition(newButtonsPosition)
    }
  )

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteButton = useCallback(
    (item) => {
      alert(
        item.name,
        gettext('Remove this button?'), [
          {
            text: gettext('No'),
            onPress: () => { },
            style: 'destructive'
          },
          {
            text: gettext('Yes'),
            onPress: () => {
              dispatch({
                type: 'DELETE_BUTTON_REQUEST',
                id: item.id,
                slaveId: get(remote, 'remote.slaveId'),
                remoteId: get(remote, 'remote.id')
              })
            }
          }
        ],
        { cancelable: false }
      )
    }
  )

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onItemPress = useCallback(
    (item) => {
      showActionSheetWithOptions({
        cancelButtonIndex: 3,
        destructiveButtonIndex: 2,
        options: [
          gettext('Edit Button'),
          gettext('Test Command'),
          gettext('Delete Button'),
          gettext('Cancel')
        ]
      }, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            dispatch({
              type: 'REMOTE_SET_EDIT_BUTTON',
              button: item
            })
            navigation.navigate('RemoteButton')
            break
          case 1:
            dispatch(WsActions.wsConnectionSendMessage({
              type: 'slave',
              id: get(remote, 'remote.slaveId'),
              command: 'transmit',
              rfir_command_id: item.rfirCommandId
            }))
            break
          case 2:
            onDeleteButton(item)
            break
          case 3:
            break
        }
      })
    }
  )
  return (
        <ScrollView
          scrollEnabled={scrollEnable}
          nestedScrollEnabled={scrollEnable}
          contentContainerStyle={styles.container}
          style={{ marginBottom: 2 }}
          >

          {
            remote.remote?.buttons != null && <RemoteControlGrid
            key={remote.uuid_remote}
            remote={remote.remote}
            enableDrag
            clickDisable
            onDragStart={onDragStart}
            onDragRelease={onDragRelease}
            onItemPress={onItemPress}
            />
          }
        </ScrollView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: 3
  },
  saving: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
})
