import { createReducer, createActions } from 'reduxsauce'
import { find, clone, orderBy, sumBy, without, get } from 'lodash'

function consumptionThreePhaseData () {
  return {
    timeoutWsRequest: false,
    selectedThreePhase: {
      slave_id: null
    },
    loading: false,
    consumptionData: {
      totalValue: undefined,
      values: undefined
    },
    potency: {
      consumption: undefined,
      phases: {
        a: undefined,
        b: undefined,
        c: undefined
      }
    },
    voltage: {
      phases: {
        a: undefined,
        ab: undefined,
        b: undefined,
        bc: undefined,
        c: undefined,
        ac: undefined
      },
      fp: {
        a: undefined,
        b: undefined,
        c: undefined
      }
    },
    current: {
      totalCurrent: undefined,
      phases: {
        a: undefined,
        b: undefined,
        c: undefined
      }
    }
  }
}

const { Types, Creators } = createActions({
  consumptionGetAllHourlyRequest: null,
  consumptionGetAllHourlyRequestSuccess: ['payload'],
  consumptionGetAllHourlyRequestFailure: null,
  consumptionAddAmbientConsumption: ['payload'],
  consumptionSubscribeAmbientConsumption: ['payload'],
  consumptionUnsubscribeAmbientConsumption: ['payload'],
  clearConsumptionState: null,
  setSelectedThreePhase: ['payload'],
  updateSelectedThreePhaseConsumption: ['payload'],
  updatePotency: ['payload'],
  updateVoltage: ['payload'],
  updateCurrent: ['payload'],
  getThreePhaseConsumptionRequest: null,
  getThreePhaseConsumptionRequestSuccess: ['payload'],
  getThreePhaseConsumptionRequestFailure: null,
  clearConsumptionThreePhase: null,
  resetConsumptionThreePhase: null,
  websocketRequestSetTimeout: ['payload']
})

export const INITIAL_STATE = {
  consumptionToday: {
    consumptionHourly: [],
    consumptionHourlyLabelValue: [],
    consumptionHourlyTotal: 0,
    consumptionHourlyLoading: false
  },
  consumptionReport: {
    consumptionHourly: [],
    consumptionHourlyLabelValue: [],
    consumptionHourlyTotal: 0,
    consumptionHourlyLoading: false
  },
  consumptionAmbients: {
    consumptionList: [],
    subscriptions: []
  },
  consumptionThreePhase: consumptionThreePhaseData()
}

export default Creators

function clearConsumptionState () {
  return { ...INITIAL_STATE }
}

function getAllHourlyRequest (state: any, action: any) {
  const dataReturn = {
    consumptionHourly: [],
    consumptionHourlyLabelValue: [],
    consumptionHourlyTotal: 0,
    consumptionHourlyLoading: true

  }

  if (action.typeReport === 'today') {
    return {
      ...state,
      consumptionToday: {
        ...dataReturn
      }
    }
  }

  return {
    ...state,
    consumptionReport: {
      ...dataReturn
    }
  }
}

function getAllHourlyRequestSuccess (state: any, action: any) {
  const { payload } = action

  const ordered = orderBy(payload.values, ['timestamp'], ['asc']).map(c => {
    return {
      label: c.timestamp,
      value: (c.value / 1000)
    }
  })
  const total = sumBy(payload.values, 'value')

  const dataReturn = {
    consumptionHourly: payload,
    consumptionHourlyLabelValue: ordered,
    consumptionHourlyTotal: total,
    consumptionHourlyLoading: false

  }

  if (payload.typeReport === 'today') {
    return {
      ...state,
      consumptionToday: {
        ...dataReturn
      }
    }
  }

  return {
    ...state,
    consumptionReport: {
      ...dataReturn
    }
  }
}

function getAllHourlyRequestFailure (state: any, action: any) {
  const dataReturn = {
    consumptionHourly: [],
    consumptionHourlyLabelValue: [],
    consumptionHourlyTotal: 0,
    consumptionHourlyLoading: false
  }

  return {
    ...state,
    consumptionToday: {
      ...dataReturn
    },
    consumptionReport: {
      ...dataReturn
    }
  }
}

function addAmbientConsumption (state: any, action: any) {
  const { consumptionList } = state.consumptionAmbients
  const { payload } = action

  const newConsumptionList = clone(consumptionList)
  const oldConsumption = find(newConsumptionList, { id: payload.id })

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (oldConsumption) {
    oldConsumption.value = payload.value
  } else {
    newConsumptionList.push(payload)
  }

  return {
    ...state,
    consumptionAmbients: {
      ...state.consumptionAmbients,
      consumptionList: newConsumptionList
    }
  }
}

function subscribeAmbientConsumption (state: any, action: any) {
  const { subscriptions } = state.consumptionAmbients
  const { payload } = action
  const newSubscriptionsList = clone(subscriptions)

  newSubscriptionsList.push(payload)

  return {
    ...state,
    consumptionAmbients: {
      ...state.consumptionAmbients,
      subscriptions: newSubscriptionsList
    }
  }
}

function unsubscribeAmbientConsumption (state: any, action: any) {
  const { subscriptions } = state.consumptionAmbients
  const { payload } = action
  const newSubscriptionsList = without(subscriptions, payload)

  return {
    ...state,
    consumptionAmbients: {
      ...state.consumptionAmbients,
      subscriptions: newSubscriptionsList
    }
  }
}

function setSelectedThreePhase (state: any, action: any) {
  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      selectedThreePhase: action.payload
    }
  }
}

function updateSelectedThreePhaseConsumption (state: any, action: any) {
  const msg = action.payload

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (get(state, 'consumptionThreePhase.selectedThreePhase.slave_id') !== msg.id || !get(state, 'consumptionThreePhase.selectedThreePhase.is_slave')) {
    return { ...state }
  }

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      selectedThreePhase: {
        ...state.consumptionThreePhase.selectedThreePhase,
        lastConsumption: msg.value
      }
    }
  }
}

function updatePotency (state: any, action: any) {
  const { id, phases, value } = action.payload

  if (parseInt(id) !== parseInt(get(state, 'consumptionThreePhase.selectedThreePhase.slave_id'))) return { ...state }

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      potency: {
        phases: {
          a: phases.a.toFixed(0),
          b: phases.b.toFixed(0),
          c: phases.c.toFixed(0)
        },
        consumption: value
      }
    }
  }
}

function updateVoltage (state: any, action: any) {
  const { id, fp, voltage } = action.payload

  if (parseInt(id) !== parseInt(get(state, 'consumptionThreePhase.selectedThreePhase.slave_id'))) return { ...state }

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      voltage: {
        fp: {
          a: (fp.a / 255).toFixed(2),
          b: (fp.b / 255).toFixed(2),
          c: (fp.c / 255).toFixed(2)
        },
        phases: {
          a: Math.round(voltage.a),
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          ab: (((voltage.a + voltage.b) / 2) * Math.sqrt(3)).toFixed(0),
          b: Math.round(voltage.b),
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          bc: (((voltage.b + voltage.c) / 2) * Math.sqrt(3)).toFixed(0),
          c: Math.round(voltage.c),
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          ac: (((voltage.a + voltage.c) / 2) * Math.sqrt(3)).toFixed(0)
        }
      }
    }
  }
}

function updateCurrent (state: any, action: any) {
  const data = action.payload

  if (parseInt(data.id) !== parseInt(get(state, 'consumptionThreePhase.selectedThreePhase.slave_id'))) return { ...state }

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      current: {
        phases: {
          a: data.a,
          b: data.b,
          c: data.c
        },
        totalCurrent: data.total_current
      }
    }
  }
}

function getThreePhaseConsumptionRequest (state: any) {
  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      loading: true,
      consumptionData: { totalValue: undefined, values: undefined }
    }
  }
}

function getThreePhaseConsumptionRequestSuccess (state: any, action: any) {
  const { payload } = action
  const totalValue = sumBy(payload.values, 'value')

  const ordered = orderBy(payload.values, ['timestamp'], ['asc']).map(c => {
    return { label: c.timestamp, value: (c.value / 1000) }
  })

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      loading: false,
      consumptionData: { totalValue, values: ordered }
    }
  }
}

function getThreePhaseConsumptionRequestFailure (state: any) {
  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase,
      loading: false
    }
  }
}

function clearConsumptionThreePhase (state: any) {
  return {
    ...state,
    consumptionThreePhase: {
      ...consumptionThreePhaseData(),
      selectedThreePhase: {
        ...state.consumptionThreePhase.selectedThreePhase
      }
    }
  }
}

function resetConsumptionThreePhase (state: any) {
  const threePhase = state.consumptionThreePhase.selectedThreePhase
  const consumptionThreePhase = consumptionThreePhaseData()
  consumptionThreePhase.selectedThreePhase = threePhase

  return { ...state, consumptionThreePhase }
}

function websocketRequestSetTimeout (state: any, action: any) {
  const { payload } = action
  const selectedThreePhaseId = state.consumptionThreePhase.selectedThreePhase.slave_id
  let timeoutWsRequest = false

  if (selectedThreePhaseId === payload.id) timeoutWsRequest = true

  return {
    ...state,
    consumptionThreePhase: {
      ...state.consumptionThreePhase, timeoutWsRequest
    }
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CONSUMPTION_GET_ALL_HOURLY_REQUEST]: getAllHourlyRequest,
  [Types.CONSUMPTION_GET_ALL_HOURLY_REQUEST_SUCCESS]: getAllHourlyRequestSuccess,
  [Types.CONSUMPTION_GET_ALL_HOURLY_REQUEST_FAILURE]: getAllHourlyRequestFailure,
  [Types.CONSUMPTION_ADD_AMBIENT_CONSUMPTION]: addAmbientConsumption,
  [Types.CONSUMPTION_SUBSCRIBE_AMBIENT_CONSUMPTION]: subscribeAmbientConsumption,
  [Types.CONSUMPTION_UNSUBSCRIBE_AMBIENT_CONSUMPTION]: unsubscribeAmbientConsumption,
  [Types.CLEAR_CONSUMPTION_STATE]: clearConsumptionState,
  [Types.SET_SELECTED_THREE_PHASE]: setSelectedThreePhase,
  [Types.UPDATE_SELECTED_THREE_PHASE_CONSUMPTION]: updateSelectedThreePhaseConsumption,
  [Types.UPDATE_POTENCY]: updatePotency,
  [Types.UPDATE_VOLTAGE]: updateVoltage,
  [Types.UPDATE_CURRENT]: updateCurrent,
  [Types.GET_THREE_PHASE_CONSUMPTION_REQUEST]: getThreePhaseConsumptionRequest,
  [Types.GET_THREE_PHASE_CONSUMPTION_REQUEST_SUCCESS]: getThreePhaseConsumptionRequestSuccess,
  [Types.GET_THREE_PHASE_CONSUMPTION_REQUEST_FAILURE]: getThreePhaseConsumptionRequestFailure,
  [Types.CLEAR_CONSUMPTION_THREE_PHASE]: clearConsumptionThreePhase,
  [Types.RESET_CONSUMPTION_THREE_PHASE]: resetConsumptionThreePhase,
  [Types.WEBSOCKET_REQUEST_SET_TIMEOUT]: websocketRequestSetTimeout
})
