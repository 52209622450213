import React, { useEffect, useCallback } from 'react'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { Text, SectionList, View, Pressable } from 'react-native'
import { Left } from '../components/List'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useNavigation, useTheme } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import LogsActions from '../redux/logs'
import { groupBy, map } from 'lodash'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { selectUsersAsList } from '#app/user'

const TYPE_COLORS = {
  user_action: '#FBC02D',
  manual_action: '#673AB6',
  binary_sensor: '#0FC482'
}

export default function Logs () {
  const { logs, status } = useAppSelector(state => state.logs)
  const theme = useAppTheme()
  const navTheme = useTheme()
  const insets = useSafeAreaInsets()
  const users = useAppSelector(selectUsersAsList).map(u => {
    return { label: u.name, value: u.id }
  })
  const slaves = useAppSelector(state => state.slaves.allSlaves)
  const sectionLogs = map(groupBy(logs, (log) => {
    return log.timestamp.split(' ')[0]
  }), (value, index) => {
    return {
      title: index,
      data: value
    }
  })

  const labelify = (device: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device.is_slave) {
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `slave_${device.slave_id}`
      }
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (device.is_channel) {
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `channel_${device.slave_id}_${device.channel}`
      }
    } else { // is_device
      return {
        label: device.name,
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        value: `remote_${device.slave_id}_${device.id}`
      }
    }
  }

  const devices = [
    ...slaves.filter((slave: any) => slave.is_slave).map(labelify),
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    ...slaves.filter((slave: any) => slave.is_channel || slave.is_device).map(labelify)
  ]

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    dispatch(LogsActions.setDefaultFilter({ users, devices }))
    dispatch({ type: 'GET_LOGS_REQUEST', items: { type: 'first' } })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    return () => {
      dispatch({ type: 'CLEAR_LOGS' })
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const onPressFilter = useCallback((param) => {
    navigation.navigate('FilterLogs')
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [dispatch])

  /* const loadMore = useCallback(() => {
    const newDateStart = moment(filters.date_start).startOf('day').subtract(1, 'days')
    const newDateEnd = moment(filters.date_end).endOf('day').subtract(1, 'days')

    dispatch({
      type: 'GET_LOGS_REQUEST',
      items: {
        type: 'date',
        dateStart: newDateStart,
        dateEnd: newDateEnd
      }
    })
  }, []) */

  const checkIsNull = (item: any) => {
    return item !== null
  }

  const renderItemByType = {
    user_action: (item: any) => {
      return (
        <>
          <Text style={{ color: theme.textColor }}>{item.action_type}</Text>

          <View style={{ flexDirection: 'row' }}>
            {checkIsNull(item.slave_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.slave_name}
              </Text>
            )}

            {checkIsNull(item.channel_name) && (
              <Text style={{
                color: theme.textColor,
                marginLeft: 4,
                marginRight: 4
              }}
              >
                -
              </Text>
            )}

            {checkIsNull(item.channel_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.channel_name}
              </Text>
            )}
          </View>
          {checkIsNull(item.ambient_name) && (
            <Text style={{ color: theme.textColor }}>{t`Ambient`}: {item.ambient_name}</Text>
          )}
          {checkIsNull(item.scene_name) && (
            <Text style={{ color: theme.textColor }}>{t`Scene`}: {item.scene_name}</Text>
          )}
          {checkIsNull(item.rfir_command_id) && (
            <Text style={{ color: theme.textColor }}>{t`Command`}: {item.rfir_command_id}</Text>
          )}
          {checkIsNull(item.value) && (
            <Text style={{ color: theme.textColor }}>{t`Action`}: {item.value}</Text>
          )}
          <Text style={{ color: theme.textColor }}>{t`User`}: {item.user_name}</Text>
        </>
      )
    },
    manual_action: (item: any) => {
      return (
        <>
          <View style={{ flexDirection: 'row' }}>
            {checkIsNull(item.slave_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.slave_name}
              </Text>
            )}
            {checkIsNull(item.channel_name) && (
              <Text style={{
                color: theme.textColor,
                marginLeft: 4,
                marginRight: 4
              }}
              >
                -
              </Text>
            )}
            {checkIsNull(item.channel_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.channel_name}
              </Text>
            )}
          </View>
          {checkIsNull(item.value) && (
            <Text style={{ color: theme.textColor }}>{t`Action`}: {item.value}</Text>
          )}
        </>
      )
    },
    binary_sensor: (item: any) => {
      return (
        <>
          <View style={{ flexDirection: 'row' }}>
            {checkIsNull(item.slave_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.slave_name}
              </Text>
            )}
            {checkIsNull(item.channel_name) && (
              <Text style={{
                color: theme.textColor,
                marginLeft: 4,
                marginRight: 4
              }}
              >
                -
              </Text>
            )}
            {checkIsNull(item.channel_name) && (
              <Text style={{ color: theme.textColor }}>
                {item.channel_name}
              </Text>
            )}
          </View>
          {checkIsNull(item.value) && (
            <Text style={{ color: theme.textColor }}>{t`Action`}: {item.value}</Text>
          )}
        </>
      )
    }
  }

  const renderItem = ({
    item
  }: any) => {
    return (
      <View>
        <View style={{ flexDirection: 'row', padding: 15, height: 'auto' }}>
          <Left style={{ flexDirection: 'column' }}>
            {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            <Text style={{ color: TYPE_COLORS[item.raw_type], fontWeight: 'bold' }}>
              {item.type}
            </Text>

            {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
            {renderItemByType[item.raw_type](item)}

            <Text style={{ color: theme.textColor, fontWeight: 'bold' }}>{item.timestamp.split(' ')[1]}</Text>

          </Left>
        </View>
        <View style={theme.list.borderBottom} />
      </View>
    )
  }

  const noContent = () => {
    return (
      <View>
        <View style={{ padding: 15, height: 'auto' }}>
          <Text style={{ color: theme.textColor, textAlign: 'center', alignItems: 'center' }}>
            {status}
          </Text>
        </View>
      </View>
    )
  }

  const _renderSection = ({
    section
  }: any) => {
    return (
      <View style={{ justifyContent: 'center', alignItems: 'center', paddingTop: 8 }}>
        <View style={{ padding: 8, backgroundColor: theme.colors.purple, borderRadius: 8 }}>
          <Text style={[{
            fontSize: 16
          }, { color: 'white' }]}
          >
            {section.title}
          </Text>
        </View>
      </View>
    )
  }

  return (
    <View style={{
      maxHeight: '100%',
      flexBasis: 1,
      flexGrow: 1
    }}>

      <SectionList
        sections={sectionLogs}
        horizontal={false}
        stickySectionHeadersEnabled
        renderSectionHeader={_renderSection}
        renderItem={renderItem}
        ListEmptyComponent={noContent}
        keyExtractor={(item, index) => `${index}`}
      />

      <View style={{
        justifyContent: 'center',
        alignItems: 'flex-start',
        width: '100%',
        backgroundColor: navTheme.colors.card,
        borderTopColor: navTheme.colors.border,
        borderTopWidth: 1,
        paddingTop: 8,
        paddingBottom: insets.bottom + 8,
        paddingLeft: insets.left + 8,
        paddingRight: insets.right + 8
      }}
      >
        <Pressable
          onPress={() => onPressFilter(null)}
          hitSlop={10}
        >
          <MaterialCommunityIcons
            name="filter-variant"
            size={24}
            color={navTheme.colors.text}
          />
        </Pressable>
      </View>
    </View>
  )
}
