import React, { ReactNode } from 'react'
import { KeyboardAvoidingView, Platform, ScrollView, ScrollViewProps, StyleSheet } from 'react-native'

const LoginContainer = (props: LoginContainerProps) =>
  <KeyboardAvoidingView enabled={(Platform.OS === 'ios') || (Platform.OS === 'android')} behavior="padding" style={styles.container}>
    <ScrollView
      bounces={false}
      centerContent
      contentInsetAdjustmentBehavior="automatic"
      indicatorStyle="white"
      contentContainerStyle={styles.contentContainerStyle}
      {...props}
    />
  </KeyboardAvoidingView>

export default LoginContainer

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flex: 1
  },
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    paddingHorizontal: 30,
    maxWidth: 460,
    maxHeight: 720,
    width: '100%',
    ...(Platform.OS !== 'ios' && {
      alignSelf: 'center'
    })
  }
})

export interface LoginContainerProps extends ScrollViewProps {
  children: ReactNode[]
}
