import React from 'react'
import { View, Text, StyleSheet, TextProps, TextStyle } from 'react-native'

const LoginNav = ({ style, ...props }: LoginNavProps) =>
  <View>
    <Text style={StyleSheet.compose<TextStyle>(styles.textNewAccount, style)} {...props} />
  </View>

export default LoginNav

const styles = StyleSheet.create({
  textNewAccount: {
    marginVertical: 30,
    color: '#fff',
    fontSize: 16,
    textAlign: 'center'
  }
})

export interface LoginNavProps extends TextProps {
  children: string
}
