import React from 'react'
import { StyleSheet, View, ActivityIndicator, Text } from 'react-native'
import { useAppTheme } from '#app/theme'

interface OwnProps {
  text: string
  loading?: boolean
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof TextLoading.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'styleIndicator' does not exist on type '... Remove this comment to see the full error message
export default function TextLoading ({ loading, text, styleIndicator, style }: Props) {
  const theme = useAppTheme().titleLoading

  return (
    <View style={[{ justifyContent: 'space-between', flexDirection: 'row' }, { ...style }]}>
      <Text style={[styles.text, { color: theme.textColor }]}>{text}</Text>
      {loading && (
        <ActivityIndicator color={theme.indicatorColor} size="small" style={styleIndicator} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  text: {
    textAlignVertical: 'center',
    margin: 1,
    fontSize: 14,
    fontWeight: 'bold'
  },
  styleIndicator: {
    width: 11,
    height: 11
  },
  sceneBox: {
    marginTop: 15,
    flexDirection: 'column',
    width: '100%'
  },
  scenes: {
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    flexWrap: 'wrap'
  }
})

TextLoading.defaultProps = {
  text: String,
  loading: false
}
