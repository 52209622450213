import React from 'react'
import { View, StyleSheet } from 'react-native'
import AmbientList from './AmbientList'
import NoContent from './Utils/NoContent'
import { useAppTheme } from '#app/theme'

const AmbientBox = ({ ambients, style }: AmbientBoxProps) => (
  <View style={[styles.box, style]}>
    <AmbientList
      horizontal
      data={ambients}
      ListEmptyComponent={ListEmptyComponent}
      contentContainerStyle={styles.listContainer}
    />
  </View>
)

export default AmbientBox

const ListEmptyComponent = () => {
  const theme = useAppTheme()
  return <NoContent theme={theme} type="ambients" style={styles.listEmpty} />
}

const styles = StyleSheet.create({
  box: {
    marginBottom: 20,
    flexDirection: 'column',
    width: '100%'
  },
  listContainer: {
    flexGrow: 1,
    paddingHorizontal: 15
  },
  listEmpty: {
    display: 'flex',
    flexGrow: 1
  }
})
interface AmbientBoxProps {
  ambients?: string[]
  style?: any
}
