import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import { View, ScrollView, ActivityIndicator } from 'react-native'

import { Error } from '../components/Utils'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { Picker } from '@react-native-picker/picker'

import { ScreenProps, SlaveConfigCurrentTransformerFormData } from '#app/types'
import { selectSlaveClampTypeForEdit, updateSlaveCurrentTransformer, slaveConfigClampTypeList } from '#app/slave'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'

const SlaveClamp = ({ navigation, route: { params: { id } } }: ScreenProps<'SlaveDetail'>) => {
  const theme = useAppTheme()

  const { defaultValues, clampTypes } = useAppSelector(state => selectSlaveClampTypeForEdit(state, { id }))
  const { control, handleSubmit, formState: { isDirty } } = useForm<SlaveConfigCurrentTransformerFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<SlaveConfigCurrentTransformerFormData>>((data) => {
    dispatch(updateSlaveCurrentTransformer({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'slave/updateCurrentTransformer' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        isLoading
          ? <ActivityIndicator />
          : isDirty
            ? <HeaderButtons>
                <HeaderItem
                  title={t`Save`}
                  iconName="save"
                  onPress={handleSubmit(onSubmit)}
                />
              </HeaderButtons>
            : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      <View style={theme.form.area}>
        {showError &&
          <View style={theme.form.formGroup}>
            <Error>{message}</Error>
          </View>
        }
        <Controller
          control={control}
          name="clampType"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <Picker
              ref={ref}
              onBlur={onBlur}
              selectedValue={value === null ? '' : value}
              onValueChange={v => onChange(v === '' ? null : Number(v))}
            >
              {clampTypes.map((item, idx) =>
                <Picker.Item key={`${idx}`} {...item} />
              )}
            </Picker>
          }
        />
      </View>
    </ScrollView>
  )
}

export default SlaveClamp

const schema: Yup.SchemaOf<SlaveConfigCurrentTransformerFormData> = Yup.object({
  clampType: Yup.mixed().oneOf(slaveConfigClampTypeList).nullable().defined()
}).required()
