import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { HeaderButtons, Item as HeaderItem } from 'react-navigation-header-buttons'
import { TextInput, ScrollView, View, ActivityIndicator } from 'react-native'
import { Separator, PickerCell, SelectCell } from '../components/List'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import {
  rfirDeviceCategoryList,
  rfirDeviceOutputList,
  rfirDeviceTypeList,
  selectRfIrDeviceForCreateOrUpdate,
  selectRfIrCommandsForPicker,
  selectRfIrDeviceCategoriesForPicker,
  selectRfIrDeviceOutputsForPicker,
  selectRfIrDeviceTypesForPicker,
  createRfIrDevice,
  deleteRfIrDevice,
  updateRfIrDevice
} from '#app/rfirdevice'
import { RfIrDeviceConfigFormData, ScreenProps } from '#app/types'

import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'
import DeleteButton from '#app/components/Form/DeleteButton'

const CreateOrUpdateRfIrDevice = ({ navigation, route: { params } }: ScreenProps<'IrAccessory'> | ScreenProps<'CreateRfIrDevice'>) => {
  const theme = useAppTheme()

  const categories = useAppSelector(selectRfIrDeviceCategoriesForPicker)
  const outputs = useAppSelector(selectRfIrDeviceOutputsForPicker)
  const types = useAppSelector(selectRfIrDeviceTypesForPicker)
  const { id, defaultValues } = useAppSelector(state => selectRfIrDeviceForCreateOrUpdate(state, params))
  const commands = useAppSelector(state => selectRfIrCommandsForPicker(state, { id }))
  const { control, handleSubmit, formState: { isDirty } } = useForm<RfIrDeviceConfigFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<RfIrDeviceConfigFormData>>((data) => {
    dispatch(id === ''
      ? createRfIrDevice({ data })
      : updateRfIrDevice({ id, data }))
  }, [dispatch, id])
  useEffect(() => {
    navigation.getParent()?.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  const loaderId = id === ''
    ? 'rfirDevice/create'
    : 'rfirDevice/update'
  const loader = useAppSelector(state => selectLoaderById(state, { id: loaderId }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.getParent()?.goBack()
  }, [navigation])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : isDirty
          ? <HeaderButtons>
            <HeaderItem
              title={t`Save`}
              iconName="save"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>
          : <></>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading, isDirty])

  return (
    <ScrollView contentInsetAdjustmentBehavior="automatic">
      {showError && <Error>{message}</Error>}

      <View style={theme.form.area}>
        <Controller
          control={control}
          name="rfirDevice.name"
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <TextInput
              ref={ref}
              style={theme.form.input}
              placeholder={t`Name`}
              placeholderTextColor={theme.formForm.placeholderTextColor}
              autoCapitalize="words"
              autoCorrect={false}
              onChangeText={onChange}
              value={value}
              onBlur={onBlur}
            />
          }
        />
      </View>

      <Separator text=""/>

      <Controller
        control={control}
        name="rfirDevice.type"
        render={({ field: { ref, onChange, onBlur, value } }) =>
          <SelectCell
            ref={ref}
            selectedValue={value}
            onValueChange={onChange}
            onBlur={onBlur}
            title={t`Connection`}
            items={types}
          />
        }
      />

      <Controller
        control={control}
        name="rfirDevice.category"
        render={({ field: { ref, onChange, onBlur, value } }) =>
          <SelectCell
            ref={ref}
            selectedValue={value}
            onValueChange={onChange}
            onBlur={onBlur}
            title={t`Type`}
            items={categories}
          />
        }
      />

      <Controller
        control={control}
        name="rfirDevice.output"
        render={({ field: { ref, onChange, onBlur, value } }) =>
          <SelectCell
            ref={ref}
            selectedValue={value}
            onValueChange={onChange}
            onBlur={onBlur}
            title={t`Output`}
            items={outputs}
            />
        }
      />

      <Separator text=""/>
      {id !== '' &&
        <>
          <Controller
            control={control}
            name="rfirDevice.commandOnId"
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <PickerCell
                id="commandOnId"
                title={t`Power on command`}
                items={commands}
                mode="single"
                value={typeof value === 'number' ? String(value) : null}
                onChange={v => onChange(v == null ? null : Number(v))}
                onBlur={onBlur}
              />
            }
          />
          <Controller
            control={control}
            name="rfirDevice.commandOffId"
            render={({ field: { ref, onChange, onBlur, value } }) =>
              <PickerCell
                id="commandOffId"
                title={t`Power off command`}
                items={commands}
                mode="single"
                value={typeof value === 'number' ? String(value) : null}
                onChange={v => onChange(v == null ? null : Number(v))}
                onBlur={onBlur}
              />
            }
          />
          <Separator text=""/>
        </>
      }

      {id !== '' &&
        <View style={theme.form.area}>
          <DeleteButton
            id={id}
            title={t`Delete Device`}
            loader="rfirDevice/delete"
            creator={deleteRfIrDevice}
          />
        </View>
      }
    </ScrollView>
  )
}

export default CreateOrUpdateRfIrDevice

const schema: Yup.SchemaOf<RfIrDeviceConfigFormData> = Yup.object({
  rfirDevice: Yup.object({
    name: Yup.string().required(),
    type: Yup.mixed()
      .oneOf(rfirDeviceTypeList)
      .required(),
    output: Yup.mixed()
      .oneOf(rfirDeviceOutputList)
      .required(),
    category: Yup.mixed()
      .oneOf(rfirDeviceCategoryList)
      .required(),
    commandOnId: Yup.number().nullable().defined(),
    commandOffId: Yup.number().nullable().defined()
  }).required()
}).required()
