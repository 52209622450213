import React from 'react'

import { ScreenProps } from '#app/types'
import { swapSlave } from '#app/slave'

import { Component } from './NewSlaveStepCode'
import { ActionCreator, AnyAction } from 'redux'
import { merge, set } from 'lodash/fp'

const withId = (id: string) =>
  <A extends AnyAction = AnyAction,>(creator: ActionCreator<A>) =>
    (...args: any[]) =>
      merge(creator(...args), set('payload.id', id, {}))

const SwapSlaveStepCode = ({ navigation, route }: ScreenProps<'SlaveDetail'>) =>
  <Component goTo="SwapSlaveConfig" action={withId(route.params.id)(swapSlave)} loader="slave/swap" />

export default SwapSlaveStepCode
