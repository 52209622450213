import React from 'react'
import GenericButton from '../GenericButton'
import { t } from 'ttag'

function getIcon () {
  return 'pulse'
}

interface OwnProps {
  children?: React.ReactNode
  onPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof PulseUp.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'onLongPress' does not exist on type 'Pro... Remove this comment to see the full error message
export default function PulseUp ({ onPress, onLongPress, offline, text, connectionStatus, confirm, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon={getIcon()}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; connectionStatus:... Remove this comment to see the full error message
      text={text}
      connectionStatus={connectionStatus}
      iconOnColor="#f59b10"
      on
      status={t`Pulse Up`}
      offline={offline}
      onPress={onPress}
      onLongPress={onLongPress}
      confirm={confirm}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

PulseUp.defaultProps = {
  children: null,
  onPress: () => { }
}
