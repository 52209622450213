import { pick } from 'lodash'
import { schema } from 'normalizr'
import {
  Ambient,
  AmbientPermission,
  Central,
  CentralUser,
  User
} from '#app/types'
import moment from 'moment'

export const slave = new schema.Entity('slaves', {})
export const ambient = new schema.Entity<Ambient>(
  'ambients',
  { slaves: [slave] },
  {
    idAttribute: v => String(v.id),
    processStrategy: v => ({
      ...v,
      id: String(v.id),
      slaves: v.slaves ?? []
    })
  }
)
export const ambientPermission = new schema.Entity<AmbientPermission>('ambientpermissions', undefined, {
  processStrategy: ({ id, ambientId, user: userId }) => ({
    id: String(id),
    ambientId: String(ambientId),
    userId
  })
})
export const central = new schema.Entity<Central>('centrals', undefined, {
  idAttribute: (value) => value.central_id ?? value.UUID,
  processStrategy: (value): Central => ({
    ...pick(value, ['role', 'token', 'serial', 'mac', 'yggdrasilIP']),
    name: value.name ?? '',
    id: value.central_id ?? value.UUID,
    createdAt: moment(value.createdAt).valueOf()
  })
})
export const centralUser = new schema.Entity<CentralUser>('centralusers', {}, {
  // idAttribute: ({ CentralUUID: centralId, UserUUID: userId }) => `${String(centralId)}_${String(userId)}`,
  idAttribute: ({ id }) => String(id),
  processStrategy: ({ CentralUUID: centralId, UserUUID: userId, name, role, notify, expiresAt, id }) => ({
    // id: `${String(centralId)}_${String(userId)}`,
    id: String(id),
    centralId,
    userId,
    name,
    role,
    notify,
    expiresAt
  })
})
export const user = new schema.Entity<User>(
  'users',
  {
    centrals: [central],
    centralUser: [centralUser]
  },
  {
    idAttribute: (value) => value.UUID,
    processStrategy: (value) => {
      const centrals = value.CentralUser?.CentralUUID != null
        ? [value.CentralUser?.CentralUUID]
        : value.centrals
      return {
        ...pick(value, ['name', 'role', 'email']),
        id: value.UUID,
        centrals,
        centralUser: [value.CentralUser]
      }
    },
    mergeStrategy: (entityA, entityB) => ({
      ...entityA,
      ...entityB,
      centrals: [...entityA.centrals, ...entityB.centrals],
      centralUser: [...entityA.centralUser, ...entityB.centralUser]
    })
  }
)

export const authResponse = user
export const getAmbientsResponse = [ambient]
export const addAmbientResponse = ambient
export const updateAmbientResponse = ambient
export const getAmbientPermissionsResponse = [ambientPermission]
export const updateAmbientPermissionsResponse = [ambientPermission]
export const getUserCentralsResponse = user
export const getCentralsResponse = [central]
export const getUsersResponse = [user]
export const associateUserResponse = centralUser
export const updateCentralUserResponse = centralUser
