import React, { useEffect } from 'react'
import RemoteControlGrid from '../components/RemoteControl/RemoteControlGrid'
import { ScrollView } from 'react-native'
import { ScreenProps } from '#app/types'

const RemoteControl = ({ navigation, route: { params: { remote } } }: ScreenProps<'RemoteControl'>) => {
  useEffect(() => {
    navigation.setOptions({ title: remote.name })
  }, [navigation, remote.name])
  return (
      <ScrollView>
        <RemoteControlGrid remote={remote} />
      </ScrollView>
  )
}

export default RemoteControl
