import React, { useState, useCallback, useEffect } from 'react'
import { Switch, ActivityIndicator, TextInput, Text, View, TouchableOpacity, ScrollView } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { Separator, Left, ItemPress, Right } from '../components/List'
import ButtonForm from '../components/Form/ButtonForm'
import Icon from '../components/Icon'
import DeviceSelector from '../components/DeviceSelector'
import MyioAlarm from '../components/MyioAlarm'
import AlarmsActions from '../redux/alarms'
import { map, get, filter, find, remove, isEmpty } from 'lodash'
import { showMessage } from 'react-native-flash-message'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { RootStackParamList } from '#app/routes'
import { selectUsersAsList } from '#app/user'
import { User } from '#app/types'
import { fetchCentralUsers } from '#app/centraluser'
import { selectCurrentCentralId } from '#app/central'
import { useFocusEffect } from '@react-navigation/native'

const AlarmForm = ({
  navigation,
  route
}: Props) => {
  const id = route.params?.id

  const DEFAULT_ALARM = { startHour: 3, startMinute: 0, offsetMinutes: 1439, weekDays: [], disabled: false }
  const TYPES = [
    { value: 'CONSUMPTION_ZERO', label: gettext('Device without consumption') },
    { value: 'CONSUMPTION_USE_HOURS', label: gettext('Consumption during unauthorized hours') },
    { value: 'USE_HOURS', label: gettext('Action in unauthorized time') },
    { value: 'CONSUMPTION_OVER', label: gettext('Consumption Over Default') },
    { value: 'CONSUMPTION_UNDER', label: gettext('Consumption Under Default') },
    { value: 'NO_COMM', label: gettext('No Communication') },
    { value: 'SENSOR_DETECTED', label: gettext('Sensor Detection') }
  ]

  const [alarmList, setAlarmList] = useState([])
  const [selectedUsers, setSelectedUsers] = useState<Array<User['id']>>([])
  const [channels, setChannels] = useState([])
  const [type, setType] = useState(TYPES[0])
  const [slave, setSlave] = useState({})
  const [channel, setChannel] = useState({})
  const [alarmName, setAlarmName] = useState('')
  const [selectedDevice, setSelectedDevice] = useState(null)
  const [selectedDeviceValue, setSelectedDeviceValue] = useState(100)

  const slaves = useAppSelector(state => state.slaves.allSlaves)
  const theme = useAppTheme()
  const loading = useAppSelector(state => state.alarms.loading)
  const alarms = useAppSelector(state => state.alarms.alarms)
  const users = useAppSelector(selectUsersAsList)

  const dispatch = useAppDispatch()
  const centralId = useAppSelector(selectCurrentCentralId)
  useFocusEffect(useCallback(() => {
    dispatch(fetchCentralUsers({ id: centralId }))
  }, [dispatch, centralId]))

  useEffect(() => {
    if (alarmList.length === 0) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ startHour: number; startMinute: number; of... Remove this comment to see the full error message
      setAlarmList([DEFAULT_ALARM])
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [alarmList])

  useEffect(() => { navigation.setOptions({ title: gettext('New Alarm') }) })
  useEffect(() => {
    if (id == null) return

    const alarm = find(alarms, { id })
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!alarm) { return }

    const mType = get(alarm, 'type')
    const mName = get(alarm, 'name')
    const mSlaveId = get(alarm, 'slave_id')
    const mSlave = find(slaves, { id: mSlaveId, is_slave: true })
    const mChannelId = get(alarm, 'extra.channel')
    const mChannel = find(slaves, { slave_id: mSlaveId, channel: mChannelId })
    const mValue = get(alarm, 'extra.value')
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    const mUsers = get(alarm, 'extra.users') || []
    const mAlarmList = get(alarm, 'extra.alarms').map((i: any) => {
      return {
        startHour: i.start_hour,
        startMinute: i.start_minute,
        offsetMinutes: i.offset_minutes,
        weekDays: i.week_days,
        disabled: i.disabled
      }
    })

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (mSlave) {
      setSlave({ label: mSlave.name, value: mSlave.id })
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const mChannels = mSlave.channels || []
      const slaveChannels = map(mChannels, (channel) => (
        {
          label: channel.name,
          value: { channel_id: channel.id, channel: channel.channel }
        }
      ))
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: any; value: { channel_i... Remove this comment to see the full error message
      setChannels(slaveChannels)
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ value: string; label: string; ... Remove this comment to see the full error message
    setType(find(TYPES, { value: mType }))
    setSelectedUsers(mUsers)
    setAlarmList(mAlarmList)
    setAlarmName(mName)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (mChannel) {
      const mDevice = find(slaves, { slaveId: mSlaveId, channel: channel })

      const channelObj = {
        label: mChannel.name,
        value: { channel_id: mChannel.id, channel: mChannel.channel }
      }

      setSelectedDevice(mDevice)
      setChannel(channelObj)
    }

    if (mType === 'SENSOR_DETECTED') {
      setSelectedDeviceValue(parseInt(mValue, 10))
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      setSelectedDeviceValue(`${mValue}`)
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [alarms])

  useEffect(() => {
    navigation.setOptions({
      title: gettext('Edit Alarm'),
      headerRight: (state: any) => {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return loading
          ? <ActivityIndicator style={{ marginRight: 16 }} />
          : (
            <HeaderButtons>
              <Item
                title={t`Save`}
                iconName="save"
                onPress={validateAlarm}
              />
            </HeaderButtons>
            )
      }
    })
  })

  const showError = (description: any) => {
    showMessage({ message: gettext('Invalid Alarm.'), description, type: 'danger', duration: 4000 })
  }

  const validateAlarm = useCallback(() => {
    if (isEmpty(slave)) {
      showError(gettext('Slave is required.'))
      return
    }

    if (isEmpty(type)) {
      showError(gettext('Alarm type is required.'))
      return
    }

    if (alarmName.length === 0) {
      showError(gettext('Name is required.'))
      return
    }

    if (selectedUsers.length === 0) {
      showError(gettext('Users are required.'))
      return
    }

    if (alarmList.length === 0) {
      showError(gettext('Alert time is required.'))
      return
    }

    const payload = {
      type: get(type, 'value'),
      slave: get(slave, 'value'),
      channel_id: get((channel as any).value, 'channel_id'),
      channel: get((channel as any).value, 'channel'),
      name: alarmName,
      alarmList,
      users: selectedUsers,
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
      value: parseInt(selectedDeviceValue, 10)
    }

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (id) {
      dispatch(AlarmsActions.alarmsUpdateRequest({ ...payload, id }))
    } else {
      dispatch(AlarmsActions.alarmsSaveRequest(payload))
    }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [
    type,
    slave,
    channel,
    alarmName,
    alarmList,
    selectedDeviceValue,
    loading,
    selectedUsers
  ])

  useEffect(() => {
    const device = find(slaves, { slaveId: get(slave, 'value'), channel: get(channel, 'value') })
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (device) { setSelectedDevice(device) }
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [slave, channel])

  const toggleSwitch = useCallback((value, indexAlarm) => {
    const newAlarmList = alarmList.map((item, idx) => {
      if (idx === indexAlarm) (item as any).disabled = value
      return item
    })
    setAlarmList(newAlarmList)
  }, [alarmList])

  const toggleDay = useCallback((day, indexAlarm) => {
    const newAlarmList = alarmList.map((item, idx) => {
      if (idx === indexAlarm) {
        const exists = (item as any).weekDays.includes(day)
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        exists ? remove((item as any).weekDays, (e) => e === day) : (item as any).weekDays.push(day)
      }

      return item
    })
    setAlarmList(newAlarmList)
  }, [alarmList])

  const removeAlarm = useCallback((indexAlarm) => {
    if (alarmList.length >= 2) setAlarmList(alarmList.filter((i, index) => index !== indexAlarm))
  }, [alarmList])

  const insertAlarm = useCallback(() => {
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ startHour: number; startMinute: number; of... Remove this comment to see the full error message
    setAlarmList([...alarmList, DEFAULT_ALARM])
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [alarmList])

  const onTimeChange = useCallback((indexAlarm, model) => {
    const newAlarmList = alarmList.map((item, idx) => {
      if (idx === indexAlarm) {
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        return { ...item, ...model }
      }
      return item
    })
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setAlarmList(newAlarmList)
  }, [alarmList])

  const onDevicePress = useCallback(() => {
    setSelectedDeviceValue(selectedDeviceValue === 100 ? 0 : 100)
  }, [selectedDeviceValue])

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onPressOkSlave = useCallback((device) => {
    const selectedSlave = find(slaves, (slave) => {
      return slave.id === device.value
    })

    setChannels(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ label: any; value: { channel_i... Remove this comment to see the full error message
      map(
        get(selectedSlave, 'channels'),
        (channel) => ({
          label: channel.name,
          value: { channel_id: channel.id, channel: channel.channel }
        })
      )
    )

    setSlave(device)
    setChannel({})
    setSelectedDevice(null)
  })

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onRemove = useCallback(() => dispatch(AlarmsActions.alarmsDeleteRequest({ id })))

  const filterSlaves = useCallback(() => {
    return map(filter(slaves, (slave) => {
      if ([
        'CONSUMPTION_ZERO',
        'CONSUMPTION_USE_HOURS',
        'CONSUMPTION_OVER',
        'CONSUMPTION_UNDER'
      ].includes(type.value)) {
        return (
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          (slave.is_slave &&
          slave.type !== 'ir' && slave.type !== 'infrared')
        )
      } else if ([
        'NO_COMM'
      ].includes(type.value)) {
        return slave.is_slave
      } else {
        return (
          // TODO: Fix this the next time the file is edited.
          // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
          (slave.is_slave &&
          slave.type !== 'infrared' && slave.type !== 'ir')
        )
      }
    }), (slave) => {
      return {
        label: slave.name,
        value: slave.id
      }
    })
  }, [type, slaves])

  const onUserSwitchToggle = useCallback((id) => {
    let newSelectedUsers
    if (selectedUsers.includes(id)) {
      newSelectedUsers = filter(selectedUsers, (user) => user !== id)
    } else {
      newSelectedUsers = [...selectedUsers, id]
    }
    setSelectedUsers(newSelectedUsers)
  }, [selectedUsers])

  return (
      <ScrollView>

        <Separator text={t`Alarm name`} />

        <View style={[theme.form.area]}>
          <View style={theme.form.formGroup}>
            <TextInput
              style={[theme.form.input]}
              placeholder={t`Ex: open office window.`}
              placeholderTextColor={theme.formForm.placeholderTextColor}
              autoCapitalize="none"
              value={alarmName}
              onChangeText={setAlarmName}
            />
          </View>
        </View>

        <Separator text={t`Alarm Type`} />

        <View style={[theme.form.formGroup, { padding: 16 }]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Alarm Type'),
                data: TYPES,
                value: type,
                search: true,
                multiple: false,
                required: true,
                onPressOk: (data: any) => {
                  setType(data.selectedItem)
                  setSlave({})
                  setChannel({})
                  setSelectedDevice(null)
                }
              })
            }}
            style={theme.form.inputTouchable}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {type.label}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        <Separator text={t`Product`} />

        <View style={[theme.form.formGroup, { padding: 16 }]}>
          <TouchableOpacity
            onPress={() => {
              navigation.navigate('ModalPicker', {
                name: gettext('Product'),
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                data: filterSlaves(slaves),
                value: slave,
                search: true,
                multiple: false,
                required: true,
                onPressOk: (data: any) => {
                  onPressOkSlave(data.selectedItem)
                }
              })
            }}
            style={theme.form.inputTouchable}
          >
            <Text style={{ fontSize: 15, color: theme.textColor }}>
              {/* TODO: Fix this the next time the file is edited. */}
              {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
              {get(slave, 'label') || gettext('Select an product...')}
            </Text>
            <Right>
              <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
            </Right>
          </TouchableOpacity>
        </View>

        {
          ([
            'USE_HOURS',
            'SENSOR_DETECTED'
          ].includes(type.value)) && (
            <>
              <Separator text={t`Accessory`} />
              <View style={[theme.form.formGroup, { padding: 16 }]}>
                <TouchableOpacity
                  onPress={() => {
                    navigation.navigate('ModalPicker', {
                      name: gettext('Accessory'),
                      data: channels,
                      value: channel,
                      search: true,
                      multiple: false,
                      required: true,
                      onPressOk: (data: any) => {
                        setChannel(data.selectedItem)
                      }
                    })
                  }}
                  style={theme.form.inputTouchable}
                >
                  <Text style={{ fontSize: 15, color: theme.textColor }}>
                    {/* TODO: Fix this the next time the file is edited. */}
                    {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                    {get(channel, 'label') || gettext('Select an accessory...')}
                  </Text>
                  <Right>
                    <Icon name="chevron-down-circle-outline" size={20} color={theme.colors.grey} />
                  </Right>
                </TouchableOpacity>
              </View>
            </>
          )
        }

        {
          ([
            'CONSUMPTION_OVER',
            'CONSUMPTION_UNDER'
          ].includes(type.value)) && (
            <>
              <Text style={{ color: theme.textColor, marginBottom: 5 }}>
                {type.value === 'CONSUMPTION_OVER' ? gettext('Consumption above of:') : gettext('Consumption below of:')}
              </Text>

              <View style={{ flexDirection: 'row' }}>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <TextInput
                  value={selectedDeviceValue}
                  textAlign="center"
                  onChangeText={setSelectedDeviceValue}
                  style={[theme.form.input, { width: 150 }]}
                />

                <View style={{ justifyContent: 'center' }}>
                  <Text style={{ fontSize: 18 }}> W</Text>
                </View>
              </View>
            </>
          )
        }

        {
          ((['SENSOR_DETECTED'].includes(type.value)) && selectedDevice) && (
            <>
              <Separator text={t`Alarm when accessory is:`} />
              <View style={{ justifyContent: 'center', alignItems: 'center', padding: 12 }}>
                <DeviceSelector
                  device={{
                    // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                    ...selectedDevice,
                    value: selectedDeviceValue
                  }}
                  onPress={onDevicePress}
                />
              </View>
            </>
          )
        }

        {
          ([
            'CONSUMPTION_ZERO',
            'CONSUMPTION_USE_HOURS',
            'CONSUMPTION_OVER',
            'CONSUMPTION_UNDER',
            'USE_HOURS',
            'SENSOR_DETECTED'
          ].includes(type.value)) && (
            <Separator text={t`Alarms`} />
          )
        }

        <View style={{ padding: 15 }}>
          {
            ([
              'CONSUMPTION_ZERO',
              'CONSUMPTION_USE_HOURS',
              'CONSUMPTION_OVER',
              'CONSUMPTION_UNDER',
              'USE_HOURS',
              'SENSOR_DETECTED'
            ].includes(type.value)) && (
              <View>
                {
                  alarmList.map((item, index) =>
                    <MyioAlarm
                      key={index}
                      index={index}
                      item={item}
                      theme={theme}
                      toggleSwitch={toggleSwitch}
                      toggleDay={toggleDay}
                      removeAlarm={removeAlarm}
                      onTimeChange={onTimeChange}
                    />
                  )
                }

                <ButtonForm
                  title={t`New`}
                  btnStyle={theme.form.btn}
                  color={theme.colors.grey}
                  onPress={insertAlarm}
                />
              </View>
            )
          }
        </View>

        <View>
          <Separator text={t`Users to notify`} />
          <ScrollView>
            {
              users.map((user) => <ItemPress key={user.id}>
                <Left style={{ flexDirection: 'column' }}>
                  <Text style={{ color: theme.textColor }}>{user.name}</Text>
                  <Text style={{ color: theme.textColor }}>{user.email}</Text>
                </Left>

                <Right>
                  <Switch
                    onValueChange={() => onUserSwitchToggle(user.id)}
                    value={selectedUsers.includes(user.id)}
                  />
                </Right>
              </ItemPress>
              )
            }
          </ScrollView>
        </View>

        <View style={[theme.form.area]}>
          <ButtonForm
            title={t`Delete Alarm`}
            btnStyle={theme.form.btn}
            color={theme.colors.grey}
            onPress={onRemove}
          />
        </View>
      </ScrollView>
  )
}

export default AlarmForm

type Props = NativeStackScreenProps<RootStackParamList, 'AlarmForm'>
