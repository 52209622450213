import { AnyAction } from 'redux'
import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  getFavoritesRequest: null,
  getFavoritesRequestSuccess: ['payload'],
  getFavoritesRequestFailure: null,
  updateFavoritesRequest: ['payload'],
  updateFavoritesRequestSuccess: ['payload'],
  updateFavoritesRequestFailure: ['payload'],
  clearFavoritesState: null,
  updateFavoriteDeviceList: ['payload']
})

export const INITIAL_STATE = {
  favorites: {
    ambients: [] as string[],
    channels: [] as number[],
    devices: [] as number[],
    energy: [] as number[],
    temperature: [] as number[],
    scenes: [] as number[]
  },
  loading: false,
  error: false
}

export default Creators
export type FavoritesState = typeof INITIAL_STATE
export type FavoritesReducer = (state: FavoritesState, action: AnyAction) => FavoritesState

const clearFavoritesState: FavoritesReducer = (state, action) => {
  return { ...INITIAL_STATE }
}

const getFavoritesRequest: FavoritesReducer = (state, action) => {
  return {
    ...state,
    loading: true,
    error: false
  }
}

const getFavoritesRequestSuccess: FavoritesReducer = (state, action) => {
  const { payload } = action

  return {
    ...state,
    favorites: payload,
    loading: false,
    error: false
  }
}

const getFavoritesRequestFailure: FavoritesReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    error: true
  }
}

const updateFavoritesRequest: FavoritesReducer = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const updateFavoritesRequestSuccess: FavoritesReducer = (state, { payload: favorites }) => {
  return {
    ...state,
    favorites,
    loading: false
  }
}

const updateFavoritesRequestFailure: FavoritesReducer = (state, action) => {
  return {
    ...state,
    loading: false
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_FAVORITES_STATE]: clearFavoritesState,
  [Types.GET_FAVORITES_REQUEST]: getFavoritesRequest,
  [Types.GET_FAVORITES_REQUEST_SUCCESS]: getFavoritesRequestSuccess,
  [Types.GET_FAVORITES_REQUEST_FAILURE]: getFavoritesRequestFailure,
  [Types.UPDATE_FAVORITES_REQUEST]: updateFavoritesRequest,
  [Types.UPDATE_FAVORITES_REQUEST_SUCCESS]: updateFavoritesRequestSuccess,
  [Types.UPDATE_FAVORITES_REQUEST_FAILURE]: updateFavoritesRequestFailure
})
