import { REHYDRATE } from 'redux-persist/lib/constants'
import { takeLatest, fork, all } from 'redux-saga/effects'
import slaves, { getSlaves, setSlavesToFilter } from './slaves'
import scene, { getScenes, setSlave, setScene, deleteScene, createScene, updateScene, insertSlaveToList, getAndSetSlaveIr, findCommandIr } from './scene'
import ambients, { saveAmbientFilter, clearAmbientFilters, uploadImage, hideDeviceAmbient, updatePositionRequest } from './ambients'
import app from './app'
import { unsubscribeConsumption, subscribeConsumption, getAllConsumptionHourly, getThreePhaseConsumption } from './consumption'
import { setRemote, setActionModal, setRemoteAndActionModal, setNewButton, updateButton, setEditButton, setSaveStatusButton, saveButton, deleteButton, saveFakeButton, updateButtonsOrder } from './remote'
import ws from './ws'
import { getAlertsHistory } from './alerts'
import { getAlarms, saveAlarm, updateAlarm, deleteAlarm, onClickAlarmNotification } from './alarms'
import { reloadLogs } from './logs'
import { getSchedules, createSchedule, updateSchedule, deleteSchedule } from './schedules'
import { getFavorites, updateFavorites, updateFavoriteDeviceList } from './favorites'
import notification from './notification'
import ui from './ui'
import i18n from './i18n'
import { saga as ambient } from '#app/ambient'
import { saga as central } from '#app/central'
import { saga as centraluser } from '#app/centraluser'
import { saga as environment } from '#app/environment'
import { saga as user } from '#app/user'
import { saga as slave } from '#app/slave'
import { saga as channel } from '#app/channel'
import { saga as rfirdevice } from '#app/rfirdevice'

const sagas = [
  ambient,
  ambients,
  app,
  central,
  centraluser,
  channel,
  environment,
  i18n,
  notification,
  rfirdevice,
  scene,
  slave,
  slaves,
  ui,
  user,
  ws
]

function * rootSaga () {
  yield all([
    ...sagas.map(s => fork(s)),
    takeLatest('ALARMS_GET_REQUEST', getAlarms),
    takeLatest('ALARMS_SAVE_REQUEST', saveAlarm),
    takeLatest('ALARMS_UPDATE_REQUEST', updateAlarm),
    takeLatest('ALARMS_DELETE_REQUEST', deleteAlarm),
    takeLatest('ALERTS_GET_ALERTS_HISTORY', getAlertsHistory),
    takeLatest('SAVE_AMBIENT_FILTER', saveAmbientFilter),
    takeLatest('CLEAR_AMBIENT_FILTERS', clearAmbientFilters),
    takeLatest('CONSUMPTION_UNSUBSCRIBE_AMBIENT_CONSUMPTION', unsubscribeConsumption),
    takeLatest('CONSUMPTION_SUBSCRIBE_AMBIENT_CONSUMPTION', subscribeConsumption),
    takeLatest('CONSUMPTION_GET_ALL_HOURLY_REQUEST', getAllConsumptionHourly),
    takeLatest('DATA_GET_SLAVES_REQUEST', getSlaves),
    takeLatest('DATA_SET_SLAVES_TO_FILTER', setSlavesToFilter),
    takeLatest('DATA_SET_SELECTED_SLAVE', setSlave),
    takeLatest('DATA_GET_SCENES_REQUEST', getScenes),
    takeLatest('DATA_SET_SELECTED_SCENE', setScene),
    takeLatest('CREATE_SCENE_REQUEST', createScene),
    takeLatest('DELETE_SCENE_REQUEST', deleteScene),
    takeLatest('UPDATE_SCENE_REQUEST', updateScene),
    takeLatest('GET_AND_SET_SLAVE_IR_REQUEST', getAndSetSlaveIr),
    takeLatest('FIND_COMMAND_IR', findCommandIr),
    takeLatest('DATA_INSERT_SLAVE_TO_LIST', insertSlaveToList),
    takeLatest('REMOTE_SET_REMOTE', setRemote),
    takeLatest('REMOTE_SET_ACTION_MODAL', setActionModal),
    takeLatest('REMOTE_SET_REMOTE_AND_ACTION_MODAL', setRemoteAndActionModal),
    takeLatest('REMOTE_SET_REMOTE_AND_ACTION_MODAL', setRemoteAndActionModal),
    takeLatest('REMOTE_SET_NEW_BUTTON', setNewButton),
    takeLatest('REMOTE_SET_EDIT_BUTTON', setEditButton),
    takeLatest('REMOTE_SET_SAVE_STATUS_BUTTON', setSaveStatusButton),
    takeLatest('REMOTE_UPDATE_MODEL_BUTTON', updateButton),
    takeLatest('SAVE_BUTTON_REQUEST', saveButton),
    takeLatest('SAVE_FAKE_BUTTON_REQUEST', saveFakeButton),
    takeLatest('DELETE_BUTTON_REQUEST', deleteButton),
    takeLatest('UPDATE_BUTTONS_ORDER_REQUEST', updateButtonsOrder),
    takeLatest('GET_LOGS_REQUEST', reloadLogs),
    takeLatest('GET_SCHEDULES_REQUEST', getSchedules),
    takeLatest('UPDATE_SCHEDULE_REQUEST', updateSchedule),
    takeLatest('DELETE_SCHEDULE_REQUEST', deleteSchedule),
    takeLatest('CREATE_SCHEDULE_REQUEST', createSchedule),
    takeLatest('GET_THREE_PHASE_CONSUMPTION_REQUEST', getThreePhaseConsumption),
    takeLatest('UPLOAD_AMBIENT_IMAGE_REQUEST', uploadImage),
    takeLatest('HIDE_DEVICE_AMBIENT', hideDeviceAmbient),
    takeLatest('ONCLICK_ALARM_NOTIFICATION', onClickAlarmNotification),
    takeLatest('GET_FAVORITES_REQUEST', getFavorites),
    takeLatest('UPDATE_FAVORITES_REQUEST', updateFavorites),
    takeLatest('UPDATE_FAVORITE_DEVICE_LIST', updateFavoriteDeviceList),
    takeLatest('UPDATE_POSITION_REQUEST', updatePositionRequest)
  ])
}

export default function * persistGateSaga () {
  yield takeLatest(REHYDRATE, rootSaga)
}
