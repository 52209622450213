import React from 'react'
import { FlatList, ScrollView, StyleSheet } from 'react-native'
import Scene from './Scene'
import NoContent from '../Utils/NoContent'
import { useAppTheme } from '#app/theme'

const SceneBox = ({ scenes = [] }: SceneBoxProps) => {
  const numColumns = Math.ceil(scenes.length / 2)

  return (
    <ScrollView
      horizontal
      contentContainerStyle={styles.container}
      showsHorizontalScrollIndicator={false}
    >
      <FlatList
        style={styles.list}
        key={numColumns}
        numColumns={Math.ceil(scenes.length / 2)}
        renderItem={renderItem}
        data={scenes}
        ListEmptyComponent={ListEmptyComponent}
        keyExtractor={keyExtractor}
      />
    </ScrollView>
  )
}

export default SceneBox

const ListEmptyComponent = () => {
  const theme = useAppTheme()
  return <NoContent theme={theme} type="scenes" style={styles.listEmpty} />
}

const renderItem = ({ item }: {item: number}) => <Scene sceneId={item} />

const keyExtractor = (i: number) => i.toString()

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    paddingHorizontal: 15,
    marginBottom: 20
  },
  list: { width: '100%' },
  listEmpty: {
    display: 'flex',
    flexGrow: 1
  }
})

export interface SceneBoxProps {
  scenes?: number[]
}
