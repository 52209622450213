import React, { useEffect, useCallback, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks'
import WsActions from '../redux/ws'
import ButtonForm from '../components/Form/ButtonForm'
import {
  StyleSheet,
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  ActivityIndicator
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { get } from 'lodash'
import { t, gettext } from 'ttag'
import { useAppTheme } from '#app/theme'

function RemoteButton () {
  const theme = useAppTheme()
  const remote = useAppSelector(state => state.remote)
  const [button, setButton] = useState({})

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    setButton({ ...remote.button })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  useEffect(() => {
    navigation.setOptions({ title: gettext('New Button') })
  })

  const updateModelButton = useCallback(
    (btn) => {
      const b = {
        ...btn,
        rfirCommandId: get(remote, 'button.rfirCommandId')
      }

      setButton(b)
      dispatch({
        type: 'REMOTE_UPDATE_MODEL_BUTTON',
        button: b
      })
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const setStatusRecord = useCallback(
    (status) => {
      dispatch({
        type: 'REMOTE_SET_SAVE_STATUS_BUTTON',
        status: status
      })
    },
    [dispatch]
  )

  const testButton = useCallback((button) => {
    console.log('command id: ', remote.button.rfirCommandId)
    dispatch(WsActions.wsConnectionSendMessage({
      type: 'slave',
      id: remote.remote.slaveId,
      rfir_command_id: remote.button.rfirCommandId,
      command: 'transmit'
    }))
  }, [remote, dispatch])

  const sendMessageRecordCommand = useCallback(
    () => {
      dispatch(WsActions.wsConnectionSendMessage({
        type: 'slave',
        id: remote.remote.slaveId,
        rfir_device_id: remote.remote.id,
        command: 'record_command'
      }))
    },
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  )

  const saveButton = useCallback(
    (btn, slaveId, remoteId) => {
      dispatch({
        type: 'SAVE_BUTTON_REQUEST',
        button: btn,
        slaveId,
        remoteId
      })
    },
    [dispatch]
  )

  const onPressRecord = () => {
    setStatusRecord('start_record')
    sendMessageRecordCommand()
  }

  return (
    <ScrollView>
        <View style={[theme.form.area]}>
          <View style={theme.form.formGroup}>
            <Text style={{ color: theme.textColor, marginBottom: 5 }}>
              {t`Button Text`}
            </Text>
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <TextInput disabled={remote.savingButton} style={[theme.form.input]} placeholder={t`Button text...`} placeholderTextColor={theme.formForm.placeholderTextColor} autoCapitalize="none" autoCorrect={false} value={(button as any).name} onChangeText={(name) => { updateModelButton({ ...button, name: name }) }}/>
          </View>
          {/* <View style={{ ...theme.form.formGroup }}>
        <Text style={{ color: theme.textColor }}>Cor do Botão</Text>
      </View>
      <View style={theme.form.formGroup}>
        <Switch
          disabled={remote.savingButton}
          onValueChange={(value) => { updateModelButton({ ...button, color: value }) }}
          value={button.color}
        />
      </View> */}

          {/* TODO: Fix this the next time the file is edited. */}
          {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
          {!remote.savingButton &&
          <View>
              {(remote.savingButtonStage === 0) &&
                  <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                    <TouchableOpacity style={{ ...styles.recordButton }} onPress={() => onPressRecord()}>
                      <View style={{ ...styles.recordButtonInner, flex: 1, justifyContent: 'center' }}>
                        <Text style={{ color: '#FFFFFF', alignSelf: 'center' }}>{t`Record`}</Text>
                      </View>
                    </TouchableOpacity>
                  </View>
                  <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                    <Text style={{ color: theme.textColor, textAlign: 'center' }}>{t`Click on "Record" to add a new command.`}</Text>
                  </View>
                </View>}
            </View>}
          {(remote.savingButtonStage === 1) &&
          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                <ActivityIndicator color={theme.textColor} size="small"/>
              </View>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                <Text style={{ color: theme.textColor }}>{t`Searching Device...`}</Text>
              </View>
            </View>}
          {(remote.savingButtonStage === 2) &&
          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                <ActivityIndicator color={theme.textColor} size="small"/>
              </View>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                <Text style={{ color: theme.textColor }}>{t`Click on button to copy command.`}</Text>
              </View>
            </View>}
          {((remote.savingButtonStage === 3) || (remote.savingButtonStage === 5)) &&
          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}/>
              {(remote.savingButtonStage === 3) &&
                  <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                  <Text style={{ color: theme.textColor }}>{t`Error when coping command.`}</Text>
                </View>}
              {(remote.savingButtonStage === 5) &&
                  <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                  <Text style={{ color: theme.textColor }}>{t`Device search error.`}</Text>
                </View>}
              <View style={theme.form.formGroup}>
                <ButtonForm title={t`Repeat`} textColor="#444444" btnStyle={theme.form.btn} color={theme.colors.default} onPress={() => setStatusRecord(null)}/>
              </View>
            </View>}
          {(remote.savingButtonStage === 4) &&
          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <View style={{ ...theme.form.formGroup, alignSelf: 'center' }}>
                <Text style={{ color: theme.textColor }}>{t`Command successfully recorded!`}</Text>
              </View>
            </View>}
          {(remote.savingButtonStage === 4 || (remote.savingButtonStage === 0 && (button as any).id)) &&
          <View>
            {/* TODO: Fix this the next time the file is edited. */}
            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
            {!remote.savingButton && (<View style={theme.form.formGroup}>
                {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0. */}
                <ButtonForm title={t`Test Button`} btnStyle={theme.form.btn} color={theme.colors.purple} onPress={() => testButton()}/>
              </View>)}
            <View style={theme.form.formGroup}>
              <ButtonForm title={t`Save`} btnStyle={theme.form.btn} loading={remote.savingButton} disabled={remote.savingButton} color={theme.colors.success} onPress={() => saveButton(button, remote.remote.slaveId, remote.remote.id)}/>
            </View>
          </View>}
        </View>
      </ScrollView>
  )
}

const styles = StyleSheet.create({
  recordButton: {
    width: 140,
    height: 140,
    borderRadius: 70,
    padding: 7,
    backgroundColor: 'transparent',
    borderWidth: 3,
    borderColor: '#673ab6'
  },
  recordButtonInner: {
    width: 120,
    height: 120,
    borderRadius: 60,
    backgroundColor: '#673ab6'
  }
})

export default RemoteButton
