import React from 'react'
import { View } from 'react-native'

import { useAppTheme } from '#app/theme'

function Item ({
  children
}: any) {
  const theme = useAppTheme().list

  return (
    <View>
      <View style={theme.container}>
        {children}
      </View>
      <View style={theme.borderBottom} />
    </View>
  )
}

export default Item
