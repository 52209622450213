import { AnyAction } from 'redux'
import { select } from 'typed-redux-saga'
import { put, call } from 'redux-saga/effects'
import SchedulesActions from '../redux/schedules'
import * as RootNavigation from '../navigation/RootNavigation'
import _ from 'lodash'
import { selectApi } from '#app/api'

export function * getSchedules () {
  const api = yield * select(selectApi)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.getSchedules)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SchedulesActions.getSchedulesRequestSuccess(response.data))
    } else {
      yield put(SchedulesActions.getSchedulesRequestFailure())
    }
  } catch (e) {
    yield put(SchedulesActions.getSchedulesRequestFailure())
  }
}

export function * createSchedule (action: AnyAction) {
  const { scheduleInfo, selectedWeekDays, selectedScene } = action.payload
  const api = yield * select(selectApi)

  const schedule = parseSchedule({ scheduleInfo, selectedWeekDays, selectedScene, isNew: true })

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.createSchedule, schedule)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SchedulesActions.createScheduleRequestSuccess(response.data))
      RootNavigation.navigate('Schedules')
    } else {
      yield put(SchedulesActions.createScheduleRequestFailure())
    }
  } catch (e) {
    yield put(SchedulesActions.createScheduleRequestFailure())
  }
}

export function * updateSchedule (action: AnyAction) {
  const { scheduleInfo, selectedWeekDays, selectedScene } = action.payload
  const api = yield * select(selectApi)
  const schedule = parseSchedule({ scheduleInfo, selectedWeekDays, selectedScene, isNew: false })

  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.updateSchedule, schedule)
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SchedulesActions.updateScheduleRequestSuccess(response.data))
      RootNavigation.navigate('Schedules')
    } else {
      yield put(SchedulesActions.updateScheduleRequestFailure())
    }
  } catch (e) {
    yield put(SchedulesActions.updateScheduleRequestFailure())
  }
}

export function * deleteSchedule (action: any) {
  const api = yield * select(selectApi)
  try {
    // @ts-expect-error ts-migrate(7057) FIXME: 'yield' expression implicitly results in an 'any' ... Remove this comment to see the full error message
    const response = yield call(api.deleteSchedule, action.shceduleId)

    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.ok) {
      yield put(SchedulesActions.deleteScheduleRequestSuccess(action.shceduleId))
      RootNavigation.navigate('Schedules')
    } else {
      yield put(SchedulesActions.deleteScheduleRequestFailure())
    }
  } catch (e) {
    yield put(SchedulesActions.deleteScheduleRequestFailure())
  }
}

function parseSchedule ({
  scheduleInfo,
  selectedWeekDays,
  selectedScene,
  isNew
}: any) {
  const hour = scheduleInfo.hour.split(':')[0]
  const minute = scheduleInfo.hour.split(':')[1]
  const cronDays = _.chain(selectedWeekDays).filter(day => day.selected).map('value').value()

  const output = {
    action: { command: 'activate', id: selectedScene.value, type: 'scene' },
    active: true,
    name: scheduleInfo.name,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    cron: `${minute} ${hour} * * ${cronDays.join(',')}`
  }

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (!isNew) {
    (output as any).id = scheduleInfo.id
  }

  return output
}
