import React, { useEffect, useCallback } from 'react'
import { ScrollView } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { get } from 'lodash'
import GenericSmallButton from '../components/GenericSmallButton'
import { useNavigation } from '@react-navigation/native'

export default function IrPicker () {
  const selectedSlaveIr = useAppSelector(state => state.scene.selectedSlaveIr)
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const rfirButtons = get(selectedSlaveIr, 'rfir_buttons') || []

  const dispatch = useAppDispatch()
  const navigation = useNavigation()

  useEffect(() => {
    navigation.setOptions({
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type '{}'.
      title: selectedSlaveIr.name
    })
  }, // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [])

  const onButtonPress = useCallback(
    (item) => {
      dispatch({
        type: 'FIND_COMMAND_IR',
        item
      })
    },
    [dispatch]
  )

  return (
    <ScrollView>
      <ScrollView contentContainerStyle={{
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: 8
      }}
      >
        {
          rfirButtons.map((item: any) => <GenericSmallButton key={item.id} onPress={() => onButtonPress(item)} text={item.name} />)
        }
      </ScrollView>
    </ScrollView>
  )
}
