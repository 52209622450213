import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '#app/hooks'
import { ActivityIndicator, TextInput, View } from 'react-native'
import { HeaderButtons, Item } from 'react-navigation-header-buttons'
import { t } from 'ttag'
import { useAppTheme } from '#app/theme'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { AddAmbientFormData, ScreenProps } from '#app/types'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { addAmbient } from '#app/ambient'
import { selectLoaderById, useLoaderError, useLoaderSuccess } from '#app/loader'
import { Error } from '#app/components/Utils'

const Ambient = ({ navigation, route }: ScreenProps<'Ambient'>) => {
  const theme = useAppTheme()

  const form = useForm<AddAmbientFormData>({
    defaultValues,
    resolver: yupResolver(schema)
  })
  const { handleSubmit, formState: { isDirty }, setFocus, control } = form

  const dispatch = useAppDispatch()
  const onSubmit = useCallback<SubmitHandler<AddAmbientFormData>>((data) => {
    dispatch(addAmbient({ data }))
  }, [dispatch])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'ambient/add' }))
  const { isLoading, message } = loader
  const onSuccess = useCallback(() => {
    navigation.pop()
    navigation.navigate('AmbientDetail', { id: String(loader.meta?.id) })
  }, [navigation, loader.meta?.id])
  useLoaderSuccess(loader, onSuccess)
  const showError = useLoaderError(loader)

  useEffect(() => {
    navigation.setOptions({
      headerRight: () => isLoading
        ? <ActivityIndicator />
        : <HeaderButtons>
            <Item
              title={t`Add`}
              iconName="add"
              onPress={handleSubmit(onSubmit)}
            />
          </HeaderButtons>
    })
  }, [navigation, handleSubmit, onSubmit, isLoading])

  useEffect(() => {
    navigation.setOptions({ gestureEnabled: !isDirty })
  }, [navigation, isDirty])

  useEffect(() => {
    setFocus('name')
  }, [setFocus])

  return (
    <View style={[{ backgroundColor: theme.backgroundColor }]}>
      <View style={[theme.form.area]}>
        <View style={theme.form.formGroup}>
          {showError && <Error>{message}</Error>}
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
              <>
                <TextInput
                  ref={ref}
                  onBlur={onBlur}
                  style={theme.form.input}
                  placeholder={t`Ambient name`}
                  placeholderTextColor={theme.formForm.placeholderTextColor}
                  autoCapitalize="words"
                  autoCorrect={false}
                  value={value}
                  onChangeText={onChange}
                  onSubmitEditing={handleSubmit(onSubmit)}
                />
                {error?.message != null && <Error>{error.message}</Error> }
              </>
            )}
          />
        </View>
      </View>
    </View>
  )
}

export default Ambient

const defaultValues = { name: '' }

const schema: Yup.SchemaOf<AddAmbientFormData> = Yup.object({
  name: Yup.string()
    .required()
}).required()
