import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  alertsGetAlertsHistory: null,
  alertsGetAlertsHistorySuccess: ['payload'],
  alertsGetAlertsHistoryFailure: null,
  clearAlertsState: null
})

export const INITIAL_STATE = {
  alertsHistory: [],
  loading: false
}

export default Creators

function clearAlertsState () {
  return { ...INITIAL_STATE }
}

function alertsGetAlertsHistory (state: any, action: any) {
  return {
    ...state,
    loading: true
  }
}

function alertsGetAlertsHistorySuccess (state: any, action: any) {
  const { payload } = action

  return {
    ...state,
    alertsHistory: payload,
    loading: false
  }
}

function alertsGetAlertsHistoryFailure (state: any, action: any) {
  return {
    ...state,
    loading: false
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.ALERTS_GET_ALERTS_HISTORY]: alertsGetAlertsHistory,
  [Types.ALERTS_GET_ALERTS_HISTORY_SUCCESS]: alertsGetAlertsHistorySuccess,
  [Types.ALERTS_GET_ALERTS_HISTORY_FAILURE]: alertsGetAlertsHistoryFailure,
  [Types.CLEAR_ALERTS_STATE]: clearAlertsState
})
