import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { stdChannel } from 'redux-saga'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { persistStore, persistReducer } from 'redux-persist'
import { enableBatching, BATCH } from 'redux-batched-actions'
import { ActionWithPayload } from 'robodux'
import { rootReducer } from './redux'
import sagas from './sagas'

const persistedReducer = persistReducer({
  key: 'root',
  storage: AsyncStorage,
  // whitelist: ['central', 'user']
  blacklist: ['loader', 'ws', 'picker']
}, rootReducer)

// set redux-saga to work with redux-batched-actions
const channel = stdChannel<ActionWithPayload>()
const rawPut = channel.put
channel.put = (action) => {
  if (action.type === BATCH) {
    action.payload.forEach(rawPut)
    return
  }
  rawPut(action)
}
const sagaMiddleware = createSagaMiddleware({ channel })

const middlewares = composeWithDevTools(applyMiddleware(sagaMiddleware))

export const store = createStore(enableBatching(persistedReducer), middlewares)
export const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
