import { createAction } from 'robodux'
import {
  WSOutboxMessage,
  WSConnectionRequest,
  WSAdminMessage,
  WSChannelUpdateMessage,
  WSConfigClampSuccessMessage,
  WSConfigTemperatureSuccessMessage,
  WSTimeoutMessage,
  WSStatusUpdateMessage,
  WSConfigChannelSuccessMessage,
  WSCentralCapabilitiesMessage
} from '#app/types'

export const userLogin = createAction('USER_LOGIN')
export const userLogout = createAction('USER_LOGOUT')

export const wsConnectionRequest = createAction<WSConnectionRequest>('WS_CONNECTION_REQUEST')

export const wsDisconnectionRequest = createAction('WS_CONNECTION_CLOSE_REQUEST')

export const wsSendMessage = createAction<WSOutboxMessage>('WS_CONNECTION_SEND_MESSAGE')

export const wsStateUpdate = createAction('WS_MESSAGE_STATE_UPDATE')

export const wsAuthReq = createAction('WS_MESSAGE_AUTH_REQ')

export const wsAdmin = createAction<WSAdminMessage>('WS_MESSAGE_ADMIN')

export const wsChannelUpdate = createAction<WSChannelUpdateMessage>('WS_MESSAGE_CHANNEL_UPDATE')

export const wsConfigClampSuccess = createAction<WSConfigClampSuccessMessage>('WS_MESSAGE_CONFIG_CLAMP_SUCCESS')

export const wsConfigTemperatureSuccess = createAction<WSConfigTemperatureSuccessMessage>('WS_MESSAGE_CONFIG_TEMPERATURE_SUCCESS')

export const wsConfigChannelSuccess = createAction<WSConfigChannelSuccessMessage>('WS_MESSAGE_CONFIG_CHANNEL_SUCCESS')

export const wsTimeout = createAction<WSTimeoutMessage>('WS_MESSAGE_TIMEOUT')

export const wsStatusUpdate = createAction<WSStatusUpdateMessage>('WS_MESSAGE_STATUS_UPDATE')

export const wsCentralCapabilities = createAction<WSCentralCapabilitiesMessage>('WS_MESSAGE_CENTRAL_CAPABILITIES')
