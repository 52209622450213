import { AnyAction } from 'redux'
import { createReducer, createActions } from 'reduxsauce'
import _, { find, map } from 'lodash'
import moment from 'moment'

const datesAreOnSameDay = (first: any, second: any) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate()

export const { Types, Creators } = createActions({
  dataGetSlavesRequest: null,
  dataGetSlavesRequestSuccess: ['payload'],
  dataGetSlavesRequestFailure: null,
  clearSlaveState: null,
  setSlavesToFilter: ['payload'],
  updateConsumption: ['payload'],
  updateSlaves: ['payload'],
  setSelectedSlave: ['payload'],
  addButton: ['payload'],
  updateButton: ['payload'],
  removeButton: ['payload'],
  updateButtonsOrder: ['payload'],
  updateTemperature: ['payload'],
  toggleSlaveLoading: ['payload'],
  toggleLoading: ['payload']
})

export const INITIAL_STATE = {
  allSlaves: [] as any[],
  filterSlaves: [] as any[],
  selectedSlave: {
    id: null as null | number,
    config: null as null | any,
    ambients: null as null | any[]
  },
  loading: false,
  failed: false,
  getError: false
}

export default Creators
export type SlavesState = typeof INITIAL_STATE
export type SlavesReducer = (state: SlavesState, action: AnyAction) => SlavesState

const setSelectedSlave: SlavesReducer = (state, action) => {
  return {
    ...state,
    selectedSlave: action.payload
  }
}

const getSlavesRequest: SlavesReducer = (state, action) => {
  return {
    ...state,
    // allSlaves: undefined,
    getError: false,
    filterSlaves: INITIAL_STATE.filterSlaves,
    loading: true
  }
}

const getSlavesRequestSuccess: SlavesReducer = (state, action) => {
  const { payload } = action
  return {
    ...state,
    loading: false,
    allSlaves: payload as SlavesState['allSlaves'],
    getError: false,
    filterSlaves: INITIAL_STATE.filterSlaves
  }
}

const getSlavesRequestFailure: SlavesReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    getError: true,
    filterSlaves: INITIAL_STATE.filterSlaves
  }
}

const updateConsumption: SlavesReducer = (state, action) => {
  const msg = action.payload
  let allSlaves = state.allSlaves
  if (msg.scope === 'slave') {
    allSlaves = _.chain(state.allSlaves).map(s => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (s.id === msg.id && s.is_slave) {
        s.lastConsumption = msg.value
      }
      return s
    }).value()
  }

  return {
    ...state,
    allSlaves
  }
}

const updateStatus: SlavesReducer = (state, { payload }) => {
  const allSlaves = state.allSlaves.map((s: any) => {
    if (s.slave_id === payload?.id) {
      s.status = payload?.status
    }
    return s
  })

  return { ...state, allSlaves }
}

const updateTemperature: SlavesReducer = (state, action) => {
  const { payload } = action

  let allSlaves = state.allSlaves
  allSlaves = _.chain(state.allSlaves)
    .map(s => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (s.slave_id === payload.slaveId && s.is_slave && payload.temperature !== 255) {
        s.temperature = payload.temperature
      }

      return s
    }).value()

  return { ...state, allSlaves }
}

const pulseUpdate: SlavesReducer = (state, action) => {
  const { payload } = action

  const newAllSlaves = _.map(state.allSlaves, (device) => {
    if (device.type === 'flow_sensor' &&
      device.slave_id === payload.id) {
      const updatedChannel = find(payload.channels, {
        id: device.channel
      })

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (!updatedChannel) { return device }

      // Calculate today's pulses
      //
      const pulseCount = _.reduce(updatedChannel.last_pulses, (acc, pulse) => {
        const date = moment.utc(pulse.timestamp).toDate()

        if (!datesAreOnSameDay(new Date(), date)) {
          return acc
        }

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        return acc + pulse.value
      }, 0)

      return {
        ...device,
        value: pulseCount
      }
    }

    return device
  })

  return {
    ...state,
    allSlaves: newAllSlaves
  }
}

export const updateSlaves: SlavesReducer = (state, { payload }) => {
  const { id: slaveId, channels } = payload

  if (!Array.isArray(channels)) return state
  if (!(channels.length > 0)) return state

  const newAllSlaves = map(state.allSlaves, (entity) => {
    if (!(entity.is_channel as boolean)) return entity
    if (entity.slave_id !== slaveId) return entity

    const channel = find(channels, (c) => c.id === entity.channel)
    if (channel == null) return entity

    const { input, output } = channel

    return { ...entity, input, output }
  })

  return { ...state, allSlaves: newAllSlaves }
}

const setSlavesToFilter: SlavesReducer = (state, action) => {
  return {
    ...state,
    filterSlaves: action.payload
  }
}

const clearSlaveState: SlavesReducer = () => {
  return { ...INITIAL_STATE }
}

const addButton: SlavesReducer = (state, action) => {
  const { button, slaveId, remoteId } = action.payload

  const allSlaves = state.allSlaves.map((s: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (s.slaveId === slaveId && s.is_device && s.id === remoteId) {
      s.buttons.push(button)
    }
    return { ...s }
  })

  return {
    ...state,
    allSlaves
  }
}

const updateButton: SlavesReducer = (state, action) => {
  const { button, slaveId, remoteId } = action.payload

  const allSlaves = state.allSlaves.map((s: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (s.slaveId === slaveId && s.is_device && s.id === remoteId) {
      s.buttons = s.buttons.map((b: any) => {
        if (b.id === button.id) {
          b = button
        }
        return b
      })
    }
    return { ...s }
  })

  return {
    ...state,
    allSlaves
  }
}

const removeButton: SlavesReducer = (state, action) => {
  const { id, slaveId, remoteId } = action.payload

  const slave = state.allSlaves.find((s: any) => s.slaveId === slaveId && s.is_device && s.id === remoteId)

  slave.buttons = slave.buttons.filter((b: any) => b.id !== id)

  return {
    ...state
  }
}

const updateButtonsOrder: SlavesReducer = (state, action) => {
  const { positions, slaveId, remoteId } = action.payload

  const allSlaves = state.allSlaves.map((s: any) => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (s.slaveId === slaveId && s.is_device && s.id === remoteId) {
      s.buttons = s.buttons.map((b: any) => {
        b.order = positions.find((p: any) => p.id === b.id).order
        return b
      })
    }
    return { ...s }
  })

  return {
    ...state,
    allSlaves
  }
}

const toggleLoading: SlavesReducer = (state, action) => {
  const { payload } = action
  return {
    ...state,
    loading: payload
  }
}

const toggleSlaveLoading: SlavesReducer = (state, action) => {
  const { payload } = action
  let allSlaves = state.allSlaves

  if (payload.type === 'off') {
    allSlaves = allSlaves.map((s: any) => {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (payload.slaveId === s.slave_id && s.is_channel) { s.loading = false }
      return { ...s }
    })
  } else if (payload.type === 'on') {
    allSlaves = allSlaves.map((s: any) => {
      if (payload.channel === null) {
        if (payload.slaveId === s.slave_id) s.loading = true
        return { ...s }
      } else {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (payload.slaveId === s.slave_id && payload.channel === s.channel && s.is_channel) { s.loading = true }
        return { ...s }
      }
    })
  }

  return {
    ...state, allSlaves
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DATA_GET_SLAVES_REQUEST]: getSlavesRequest,
  [Types.DATA_GET_SLAVES_REQUEST_SUCCESS]: getSlavesRequestSuccess,
  [Types.DATA_GET_SLAVES_REQUEST_FAILURE]: getSlavesRequestFailure,
  [Types.CLEAR_SLAVE_STATE]: clearSlaveState,
  [Types.SET_SLAVES_TO_FILTER]: setSlavesToFilter,
  [Types.UPDATE_CONSUMPTION]: updateConsumption,
  [Types.UPDATE_SLAVES]: updateSlaves,
  WS_MESSAGE_PULSE_UPDATE: pulseUpdate,
  [Types.SET_SELECTED_SLAVE]: setSelectedSlave,
  [Types.ADD_BUTTON]: addButton,
  [Types.UPDATE_BUTTON]: updateButton,
  [Types.REMOVE_BUTTON]: removeButton,
  [Types.UPDATE_BUTTONS_ORDER]: updateButtonsOrder,
  [Types.UPDATE_TEMPERATURE]: updateTemperature,
  WS_MESSAGE_STATUS_UPDATE: updateStatus,
  [Types.TOGGLE_SLAVE_LOADING]: toggleSlaveLoading,
  [Types.TOGGLE_LOADING]: toggleLoading
})
