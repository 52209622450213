import { ColorValue, StyleSheet } from 'react-native'

import { colors } from './colors'

const m = {
  m0: { margin: 0 },
  m1: { margin: 5 },
  m3: { margin: 10 },
  m4: { margin: 15 },
  m5: { margin: 20 }
}

const mt = {
  mt0: { marginTop: 0 },
  mt1: { marginTop: 5 },
  mt3: { marginTop: 10 },
  mt4: { marginTop: 15 },
  mt5: { marginTop: 20 }
}

const mb = {
  mb0: { marginBottom: 0 },
  mb1: { marginBottom: 5 },
  mb3: { marginBottom: 10 },
  mb4: { marginBottom: 15 },
  mb5: { marginBottom: 20 }
}

const p = {
  p0: { padding: 0 },
  p1: { padding: 5 },
  p3: { padding: 10 },
  p4: { padding: 15 },
  p5: { padding: 20 }
}

const pb = {
  pb0: { paddingBottom: 0 },
  pb1: { paddingBottom: 5 },
  pb3: { paddingBottom: 10 },
  pb4: { paddingBottom: 15 },
  pb5: { paddingBottom: 20 }
}

export const themeLight = {
  name: 'light',
  ...m,
  ...mt,
  ...mb,
  ...p,
  ...pb,
  scene: {
    blurView: {
      tint: 'light',
      intensity: 90
    },
    textColor: 'rgba(0, 0, 0, 0.7)'
  },
  genericButton: {
    textColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    iconColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    buttonColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    blurView: {
      on: {
        tint: 'light',
        intensity: 90
      },
      off: {
        tint: 'dark',
        intensity: 100
      }
    },
    androidButton: {
      off: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      },
      on: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        opacity: 0.9
      }
    }
  },
  backgroundImage: require('#assets/bg_light.png'),
  backgroundColor: 'rgba(240, 240, 240, 1)',
  backgroundImageOverlay: 'rgba(0, 0, 0, 0.4)',
  textColorInverted: 'rgba(0, 0, 0, 1)',
  textColor: 'rgba(0, 0, 0, 1)',
  textColor50: 'rgba(68, 68, 68, 0.5)',
  textColor70: 'rgba(68, 68, 68, 0.7)',
  textColor90: 'rgba(68, 68, 68, 0.9)',
  border: {
    borderWidth: 1,
    borderColor: 'rgba(0, 0, 0, 0.15)'
  },
  colors: {
    primary: '#2194f2',
    success: '#0FC482',
    purple: colors.purple,
    grey: '#909090',
    green: colors.green,
    default: '#444444',
    white: '#ffffff',
    danger: '#dc3545'
  },
  home: {
    topConsumption: {
      chartBarColor: 'rgba(68, 68, 68, 1)',
      textColor: 'rgba(68, 68, 68, 1)'
    }
  },
  titleLoading: {
    textColor: 'rgba(68, 68, 68, 1)',
    indicatorColor: 'rgba(68, 68, 68, 1)'
  },
  blurView: {
    tint: 'light',
    intensity: 90,
    purpleBox: {
      borderRadius: 15,
      padding: 20,
      width: '100%'
    }
  },
  header: {
    rightButtonColor: '#444444'
  },
  formForm: {
    search: 'rgba(228, 228, 228, 1)',
    searchIconColor: 'rgba(128, 128, 128, 1)',
    buttonsTextColor: {
      default: '#FFFFFF',
      success: '#FFFFFF',
      primary: '#FFFFFF',
      danger: '#FFFFFF'
    },
    btnColorInverse: '#FFFFFF',
    placeholderTextColor: 'rgba(68, 68, 68, 0.9)'
  },
  form: StyleSheet.create({
    area: {
      padding: 15,
      width: '100%'
    },
    input: {
      paddingHorizontal: 20,
      fontSize: 16,
      color: 'rgba(68, 68, 68, 0.9)',
      backgroundColor: 'rgba(228, 228, 228, 1)',
      height: 50,
      borderRadius: 5
    },
    inputTouchable: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      paddingHorizontal: 20,
      backgroundColor: 'rgba(228, 228, 228, 1)',
      height: 50,
      borderRadius: 5
    },
    formGroup: {
      marginBottom: 20
    },
    btn: {
      height: 40,
      borderRadius: 5,
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      shadowColor: 'rgba(68, 68, 68, .4)', // IOS
      shadowOffset: { height: 1, width: 1 }, // IOS
      shadowOpacity: 1, // IOS
      shadowRadius: 0, // IOS
      elevation: 2 // Android
    }
  }),
  list: StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      height: 56
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: '#E4E4E4'
    },
    separator: {
      backgroundColor: '#E4E4E4'
    },
    separatorText: {
      fontWeight: 'bold', padding: 15, color: 'rgba(68, 68, 68, 1)'
    },
    textList: {
      alignSelf: 'center',
      color: 'rgba(68, 68, 68, 1)'
    }
  }),
  swipeablePanel: {
    backgroundColor: '#222222',
    title: {
      borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: '#444444'
      }
    }
  }
}

export const themeDark = {
  name: 'dark',
  ...m,
  ...mt,
  ...mb,
  ...p,
  ...pb,
  scene: {
    blurView: {
      tint: 'light',
      intensity: 90
    },
    textColor: 'rgba(0, 0, 0, 0.7)'
  },
  genericButton: {
    textColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    iconColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    buttonColor: {
      on: 'rgba(68, 68, 68, 1)',
      off: 'rgba(255, 255, 255, 0.7)'
    },
    blurView: {
      on: {
        tint: 'light',
        intensity: 90
      },
      off: {
        tint: 'dark',
        intensity: 100
      }
    },
    androidButton: {
      off: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      },
      on: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        opacity: 0.9
      }
    }
  },
  backgroundImage: require('#assets/bg2_dark.png'),
  backgroundColor: 'rgba(0, 0, 0, 1)',
  backgroundImageOverlay: 'rgba(0, 0, 0, 0.4)',
  textColorInverted: 'rgba(68, 68, 68, 1)',
  textColor: 'rgba(255, 255, 255, 1)',
  textColor50: 'rgba(255, 255, 255, 0.8)',
  textColor70: 'rgba(255, 255, 255, 0.7)',
  textColor90: 'rgba(255, 255, 255, 0.9)',
  border: {
    borderWidth: 1,
    borderColor: 'rgba(255, 255, 255, 0.15)'
  },
  colors: {
    primary: '#2194f2',
    success: '#0FC482',
    green: colors.green,
    purple: colors.purple,
    grey: '#373737',
    default: '#f0f0f0',
    white: '#ffffff',
    danger: '#dc3545'
  },
  home: {
    topConsumption: {
      chartBarColor: 'rgba(255, 255, 255, 0.7)',
      textColor: 'rgba(255, 255, 255, 0.7)'
    }
  },
  titleLoading: {
    textColor: 'rgba(255, 255, 255, 0.7)',
    indicatorColor: 'rgba(255, 255, 255, 0.7)'
  },
  blurView: {
    tint: 'dark',
    intensity: 90,
    purpleBox: {
      borderRadius: 15,
      padding: 20,
      width: '100%'
    }
  },
  header: {
    rightButtonColor: '#2FCA76'
  },
  formForm: {
    search: 'rgba(255, 255, 255, 0.1)',
    searchIconColor: 'rgba(255, 255, 255, 0.3)',
    buttonsTextColor: {
      default: '#444444',
      success: '#FFFFFF',
      primary: '#FFFFFF',
      danger: '#FFFFFF'
    },
    btnColorInverse: '#444444',
    placeholderTextColor: 'rgba(255, 255, 255, 0.3)'

  },
  form: StyleSheet.create({
    area: {
      padding: 15,
      width: '100%'
    },
    input: {
      paddingHorizontal: 20,
      fontSize: 16,
      color: 'rgba(255, 255, 255, 0.9)',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      height: 40,
      borderRadius: 5
    },
    inputTouchable: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      paddingHorizontal: 20,
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      height: 40,
      borderRadius: 5
    },
    formGroup: {
      marginBottom: 20
    },
    btn: {
      height: 40,
      borderRadius: 5,
      justifyContent: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      shadowColor: 'rgba(255, 255, 255, .4)', // IOS
      shadowOffset: { height: 1, width: 1 }, // IOS
      shadowOpacity: 1, // IOS
      shadowRadius: 0, // IOS
      elevation: 1 // Android
    }
  }),
  list: StyleSheet.create({
    container: {
      flexDirection: 'row',
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 10,
      paddingBottom: 10,
      height: 56
    },
    borderBottom: {
      borderBottomWidth: 1,
      borderBottomColor: '#0A0A0A'
    },
    separator: {
      backgroundColor: '#0c0c0c'
    },
    separatorText: {
      fontWeight: 'bold',
      padding: 15,
      color: '#E4E4E4'
    },
    textList: {
      alignSelf: 'center',
      color: '#E4E4E4'
    }
  }),
  swipeablePanel: {
    backgroundColor: '#222222',
    title: {
      borderBottom: {
        borderBottomWidth: 1,
        borderBottomColor: '#444444'
      }
    }
  }
}

type RawTheme = typeof themeDark & typeof themeLight

interface ThemeForm extends Omit<StyleSheet.NamedStyles<RawTheme['form']>, 'placeholderTextColor'> {
  placeholderTextColor: ColorValue
}

interface ThemeList extends StyleSheet.NamedStyles<RawTheme['list']> {
}
export interface Theme extends Omit<RawTheme, 'list' | 'form'> {
  form: ThemeForm
  list: ThemeList
}
