import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useAppTheme } from '#app/theme'
import { SafeAreaView } from 'react-native-safe-area-context'

function ItemPress ({
  disabled,
  onPress,
  children
}: any) {
  const theme = useAppTheme().list

  return (
    <>
      <SafeAreaView edges={['left', 'right']}>
      <TouchableOpacity
        disabled={disabled}
        style={theme.container}
        onPress={onPress}
      >
        {children}
      </TouchableOpacity>
      </SafeAreaView>
      <View style={theme.borderBottom} />
    </>
  )
}

export default ItemPress
