import { useEffect, useLayoutEffect, useRef } from 'react'

export const usePrevious = <T>(value: T) => {
  const ref = useRef<T>(value)
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export const useFreshRef = <T>(value: T) => {
  const ref = useRef(value)
  useLayoutEffect(() => {
    ref.current = value
  }, [value])
  return ref
}
