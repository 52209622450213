import React from 'react'
import GenericButton from '../GenericButton'

function getIcon (category: any) {
  if (category === 'tv') {
    return 'remote'
  } else if (category === 'ar' || (category === 'ac')) {
    return 'air-conditioner'
  } else {
    return 'remote'
  }
}

interface OwnProps {
  children?: React.ReactNode
  category?: string
  onPress?: (...args: any[]) => any
  onLongPress?: (...args: any[]) => any
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof RemoteWidget.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'on' does not exist on type 'Props'.
export default function RemoteWidget ({ onPress, on, offline, text, category, onLongPress, connectionStatus, hide, hideButtonPress }: Props) {
  return (
    <GenericButton
      icon={getIcon(category)}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ icon: string; text: any; on: any; offline:... Remove this comment to see the full error message
      text={text}
      on={on}
      offline={offline}
      connectionStatus={connectionStatus}
      onPress={onPress}
      onLongPress={onLongPress}
      hide={hide}
      hideButtonPress={hideButtonPress}
    />
  )
}

RemoteWidget.defaultProps = {
  children: null,
  category: '',
  onPress: () => { },
  onLongPress: () => { }
}
