import React from 'react'
import { Text } from 'react-native'
import { useAppTheme } from '#app/theme'

export default function TextList ({
  text
}: any) {
  const theme = useAppTheme().list

  return (
    <Text style={theme.textList}>
      {text}
    </Text>
  )
}
