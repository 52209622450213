import React from 'react'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { Separator, ItemPress, ListItem, TextList, Right } from '#app/components/List'
import { useAppTheme } from '#app/theme'
import { ScreenProps } from '#app/types'

const SuperadminHome = ({ navigation }: ScreenProps<'SuperadminHome'>) => {
  const theme = useAppTheme()
  return (
    <ListItem>
      <Separator text="Superadmin Tools" />
      <ItemPress onPress={() => navigation.navigate('SuperadminCentrals')}>
        <TextList text="Central Management" />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
      <Separator text="Developer Tools" />
      <ItemPress onPress={() => navigation.navigate('DeveloperDispatcher')}>
        <TextList text="Action Dispatcher" />
        <Right>
          <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey} />
        </Right>
      </ItemPress>
    </ListItem>
  )
}

export default SuperadminHome
