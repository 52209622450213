import React from 'react'
import { StyleSheet, TouchableOpacity, ActivityIndicator, Text } from 'react-native'
import { get } from 'lodash'
import { useAppTheme } from '#app/theme'

function hexToRgbA (hex: any, opacity: any) {
  let c

  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    c = '0x' + c.join('')
    // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }

  throw new Error(hex)
}

interface OwnProps {
  onPress?: (...args: any[]) => any
  title: string
  loading?: boolean
  btnStyle?: any | any[]
  textColor?: string
  color?: string
  disabled?: boolean
  indicatorColor?: string
}

// @ts-expect-error ts-migrate(2565) FIXME: Property 'defaultProps' is used before being assig... Remove this comment to see the full error message
type Props = OwnProps & typeof ButtonForm.defaultProps

// @ts-expect-error ts-migrate(2339) FIXME: Property 'styleIndicator' does not exist on type '... Remove this comment to see the full error message
function ButtonForm ({ btnStyle, color, disabled, onPress, textColor, title, styleIndicator, indicatorColor, loading }: Props) {
  const theme = useAppTheme()
  const themeBtn = get(theme, 'form.btn')
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/prefer-nullish-coalescing
  let finalColor = color || theme.colors.primary

  if (disabled) {
    finalColor = hexToRgbA(color, 0.3)
  }

  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        themeBtn,
        btnStyle,
        { backgroundColor: finalColor }
      ]}
      onPress={onPress}
    >

      {!loading && (
        <Text style={[styles.text, { color: textColor }]}>
          {title}
        </Text>
      )}

      {loading && (
        <ActivityIndicator
          color={indicatorColor}
          size="small"
          style={styleIndicator}
        />
      )}
    </TouchableOpacity>
  )
}

export default ButtonForm

const styles = StyleSheet.create({
  text: {
    textAlignVertical: 'center',
    fontSize: 15,
    paddingLeft: 10,
    paddingRight: 10
  },
  styleIndicator: {
    width: 11,
    height: 11
  }
})

ButtonForm.defaultProps = {
  onPress: () => {},
  title: '',
  loading: false,
  btnStyle: {},
  textColor: '#FFFFFF',
  disabled: false,
  indicatorColor: '#FFFFFF'
}
