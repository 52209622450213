import React from 'react'
import Icon from './Icon'
import { colors } from '#app/theme/colors'

const RfIrDeviceIcon = ({ category }: { category: string }) =>
  <Icon name={getIconName(category)} size={20} colorIcon={colors.white} colorBg={colors.green} />

export default RfIrDeviceIcon

const getIconName = (category: string) => {
  switch (category) {
    case 'tv':
      return 'youtube-tv'
    case 'ac':
      return 'snowflake'
    case 'other':
    default:
      return 'remote'
  }
}
