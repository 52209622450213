import React, { useCallback, useMemo } from 'react'
import { TextInput, StyleSheet, TextStyle } from 'react-native'
import moment from 'moment'
import { DatePickerProps } from 'react-native-date-picker'

const MODE_FMT = {
  date: moment.HTML5_FMT.DATE,
  time: moment.HTML5_FMT.TIME,
  datetime: moment.HTML5_FMT.DATETIME_LOCAL,
  month: moment.HTML5_FMT.MONTH,
  week: moment.HTML5_FMT.WEEK
}

const format = (mode: NonNullable<DatePickerProps['mode']>, value?: Date) => moment(value).format(MODE_FMT[mode])

const DatePicker = ({
  date: value,
  mode = 'date',
  minimumDate,
  maximumDate,
  onDateChange,
  textColor,
  style,
  ...props
}: DatePickerProps) => {
  const inputRef = useCallback((node: TextInput | null) => {
    node?.setNativeProps({
      type: mode === 'datetime' ? 'datetime-local' : mode,
      ...(minimumDate != null && { min: format(mode, minimumDate) }),
      ...(maximumDate != null && { max: format(mode, maximumDate) })
    })
  }, [mode, minimumDate, maximumDate])

  const onChangeText = useCallback((text: string) => {
    const date = moment(text)
    if (date.isValid()) {
      onDateChange(date.toDate())
    }
  }, [onDateChange])

  const date = useMemo(() => format(mode, value), [mode, value])

  const defaultStyle = useMemo(() => {
    return { color: textColor }
  }, [textColor])

  return (
    <TextInput
      ref={inputRef}
      onChangeText={onChangeText}
      value={date}
      style={StyleSheet.compose<TextStyle>(defaultStyle, style)}
      {...props}
    />
  )
}

export default DatePicker
