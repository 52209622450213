import React from 'react'
import { StyleSheet, Text } from 'react-native'
import { t } from 'ttag'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'
import { useAppTheme } from '#app/theme'
import { PickerInputProps, usePicker } from '#app/picker'
import Right from './Right'
import Left from './Left'
import ItemPress from './ItemPress'
import TextList from './TextList'

const PickerCell = (props: PickerInputProps) => {
  const { items, title } = props
  const { onPress, value } = usePicker(props)
  const detail = Array.isArray(value)
    ? `${value.length}`
    : items.find(i => i.id === value)?.title ?? t`No item selected`
  const theme = useAppTheme()
  return (
    <ItemPress onPress={onPress}>
      <Left>
        <TextList text={title}/>
      </Left>
      <Right style={styles.right}>
        <Text style={theme.list.textList}>{detail}</Text>
        <MaterialCommunityIcons name="chevron-right" size={20} color={theme.colors.grey}/>
      </Right>
    </ItemPress>
  )
}

export default PickerCell

const styles = StyleSheet.create({
  right: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
})
