import { StackActions, createNavigationContainerRef } from '@react-navigation/native'
import { RootStackParamList } from '../routes'

export const navigationRef = createNavigationContainerRef<RootStackParamList>()

export const navigate = navigationRef.navigate
export const reset = navigationRef.reset
export const goBack = navigationRef.goBack
export const dispatch = navigationRef.dispatch
export const setParams = navigationRef.setParams

export const pop = (...args: Parameters<typeof StackActions.pop>) => dispatch(StackActions.pop(...args))

export const resetTo = (name: string) => {
  reset({
    index: 0,
    routes: [{ name }]
  })
}
