import { AnyAction } from 'redux'
import { createReducer, createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  dataGetScenesRequest: null,
  dataGetScenesRequestSuccess: ['payload'],
  dataGetScenesRequestFailure: null,
  dataSetSelectedSlave: ['payload'],
  dataSetSelectedScene: ['payload'],
  getAndSetSlaveIrRequest: null,
  getAndSetSlaveIrRequestSuccess: ['payload'],
  getAndSetSlaveIrRequestFailure: null,
  dataUpdateValueSelectedSlave: null,
  dataInsertSlaveToList: ['payload'],
  dataUpdateNameSelectedScene: null,
  dataUpdateAmbientSelectedScene: null,
  clearCommands: null,
  removeCommand: ['payload'],
  insertSlaveIrToList: ['payload'],
  clearSceneState: null,
  activateScene: ['payload'],
  finishedScene: null,
  errorExecutingScene: ['payload'],
  activeSceneLoading: null,
  createSceneRequest: null,
  createSceneRequestSuccess: ['payload'],
  createSceneRequestFailure: null,
  deleteSceneRequest: null,
  deleteSceneRequestSuccess: ['payload'],
  deleteSceneRequestFailure: null,
  updateSceneRequest: null,
  updateSceneRequestSuccess: ['payload'],
  updateSceneRequestFailure: null,
  updateConfigScene: ['payload']
})

export const INITIAL_STATE = {
  scenes: [] as any[],
  favorites: [],
  loading: false,
  failed: false,
  getError: false,
  selectedSlave: {},
  selectedSlaveIr: {},
  selectedScene: {
    id: null as null | number,
    name: '',
    color: '0.098,0.584,0.8705',
    ambientId: null as null | string,
    config: {
      confirm: false
    },
    json: [] as any[]
  },
  activedScene: {
    loading: false,
    sceneId: null as null | number,
    error: false,
    errorMessage: null
  }
}

export default Creators
export type SceneState = typeof INITIAL_STATE
export type SceneReducer = (state: SceneState, action: AnyAction) => SceneState

const clearSceneState: SceneReducer = (state, action) => {
  return { ...INITIAL_STATE }
}

const getScenesRequest: SceneReducer = (state, action) => {
  return {
    ...state,
    favorites: [],
    selectedSlave: {},
    selectedSlaveIr: {},
    selectedScene: { ...INITIAL_STATE.selectedScene },
    activedScene: { ...INITIAL_STATE.activedScene },
    getError: false
  }
}

const getScenesRequestSuccess: SceneReducer = (state, action) => {
  const { payload } = action

  function convertColor (color: any) {
    const values = color.split(',')
    return values.map((value: any) => {
      return Math.ceil(value * 255)
    })
  }

  const arrayFavorites = payload.map((item: any) => {
    return {
      ...item,
      colorRgb: convertColor(item.color).join(', ').concat(', 1')
    }
  })

  return {
    ...state,
    scenes: payload.map((x: any) => {
      x.ambientId = x.ambientId === null ? null : x.ambientId
      return x
    }),
    favorites: arrayFavorites.filter((x: any) => x.ambientId === null),
    activedScene: INITIAL_STATE.activedScene,
    loading: false,
    getError: false
  }
}

const getScenesRequestFailure: SceneReducer = (state, action) => {
  return {
    ...state,
    getError: true
  }
}

const setSelectedSlave: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedSlave: {
      ...(action.item.channel ?? action.item.device),
      value: 0
    }
  }
}

const setSelectedScene: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedScene: action.data ?? { ...INITIAL_STATE.selectedScene }
  }
}

const updateSlaveValue: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedSlave: {
      ...state.selectedSlave,
      value: action.value
    }
  }
}

const insertSlaveIrToList: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      json: [...state.selectedScene.json, action.payload]
    }
  }
}

const insertSlaveToList: SceneReducer = (state, action) => {
  const selectedDevice = action.selectedSlave

  const enrichedJson = {
    name: '',
    id: selectedDevice.slaveId,
    accessory_id: selectedDevice.id,
    command: {},
    command_type: 'accessory'
  }

  if (selectedDevice.type === 'pulse_up') {
    enrichedJson.command = {
      id: selectedDevice.slaveId,
      value: 100,
      channel: selectedDevice.channel,
      command: 'pulse',
      type: 'slave'
    }
    enrichedJson.name = 'Pulse Up'
  } else if (selectedDevice.type === 'pulse_down') {
    enrichedJson.command = {
      id: selectedDevice.slaveId,
      value: 0,
      channel: selectedDevice.channel,
      command: 'pulse',
      type: 'slave'
    }
    enrichedJson.name = 'Pulse Down'
  } else {
    enrichedJson.command = {
      id: selectedDevice.slaveId,
      value: selectedDevice.value,
      channel: selectedDevice.channel,
      command: 'light_control',
      type: 'slave'
    }
    if (selectedDevice.value > 0) {
      enrichedJson.name = 'Ligar'
    } else {
      enrichedJson.name = 'Desligar'
    }
  }

  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      json: [...state.selectedScene.json, enrichedJson]
    },
    selectedSlave: {}
  }
}

const updateNameSelectedScene: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      name: action.name
    }
  }
}

const updateAmbientSelectedScene: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      ambientId: String(action.ambientId)
    }
  }
}

const clearCommands: SceneReducer = (state, action) => {
  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      json: []
    }
  }
}

const removeCommand: SceneReducer = (state, action) => {
  const { command } = action
  const commandIndex = state.selectedScene.json.indexOf(command.complete)
  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      json: state.selectedScene.json.filter((elem: any, index: any) => index !== commandIndex)
    }
  }
}

const getAndSetSlaveIrRequest: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: true,
    failed: false
  }
}

const getAndSetSlaveIrRequestFailure: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: true
  }
}

const getAndSetSlaveIrRequestSuccess: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: false,
    selectedSlaveIr: action.payload[0]
  }
}

const activateScene: SceneReducer = (state, action) => {
  const { payload } = action
  return {
    ...state,
    activedScene: {
      ...state.activedScene,
      sceneId: payload.sceneId,
      loading: payload.loading
    }
  }
}

const finishedScene: SceneReducer = (state, action) => {
  return {
    ...state,
    activedScene: INITIAL_STATE.activedScene
  }
}

const errorExecutingScene: SceneReducer = (state, action) => {
  const { payload } = action
  return {
    ...state,
    activedScene: {
      ...state.activedScene,
      loading: payload.loading,
      error: payload.error,
      errorMessage: payload.errorMessage
    }
  }
}

const activeSceneLoading: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: true
  }
}

const createSceneRequest: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: true,
    failed: false
  }
}

const createSceneRequestSuccess: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: false
  }
}

const createSceneRequestFailure: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: true
  }
}

const deleteSceneRequest: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: true,
    failed: false
  }
}

const deleteSceneRequestSuccess: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: false
  }
}

const deleteSceneRequestFailure: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: true
  }
}

const updateSceneRequest: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: true,
    failed: false
  }
}

const updateSceneRequestSuccess: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: false
  }
}

const updateSceneRequestFailure: SceneReducer = (state, action) => {
  return {
    ...state,
    loading: false,
    failed: true
  }
}

const updateConfigScene: SceneReducer = (state, action) => {
  console.log(action.config)

  return {
    ...state,
    selectedScene: {
      ...state.selectedScene,
      config: action.config
    }
  }
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.DATA_GET_SCENES_REQUEST]: getScenesRequest,
  [Types.DATA_GET_SCENES_REQUEST_SUCCESS]: getScenesRequestSuccess,
  [Types.DATA_GET_SCENES_REQUEST_FAILURE]: getScenesRequestFailure,
  [Types.DATA_SET_SELECTED_SLAVE]: setSelectedSlave,
  [Types.DATA_SET_SELECTED_SCENE]: setSelectedScene,
  [Types.DATA_UPDATE_VALUE_SELECTED_SLAVE]: updateSlaveValue,
  [Types.DATA_INSERT_SLAVE_TO_LIST]: insertSlaveToList,
  [Types.DATA_UPDATE_NAME_SELECTED_SCENE]: updateNameSelectedScene,
  [Types.DATA_UPDATE_AMBIENT_SELECTED_SCENE]: updateAmbientSelectedScene,
  [Types.CLEAR_COMMANDS]: clearCommands,
  [Types.REMOVE_COMMAND]: removeCommand,
  [Types.GET_AND_SET_SLAVE_IR_REQUEST]: getAndSetSlaveIrRequest,
  [Types.GET_AND_SET_SLAVE_IR_REQUEST_SUCCESS]: getAndSetSlaveIrRequestSuccess,
  [Types.GET_AND_SET_SLAVE_IR_REQUEST_FAILURE]: getAndSetSlaveIrRequestFailure,
  [Types.INSERT_SLAVE_IR_TO_LIST]: insertSlaveIrToList,
  [Types.CLEAR_SCENE_STATE]: clearSceneState,
  [Types.ACTIVATE_SCENE]: activateScene,
  [Types.FINISHED_SCENE]: finishedScene,
  [Types.ERROR_EXECUTING_SCENE]: errorExecutingScene,
  [Types.ACTIVE_SCENE_LOADING]: activeSceneLoading,
  [Types.DELETE_SCENE_REQUEST]: deleteSceneRequest,
  [Types.DELETE_SCENE_REQUEST_SUCCESS]: deleteSceneRequestSuccess,
  [Types.DELETE_SCENE_REQUEST_FAILURE]: deleteSceneRequestFailure,
  [Types.CREATE_SCENE_REQUEST]: createSceneRequest,
  [Types.CREATE_SCENE_REQUEST_SUCCESS]: createSceneRequestSuccess,
  [Types.CREATE_SCENE_REQUEST_FAILURE]: createSceneRequestFailure,
  [Types.UPDATE_SCENE_REQUEST]: updateSceneRequest,
  [Types.UPDATE_SCENE_REQUEST_SUCCESS]: updateSceneRequestSuccess,
  [Types.UPDATE_SCENE_REQUEST_FAILURE]: updateSceneRequestFailure,
  [Types.UPDATE_CONFIG_SCENE]: updateConfigScene
})
