const pantone2090c = 'rgb(102, 56, 182)'
const pantone3395c = 'rgb(0, 195, 137)'
const pantone296c = 'rgb(5, 28, 44)'

export const brandPrimary = pantone2090c
export const brandSecondary = pantone3395c
export const brandTertiary = pantone296c

export const colors = {
  purple: pantone2090c,
  green: pantone3395c,
  grey: '#373737',
  lightGrey: '#828282',
  darkGrey: '#373737',
  black: '#000000',
  lightWhite: 'rgba(255, 255, 255, 0.7)',
  white: '#FFFFFF'
}
