import React, { useCallback } from 'react'
import {
  LoginContainer,
  LoginLogo,
  LoginFormArea,
  LoginTextInput,
  LoginEmailInput,
  LoginPasswordInput,
  LoginButton,
  LoginNav
} from '#app/components'
import { View, Text, StyleSheet } from 'react-native'
import { useAppDispatch, useAppSelector, useLoaderSuccess } from '#app/hooks'
import { t } from 'ttag'
import { ScreenProps } from '#app/types'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { fetchUserRegister } from '#app/user'
import { resetLoaderById, selectLoaderById } from '#app/loader'
import { Error } from '#app/components/Utils'
import { useFocusEffect } from '@react-navigation/native'

const Register = ({ navigation, route }: ScreenProps<'Register'>) => {
  const dispatch = useAppDispatch()

  const { control, handleSubmit, setFocus, getValues } = useForm<FormData>({
    defaultValues: route.params,
    resolver: yupResolver(schema)
  })
  const onSubmit = useCallback((data: FormData) => {
    dispatch(fetchUserRegister(data))
  }, [dispatch])
  const onRegister = handleSubmit(onSubmit)

  const onLogin = useCallback(() => {
    navigation.navigate('Login', { email: getValues('email') })
  }, [navigation, getValues])

  const loader = useAppSelector(state => selectLoaderById(state, { id: 'user/register' }))
  const { isLoading, isError, message } = loader
  useFocusEffect(useCallback(() => {
    return () => {
      dispatch(resetLoaderById('user/register'))
    }
  }, [dispatch]))
  useLoaderSuccess(onLogin, loader)

  return (
    <LoginContainer>
      <LoginLogo />

      <LoginFormArea>
        <Controller
          name="name"
          control={control}
          render={({ field: { ref, onChange, onBlur, value }, fieldState: { error } }) =>
            <View>
              {error?.message != null && <Text style={styles.errorText}>{error.message}</Text>}
              <LoginTextInput
                ref={ref}
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                returnKeyType="next"
                onSubmitEditing={() => setFocus('email')}
                placeholder={t`Name`}
                textContentType="name"
                autoCompleteType="name"
                autoCorrect={false}
              />
            </View>
          }
        />
        <Controller
          name="email"
          control={control}
          render={({ field: { ref, onChange, onBlur, value }, fieldState: { error } }) =>
            <View>
              {error?.message != null && <Text style={styles.errorText}>{error.message}</Text>}
              <LoginEmailInput
                ref={ref}
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                returnKeyType="next"
                onSubmitEditing={() => setFocus('password')}
              />
            </View>
          }
        />
        <Controller
          name="password"
          control={control}
          render={({ field: { ref, onChange, onBlur, value }, fieldState: { error } }) =>
            <View>
              {error?.message != null && <Text style={styles.errorText}>{error.message}</Text>}
              <LoginPasswordInput
                ref={ref}
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                returnKeyType="next"
                onSubmitEditing={() => setFocus('passwordConfirm')}
              />
            </View>
          }
        />
        <Controller
          name="passwordConfirm"
          control={control}
          render={({ field: { ref, onChange, onBlur, value }, fieldState: { error } }) =>
            <View>
              {error?.message != null && <Text style={styles.errorText}>{error.message}</Text>}
              <LoginPasswordInput
                ref={ref}
                onChangeText={onChange}
                onBlur={onBlur}
                value={value}
                returnKeyType="done"
                onSubmitEditing={onRegister}
              />
            </View>
          }
        />
      </LoginFormArea>

      <LoginFormArea>
        {isError && <Error>{message}</Error>}
      </LoginFormArea>

      <LoginButton title={t`Register`} onPress={onRegister} isLoading={isLoading} />

      <LoginNav onPress={onLogin}>
        {t`Already have an account? Log in!`}
      </LoginNav>
    </LoginContainer>
  )
}

export default Register

const styles = StyleSheet.create({
  errorText: {
    color: '#C45A5A'
  }
})

const schema: Yup.SchemaOf<FormData> = Yup.object({
  name: Yup.string()
    .required(),
  email: Yup.string()
    .required()
    .email(),
  password: Yup.string()
    .required()
    .min(8),
  passwordConfirm: Yup.string()
    .required()
    .oneOf([Yup.ref('password')])
}).required()

interface FormData {
  name: string
  email: string
  password: string
  passwordConfirm: string
}
