import React from 'react'
import { TextInput, TextInputProps } from 'react-native'
import LoginTextInput from './LoginTextInput'

const LoginEmailInput = React.forwardRef<TextInput, TextInputProps>((props, ref) =>
  <LoginTextInput
    ref={ref}
    autoCapitalize="none"
    autoCorrect={false}
    keyboardType="email-address"
    placeholder="Email"
    textContentType="emailAddress"
    autoCompleteType="email"
    {...props}
  />
)

export default LoginEmailInput
