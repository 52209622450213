import React from 'react'
import { StyleSheet, View } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'

interface Props {
  name: string
  size: number
  colorIcon: string
  colorBg: string
}

export default function Icon ({ name, size, colorIcon, colorBg }: Props) {
  return (
    <View style={[styles.btnIcon, { backgroundColor: colorBg }]}>
      <MaterialCommunityIcons name={name} size={size} color={colorIcon} />
    </View>
  )
}

const styles = StyleSheet.create({
  btnIcon: {
    marginRight: 10,
    height: 35,
    width: 35,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
