
import React, { useCallback } from 'react'
import {
  LoginContainer,
  LoginLogo,
  LoginFormArea,
  LoginEmailInput,
  LoginPasswordInput,
  LoginButton,
  LoginNav
} from '#app/components'
import { Text, StyleSheet } from 'react-native'
import { useAppDispatch, useAppSelector } from '../hooks'
import { t } from 'ttag'
import { fetchUserAuth } from '#app/user'
import { resetLoaderById, selectLoaderById } from '#app/loader'
import { Error } from '#app/components/Utils'
import { ScreenProps } from '#app/types'
import { useForm, Controller } from 'react-hook-form'
import { useFocusEffect } from '@react-navigation/native'

const Login = ({ navigation, route }: ScreenProps<'Login'>) => {
  const dispatch = useAppDispatch()

  const { isError, isLoading } = useAppSelector(state => selectLoaderById(state, { id: 'user/auth' }))
  useFocusEffect(useCallback(() => {
    return () => {
      dispatch(resetLoaderById('user/auth'))
    }
  }, [dispatch]))

  const { control, handleSubmit, setFocus, getValues } = useForm<FormData>({
    defaultValues: route.params
  })
  const onSubmit = useCallback((data: FormData) => {
    dispatch(fetchUserAuth(data))
  }, [dispatch])
  const onLogin = handleSubmit(onSubmit)

  const onForgotPassword = useCallback(() => {
    navigation.navigate('ResetPassword', { email: getValues('email') })
  }, [navigation, getValues])

  const onRegister = useCallback(() => {
    navigation.navigate('Register', { email: getValues('email') })
  }, [navigation, getValues])

  return (
    <LoginContainer>
      <LoginLogo />

      <LoginFormArea>
        <Controller
          name="email"
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <LoginEmailInput
              ref={ref}
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              returnKeyType="next"
              onSubmitEditing={() => setFocus('password')}
            />
          }
        />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, onChange, onBlur, value } }) =>
            <LoginPasswordInput
              ref={ref}
              onChangeText={onChange}
              onBlur={onBlur}
              value={value}
              returnKeyType="done"
              onSubmitEditing={onLogin}
            />
          }
        />
        <Text style={styles.textPassword} onPress={onForgotPassword}>
          {t`I forgot my password.`}
        </Text>
      </LoginFormArea>

      <LoginFormArea>
        {isError && <Error>{t`Wrong password or email.`}</Error>}
      </LoginFormArea>

      <LoginButton title={t`Log in`} isLoading={isLoading} onPress={onLogin}/>

      <LoginNav onPress={onRegister}>
        {t`Don't have an account? Register now!`}
      </LoginNav>
    </LoginContainer>
  )
}

export default Login

const styles = StyleSheet.create({
  textPassword: {
    color: '#fff',
    fontSize: 15
  }
})

interface FormData {
  email: string
  password: string
}
