import React, { useCallback, useState } from 'react'
import { View, Dimensions, ScrollView } from 'react-native'
import { DraggableGrid } from 'react-native-draggable-grid'
import Ambient from './Ambient'

export function calculateButtonSize () {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const numColumns = getNumColumns()
  const screenWidth = Dimensions.get('window').width
  const size = (screenWidth / numColumns) - 20

  if (size > 160) {
    return 160
  }

  return size
}

export function getNumColumns (width: any) {
  const screenWidth = Dimensions.get('window').width

  if (screenWidth <= 385) {
    return 2
  } else if (screenWidth > 385 && screenWidth <= 600) {
    return 3
  } else if (screenWidth > 600 && screenWidth <= 1000) {
    return 4
  } else {
    return Math.floor(screenWidth / 180)
  }
}

const renderItem = (item: any) => {
  return (
    <View>
      <Ambient id={item.id} disabled />
    </View>
  )
}

// @ts-expect-error ts-migrate(7031) FIXME: Binding element 'onDragRelease' implicitly has an ... Remove this comment to see the full error message
const DraggableAmbientList = ({ onDragRelease, data }) => {
  const numColumns = getNumColumns(Dimensions.get('window').width)

  const [scrollEnable, setScrollEnable] = useState(true)

  const onDragStart = useCallback(() => setScrollEnable(false), [])
  const onDragReleaseWrapped = useCallback((newData) => {
    setScrollEnable(true)
    onDragRelease(newData)
  }, [onDragRelease])

  return (
    <ScrollView scrollEnabled={scrollEnable} nestedScrollEnabled={scrollEnable}>
      <DraggableGrid
        numColumns={numColumns}
        renderItem={renderItem}
        data={data}
        style={{ alignItems: 'center', padding: 20 }}
        onDragStart={onDragStart}
        onDragRelease={onDragReleaseWrapped}
      />
    </ScrollView>
  )
}

// export default React.memo(DraggableAmbientList)
export default DraggableAmbientList
