import { createReducer, createActions } from 'reduxsauce'
import { without } from 'lodash'
import { AnyAction } from 'redux'

const { Types, Creators } = createActions({
  clearWsState: null,
  wsConnectionRequest: ['payload'],
  wsConnectionCloseRequest: null,
  wsConnectionConnected: null,
  wsConnectionDisconnected: null,
  wsConnectionUnresponsive: null,
  wsConnectionSendMessage: ['payload'],
  wsMessageAuthReq: null,
  wsMessageAuthSuccess: null,
  wsConnectionTimeout: ['payload'],
  wsRequestSuccess: ['payload']
})

export const INITIAL_STATE = {
  status: 'disconnected' as WSStatus,
  slavesWaiting: [] as number[]
}

export default Creators
export type WSState = typeof INITIAL_STATE
export type WSReducer = (state: WSState, action: AnyAction) => WSState

/**
  disconnected -> connecting

  connecting -> connected

  connected | authenticated -> unauthenticated

  unauthenticated -> authenticated

  authenticated -> disconnecting

  \* -> disconnected

  connected | unauthenticated | authenticated -> unresponsive
*/
// export type SocketStatus = 'disconnected' | 'connecting' | 'connected' | 'disconnecting'
// export type AuthStatus = 'unauthenticated' | 'authenticated'
// export type ResponsivenessStatus = 'responsive' | 'unresponsive'
export type WSStatus = 'disconnected' | 'connecting' | 'connected' | 'unauthenticated' | 'authenticated' | 'disconnecting' | 'unresponsive'

const clearWsState: WSReducer = (state, action) => {
  return { ...INITIAL_STATE }
}

const wsConnectionRequest: WSReducer = (state, action) => {
  return { ...state, status: 'connecting' }
}

const wsConnectionCloseRequest: WSReducer = (state, action) => {
  return { ...state, status: 'disconnecting' }
}

const wsConnectionConnected: WSReducer = (state, action) => {
  return { ...state, status: 'connected' }
}

const wsConnectionDisconnected: WSReducer = (state, action) => {
  return { ...state, status: 'disconnected' }
}

const wsConnectionUnresponsive: WSReducer = (state, action) => {
  return { ...state, status: 'unresponsive' }
}

const wsMessageAuthReq: WSReducer = (state, action) => {
  return { ...state, status: 'unauthenticated' }
}

const wsMessageAuthSuccess: WSReducer = (state, action) => {
  return { ...state, status: 'authenticated' }
}

const wsConnectionTimeout: WSReducer = (state, action) => {
  const { payload } = action
  const newSlavesWaiting = without(state.slavesWaiting, payload.id)

  return {
    ...state,
    slavesWaiting: newSlavesWaiting
  }
}

const wsRequestSuccess: WSReducer = (state, action) => {
  const { payload } = action
  const newSlavesWaiting = without(state.slavesWaiting, payload.id)

  return {
    ...state,
    slavesWaiting: newSlavesWaiting
  }
}

const wsConnectionSendMessage: WSReducer = (state, action) => {
  const { payload } = action

  if ([
    'light_control',
    'transmit',
    'check_potency',
    'check_current',
    'check_voltage'
  ].includes(payload.command) && !state.slavesWaiting.includes(payload.id)) {
    return {
      ...state,
      slavesWaiting: [...state.slavesWaiting, payload.id]
    }
  }

  return state
}

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CLEAR_WS_STATE]: clearWsState,
  [Types.WS_CONNECTION_REQUEST]: wsConnectionRequest,
  [Types.WS_CONNECTION_CLOSE_REQUEST]: wsConnectionCloseRequest,
  [Types.WS_CONNECTION_CONNECTED]: wsConnectionConnected,
  [Types.WS_CONNECTION_DISCONNECTED]: wsConnectionDisconnected,
  [Types.WS_CONNECTION_UNRESPONSIVE]: wsConnectionUnresponsive,
  [Types.WS_CONNECTION_SEND_MESSAGE]: wsConnectionSendMessage,
  [Types.WS_MESSAGE_AUTH_REQ]: wsMessageAuthReq,
  [Types.WS_MESSAGE_AUTH_SUCCESS]: wsMessageAuthSuccess,
  [Types.WS_CONNECTION_TIMEOUT]: wsConnectionTimeout,
  [Types.WS_REQUEST_SUCCESS]: wsRequestSuccess
})
