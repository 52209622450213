import React, { useState, useEffect } from 'react'
import { TextInput, View, TouchableOpacity } from 'react-native'
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons'

export default function Search ({
  placeholder,
  placeholderTextColor,
  valueTextColor,
  iconColor,
  onChangeText,
  onTrashPress,
  style
}: any) {
  const [value, setValue] = useState('')
  const onChangeTextWrapped = (text: string) => {
    setValue(text)
    onChangeText(text)
  }
  const onTrashPressWrapped = () => {
    setValue('')
    onTrashPress()
  }
  const [icon, setIcon] = useState('magnify')

  useEffect(() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    value && value.length >= 1 ? setIcon('close-circle') : setIcon('magnify')
  }, [value])

  return (
    <View style={style}>
      <TextInput
        style={{ flex: 1, color: valueTextColor }}
        placeholderTextColor={placeholderTextColor}
        placeholder={placeholder}
        autoCapitalize="none"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        autocomplete="off"
        value={value}
        autoCorrect={false}
        selectTextOnFocus
        onChangeText={onChangeTextWrapped}
      />
      <View style={{ justifyContent: 'center' }}>
        {icon === 'magnify'
          ? (
            <MaterialCommunityIcons name={icon} size={20} color={iconColor} />
            )
          : (
            <TouchableOpacity onPress={onTrashPressWrapped}>
              <MaterialCommunityIcons name={icon} size={20} color={iconColor} />
            </TouchableOpacity>)}
      </View>
    </View>
  )
}
